/*
Author       : Dreamguys
Template Name: KofeJob - Bootstrap Template
Version      : 1.0
*/

/*KhliL:#01243*/

/*============================
 [Table of CSS]
 
1. General
2. Table
3. Bootstrap Classes
4. Avatar
5. Nav Tabs
6. Focus Label
7. Components
8. Header
8. Header-2
8. Header-3
8. Header-4
8. Header-5
9. Mobile menu
10. Home 
10. Home Two
10. Home Three
10. Home Four
10. Home Five
11. Login
12. Select2
13. Search
14. Profile
15. Profile Settings
16. Calendar
17. Profile List
18. User Details
19. Range
21. Customer Profile
22. Blog 
23. Blog Details
24. Blog Grid
25. Terms and Conditions
26. Category Detail
27. Chat
28. Outgoing Call
39. Incoming Call
30. Profile-resume-edit
31. Profile-details-view
32. Freelancer
33. Employer
34. Membership
35. Freelancer Proposals
36. Invoices
37. Footer
37. Footer-2
37. Footer-3
37. Footer-4
37. Footer-5
38. Responsive
 
 ========================================*/

/*-----------------
	1. General
-----------------------*/

@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;500;600;700;800;900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

html {
    height: 100%;
}
@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
	src: local('Material Icons'),
	local('MaterialIcons-Regular'),
	url(../fonts/MaterialIcons-Regular.woff2) format('woff2'),
	url(../fonts/MaterialIcons-Regular.woff) format('woff'),
	url(../fonts/MaterialIcons-Regular.ttf) format('truetype');
}
.material-icons {
	font-family: 'Material Icons';
	font-weight: normal;
	font-style: normal;
	font-size: inherit;
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
    vertical-align: middle;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: 'liga';
}
body {
	background-color: #ffffff;
	color: #1B1B1B;
	font-family: "Lato", sans-serif;
	font-size: 16px;
    height: 100%;
	overflow-x: hidden;
}
.bg-one {
	background-color: #fdfdfd;
}
.bg-one .container, 
.bg-two .container, 
.bg-three .container, 
.bg-four .container, 
.bg-five .container {
    max-width: 1200px;
}
h1, h2, h3, h4, h5, h6 {
	font-family: 'Lato', sans-serif;
	color: #1B1B1B;
}
.h1, h1 {
    font-size: 32px;
	font-weight: bold;
}
.h2, h2 {
    font-size: 28px;
	font-weight: bold;
}
.h3, h3 {
    font-size: 24px;
	font-weight: 600;
}
.h4, h4 {
    font-size: 20px;
	font-weight: 600;
}
.h5, h5 {
    font-size: 18px;
	font-weight: 600;
}
.h6, h6 {
    font-size: 16px;
	font-weight: 500;
}
label {
	color: #2E2E2E;
}
a {
    color: #1B1B1B;
    text-decoration: none;
}
a:hover {
	color: #71d3a9;
}
a:hover,
a:active,
a:focus {
	outline: none;
	text-decoration: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
input,
button,
a {
	transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	-o-transition: all 0.4s ease;
	-ms-transition: all 0.4s ease;
	-webkit-transition: all 0.4s ease;
}
button:focus {
	outline: 0;
}
input[type=text],
input[type=password] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.dropdown-menu {
    color: #161c2d;
	font-size: 14px;
    border: 1px solid #eff2f7;
    background-color: #fff;
    box-shadow: 0 0 1.25rem rgba(31,45,61,.08);
}
.btn.focus, .btn:focus {
	box-shadow: inherit;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #71d3a9;
    border-color: #71d3a9;
}
.btn-primary {
    color: #fff;
    background-color: #71d3a9;
    border-color: #71d3a9;
}
.form-control {
    border-color: #E7E8EA;
    color: #353535;
    font-size: 15px;
    min-height: 40px;
    padding: 6px 15px;
	border-radius: 5px;
}
.nav-link {
	font-weight: bold;
    font-size: 14px;
    color: #6A1B9A;
}
.form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}
.form-row>.col, .form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
}
.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
}
.dashboard-page .content > .container-fluid {
	padding-left: 30px;
	padding-right: 30px;
}

/*-----------------
	2. Table
-----------------------*/

.table {
	color: #161c2d;
	max-width: 100%;
	margin-bottom: 0;
	width: 100%;
	--bs-table-bg : transparent;
	border: 1px solid #CED4E6;
}
.table.datatable {
	border: 0;
}
.table>:not(:first-child) {
    border-top: none;
}
.table>:not(caption)>*>* {
	box-shadow: unset;
}
.table-striped > tbody > tr:nth-of-type(2n+1) {
	background-color: #f8f9fa;
}
.table.no-border > tbody > tr > td,
.table > tbody > tr > th,
.table.no-border > tfoot > tr > td,
.table.no-border > tfoot > tr > th,
.table.no-border > thead > tr > td,
.table.no-border > thead > tr > th {
	border-top: 0;
	padding: 10px 8px;
}
.table-nowrap td,
.table-nowrap th {
	white-space: nowrap
}
.table.dataTable {
	border-collapse: collapse !important;
}
.table td {
	color: #6B7385;
	font-size: 14px;
}
.plan-billing .table td a {
	color: #6B7385;
}
.plan-billing .table td.invoice-td {
	padding: 6px 0.75rem;;
}
table.table td h2 {
	display: inline-block;
	font-size: inherit;
	font-weight: 400;
	margin: 0;
	padding: 0;
	vertical-align: middle;
}
table.table td h2.table-avatar {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
	white-space: nowrap;
}
table.table td h2.table-avatar .profile-name {
	font-weight: 600;
    font-size: 15px;
    color: #000000;
}
table.table td h2 a {
	color: #161c2d;
}
table.table td h2 a:hover {
	color: #E65425;
}
table.table td h2 span {
	color: #6e727d;
	display: block;
	font-size: 12px;
	margin-top: 3px;
}
.table thead {
	border-bottom: 1px solid #CED4E6;
	background: #F9F9F9;
}
.table td, .table th {
	padding: 1rem 0.75rem;
}
/* .table thead tr th {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
} */
.table tbody tr {
	border-bottom: 1px solid #CED4E6;
}
/* .table tbody tr:last-child {
	border-color: transparent;
} */
.table.table-center td, .table-center td, .table-center th, .table.table-center th {
	vertical-align: middle;
	font-size: 15px;
}
.table-hover tbody tr:hover {
	background-color: #f7f7f7;
}
.table-hover tbody tr:hover td {
	color: #474648;
}
.table tr td:first-child, .table tr th:first-child {
    padding-left: 20px;
}
.table tr td:last-child, .table tr th:last-child {
	padding-right: 20px;
}
.table-striped thead tr {
	border-color: transparent;
}
.table-striped tbody tr {
	border-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
	background-color: rgba(255, 255, 255, 0.3);
}
.table-striped tbody tr:nth-of-type(odd) {
	background-color: rgba(235, 235, 235, 0.4);
}
.table-bordered {
	border: 1px solid rgba(0, 0, 0, 0.05) !important;
}
.table-bordered th,
.table-bordered td {
	border-color: rgba(0, 0, 0, 0.05);
}
.card-table .card-body {
    padding: 0;
}
.card-table .card-body .table > thead > tr > th {
    border-top: 0;
	font-size: 14px;
}
.card-table .table tr td:first-child,
.card-table .table tr th:first-child {
    padding-left: 1.5rem;
}
.card-table .table tr td:last-child,
.card-table .table tr th:last-child {
    padding-right: 1.5rem;
}
.card-table .table td, .card-table .table th {
    border-top: 1px solid #e2e5e8;
    padding: 1rem 0.75rem;
    white-space: nowrap;
}

/*-----------------
	3. Bootstrap Classes
-----------------------*/

.btn.focus, .btn:focus {
    box-shadow: unset;
}
.btn-block {
	width: 100%;
}
.btn-link {
	text-decoration: none;
}
.btn-link:hover {
	text-decoration: underline;
}
.btn-white {
    background-color: #fff;
    border-color: #ebebeb;
}
.btn-white:hover {
	color: #fff;
	background-color: #71d3a9;
	border-color: #71d3a9;
}
.btn.btn-rounded {
	border-radius: 50px;
}
.btn {
	line-height: normal;
}
.bg-primary,
.badge-primary {
	background-color: #71d3a9 !important;
}
a.bg-primary:focus, 
a.bg-primary:hover, 
button.bg-primary:focus, 
button.bg-primary:hover {
    background-color: #71d3a9!important;
}
.bg-success,
.badge-success {
	background-color: #00e65b !important;
}
a.bg-success:focus, 
a.bg-success:hover, 
button.bg-success:focus, 
button.bg-success:hover {
    background-color: #00cc52 !important;
}
.bg-info,
.badge-info {
	background-color: #71d3a9 !important;
}
a.bg-info:focus, 
a.bg-info:hover, 
button.bg-info:focus, 
button.bg-info:hover {
    background-color: #028ee1 !important;
}
.bg-warning,
.badge-warning {
	background-color: #ffbc34 !important;
}
a.bg-warning:focus, 
a.bg-warning:hover, 
button.bg-warning:focus, 
button.bg-warning:hover {
    background-color: #e9ab2e !important;
}
.bg-danger,
.badge-danger {
	background-color: #71d3a9 !important;
}
a.bg-danger:focus, 
a.bg-danger:hover, 
button.bg-danger:focus, 
button.bg-danger:hover {
    background-color: #71d3a9 !important;
}
.bg-white {
	background-color: #fff;
}
.bg-purple,
.badge-purple {
    background-color: #9368e9 !important;
}
.text-primary,
.dropdown-menu > li > a.text-primary {
	color: #71d3a9 !important;
}
.text-success,
.dropdown-menu > li > a.text-success {
	color: #00cc52 !important;
}
.text-danger,
.dropdown-menu > li > a.text-danger {
	color: #71d3a9 !important;
}
.text-info,
.dropdown-menu > li > a.text-info {
	color: #71d3a9 !important;
}
.text-warning,
.dropdown-menu > li > a.text-warning {
	color: #ffbc34 !important;
}
.text-purple,
.dropdown-menu > li > a.text-purple {
	color: #7460ee !important;
}
.text-muted {
    color: #6B7385 !important;
}
.btn-primary {
	background-color: #71d3a9;
	border: 1px solid #71d3a9;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
	background-color: #fff;
	border: 1px solid #71d3a9;
	box-shadow: unset;
	color: #71d3a9;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
	background-color: #71d3a9;
	border: 1px solid #71d3a9;
	color: #fff !important;
}
.btn-primary.active:not(:disabled):not(.disabled),
.btn-primary:active:not(:disabled):not(.disabled),
.show > .btn-primary.dropdown-toggle {
	background-color: #71d3a9;
	border-color: #71d3a9;
	color: #fff;
}
.btn-primary.active:focus:not(:disabled):not(.disabled), 
.btn-primary:active:focus:not(:disabled):not(.disabled), 
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-primary.disabled, .btn-primary:disabled {
    background-color: #09e5ab;
    border-color: #09e5ab;
    color: #fff;
}
.btn-secondary.active:focus:not(:disabled):not(.disabled), 
.btn-secondary:active:focus:not(:disabled):not(.disabled), 
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success {
	background-color: #00e65b;
	border: 1px solid #00e65b
}
.btn-success:hover,
.btn-success:focus,
.btn-success.active,
.btn-success:active,
.open > .dropdown-toggle.btn-success {
	background-color: #00cc52;
	border: 1px solid #00cc52;
	color: #fff;
}
.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open > .dropdown-toggle.btn-success.focus,
.open > .dropdown-toggle.btn-success:focus,
.open > .dropdown-toggle.btn-success:hover {
	background-color: #00cc52;
	border: 1px solid #00cc52
}
.btn-success.active:not(:disabled):not(.disabled),
.btn-success:active:not(:disabled):not(.disabled),
.show > .btn-success.dropdown-toggle {
	background-color: #00cc52;
	border-color: #00cc52;
	color: #fff;
}
.btn-success.active:focus:not(:disabled):not(.disabled), 
.btn-success:active:focus:not(:disabled):not(.disabled), 
.show > .btn-success.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-success.disabled, .btn-success:disabled {
    background-color: #00e65b;
    border-color: #00e65b;
    color: #fff;
}
.btn-info {
	background-color: #009efb;
	border: 1px solid #009efb
}
.btn-info:hover,
.btn-info:focus,
.btn-info.active,
.btn-info:active,
.open > .dropdown-toggle.btn-info {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info.focus:active,
.btn-info:active:focus,
.btn-info:active:hover,
.open > .dropdown-toggle.btn-info.focus,
.open > .dropdown-toggle.btn-info:focus,
.open > .dropdown-toggle.btn-info:hover {
	background-color: #028ee1;
	border: 1px solid #028ee1
}
.btn-info.active:not(:disabled):not(.disabled),
.btn-info:active:not(:disabled):not(.disabled),
.show > .btn-info.dropdown-toggle {
	background-color: #028ee1;
	border-color: #028ee1;
	color: #fff;
}
.btn-info.active:focus:not(:disabled):not(.disabled), 
.btn-info:active:focus:not(:disabled):not(.disabled), 
.show > .btn-info.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-info.disabled, .btn-info:disabled {
    background-color: #009efb;
    border-color: #009efb;
    color: #fff;
}
.btn-warning {
	background-color: #ffbc34;
	border: 1px solid #ffbc34
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning.active,
.btn-warning:active,
.open > .dropdown-toggle.btn-warning {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open > .dropdown-toggle.btn-warning.focus,
.open > .dropdown-toggle.btn-warning:focus,
.open > .dropdown-toggle.btn-warning:hover {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e
}
.btn-warning.active:not(:disabled):not(.disabled),
.btn-warning:active:not(:disabled):not(.disabled),
.show > .btn-warning.dropdown-toggle {
	background-color: #e9ab2e;
	border-color: #e9ab2e;
	color: #fff;
}
.btn-warning.active:focus:not(:disabled):not(.disabled), 
.btn-warning:active:focus:not(:disabled):not(.disabled), 
.show > .btn-warning.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffbc34;
    border-color: #ffbc34;
    color: #fff;
}
.btn-danger {
	background-color: #71d3a9;
	border: 1px solid #71d3a9;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger.active,
.btn-danger:active,
.open > .dropdown-toggle.btn-danger {
	background-color: #71d3a9;
	border: 1px solid #71d3a9;
}
.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open > .dropdown-toggle.btn-danger.focus,
.open > .dropdown-toggle.btn-danger:focus,
.open > .dropdown-toggle.btn-danger:hover {
	background-color: #71d3a9;
	border: 1px solid #71d3a9;
}
.btn-danger.active:not(:disabled):not(.disabled),
.btn-danger:active:not(:disabled):not(.disabled),
.show > .btn-danger.dropdown-toggle {
	background-color: #71d3a9;
	border-color: #71d3a9;
	color: #fff;
}
.btn-danger.active:focus:not(:disabled):not(.disabled), 
.btn-danger:active:focus:not(:disabled):not(.disabled), 
.show > .btn-danger.dropdown-toggle:focus {
	box-shadow: unset;
}
.btn-danger.disabled, .btn-danger:disabled {
    background-color: #71d3a9;
    border-color: #71d3a9;
    color: #fff;
}
.btn-light.active:focus:not(:disabled):not(.disabled), 
.btn-light:active:focus:not(:disabled):not(.disabled), 
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-dark.active:focus:not(:disabled):not(.disabled), 
.btn-dark:active:focus:not(:disabled):not(.disabled), 
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: unset;
}
.btn-outline-primary {
	color: #71d3a9 !important;
	border-color: #71d3a9 !important;
	padding: 11px 24px;
	border-radius: 6px;
	transition: all 0.5s;
	font-weight: 600;
}
.btn-outline-primary:hover {
	background-color: #71d3a9 !important;
	border-color: #71d3a9 !important;
	color: #fff !important;
}
.btn-outline-primary:focus, 
.btn-outline-primary.focus {
	box-shadow: none;
}
.btn-outline-primary.disabled, 
.btn-outline-primary:disabled {
	color: #71d3a9;
	background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, 
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	background-color: #71d3a9;
	border-color: #71d3a9;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, 
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-success {
	color: #00e65b;
	border-color: #00e65b;
}
.btn-outline-success:hover {
	background-color: #00e65b;
	border-color: #00e65b;
}
.btn-outline-success:focus, .btn-outline-success.focus {
	box-shadow: none;
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
	color: #00e65b;
	background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, 
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
	background-color: #00e65b;
	border-color: #00e65b;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, 
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-info {
	color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:hover {
	color: #fff;
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:focus, .btn-outline-info.focus {
	box-shadow: none;
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
	background-color: transparent;
	color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active, 
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
	background-color: #009efb;
	border-color: #009efb;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, 
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-warning {
	color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:hover {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
	box-shadow: none;
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
	background-color: transparent;
	color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, 
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
	color: #212529;
	background-color: #ffbc34;
	border-color: #ffbc34;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, 
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-danger {
	color: #71d3a9;
	border-color: #71d3a9;
}
.btn-outline-danger:hover {
	color: #fff;
	background-color: #71d3a9;
	border-color: #71d3a9;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
	box-shadow: none;
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
	background-color: transparent;
	color: #71d3a9;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, 
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
	background-color: #71d3a9;
	border-color: #71d3a9;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, 
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
	box-shadow: none;
}
.btn-outline-light {
    color: #ababab;
    border-color: #e6e6e6;
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #ababab;
}
.add-btn {
    max-width: 140px;
}
.pagination > .active > a, 
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
	background-color: #71d3a9;
	border-color: #71d3a9;
}
.pagination > li > a, 
.pagination > li > span {
	color: #71d3a9;
}
.page-link:hover {
	color: #71d3a9;
}
.page-link:focus {
	box-shadow: unset;
}
.page-item.active .page-link {
	background-color: #71d3a9;
	border-color: #71d3a9;
}
.card {
    border: 1px solid #e8e8e8;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
    margin-bottom: 24px;
}
.card-body {
	padding: 1.5rem;
}
.card-header {
	border-bottom: 1px solid #f0f0f0;
	padding: 1rem 1.5rem;
}
.card-footer {
	background-color: #fff;
	border-top: 1px solid #f0f0f0;
	padding: 1rem 1.5rem;
}
.card .card-header {
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
}
.card .card-header .card-title {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 22px;
	color: #71d3a9;
}
.btn-light {
	border-color: #e6e6e6;
	color: #a6a6a6;
}
.btn-light:hover {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}
.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
	background-color: #71d3a9;
	text-shadow: unset;
}
.bootstrap-datetimepicker-widget table td.today::before{
	border-bottom-color: #71d3a9;
}
.bg-info-light {
	background-color: rgba(2, 182, 179,0.12) !important;
	color: #1db9aa !important;
}
.bg-primary-light {
	background-color: rgba(17, 148, 247,0.12) !important;
	color: #2196f3 !important;
}
.bg-danger-light {
	background-color: rgba(242, 17, 54,0.12) !important;
	color: #e63c3c !important;
}
.bg-warning-light {
	background-color: rgba(255, 152, 0,0.12) !important;
	color: #f39c12 !important;
}
.bg-success-light {
	background-color: rgba(15, 183, 107,0.12) !important;
	color: #26af48 !important;
}
.bg-purple-light {
	background-color: rgba(197, 128, 255,0.12) !important;
	color: #c580ff !important;
}
.bg-default-light {
	background-color: rgba(40, 52, 71,0.12) !important;
	color: #283447 !important;
}
.text-xs {
	font-size: .75rem !important;
}
.text-sm {
	font-size: .875rem !important;
}
.text-lg {
	font-size: 1.25rem !important;
}
.text-xl {
	font-size: 1.5rem !important;
}
.form-control:focus {
	border-color: #C7C8CA;
	box-shadow: none;
	outline: 0 none;
}
label {
    display: inline-block;
    margin-bottom: 0.5rem;
	font-weight: 500;
	font-size: 15px;
}
.input-block {
	margin-bottom: 1.25rem;
}
.form-check {
	margin-bottom: 1.25rem;
}
.slider-btn{
    background: #09e5ab;
    color: #fff;
    border: 1px solid #09e5ab; 
    padding: 8px 16px !important;
	transition: .5s;
}
.slider-btn:hover{
    background: #fff;
    color: #09e5ab;
    border: 1px solid #fff; 
    padding: 8px 16px !important; 
}

/*-----------------
	4. Avatar
-----------------------*/

.avatar {
	position: relative;
	display: inline-block;
	width: 3rem;
	height: 3rem
}
.avatar > img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}
.avatar-title {
	width: 100%;
	height: 100%;
	background-color: #E65425;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.avatar-away::before,
.avatar-offline::before,
.avatar-online::before {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 25%;
	height: 25%;
	border-radius: 50%;
	content: '';
	border: 2px solid #fff;
}
.avatar-online::before {
	background-color: #00e65b;
}
.avatar-offline::before {
	background-color: #ff0100;
}
.avatar-away::before {
	background-color: #ffbc34;
}
.avatar .border {
	border-width: 3px !important;
}
.avatar .rounded {
	border-radius: 6px !important;
}
.avatar .avatar-title {
	font-size: 18px;
}
.avatar-xs {
	width: 1.65rem;
	height: 1.65rem;
}
.avatar-xs .border {
	border-width: 2px !important;
}
.avatar-xs .rounded {
	border-radius: 4px !important;
}
.avatar-xs .avatar-title {
	font-size: 12px;
}
.avatar-xs.avatar-away::before,
.avatar-xs.avatar-offline::before,
.avatar-xs.avatar-online::before {
	border-width: 1px;
}
.avatar-sm {
	width: 2.5rem;
	height: 2.5rem;
}
.avatar-sm .border {
	border-width: 3px !important;
}
.avatar-sm .rounded {
	border-radius: 4px !important;
}
.avatar-sm .avatar-title {
	font-size: 15px;
}
.avatar-sm.avatar-away::before,
.avatar-sm.avatar-offline::before,
.avatar-sm.avatar-online::before {
	border-width: 2px;
}
.avatar-md {
	width: 3.125rem;
	height: 3.125rem;
}
.avatar-lg {
	width: 4.75rem;
	height: 3.75rem;
}
.avatar-lg .border {
	border-width: 3px !important;
}
.avatar-lg .rounded {
	border-radius: 8px !important;
}
.avatar-lg .avatar-title {
	font-size: 24px;
}
.avatar-lg.avatar-away::before,
.avatar-lg.avatar-offline::before,
.avatar-lg.avatar-online::before {
	border-width: 3px;
}
.avatar-xl {
	width: 5rem;
	height: 5rem;
}
.avatar-xl .border {
	border-width: 4px !important;
}
.avatar-xl .rounded {
	border-radius: 8px !important;
}
.avatar-xl .avatar-title {
	font-size: 28px;
}
.avatar-xl.avatar-away::before,
.avatar-xl.avatar-offline::before,
.avatar-xl.avatar-online::before {
	border-width: 4px;
}

.avatar-xxl {
	width: 5.125rem;
	height: 5.125rem;
}
.avatar-xxl .border {
	border-width: 6px !important;
}
.avatar-xxl .rounded {
	border-radius: 8px !important;
}
.avatar-xxl .avatar-title {
	font-size: 30px;
}
.avatar-xxl.avatar-away::before,
.avatar-xxl.avatar-offline::before,
.avatar-xxl.avatar-online::before {
	border-width: 4px;
}
.avatar-group {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.avatar-group .avatar + .avatar {
	margin-left: -.75rem;
}
.avatar-group .avatar-xs + .avatar-xs {
	margin-left: -.40625rem;
}
.avatar-group .avatar-sm+.avatar-sm {
	margin-left: -.625rem;
}
.avatar-group .avatar-lg + .avatar-lg {
	margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
	margin-left: -1.28125rem;
}
.avatar-group .avatar:hover {
	z-index: 1;
}

/*-----------------
	5. Nav Tabs
-----------------------*/

.nav-tabs {
	border-bottom: 1px solid #f0f0f0;
}
.card-header-tabs {
    border-bottom: 0;
}
.nav-tabs > li > a {
	margin-right: 0;
	color: #888;
	border-radius: 0;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
	border-color: transparent;
	color: #161c2d;
}
.nav-tabs.nav-tabs-solid > li > a {
	color: #161c2d;
}
.nav-tabs.nav-tabs-solid > .active > a,
.nav-tabs.nav-tabs-solid > .active > a:hover,
.nav-tabs.nav-tabs-solid > .active > a:focus {
	background-color: #E65425;
	border-color: #E65425;
	color: #fff;
}
.tab-content {
	padding-top: 20px;
}
.nav-tabs .nav-link {
	border-radius: 0;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    background-color: #eee;
    border-color: transparent;
	color: #161c2d;
}
.nav-tabs.nav-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.nav-tabs.nav-justified > li > a:hover,
.nav-tabs.nav-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs.nav-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a {
	color: #161c2d;
}
.nav-tabs.nav-tabs-solid > li > a.active,
.nav-tabs.nav-tabs-solid > li > a.active:hover,
.nav-tabs.nav-tabs-solid > li > a.active:focus {
	background-color: #fff;
	border-color: #fff;
	color: #E65425;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
	border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
	border-radius: 50px;
}
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover,
.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
	border-radius: 50px;
}
.nav-tabs-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.nav-tabs-justified > li > a:hover,
.nav-tabs-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.nav-tabs-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.nav-tabs.nav-justified.nav-tabs-top > li > a,
.nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li {
	margin-bottom: 0;
}
.nav-tabs.nav-tabs-top > li > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.nav-tabs.nav-tabs-top > li.open > a,
.nav-tabs.nav-tabs-top > li > a:hover,
.nav-tabs.nav-tabs-top > li > a:focus {
	border-top-color: #ddd;
}
.nav-tabs.nav-tabs-top > li+li > a {
	margin-left: 1px;
}
.nav-tabs.nav-tabs-top > li > a.active,
.nav-tabs.nav-tabs-top > li > a.active:hover,
.nav-tabs.nav-tabs-top > li > a.active:focus {
	border-top-color: #71d3a9;
}
.nav-tabs.nav-tabs-bottom > li > a.active, 
.nav-tabs.nav-tabs-bottom > li > a.active:hover, 
.nav-tabs.nav-tabs-bottom > li > a.active:focus {
	border-bottom-width: 2px;
	border-color: transparent;
	border-bottom-color: #71d3a9;
	background-color: transparent;
	transition: none 0s ease 0s; 
	-moz-transition: none 0s ease 0s; 
	-o-transition: none 0s ease 0s; 
	-ms-transition: none 0s ease 0s; 
	-webkit-transition: none 0s ease 0s;
}
.nav-tabs.nav-tabs-solid {
	background-color: #fbfcff;
	border: 0;
}
.nav-tabs.nav-tabs-solid > li {
	margin-bottom: 0;
	text-align: center;
}
.nav-tabs.nav-tabs-solid > li > a {
	border-color: transparent;
}
.nav-tabs.nav-tabs-solid > li > a:hover,
.nav-tabs.nav-tabs-solid > li > a:focus {
	background-color: #f5f5f5;
}
.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
	background-color: #f5f5f5;
	border-color: transparent;
}
.nav-tabs-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.nav-tabs-justified.nav-tabs-top > li > a,
.nav-tabs-justified.nav-tabs-top > li > a:hover,
.nav-tabs-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.provider-tabs .nav-tabs.nav-tabs-solid {
	background: #fff;
    box-shadow: 0px 4px 34px rgb(215 215 215 / 25%);
    border-radius: 5px;
    border: 0;    
	padding: 10px;
}
.provider-tabs .nav-tabs.nav-tabs-solid > li > a:hover, .provider-tabs .nav-tabs.nav-tabs-solid > li > a:focus, .provider-tabs .nav-tabs.nav-tabs-solid > li > a.active {
    background-color: #f9f9f9;
    border-color: #f9f9f9;
	color: inherit;
}
.provider-tabs .nav-tabs.nav-tabs-solid > li > a  p {
	margin: 5px 0 0;
	font-weight: 500;
	font-size: 15px;
}
.provider-tabs .nav-tabs-justified > li > a {
	border-radius: 0;
	margin-bottom: 0;
}
.provider-tabs .nav-tabs-justified > li > a:hover,
.provider-tabs .nav-tabs-justified > li > a:focus {
	border-bottom-color: #ddd;
}
.provider-tabs .nav-tabs-justified.nav-tabs-solid > li > a {
	border-color: transparent;
}
.provider-tabs .nav-tabs.nav-justified.nav-tabs-top {
	border-bottom: 1px solid #ddd;
}
.provider-tabs .nav-tabs.nav-justified.nav-tabs-top > li > a,
.provider-tabs .nav-tabs.nav-justified.nav-tabs-top > li > a:hover,
.provider-tabs .nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
	border-width: 2px 0 0 0;
}
.bg-red {
	color: #FF8B7B;
}
.bg-blue {
	color: #129BE8;
}
.bg-violet {
	color: #8553EE;
}
.bg-yellow {
	color: #FEBE42;
}
.bg-pink {
	color: #F5365C;
}
.bg-green {
	color: #159C5B;
}
.bg-navy {
	color: #11099F;
}
.user-tabs ul li + li {
	margin-left: 24px;
}
.user-tabs .nav-pills .nav-link {
    background: #fff;
    border-radius: 3px;
    color: #E65425;
    border: 1px solid #E65425;
    padding: 0.75rem 1rem;
}
.user-tabs  .nav-pills .nav-link.active, .user-tabs  .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #E65425;
	border: 1px solid #E65425;
}

/*-----------------
	6. Focus Label
-----------------------*/

.form-focus {
	height: 58px;
	position: relative;
}
.form-focus .focus-label {
	font-size: 14px;
	font-weight: 400;
	pointer-events: none;
	position: absolute;
	-webkit-transform: translate3d(0, 22px, 0) scale(1);
	-ms-transform: translate3d(0, 22px, 0) scale(1);
	-o-transform: translate3d(0, 22px, 0) scale(1);
	transform: translate3d(0, 22px, 0) scale(1);
	transform-origin: left top;
	transition: 240ms;
	left: 12px;
	top: -3px;
	z-index: 1;
	color: #8C635B;
	margin-bottom: 0;
}
.form-focus.focused .focus-label {
	opacity: 1;
	top: -18px;
	font-size: 12px;
	z-index: 1;
}
.form-focus .form-control:focus ~ .focus-label, 
.form-focus .form-control:-webkit-autofill ~ .focus-label {
	opacity: 1;
	font-weight: 400;
	top: -18px;
	font-size: 12px;
	z-index: 1;
}
.form-focus .form-control {
	height: 58px;
	padding: 21px 12px 6px;	
	border: 1px solid #ffe6e1;
}
.form-focus .form-control::-webkit-input-placeholder {
	color: transparent;
	transition: 240ms;
}
.form-focus .form-control:focus::-webkit-input-placeholder {
	transition: none;
}
.form-focus.focused .form-control::-webkit-input-placeholder {
	color: #bbb;
}
.form-focus.select-focus .focus-label {
	opacity: 1;
	font-weight: 300;
	top: -20px;
	font-size: 12px;
	z-index: 1;
}
.form-focus .select2-container .select2-selection--single {
	border: 1px solid #e3e3e3;
	height: 50px;
}
.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 48px;
	right: 7px;
}
.form-focus .select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #ccc transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}
.form-focus .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #ccc;
	border-width: 0 6px 6px;
}
.form-focus .select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 12px;
	padding-top: 10px;
}
.form-focus .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #676767;
	font-size: 14px;
	font-weight: normal;
	line-height: 38px;
}
.form-focus .select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #fc6075;
}

/*-----------------
	7. Modal
-----------------------*/

.modal {
	-webkit-overflow-scrolling: touch;
}
.modal-footer.text-center {
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.modal-footer.text-left {
	justify-content: flex-start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: flex-start;
}
.modal-dialog.modal-md {
    max-width: 600px;
}
.modal-header {
	padding: 24px 24px 0px 24px;
	align-items: center;
	border-bottom: none;
}
.modal-header.small-modal {
	padding: 24px 24px 14px 24px;
}
.catergyset {
    margin-bottom: 14px;
    padding-bottom: 14px;
    border-bottom: 1px solid #E7EBF7;
}
.submit-section .btn + .btn{
	margin-left: 14px;
}
.modal-body {
	padding: 24px ;
}
.modal-content {
	border-radius: 10px;
}
.custom-modal .modal-content {
	border: 0;
}
.custom-modal .modal-header {
    padding: 20px 20px 0px;
	border-bottom: 0;
}
.custom-modal .modal-footer {
    padding: 1.25rem;
}
.custom-modal .modal-body {
    padding: 2rem;
}
.custom-modal .close {
    border: 2px solid #E65425;
    border-radius: 50%;
    color: #E65425;
    font-size: 17px;
    height: 20px;
    line-height: 16px;
    margin: 0;
    opacity: 1;
    padding: 0;
    position: absolute;
    right: 20px;
    top: 26px;
    width: 20px;
    z-index: 99;
}
.custom-modal .modal-title {
	font-size: 24px;    
	margin-bottom: 5px;
}
.custom-modal .modal-subtitle{
	font-size: 16px;
}
.rate-bottom {
	border-bottom: 1px solid #F3F3F3;
}
.modal-backdrop.show {
    opacity: 0.4;
	-webkit-transition-duration: 400ms;
	transition-duration: 400ms;
}
.modal .card {
	box-shadow: unset;
}
.modal-dialog {
	overflow: hidden;
}
.bg-modal {
	position: relative;
}
.bg-modal:before {
    content: "";
    position: absolute;
    margin: -32px;
    width: 100px;
    top: 0;
    left: 0;
    height: 100px;
    border-radius: 50%;
    background-color: #E65425;
	overflow: hidden;
}

/*-----------------
	7. Components
-----------------------*/

.comp-header {
    margin-bottom: 1.875rem;
}
.comp-header .comp-title {
    color: #161c2d;
}
.line {
    background-color: #71d3a9;
    height: 2px;
    width: 60px;
}
.comp-buttons .btn {
	margin-bottom: 5px;
}
.pagination-box .pagination {
	margin-top: 0;
}
.comp-dropdowns .btn-group {
	margin-bottom: 5px;
}
.progress-example .progress {
	margin-bottom: 1.5rem;
}
.progress-xs {
	height: 4px;
}
.progress-sm {
	height: 15px;
}
.progress.progress-sm {
	height: 6px;
}
.progress.progress-md {
	height: 8px;
}
.progress.progress-lg {
	height: 18px;
}

/*-----------------
	8. Header
-----------------------*/
.header.min-header {
	width: 100%;
	position: fixed;
    z-index: 1040;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    background-color: transparent;
}
.header {
	width: 100%;
	position: fixed;
	z-index: 1040;
	-webkit-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	background-color: #fff;
	border: 1px solid #e3e3e3;
	box-shadow: 0px 8px 16px 0px rgba(142, 141, 208, 0.12);
}
.home-page .header {
	border-color: transparent;
}
.header-nav {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: transparent;
	border: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    position: relative;
	margin-bottom: 0;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.main-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
}
.header-two .top-header .user-menu.nav > li > a{
	font-weight: 400;
}
.main-nav > li.active > a {
	color: #71d3a9;
}
.main-nav > li > a {
    position: relative;
}
.main-nav > li > a:hover {
    color: #71d3a9;
}
.main-nav > li .submenu li a {
    display: block;
    padding: 10px 15px;
    clear: both;
    white-space: nowrap;
    font-size: 15px;
    color: #2B2B2B;
    -webkit-transition: all .35s ease;
    transition: all .35s ease;
	width: 100%;
	border-top: 0;
}
.my-projects-view .book-mark .freelance-location {
    margin-bottom: 6px;
    justify-content: center;
}
.main-nav > li .submenu > li.has-submenu > a::after {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    right: 15px;
    top: 12px;
    font-size: 13px;
}
.main-nav > li .submenu li {
    position: relative;
}
.main-nav li a {
    display: block;
    font-size: 16px;
    font-weight: 600;
}
.main-nav li.login-link {
	display: none;
}
.main-menu-wrapper {
	margin: 0;
    padding: 0;
}
.logo {
    display: inline-block;
    margin-right: 0;
	padding: 0;
}
.togglesearch {
	position: absolute;
	top: 55px;
	right: 0;
	width: 285px;
	display: none;
	z-index: 999;
	background: #fff;
	padding: 10px 10px;
	border-radius: 5px;
	box-shadow: 0 2px 8px rgb(183 183 183 / 25%);
}
.home-five-wrapper .header.header-three .input-group img {
    filter: brightness(2);
}
.home-five-wrapper .header.header-three.sticky .input-group img {
    filter: brightness(1);
}
.home-five-wrapper .header.header-three .select2-container--default .select2-selection--single .select2-selection__arrow {
    right: 3px;
}
.login-right .dont-have label a{
	color: #295FCC;
}
.home-five-wrapper .header.header-three .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #fff;
}
.home-five-wrapper .header.header-three.sticky .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #637381;
}
.togglesearch .input-group{
	margin: 0 !important;
}
.togglesearch input[type="text"] {
	width: 182px;
	padding: 5px 10px;
	border: 1px solid #71d3a9;
	outline: none;
	height: 39px;
	border-radius: 5px 0 0 5px;
}
.togglesearch button {
    background: #71d3a9 !important;
    color: #fff;
    border: 1px solid #71d3a9;
    outline: none;
    cursor: pointer;
    border-radius: 0 5px 5px 0;
}
.header-contact-img {
	display: inline-block;
}
.header-contact-img i {
	color: rgba(0, 0, 0, 0.5);
	font-size: 30px;
}
.header-contact-detail {
	display: inline-block;
	padding-left: 10px;
}
.header-contact-detail p.contact-header {
	color: #484848;
	font-size: 13px;
	font-weight: 400;
	margin-bottom: 2px;
	text-align: left;
}
.header-contact-detail p.contact-info-header {
	color: #000;
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 0;
}
.header-navbar-rht {
    margin: 0;
	padding: 0;
	color: #fff;
    font-size: 15px;
    font-weight: 600;
	margin-right: 2px;
	position: relative;
}
.header-navbar-rht li{
	position: relative;
}
.header-navbar-rht li:first-child:after{
	position: absolute;
	content: "";
	height: 19px;
	width: 1px;
	background: #BFBFBF;
	right: -13px;
    top: 13px;
}
.header-navbar-rht li.searchbar {
	position: relative;
	cursor: pointer;
}
.header-navbar-rht li a {
    color: #2E2E2E;
    font-weight: 600;
    font-size: 16px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.space-login {
	color: #2e2e2e;
	margin: 0px 5px;
}
.header-navbar-rht li a:hover, .header-navbar-rht li a:focus {
    color: #E65425;
}
.header-navbar-rht li a img{
	transition: all 0.3s;
}
.header-navbar-rht li.account-item a:hover img{
	filter: none;
}
.header-navbar-rht li a:hover img{
	filter: invert(31%) sepia(79%) saturate(1243%) hue-rotate(346deg) brightness(108%) contrast(91%);
}
.header-navbar-rht li.notification-message  a:hover img{
	filter: none;
}
.header-navbar-rht li.lang-item > a {
	padding-left: 0;
	padding-right: 0;
}
.header-navbar-rht li .dropdown-menu {
	padding: 0;
    margin: 0;
	border-radius: 5px;
}
.header-navbar-rht li .dropdown-menu:before {
    border: 7px solid #fff;
    border-color: transparent transparent #fff #fff;
    box-shadow: -2px 2px 2px -1px rgb(0 0 0 / 10%);
    content: "";
    left: 45px;
    position: absolute;
    top: 2px;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}
.header-navbar-rht .dropdown-item {
	color: #161c2d;
	padding: 6px 15px;
	font-size: 14px;
	border-bottom: 1px solid #f0f0f0;
}
.header-navbar-rht .dropdown-item:last-child {
	border-bottom: 0;
}
.header-navbar-rht .dropdown-item img {
    margin-right: 7px;
}
.header-navbar-rht .login-btn {
    background-color:#71d3a9;
    border-color: #71d3a9;
    border-radius: 5px;
    padding:7px 25px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
}
.header-navbar-rht .login-btn:hover, .header-navbar-rht .login-btn:focus {
	box-shadow: 0px 60px 0 0 #131135 inset;
    border-color: #131135;
	color: #fff;
}
.header-navbar-rht li.account-item .dropdown-menu:before {
	content: none;
}
.header-navbar-rht li.account-item .dropdown-menu {
	min-width: 280px;
	padding: 20px;
	box-shadow: 0px 2px 14px rgb(189 189 189 / 25%);
    border-radius: 0;
    border: 0;
}
.account-item .dropdown-menu h6 {
	font-size: 17px;
    color: #161c2d;
    font-weight: normal;
}
.account-item .custom_radio {
    color: #8C635B;
	font-weight: normal;
}
.account-item .arrow-circle {
	background: #131135;
    color: #FFFBFB;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    align-items: center;
    text-align: center;
    line-height: 31px;
}
.header-navbar-rht li.account-item .dropdown-menu.emp {
    padding: 0;
    border: 1px solid #f0f0f0;
    box-shadow: unset;
}
.emp .drop-head {
    background: #f3f3f3;
    padding: 10px 20px;
    font-weight: bold;
    font-size: 14px;
    border-top: 1px solid #f0f0f0;
}
.header-navbar-rht .account-item .emp .dropdown-item {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-top: 1px solid #f0f0f0;
}
.header-navbar-rht .account-item .emp .dropdown-item:hover {
    color: #E65425;
}
.header-navbar-rht .account-item .emp .dropdown-item i {
    font-size: 14px;
    margin-right: 5px;
}
.header-navbar-rht .account-item .emp .dropdown-item.bal {
	padding-left: 25px;
}
.header-navbar-rht .account-item .emp .dropdown-item .amt {
	float: right;
	color: #E65425;
}
.header-navbar-rht .signout-btn {
    background-color: #E65425;
    border-radius: 130px;
    color: #fff;
    padding: 11px 30px;
    font-size: 14px;
    margin-right: 8px;
    font-weight: 700;
    color: #fff;    
	display: inline-block;
    text-transform: uppercase;
}
.header-navbar-rht .account-item  .dropdown-item {
    color: #161c2d;
    padding: 6px 0;
    font-size: 14px;
	font-weight: normal;
    border-bottom: 0;
}
.account-item .dropdown-item:focus, .account-item .dropdown-item:hover {
	background-color: transparent;
}
.header-navbar-rht li {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.min-header .header-navbar-rht li a.header-login:hover {
	background-color: #cc2d78;
    border-color: #cc2d78;
}
.header-navbar-rht li a.header-login:hover {
    background-color: #cc2d78;
    border-color: #cc2d78;
    color: #fff;
}
.header-navbar-rht li {
	font-size: 14px;
	margin-right: 25px;
}
.header-navbar-rht li:last-child {
	margin-right: 0;
}
.min-header .header-navbar-rht li a.header-login {
	border-color: #fff;
	color: #fff;
}
.header-navbar-rht li a.header-login {
    border: 2px solid #cc2d78;
    border-radius: 4px;
    padding: 10px 15px !important;
    text-align: center;
    font-size: 15px;
    color: #cc2d78;
    text-transform: uppercase;
    font-weight: 500;
}
.header .has-arrow .dropdown-toggle:after {
	border-top: 0;
	border-left: 0;
	border-bottom: 2px solid #161c2d;
	border-right: 2px solid #161c2d;
	content: '';
	height: 8px;
	display: inline-block;
	pointer-events: none;
	-webkit-transform-origin: 66% 66%;
	-ms-transform-origin: 66% 66%;
	transform-origin: 66% 66%;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
	width: 8px;
	vertical-align: 2px;
	margin-left: 10px;
}
.header .has-arrow .dropdown-toggle[aria-expanded="true"]:after {
	-webkit-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
.user-menu {
    float: right;
    margin: 0;
    position: relative;
    z-index: 99;
}
.user-menu.nav > li > a {
	color: #fff;
	font-size: 14px;
	line-height: 58px;
	padding: 0 10px;
	height: 60px;
}
.user-menu.nav > li > a:hover,
.user-menu.nav > li > a:focus {
	background-color: rgba(0, 0, 0, 0.2);
}
.user-menu.nav > li > a:hover i,
.user-menu.nav > li > a:focus i {
	color: #fff;
}
.user-img {
    display: inline-block;
    position: relative;
    margin-right: 5px;
}
.user-img > img {
    height: 46px;
    object-fit: cover;
    width: 46px;
    border-radius: 50%;
}
.user-menu.nav > li > a.mobile_btn {
	border: 0;
	position: relative;
	padding: 0;
	margin: 0;
	cursor: pointer
}
.user-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    padding: 10px 15px;
}
.user-header .user-text {
	margin-left: 10px;
}
.user-header .user-text h6 {
	font-size: 15px;
    margin-bottom: 2px;
}
.course {
	margin-left: 10px;
    padding-top: 17px;
}
.main-nav .log-select {
	background-color: #fff;
    padding: 9px 7px !important;
    color: #71d3a9;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 85px;
    cursor: pointer;
}
.main-nav .log-select.dropdown-toggle:after {
	color: #000;
}
.main-nav .log-menu .dropdown-item {
	color: #161c2d !important;
	font-weight: 400;
}
.main-nav .log-select i {
	background-color: #71d3a9;
    padding: 5px 7px;
    border-radius: 50%;
    color: #fff;
}
.language-select {
	cursor: pointer;
}
.dot-menu .nav-link {
	color: #c4c4c4;
	padding: 2px;
}
.dot-menu .dropdown-menu {
	padding: 0;
	right: 0;
    left: auto !important;
}
.dot-menu .dropdown-item:focus, .dot-menu .dropdown-item:hover {
	color: #E65425;
	background-color: transparent;
}
.dot-menu .dropdown-toggle::after {
	content: none;
}

/*-----------------
	8. Header-2
-----------------------*/

.header-two {
    background: #fff;
    top: 0;
}
.header-two .header-navbar-rht li a.reg-btn {
	background: #FFF5F1;
	border: 1px solid #EBEBEB;
	width: 55px;
	height: 55px;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.header-two .header-navbar-rht .login-btn {
	padding: 12px 39px;
	border-radius: 5px;
}
.main-nav > li > a > i.fa-search {
    font-size: 14px;
}

/*-----------------
	8. Header-3
-----------------------*/

.header-three .header-navbar-rht .login-btn {
    border-radius: 5px;
}
.header-three .header-navbar-rht li {
    color: #fff;
	font-weight: 600;
}
.header-three .header-navbar-rht li a.log-btn:hover {
	color: #E65425;
}
.header-three .header-navbar-rht li span {
	color: #fff;
}
.header-three.sticky .header-navbar-rht li span {
	color: #2e2e2e;
}
.header-three .main-nav li a {
	color: #FFF5F1;
}
.header-three .main-nav > li.active > a,
.header-three.sticky .main-nav > li.active > a {
    color: #71d3a9;
}
.header-three.sticky .scroll-logo{
	display: none;
}
.header-three .main-nav li a:hover,
.header-three.sticky .main-nav li a:hover {
	color: #71d3a9;
}
.original-logo {
	display: none;
}
.header-three.sticky .original-logo {
	display: block;
}
.header-three.sticky .main-nav li a,
.header-three.sticky .header-navbar-rht li a.log-btn {
    color: #2e2e2e;
}
.trend-projects {
	padding: 80px 0px;
}
.section-header.section-header-three p {
    color: #515151;
}
.section-header.section-header-four p {
    color: #6B7385;
	font-size: 18px;
	font-weight: 600;
}

/*-----------------
	8. Header-4
-----------------------*/

.header-four {
    background: #fff;
    top: 0;
}
.header-four .header-navbar-rht li a.reg-btn {
    color: #101828;
    font-weight: 600;
    font-size: 15px;
}
.header-four .header-navbar-rht .login-btn {
	border-radius: 5px;
}

/*-----------------
	8. Header-5
-----------------------*/

.header-five .header-navbar-rht li .reg-btn {
	font-size: 15px;
	color: #101828;
	justify-content: center;
}
.header-five .header-navbar-rht .sign-reg {
	background: #FFFFFF;
	padding: 11px 15px;
	border-radius: 5px;
	min-width: 198px;
}
.header-five.sticky .header-navbar-rht li .reg-btn {
    box-shadow: 0px 4px 48px rgb(160 160 160 / 10%);
}
.header-five .main-nav li a {
	color: #fff;
}
.header-five .main-nav > li.active > a,
.header-five.sticky .main-nav > li.active > a {
    color: #E65425;
}
.header-five.sticky .main-nav li a {
    color: #2E2E2E;
}
.header-five .scroll-logo,
.header-five.sticky .logo {
	display: none;
}
.header-five.sticky .logo.scroll-logo {
	display: block;
}

/*-----------------
	9. Mobile Menu
-----------------------*/

.sidebar-overlay {
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
}
.menu-opened .main-menu-wrapper {
    transform: translateX(0);
}
.menu-header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
    height: 60px;
    padding:  0 20px;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    border-bottom: 1px solid #71d3a9;
	display: none;
}
.menu-close {
	font-size: 18px;
}
.menu-close i {
	color: #71D3A9;
}
.bar-icon {
    display: inline-block;
    width: 31px;
}
.bar-icon span {
    background-color: #081733;
    display: block;
    float: left;
    height: 3px;
    margin-bottom: 7px;
    width: 31px;
    border-radius: 2px;
}
.bar-icon span:nth-child(2) {
    width: 16px;
}
.bar-icon span:nth-child(3) {
    margin-bottom: 0;
}
#mobile_btn {
	display: none;
	margin-right: 30px;
}
html.menu-opened body {
    overflow: hidden;
}
.social-icon ul {
	display: inline-flex;	
	display: -ms-inline-flexbox;
	list-style: none;
	margin: 0;
	padding: 0;
}
html.menu-opened body:before,
html.menu-opened body header:before {
	content: "";
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,0.6);
	z-index: 99;
}

/*-----------------
	10. Home
-----------------------*/

.home-banner {
    background-image: url(../img/banner1.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    padding-top: 84px;
}
.banner-content .rating {
    background: #fff;
    box-shadow: 0px 4px 8px 0px #8E8DD029;
    display: inline-flex !important;
    padding: 20px;
    border-radius: 50px;
	margin-bottom: 20px;
}
.banner-img {
	float: right;
	padding: 40px 0;
}
.banner-content .rating .checked {
    color: #FF9C07;
}
.banner-content .rating h5 {
	padding-left: 15px;
	margin: 0;
}
.banner-content h5 {
    font-weight: 500;
    font-size: 14px;
    color: #6B7385;
}
.banner-content h1 {
    font-weight: bold;
    font-size: 48px;
    color: #1B1B1B;
    margin-bottom: 20px;
}
.banner-content h1 .orange-text{
	font-weight: 700;
	color: #E65425;
}
.banner-content p {
	font-size: 18px;
    color: #6B7385;
    font-weight: 500;
	margin-bottom: 30px;
}
.banner-content .form-inner {
    padding: 2px;
    background-color: #fff;
    border-radius: 5px;
    width: 100%;
	border: 1px solid #CED4E6;
}
.banner-content .input-group {
	background: #fff;
    border-radius: 130px;
}
.banner-content .input-group .form-control::placeholder{
	color: #353535;
}
.banner-content .input-group .input-group-prepend {
	margin-right: 0;
}
.banner-content .form-control {
	background: #fff;
	border: 0;
	height: 60px;
	color: #353535;
    font-size: 16px;
	font-weight: 500;
}
.drop-detail {
    width: 165px;
	display: flex;
    align-items: center;
}
.banner-content .select2-container {
	border-right: 1px solid #CFCFCF;
	width: 99% !important;
	z-index: unset;
}
.banner-content .select2-container .select2-selection--single .select2-selection__rendered {
	font-size: 16px;
	padding: 0 25px;
}
.banner-content .select2-container .select2-selection--single {
	border: 0;
    align-items: center;
    display: flex;
	background: transparent;
	height: 30px;
}
.banner-content .select2-container--default .select2-selection--single .select2-selection__arrow {
	top: auto;
}
.banner-content .sub-btn {
	background: #71d3a9;
	border-radius: 5px !important;
	font-weight: bold;
	border: 1px solid #71d3a9;
    height: 40px;
    margin: 10px;
	font-size: 16px;
	line-height: 18px;
	padding: 5px ;
	text-transform: capitalize;
	color: #fff;
	min-width: 96px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.banner-content .sub-btn:hover, 
.banner-content .sub-btn:focus {
	box-shadow: 0px 60px 0 0 #131135 inset;
    border-color: #131135;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.btn-item {
    margin-top: 25px;
}
.btn-item .btn{
	padding: 10px 24px;
	font-size: 16px;
	font-weight: 600;
	border-radius: 5px;
}
.buget-img ul {
    list-style: none;
    display: flex;
}
.hours-rate-img {
    border: 1px solid #CED4E6;
    margin-right: 10px;
    text-align: center;
    padding: 20px;
    border-radius: 5px;
	position: relative;
}
.hours-rate-img h6{
	margin:0;
}
.customize-radio input{
	display:none;
}
.success-check{
	display:none;
	position: absolute;
	right: 10px;
	top:10px;
}
.active .hours-rate-img {
    border: 1px solid #34C759;
}	
.active .success-check{
	display: block;
}
.hours-rate-img img{
	margin-bottom: 5px;
}
textarea.form-control {
    height: 115px;
}
.btn-item .reset-btn {
    background: #081733;
    color: #fff;
}
.get-btn {
	background: #71d3a9;
	border-radius: 130px;
	border-color: #71d3a9;
	font-weight: bold;
    padding: 14px 42px;
	color: #fff;
	margin-right: 10px;
	font-size: 14px;
}
.get-btn:hover {
	color: #fff;
	background-color: #71d3a9;
    border-color: #71d3a9;
}
.courses-btn {
	background: #131135;
	border-radius: 130px;
	font-weight: bold;
    padding: 12px 39px;
	color: #fff;
	text-transform: uppercase;
	font-size: 15px;
}
.courses-btn:hover {
	color: #fff;
	background-color: #01243f ;
    border-color: #01243f ;
}
.feature {
    position: relative;
    padding-bottom: 20px;
    background-image: url(../../assets/img/bg/bg-07.png);
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 20%;
    padding-top: 50px;
}
.feature-item {
    position: relative;
    padding: 30px;
    margin-bottom: 30px;
    background: #fff;
	box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
	border-radius: 10px;
    text-align: center;
	overflow: hidden;
	border: 1px solid #fff;
	border-top: 2px solid #E65425;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.work-set-image {
    display: flex;
    align-items: center;
}
.work-set {
    width: 50%;
}
.work-sets {
    width: 42%;
}
.work-set-image img {
    padding: 5px;
    width: 100%;
}
.feature-item:hover {
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
	transform: scale(1.05);
}
.feature .feature-icon {
    margin-bottom: 25px;
    position: relative;
    min-height: 78px;
}
.feature .feature-icon::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 108px;
    height: 70px;
    background-image: url(../img/icon/icon-bg.png);
}
.feature .feature-icon img{
	position: relative;
}
.feature .feature-content h3 {
    color: #01243f ;
    font-weight: bold;
    margin-bottom: 10px;
}
.feature .feature-content p {
    font-size: 18px;
    text-transform: capitalize;
    color: #5F5F5F;
	margin-bottom: 0;
	font-weight: 500;
}
.work {
	position: relative;
}
.work:before {
    content: "";
    position: absolute;
    width: 108px;
    height: 128px;
	z-index: 9;
    bottom: 60px;	
    left: 0;
    transform: translate(-50%, 0);
    background: url(../../assets/img/bg/bg-08.png) top center no-repeat;
}
.work:after {
    content: "";
    position: absolute;
    width: 108px;
    height: 128px;
    z-index: 9;
    top: 60px;
    right: 0;
    transform: translate(50%, 0);
    background: url(../../assets/img/bg/bg-14.png) top center no-repeat;
}
.work-box {
	box-shadow: 0px 16px 24px 7px rgba(142, 141, 208, 0.11);
	padding: 24px;
	position: relative;
    z-index: 1;
    background: #fff;
}
.bg1 {
	background-image: url(../../assets/img/icon/bg1.png);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 200px;
}
.bg1 .work-content{
	background-image: url(../../assets/img/icon/developer1.png);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 200px;
}
.bg2 {
	background-image: url(../../assets/img/icon/bg2.png);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 200px;
}
.bg2 .work-content{
	background-image: url(../../assets/img/icon/developer2.png);
    background-repeat: no-repeat;
    background-position: right top;
    background-size: 200px;
}
.work-content h2 {
	font-weight: bold;
    color: #1B1B1B;
    padding-bottom: 12px;
    position: relative;
}
.work-content h2 span {
    display: block;
}
.bg2 .work-content h2 {
	color: #161c2d; 
}
.work-content h2:after {
    content: "";
    width: 48px;
    height: 3px;
    background: #fff;
    left: 0px;
    right: 0;
    bottom: 0;
    position: absolute;
    border-radius: 170px;
}
.feature-content h3{
	font-size: 28px;
}
.feature-content p{
	font-size: 16px;
	color: #6B7385;
	margin: 0;
}
.work-content .btn-primary{
	background: #E65425;
    padding: 10px 25px;
    border: 1px solid #e65425;
    box-shadow: none;
}
.work-content .btn-primary:hover{
	box-shadow: 0px 60px 0 0 #131135 inset;
    padding: 10px 25px;
    border: 1px solid #131135;
	color: #fff;
}
.work-content p {
	font-weight: 500;
    font-size: 16px;
    color: #6B7385;
    margin-bottom: 24px;
    width: 300px;
    max-width: 100%;
}
.work-content .long-arrow {
	width: 37px;
	height: 37px;
	background: #FFFAF9;
	color: #E65425;
	border-radius: 50%;
    line-height: 37px;
    text-align: center;
    font-size: 20px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.work-content .long-arrow:hover, 
.work-content .long-arrow:focus {
	background: #131135;
	color: #fff;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.bg2 .work-content .long-arrow {
	background: #131135;
	color: #FFFBFB;
}
.bg2 .work-content .long-arrow:hover, .bg2 .work-content .long-arrow:focus {
    background: #fb451d;
}
.services {
	position: relative;
    padding: 80px 0;
	background: #FFFBFB;
	background-image: url(../../assets/img/bg/bg-01.png), url(../../assets/img/bg/bg-02.png);
    background-repeat: no-repeat, no-repeat;
    background-position: right bottom, left top;
	background-size: 270px, 56%;
}
.service-item {
	background: #fff;
	border: 1px solid #ececec;
	text-align: center;
	border-radius: 2px;
	padding: 20px;
	margin-bottom: 30px;
	transition: ease-in-out .15s;
	-moz-transition: ease-in-out .15s;
	-o-transition: ease-in-out .15s;
	-webkit-transition: ease-in-out .15s;
	cursor: pointer;
}
.service-item:hover, .service-item:focus {
	background: #FFF0F0;
	border-color: #FFF0F0;
}
.select-item.selected {
    box-shadow: 0px 8px 16px 0px #EEEEEE;
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    filter: grayscale(0%)
}
.selected {
    background: #E65425;
}
.selected .service-content h5 {
	color: #fff;
}
.service-icon {
	margin-bottom: 20px;
	line-height: 77px;
}
.service-content h5 {
	font-weight: bold;
	font-size: 18px;
	text-transform: uppercase;
	margin-bottom: 0;
}
.services-item {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
	margin-bottom: 15px;
}
.services-item  img{
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.section-header {
	margin-bottom: 50px;
}
.section-line {
    width: 35px;
    height: 8px;
    background: #71d3a9;
    border-radius: 5px;
    margin: auto;
	position: relative;
	margin-bottom: 20px;    
}
.header-title {
    color: #1B1B1B;
    font-weight: 700;
    position: relative;
    margin-bottom: 10px;
    font-size: 40px;
}
.section-header h5 {
    font-size: 21px;
    color: #71d3a9;
    font-weight: 700;
    position: relative;
    margin-bottom: 0;
}
.section-header p {
    font-weight: 600;
    font-size: 16px;
    color: #6B7385;
    margin-bottom: 0;
}
.services-item .content-text {
	position: absolute;
    transition: all .3s ease-in-out;
    text-align: center;
    bottom: 10%;
    width: 100%;
}
.services-item .content-part h3 {
	color: #fff;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 18px;
}
.services-item .content-part h4 {
	font-size: 17px;
	font-weight: 600;
	color: #fff;
}
.services-item .text {
    text-align: center;
    position: absolute;
    top: 70%;
}
.services-item:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.services-item:hover img {
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.see-all {
	margin-top: 20px;
}
.see-all .all-btn {
    padding: 10px 24px;
    background: #71d3a9;
    border-radius: 5px;
    color: #FFF5F3;
    font-weight: 600;
    font-size: 16px;
}
.see-all .all-btn:hover {
	box-shadow: 0px 60px 0 0 #131135 inset;
    color: #fff;
	transition: all 0.3s ease;
	border: 1px solid #131135;
}
.job-content-blk .all-btn {
    padding: 12px 40px;
    background: #71d3a9;
    border-radius: 5px;
    color: #FFF5F3;
    font-weight: bold;
    font-size: 16px;
}
.job-content-blk .all-btn:hover {
	box-shadow: 0px 60px 0 0 #131135 inset;
    color: #fff;
	transition: all 0.3s ease;
}
.right-arrow {
	height: 20px;
    width: 20px;
    background: #fff;
    color: #71d3a9;
    text-align: center;
    border-radius: 50%;
    line-height: 20px;
    padding-left: 2px;
    font-size: 14px;
	margin-left: 10px;
}
.all-btn:hover .right-arrow {
	color: #115241;
}
.projects {
	position: relative;
    padding: 80px 0 65px;
	background: #71d3a8bd;
}
.project-item {
	background: #fff;
	margin-bottom: 25px;
	width: 100%;
	border: 1px solid #EBEBEB;
	box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
	border-radius: 6px;
}
.p-project-item {
	border: 1px solid #71d3a9;

}
.project-item:hover .project-icon {
	border-color: #71D3A9;
}
.project-img {
	height: 200px;
	overflow: hidden;
    padding: 14px;
	border-radius: 6px;
}
.project-img img {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    width: 100%;
	height: 100%;
	border-radius: 6px;
}
.project-icon {
    position: relative;
    width: 5px;
    height: 48px;
    border-radius: 50%;
    background: transparent;
    border: 0;
    margin: 15px 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.pro-icon {
    position: relative;
	height: 60px;
}
.project-img {
    position: relative;
}
.project-item:hover .project-img h6{
	background: #71d3a9;
	border: 1px solid #EBEBEB;
	color: #fff;
}
.project-img h6 {
    position: absolute;
    top: 20px;
    left: 20px;
    background: #fff;
    font-size: 12px;
    padding: 8px;
    border-radius: 5px;
    color: #6B7385;
	border: 1px solid transparent;
}
.pro-icon:before {
    content: "";
    width: 1px;
    height: 80%;
    background: #ECECEC;
	margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
}
.project-content {
	margin: 0 auto;
	text-align: center;
	width: 42%;
}
.project-content h4 {
	font-weight: 600;
    font-size: 22px;
    text-transform: uppercase;
    color: #1B1B1B;
    margin-bottom: 0;
}
.project-content h5 {
	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	margin-bottom: 0;
}
.project-content h5 a {
	color: #6B7385;
}
.project-content h5 a:hover {
	color: #E65425;
}
.developer {
	position: relative;
    padding: 80px 0 50px;
    background-image: url(../../assets/img/bg/bg-15.png);
    background-repeat: no-repeat, no-repeat;
    background-position: right center;
}
.dev-item {
	position: relative;
}
.dev-img, .dev-top {
	position: relative;
}
.dev-text {
    position: absolute;
    top: 20px;
    text-align: center;
    right: 20px;
}
.dev-text .right {
    padding: 10px 15px;
    color: #E65425;
    font-weight: bold;
    background: #FFFBFB;
    border-radius: 50px;
    font-size: 14px;
    display: inline-block;
}
.dev-user {
	position: absolute;
    left: 20px;
	top: 20px;
    display: flex;
	display: -webkit-box;
	display: -ms-flexbox;
}
.dev-user img {
    margin: auto;
    width: 40px;
}
.dev-content {
    background: #fff;
	border: 1px solid #FFEEEA;
    width: 100%;
	text-align: center;
	padding: 20px;
}
.dev-content .rating {
	margin-bottom: 10px;
}
.dev-content .rating i {
	margin-bottom: 10px;
	font-size: 13px;
}
.dev-content h3 {
    font-weight: 600;
	font-size: 20px;
	margin-bottom: 12px;
}
.dev-content h4 {
    font-weight: 500;
	font-size: 15px;
	color: #E65425;
    margin-bottom: 14px;
}
.dev-content p {
    font-weight: 600;
	font-size: 16px;
	color: #6e727d;
	margin-bottom: 0;
	text-transform: uppercase;
}
.btn-view {
	color: #fff;
    background-color: #E65425;
    padding: 15px 22px;
    font-size: 18px;
    font-weight: bold;
	width: 100%;
	border-radius: 0px 0px 15px 15px;
}
.btn-view:hover{
	color: #fff;
    background-color: #fb451d;
    border-color: #fb451d;
}
.developer-slider .slick-slide {
    display: block;
    margin-left: 0;
    padding: 0 10px;
}
.rating .checked {
	color: #ff9415;
}
.rating .non-checked {
	color: #C4C4C4;
}
.test-quote-img {
	margin-left: auto;
}
.right-text {
	text-align: right;
}
.review {
	background: #fff;
	padding: 80px 0 50px;
	background-image: none;
    background-repeat: no-repeat, no-repeat;
    background-position: left center;
}
.review-blog {
    background-color: #fff;
    padding: 20px;
    position: relative;
	box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
	border-radius: 6px;
	margin:0 0 40px;
	transition: all 0.5s;
}
.review-blog:hover{
	transform: scale(1.1);
}
.review-blog .review-content {
	padding: 0;
	border-bottom: 0;
	margin-bottom: 0px;
}
.review-blog .review-img img {
    width: 75px;
    border-radius: 50%;
    margin-right: 20px;
}
.owl-carousel .owl-item .review-img img {
	 width: 50px;
}
.review-info h3 {
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}
.review-info h5 {
    font-size: 14px;
    color: #5F5F5F;
    margin-bottom: 6px;
    font-weight: 500;
}
.review-blog .review-top {
	margin-bottom: 20px;
}
.review-info .rating i {
	font-size: 12px;
}
.review-info .rating i.filled {
    color: #E65425;
}
.review-info .rating .average-rating {
    font-size: 14px;
    color: #5F5F5F;
}
.review-blog .review-content p {
    font-size: 15px;
    margin: 0;
    line-height: 26px;
	font-weight: 500;
}
.review-bottom .review-img img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
}
.review-bottom .review-info h3 {
    font-size: 18px;
    font-weight: bold;
    color: #161c2d;
    margin-bottom: 3px;
}
.review-icon img {
    max-width: 50px;
}
.review-slider .slick-slide {
    display: block;
    margin-left: 0;
    padding: 0 10px;
    width: 280px;
}
.app-version {
    padding: 80px 0;
	background-image: url(../../assets/img/bg/bg-17.png);
    background-repeat: no-repeat, no-repeat;
    background-position:  right bottom;
    background-size: cover;
}
.app-version-blk h2 {
	color: #2e2e2e;
	margin-bottom: 20px
}
.app-version-blk p {
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 25px
}
.app-version-blk h5 {
	color: #000;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0;
}
.top-company {
	padding: 80px 0;
	border-bottom: 1px solid #EBEBEB;
}
.section-header h6 {
	font-size: 20px;
	color: #2E2E2E;
	font-weight: 600;
}
.owl-carousel .owl-item .company-logos img {
	width: auto;
	margin: auto;
}
.news {
    padding: 80px 0px;
	background: #fff;
}
.news-content h2 {
	font-size: 24px;
	color: #161c2d;
}
.news-content {
	background: #fff;
	border-radius: 0px 0px 10px 10px;
	padding: 20px;
}
.news-content p {
	font-size: 16px;
	color: #161c2d;
}
.news-content .entry-meta {
	background: #FFFBFB;
    display: inline-block;
    padding: 5px 10px 5px 0;
	border-radius: 0 25px 25px 0;
	color: #757575;
    list-style: none;
    margin-bottom: 15px;
}
.news-content .entry-meta li {
    display: inline-block;
    margin-right: 15px;
	font-size: 15px;
    font-weight: 500;
    color: #6e727d;
}
.news-content .entry-meta li.name i {
	font-size: 25px;
}
.news-content .entry-meta li.name img {
	width: 40px;
    height: 40px;
    border-radius: 100%;
}
.news-content .entry-meta li.cal i{
	color: #E65425;
}
.news-content a.read {
	font-weight: 600;
	font-size: 14px;
	color: #E65425;
}
.news .see-all {
	margin-top: 20px;
	text-align: center;
}
.clients {
    padding-top: 50px;
}
.client-img {
    text-align: center;
}
.job-counter {
	padding: 60px 0;
	background: #E65425;
}
.counter h2 {	
	font-weight: 600;
	font-size: 36px;
	color: #fff;
}
.counter h4 {	
	font-weight: 500;
	font-size: 20px;
	color: #fff;
}
.about {
	padding: 80px 0;
}
.about-content h2 {
	font-size: 40px;
	color: #1B1B1B;
	margin-bottom: 17px;
	font-weight: 600;
}
.about-content p {
	font-size: 15px;
	margin-bottom: 20px;
	line-height: 30px;
	font-weight: 500;
	color: #6F6F6F;
}
.orange-text {
    color: #E65425;
}
.learn-btn {
	background: #131135;
	border-radius: 150px;
	font-weight: 600;
	font-size: 14px;
	padding: 8px 32px;
	color: #fff;
}
.learn-btn:hover {
    color: #fff;
    background: #E65425;
}
.expert {
	padding: 80px 0;
	text-align: center;
	background-image: url(../img/bg/about-bg.png);
    background-size: cover;
}
.expert h5 {
	font-weight: 600;
	font-size: 16px;
	color: #E65425;
}
.expert h2 {
	font-size: 30px;
	color: #161c2d;
	margin-bottom: 25px;
}
.expert p {
	font-size: 16px;
	color: #161c2d;
}
.expert .btn-item {
    margin-top: 50px;
}
.agency {
	position: relative;
}
.agency-box {
	padding: 0;    
	overflow: hidden;
}
.black-bg {
	background: #131135;
}
.agency-content h2 {
	font-weight: 600;
	font-size: 28px;
	color: #fff;    
	position: relative;
    margin-bottom: 25px;
	line-height: 36px;
}
.agency-content p {
    font-size: 16px;
	color: #fff; 
	margin-bottom: 25px;
}
.agency-content {
    padding: 0 100px;
}
.agency-content .learn-btn {
	background: #E65425;
}
.agency-content .learn-btn:hover {
    color: #fff;
    background: #fb451d;
}
.about-img img{
	position: relative;
}
.about-img img:before {
  content: "";
  position: absolute;
  top: 100%;
  left: -5px;
  right: -5px;
  bottom: -5px;
  background-color: rgba(0,0,0,.2);
}
.job-register {
	background: #71d3a9;
	padding: 40px 0px 0;
}
.register-job-blk {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.job-content-blk h2 {
	color: #fff;
	font-size: 30px;
}
.job-content-blk p {
	color: #FFF5F1;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: 0;
}
.job-content-blk .all-btn{
	margin: 30px 0 40px;
	background: #FFF6F1;
	color: #01243F;
	border: 1px solid #01243F;
	padding: 12px 24px;
}
/*-----------------
	10.Home Two
-----------------------*/

.home-two {
    background-image: url(../img/techBack.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
	padding-top: 100px;
}
.home-two .banner-img {
    padding: 24px 0;
}
.banner-dot span {
	width: 11px;
	height: 11px;
	border-radius: 50px;
	display: block;
	position: absolute;
}
.blue-dot-banner {
    background: #71d3a9;
    top: 0;
    right: 0;
}
.red-dot-banner {
	background: #71d3a9;
	bottom: 100px;
    left: -40px;
}
.banner-top-bottom a {
	background: #FFFFFF;
	box-shadow: 0px 4px 14px rgba(217, 217, 217, 0.25);
	width: 55px;
	height: 55px;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	color: #E65425;;
}
.banner-top-bottom {
	position: absolute;
    bottom: -25px;
    left: 22%;
	z-index: 9;
}
.market-place h3 {
	color: #71d3a9;
}
.home-count {
	margin-top: 45px;
}
.home-count ul li {
	color: #71d3a9;
	font-size: 24px;
	font-weight: 700;
}
.home-count ul li .list-count {
	color: #01243f; 
	font-size: 18px;
	font-weight: 500;
	display: block;
	margin-bottom: 0;
}
.home-count ul li+li {
	border-left: 1px solid #EBEBEB;
	padding-left: 20px;
    margin-left: 20px;
}
.home-two .banner-content h1 {
	margin-bottom: 40px;
}
.header-title span {
	color: #71d3a9;
}
.section-header-two .header-title {
	margin-bottom: 10px;
}
.section-header-two p {
	color: #515151
}
.update-project {
    position: relative;
    padding-bottom: 80px;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 30%;
    padding-top: 80px;
}
.update-project-blk {
	background: #FFFFFF;
	box-shadow: 20px 4px 34px rgba(181, 181, 181, 0.15);
	border-radius: 10px;
	padding: 25px;
}
.update-project-blk:hover .update-content h6 {
	color: #71d3a9;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.update-content h6 {
	color:#01243f ;
	font-weight: 600;
	margin-bottom: 10px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.update-content p {
	color: #5F5F5F;
	font-weight: 500;
	margin-bottom: 0;
	font-size: 14px;
}
.update-project-blk {
	margin-bottom: 25px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.platform-group h1 {
	color: #2E2E2E;
	margin-bottom: 25px;
}
.platform-group h1 span {
	color: #71d3a9;
}
.platform-group h5 {
	color: #515151;
	margin-bottom: 15px;
	font-weight: 500;
}
.platform-group p {
	font-size: 14px;
	margin-bottom: 20px;
}
.platform {
	padding: 80px 0px;
	background: linear-gradient(270.05deg, #FFFFFF -60.55%, #71d3a9 174.03%);
	position: relative;
	z-index: 1;
}
.platform:before {
	content: "";
	/* background-image: url(../../assets/img/bg/bg-20.png); */
    background-repeat: no-repeat, no-repeat;
    background-position: left top;
	position: absolute;
    width: 220px;
    height: 390px;
    left: 0;
    top: 0;
	z-index: -1;
}
.plat-form-img img {
	width: 100%;
}
.more-project {
	margin-top: 25px;
}
.more-project .btn-primary {
	background-color: #71d3a9;
	border: 1px solid #71d3a9;
	padding: 10px 24px;
	font-size: 16px;
	font-weight: 600;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.more-project .btn-primary:hover {
	background-color: #01243f ;
	border: 1px solid #01243f;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.feature-project {
	padding: 80px 0px;
    /* background-image: url(../../assets/img/bg/bg-22.png); */
    background-repeat: no-repeat, no-repeat;
    background-position: right center;
}
.feature-project:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	/* background-image: url(../../assets/img/bg/bg-frame.png); */
	background-repeat: no-repeat, no-repeat;
}
.project-item.feature-project-item {
	background: #FFFFFF;
	box-shadow: 0px 4px 4px rgba(232, 232, 232, 0.25);
	border: 0;
}
.feature-content h4 a {
	color: #353535;
}
.feature-content h4 a:hover {
	color: #71d3a9;
}
.feature-content h4 {
	margin-bottom: 15px;
	font-size: 18px;
}
.feature-time-blk {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.project-item.feature-project-item .project-img {
	padding: 0;
}
.project-item.feature-project-item:hover {
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 20%);
	position: relative;
	top:-10px;
	border:1px solid #71d3a9;
}
.project-item.feature-project-item {
	padding: 10px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
	position: relative;
	top:0;
	border:1px solid transparent;
    -webkit-transition: all 0.5s ease;
}
.feature-time-blk .btn-primary {
	min-width: 88px;
    padding: 2px 10px;
    font-weight: 500;
    font-size: 14px;
    color: #71d3a9;
}
.feature-time-blk .green-active {
	background: #71d3a9;
	border: 1px solid #71d3a9;
}
.feature-time-blk .green-active:hover {
	background: #01243f ;
	border: 1px solid #01243f ;
}
.feature-time-blk .sky-active {
	background: #01243f ;
	border: 1px solid #01243f ;
}
.feature-time-blk .sky-active:hover {
	background: #71d3a9;
	border: 1px solid #71d3a9;
}
.feature-time-blk {
	margin-bottom: 15px;
}
.feature-content {
	margin-top: 15px;
}
.feature-time-blk span {
	font-weight: 500;
	font-size: 14px;
}
.feature-time-blk span i {
	font-size: 12px;
}
.feature-project-list {
	padding-bottom: 15px;
	margin-bottom: 10px;
	border-bottom: 1px solid #EBEBEB;
}
.feature-project-list li {
	font-weight: 500;
	font-size: 15px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.feature-project-list li+li {
	margin: auto 0 0 auto;
}
.feature-foot {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.logo-company span {
	color: #5F5F5F;
	font-weight: 500;
	font-size: 14px;
}
.bid-now {
	color: #71d3a9;
	font-size: 15px;
	font-weight: 700;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.bid-now:hover {
	color: #131135;
}
.top-skill {
	padding: 80px 0px 56px;
	background: #FBFBFB;
}
.skill-head .nav-pills .nav-link.active, 
.skill-head .nav-pills .show>.nav-link {
	background-color: transparent;
	color: #101828
}
.skill-head .nav-pills .nav-link {
	font-size: 24px;
	color: #EBEBEB;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.skill-head .nav-pills .nav-link:hover {
	color: #101828;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.skill-head .nav {
	display: block;
}
.skill-list-out ul li {
	list-style: none;
	margin-bottom: 15px;
}
.skill-list-out ul li a {
	color: #101828;
	font-size: 14px;
	font-weight: 500;
}
.skill-list-out ul li a:hover {
	color: #E65425;
}
.feature-develop-blk {
	border: 1px solid #71d3a9;
	box-shadow: 20px 24px 34px rgba(225, 225, 225, 0.25);
	border-radius: 10px;
	margin-bottom: 25px;
	background: #FFFFFF;
}
.developer-sub-blk {
	padding: 10px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.feature-developer {
	padding: 80px 0px;
	position: relative;
    background-image: url(../../assets/img/bg/bg-19.png);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 30%;
}
.img-developer img {
	border-radius: 10px;
}
.developer-content h4 {
	font-size: 16px;
	margin-bottom: 5px;
	color: #353535;
}
.developer-content p {
	font-size: 14px;
	color: #515151;
	font-weight: 500;
	margin-bottom: 4px;
}
.developer-content .rating .average-rating {
    color: #5f5f5f;
}
.verify {
    background-color: #fff;
    color: #159C5B;
    z-index: 2;
    width: 16px;
    height: 16px;
}
.hour-rate ul {
	padding: 10px;
	border-top: 1px solid #EBEBEB;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.hour-rate ul li {
	color: #101828;
	font-weight: 500;
	font-size: 13px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.hour-rate .feather-map-pin {
	color: #9D9D9D;
	font-size: 16px;
}
.review-bottom {
	margin-top: 60px;
}
.client-rate {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	margin-bottom: 10px;
}
.client-rate h4 {
	color: #2e2e2e;
	margin-bottom: 0;
	margin-right: 15px;
}
.review-bottom p {
	color: #5F5F5F;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 0;
}
.great-about {
	padding: 80px 0px 460px;
	background: linear-gradient(270.05deg, #F8ECF8 -28.55%, #FFF3EA 112.03%);
	z-index: 1;
	position: relative;
}
.great-about:before {
    content: "";
    /* background-image: url(../../assets/img/bg/bg-21.png); */
    background-repeat: no-repeat, no-repeat;
    background-position: right top;
    position: absolute;
    width: 220px;
    height: 390px;
    right: 0;
    top: 0;
    z-index: -1;
}
.great-blk {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	border-radius: 9px;
	padding: 30px;
	margin-bottom: 25px;
}
.great-icon {
	background: linear-gradient(270.05deg, #97DDBF -28.55%, #64AB8D 112.03%);
	width: 71px;
	height: 71px;
	margin-bottom: 15px;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.great-content h4 {
	color: #000000;
	margin-bottom: 15px;
}
.great-content p {
	color: #515151;
	font-size: 14px;
	margin-bottom: 0;
}
.about-it-blk {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	box-shadow: 0px 4px 24px rgba(224, 224, 224, 0.25);
	border-radius: 25px;
	padding: 20px;
}
.about-it-content {
	margin-top: 20px;
}
.about-it-content h4 {
	color: #2E2E2E;
	font-weight: 700;
	margin-bottom: 15px;
}
.about-it-content p {
	color: #515151;
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 20px;
}
.more-project .start-bid {
	background: #101828;
	border: 1px solid #101828;
}
.more-project .start-bid:hover {
	background: #71d3a9;
	border: 1px solid #71d3a9;
}
.location-img {
	overflow: hidden;
	border-radius: 10px;
}
.about-it-img {
	border-radius: 20px;
	overflow: hidden;
}
.about-it-img img {
	border-radius: 20px;
	height: 450px !important;
}
.about-it-img img,
.location-img img {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    transform: translateZ(0);
    -moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    -webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    width: 100%;

}
.about-it-img:hover img,
.location-img:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.about-project {
	min-height: 270px;
	position: relative
}
.about-position {
	position: absolute;
    bottom: 30px;
    z-index: 9;
	width: 100%;
}
.job-location {
	padding: 80px 0px;
	background: #FBFBFB;
}
.section-locate {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.view-locations .btn-primary {
	border: 1px solid #71d3a9;
	border-radius: 5px;
	background: transparent;
	color: #000000;
	font-size: 14px;
	min-width: 191px;
	padding: 10px 15px;
	font-weight: 500;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.view-locations .btn-primary:hover { 
	border: 1px solid #01243f ;
	background: #01243f ;
	color: #fff;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.location-right {
	background: #E65425;
	width: 18px;
	height: 18px;
	border-radius: 50px;
	color: #fff;
	margin-left: 10px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.job-locate-blk {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	border-radius: 10px;
	padding: 10px;
	margin-bottom: 25px;
}
.location-img img {
	border-radius: 10px;
}
.job-it-content h6 a {
	color: #000000;
}
.job-it-content h6 {
	font-weight: 600;
	margin-bottom: 8px;
}
.job-it-content {
	margin-top: 10px;
}
.job-locate-foot li {
	color: #474747;
	font-size: 14px;
	font-weight: 500;
}
.job-locate-foot {
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.review.review-two {
	background-image: none;
}
.review-blog.user-review {
	border: 1px solid #EBEBEB;
	box-shadow: 20px 24px 34px rgba(225, 225, 225, 0.25);
	border-radius: 10px;
}
.review-blog.user-review .review-img img {
	margin: auto;
}
.review-blog.user-review .review-img {
	margin-bottom: 15px;
}
.user-review .review-info h5 {
    font-size: 14px;
    color: #2B2B2B;
    font-weight: 400;
}
.user-review .rating {
	text-align: center;
	margin-top: 20px;
}
.review-two .owl-nav {
    position: absolute;
    overflow: hidden;
    bottom: 100%;
    right: -5px;
    margin-bottom: 50px;
}
.top-company-two .company-logos {
	border: 1px solid #EBEBEB;
	background: #fff;
    padding: 7px 0px;
    border: 1px solid #EBEBEB;
    border-radius: 4px;
}
.review-two .owl-carousel .owl-nav button.owl-next, 
.review-two .owl-carousel .owl-nav button.owl-prev, 
.review-two .owl-carousel button.owl-dot {
    display: block;
    float: left;
    width: 32px;
    height: 32px;
    font-size: 13px;
    color: #fff;
    border: 0;
    background: #131135;
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
    text-align: center;
    -webkit-transition: color .7s ease, background-color .7s ease, border-color .7s ease;
    transition: color .7s ease, background-color .7s ease, border-color .7s ease;
	box-shadow: 0px 1px 4px rgba(183, 183, 183, 0.25);
	border-radius: 50px;
}
.review-two .page-prev, 
.review-two .owl-nav .owl-prev {
    border-right-width: 0px;
    border-radius: 3px 0 0 3px;
}
.review-two .owl-theme .owl-nav [class*="owl-"]:hover {
	background: #E65425;
}
.review-two .owl-theme .owl-nav [class*="owl-"]:hover i {
	color: #fff;
}
.review-two .owl-carousel .owl-nav button.owl-next i, 
.review-two .owl-carousel .owl-nav button.owl-prev i {
    color: #E65425;
	line-height: 2.5;
}
.top-company-two {
	padding: 80px 0px 0px;
}
.grid-blog.blog-two {
	background: #FFFFFF;
	border: 1px solid #F5F6FA;
	box-shadow: 0px 4px 24px rgba(214, 214, 214, 0.25);
	border-radius: 10px;
}
.blog-two .blog-read {
	 display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.blog-two .blog-content {
	padding: 20px;
}
.grid-blog.blog-two .blog-content p {
    margin: 0;
}
.more-project .all-article {
	min-width: 177px;
}
.blog-tricks {
	padding: 80px 0px 56px;
}
.project-proposals-img img {
	width: 100%;
	height: 100%;
}
.blog-tricks .owl-dots {
	display: none;
}
.blog-slider.owl-carousel .owl-nav button.owl-prev,
.blog-slider.owl-carousel .owl-nav button.owl-next {
	width: 44px;
    height: 44px;
    font-size: 16px;
    background: #fff;
	color: #E65425;
	border-radius: 50px;
	position: absolute;
	border: 1px solid #EBEBEB;
}
.blog-slider.owl-carousel .owl-nav button.owl-prev:hover,
.blog-slider.owl-carousel .owl-nav button.owl-next:hover {
	background: #E65425;
	color: #fff;
}
.blog-slider.owl-carousel .owl-nav button.owl-prev {
	top: 40%;
	left: -25px;
	-webkit-transform: translate(0, 50%);
    -ms-transform: translate(0, 50%);
    transform: translate(0, 50%);
}
.blog-slider.owl-carousel .owl-nav button.owl-next {
	top: 40%;
	right: -25px;
	-webkit-transform: translate(0, 50%);
    -ms-transform: translate(0, 50%);
    transform: translate(0, 50%);
}
.owl-theme.blog-slider .owl-dots .owl-dot span {
	width: 16px;
	height: 16px;
	background: #C4C4C4;
}
.owl-theme.blog-slider .owl-dots .owl-dot.active span, 
.owl-theme.blog-slider .owl-dots .owl-dot:hover span {
    background: #2567E5;
}
.sign-in-btn .btn-primary {
	min-width: 202px;
	padding: 10px 15px;
	background: #71d3a9;
	border-radius: 5px;
	border: 1px solid #71d3a9;
	font-weight: 600;
}
.sign-in-btn .btn-primary:hover {
	background: #fff;
	border: 1px solid #fff;
	color:#01243f ;
}
.job-register-two {
	background: #01243f ;
	padding: 50px 0px;
}

/*-----------------
	10.Home Three
-----------------------*/

.home-three {
    background-image: none;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
	padding: 0;
}
.home-three .banner-content .form-control::placeholder,
.home-three .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #9d9d9d;
}
.dot-slider .slick-dots {
    position: absolute;
    top: 30%;
    right: 50px;
    bottom: unset;
    width: unset;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 5px;
    padding: 0;
    cursor: pointer;
    color: transparent;
	border: 0;
	outline: none;
	background: #C0C0C0;
}
.dot-slider .slick-dots {
	position: absolute;
	top: 50%;
    right: 50px;
    bottom: unset;
    width: unset;
    left: unset;
	-webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
.dot-slider.slick-vertical .slick-slide {
    height: 589px;
	border: 0px solid transparent;
}
.dot-slider .slick-dots li {
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 8px 0;
}
.dot-slider .slick-dots li button {
	width: 7px;
    height: 7px;
	border-radius: 50%;
	background-color: #fff;
}
.dot-slider .slick-dots li.slick-active button {
    background-color: #1860CA;
}
.dot-slider .slick-dots li button:hover,
.dot-slider .slick-dots li button:focus {
    outline: none;
}
.dot-slider .slick-dots li button:hover:before,
.dot-slider .slick-dots li button:focus:before {
    opacity: 1;
}
.dot-slider .slick-dots li button:before {
	display: none;
    font-family: 'slick';
	font-size: 76px;
	line-height: 20px;
	position: absolute;
	top: 0;
	left: 0;
	width: 20px;
	height: 20px;
	content: '-';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.dot-slider .slick-dots li.slick-active button {
    background-color: #E65425;
}
.dot-slider .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #0071DC;
}
.dot-slider .slick-slide {
	outline: none !important;
}
.dot-slider.slick-dotted.slick-slider {
    margin-bottom: 0px;
}
.dot-slider .slick-track {
	height: 589px;
}
.home-three .slick-prev,
.home-three .slick-next {
	background: #fff;
    width: 40px;
    height: 40px;
    top: inherit;
    bottom: 50px;
    border-radius: 50px;
}
.home-three .slick-prev:hover:before,
.home-three .slick-next:hover:before {
	filter: invert(0) brightness(100);
}
.home-three .slick-prev {
	right: 90px;
}
.home-three .slick-next {
	right: 40px;
}
.home-three .slick-prev:before,
.home-three .slick-next:before {
	content: "";
	background-image: url(../../assets/img/pre.svg);
	background-repeat: no-repeat;
    position: absolute;
    width: 13px;
    height: 13px;
	top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%)rotate(-45deg);
    -ms-transform: translate(-50%, -50%)rotate(-45deg);
    transform: translate(-50%, -50%)rotate(-45deg);
	background-size: cover;
}
.home-three .slick-prev:before {
	background-image: url(../../assets/img/pre.svg);
}
.home-three .slick-next:before {
	background-image: url(../../assets/img/next.svg);
}
.banner-kofe {
	position: absolute;
	top: 50%;
	-webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
	padding-top: 120px;
}
.banner-kofe .banner-content .rating .checked {
    color: #FFC700;
}
.banner-kofe .banner-content h5 {
	color: #fff;
}
.home-three .banner-content h1 {
    font-size: 32px;
    color: #fff;
	margin-bottom: 34px;
}
.home-three .banner-content .form-inner,
.home-three .banner-content .sub-btn {
	border-radius: 5px !important;
}
.company-hire {
	padding: 60px 0px;
	background: #E65425;
}
.hire-heading h6 {
	color: #fff;
}
.company-hire .company-logos {
	background: #fff;
	padding: 7px 0px;
	border: 1px solid #EBEBEB;
	border-radius: 4px;
}
.section-header-three .header-title {
    margin-bottom: 10px;
}
.section-header-four .header-title {
	margin-bottom: 15px;
}
.different-categories {
	padding: 80px 0px;
}
.different-categories-blk {
	background: #FFFFFF;
	margin-bottom: 25px;
	width: 100%;
	border: 1px solid #EBEBEB;
	border-radius: 5px;
	padding: 15px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.different-categories-blk:hover {
	border: 1px solid #EBEBEB;
	box-shadow: -10px 54px 44px rgba(218, 218, 218, 0.25);
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.category-foot {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.category-foot a {
	border: 1px solid #EBEBEB;
	width: 31px;
	height: 31px;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.category-foot a:hover,
.feature-developer-foot a:hover {
	background: #131135;
	border: 1px solid #131135;
	color: #fff;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.differ-img {
	margin-bottom: 20px;
}
.differ-content h6 {
	color: #2E2E2E;
	margin-bottom: 10px;
}
.differ-content p,
.category-foot span {
	color: #5F5F5F;
	font-weight: 500;
	font-size: 14px;
}
.differ-content p {
	margin-bottom: 25px;
}
.best-marketplace {
	padding: 80px 0px;
	background: #FBFBFB;
}
.market-place-group h2 {
    font-weight: 700;
    margin-bottom: 15px;
	font-size: 40px;
}
.market-place-group h2 span {
	color: #E65425;
}
.market-place-group p {
	color: #6B7385;
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 40px;
}
.market-list-out {
	margin-bottom: 40px;
}
.market-list-out li {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 14px;
	list-style: none;
}
.market-list-out li:last-child {
	margin-bottom: 0;
}
.market-place-btn {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.market-place-btn .btn-primary {
	min-width: 137px;
	padding: 10px 0px;
	font-weight: 600;
	font-size: 15px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.market-place-btn .btn-primary:hover {
	background: #01243f ;
    border-color: #01243f ;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.market-place-btn .market-developer {
	border: 1px solid #101828;
    border-radius: 5px;
    background: transparent;
    color: #000000;
	font-size: 15px;
}
.market-place-btn .market-developer:hover {
	border: 1px solid #101828;
    background: #101828;
    color: #fff;
}
.project-item.trend-project-item {
	padding: 15px;
	border: 1px solid #EBEBEB;
	border-radius: 5px;
	box-shadow: none;
}
.trend-project-item .project-img {
	padding: 0;
	margin-bottom: 10px;
}
.trend-project-content h5 {
	color: #E65425;
	margin-bottom: 10px;
	font-size: 14px;
}
.trend-project-content h4 {
	font-size: 18px;
	color: #101828;
	font-weight: 600;
	margin-bottom: 15px;
}
.trend-project-list {
	border-bottom: 1px solid #EBEBEB;
	margin-bottom: 15px;
	padding-bottom: 20px;
}
.trend-project-list li {
	list-style: none;
	font-size: 14px;
}
.trend-project-list li+li {
	margin-top: 12px;
}
.trend-rate .nav {
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.trend-rate li span {
	color: #E65425;
	font-weight: 600;
}
.trend-rate li {
	color: #515151;
	font-size: 14px;
}
.owl-carousel .owl-item .trend-project-list img {
    display: flex;
    width: inherit;
}
.trend-project-list li {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.view-locations.all-views {
	margin-top: 25px;
	display: inline-flex;
}
.view-locations.all-views .btn-primary {
	max-width: 191px;
}
.owl-carousel.trend-slider .owl-nav {
	position: absolute;
    margin: 0;
    right: 0;
	top: -97px;
	min-width: 90px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.owl-carousel.trend-slider .owl-nav .owl-prev,
.owl-carousel.trend-slider .owl-nav .owl-next {
	z-index: 9;
}
.owl-carousel.trend-slider .owl-nav .owl-prev:hover,
.owl-carousel.trend-slider .owl-nav .owl-next:hover {
	background: transparent;
	color: #000;
}
.owl-carousel.trend-slider .owl-dots {
	position: absolute;
    margin: 0;
    right: 0;
	min-width: 90px;
	top: -94px;
}
.owl-carousel.trend-slider .owl-dots span {
	margin: 0px 2px;
	width: 7px;
    height: 7px;
}
.trend-slider.owl-theme .owl-dots .owl-dot span {
	background: #D9D9D9;
}
.trend-slider.owl-theme .owl-dots .owl-dot.active span {
	background: #263238;
}
.feature-count-head .header-title {
	font-size: 24px;
	color: #fff;
}
.feature-count {
	position: relative;
    padding-bottom: 20px;
    padding-top: 50px;
	background: #E65425;
}
.feature-counts {
	position: relative;
    padding: 80px 0;
	background: #FE4A23;
}
.feature-count .feature-content {
	margin-top: 0;
}
.feature-count .feature-icon {
    margin-bottom: 25px;
    position: relative;
}
.feature-count .feature-item {
	background: #fff;
	box-shadow: none;
	border: 0;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.feature-count .feature-item:hover {
	background: #fff;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.feature-count .feature-item:hover img {
	filter: invert(64%) sepia(215%) saturate(478%) hue-rotate(266deg) brightness(68%) contrast(628%);
}
.feature-count .feature-content h3 {
	color: #fff;
	margin-bottom: 3px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.feature-count .feature-content p {
	color: #FFF5F1;
	font-size: 14px;
	font-weight: 500;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.feature-count .feature-item:hover .feature-content h3 {
	color: #101828;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.feature-count .feature-item:hover .feature-content p {
	color: #5F5F5F;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.project-item.project-item-feature {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	box-shadow: 0px 4px 34px rgba(233, 233, 233, 0.25);
	border-radius: 10px;
	padding: 10px;
}
.heart-blk .favourite.three-active {
	color: #fff;
    background: #E65425;
    border: 1px solid #E65425;
}
.project-item-feature .project-img {
	padding: 0;
	position: relative;
}
.feaure-project {
	padding: 80px 0px;
}
.feature-project-content {
	padding: 20px 0px 10px;
}
.feature-project-content h4 {
	margin-bottom: 5px;
	font-size: 18px;
	color: #353535;
}
.feature-project-content p {
	color: #515151;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 10px;
}
.feature-project-content .rating {
	margin-bottom: 20px;
}
.feature-developer-foot a {
	border: 1px solid #EBEBEB;
    width: 32px;
    height: 32px;
    border-radius: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.heart-blk .favourite {
	width: 28px;
	height: 28px;
	background: #FFFFFF;
	box-shadow: 0px 4px 4px rgba(206, 206, 206, 0.25);
	border: 0;
	left: 10px;
	right: unset;
    top: 10px;
}
.circle-active {
    background-color: #fff;
    color: #38B653;
    font-size: 16px;
    position: absolute;
    right: 10px;
    top: 14px;
    border-radius: 100%;
    z-index: 2;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.hour-dollr {
	background: #FFFFFF;
	border-radius: 5px;
	position: absolute;
	bottom: 10px;
	right: 10px;
	font-size: 14px;
	font-weight: 500;
	padding: 3px 10px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.owl-carousel .hour-dollr img {
	width: auto;
}
.project-img:hover .hour-dollr img {
	transform: unset;
}
.blog.blog-trick {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	box-shadow: 0px 4px 34px rgba(233, 233, 233, 0.25);
	border-radius: 10px;
	padding: 20px;
	box-shadow: none;
}
.blog-trick .blog-image img {
	border-radius: 5px;
}
.blog-trick .blog-image {
	margin-bottom: 20px;
	border-radius: 5px;
}
.blog-trick-content p {
	color: #5F5F5F;
	margin-bottom: 30px;
}
.blog-trick-content h3 {
	font-size: 20px;
	color: #2B2B2B;
}
.post-author.blog-hover-img img {
    width: 45px !important;
	height: 45px;
}
.post-author-three span {
	color: #2B2B2B;
	font-weight: 500;
	font-size: 14px;
}
.post-author.blog-hover-img a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.skill-head.skill-head-two .nav {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	margin-bottom: 55px;
}
.skill-head.skill-head-two .nav-pills .nav-link {
	padding: 0 15px;
}
.skill-head.skill-head-two .nav-pills .nav-link.active, 
.skill-head.skill-head-two .nav-pills .show>.nav-link {
    color: #101828;
}
.next-job {
	padding: 80px 0;
    background-image: url(../../assets/img/bg/bg-23.jpg);
    background-repeat: no-repeat, no-repeat;
    background-position: right bottom;
    background-size: cover;
	position: relative;
}
.next-job:before { 
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background: #E65425;
	opacity: 0.97;
	top: 0;
}
.job-next-blk h2 {
	color: #fff;
	font-size: 32px;
	margin-bottom: 20px;
}
.job-next-blk p {
	color: #FFF5F1;
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 35px;
}
.next-job-btn {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.next-job-btn .btn-primary {
	font-size: 15px;
	font-weight: 600;
	min-width: 158px;
	padding: 10px 15px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.next-job-btn .btn-primary.next-sign:hover { 
	background: transparent;
	color: #fff;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.next-job-btn .btn-primary.next-sign {
	background: #fff;
	border: 1px solid #fff;
	color: #101828;
}
.next-job-btn .btn-primary.next-register {
	background: transparent;
	border: 1px solid #fff;
	color: #fff;
}
.next-job-btn .btn-primary.next-register:hover { 
    background: #01243f ;
    border: 1px solid #fff;
    color: #fff;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.owl-carousel .owl-item img {
    display: block;
    width: inherit;
}
.review-people {
	padding: 80px 0 130px;
	background-color: #F7F7F7;
	background-image: url(../../assets/img/bg/bg-17.png);
    background-repeat: no-repeat, no-repeat;
    background-position: right bottom;
    background-size: cover;
}
.review-love-group {
	max-width: 680px;
	margin: auto;
}
.review-passage {
	text-align: center;
	max-width: 600px;
	margin: auto;
	margin-bottom: 40px;
}
.quote-love-img img {
	margin: auto;
}
.quote-love-img {
	margin-bottom: 45px;
}
.review-profile-blk {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background: #FFFFFF;
	border-radius: 110px;
	padding: 8px;
	margin-right: 15px;
	min-width: 200px;
}
.review-name-blk h6 a {
	color: #000000;
}
.review-name-blk h6 {
	margin-bottom: 2px;
}
.review-name-blk p {
	color: #515151;
	font-size: 14px;
	margin-bottom: 0;
}
.owl-carousel .owl-item .reviw-profile-img img {
	width: 48px;
	height: 48px;
	border-radius: 50px;
}
.review-love-list {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.review-people .owl-carousel.trend-slider .owl-dots {
    position: absolute;
    margin: 0;
    left: 50%;
    min-width: 90px;
    bottom: -42px;
	top: inherit;
	right: inherit;
	-webkit-transform: translate(-50%, 50%);
    -ms-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
}
.review-people .owl-carousel.trend-slider .owl-nav {
    left: 46%;
    bottom: -62px;
    min-width: 90px;
	top: inherit;
	right: inherit;
}
.scroll-top.scroll-top-next {
	-webkit-transform: rotate(46deg);
    -ms-transform: rotate(46deg);
	transform: rotate(46deg);
	background: #fff;
	border-radius: 0;
	box-shadow: 0px 4px 20px rgb(224 224 224 / 40%);
}
.scroll-top.scroll-top-next:hover {
	background: #71d3a9;
}
.scroll-top.scroll-top-next:hover span {
	color: #fff;
}
.scroll-top.scroll-top-next span { 
	-webkit-transform: rotate(-46deg);
    -ms-transform: rotate(-46deg);
	transform: rotate(-46deg);
	color: #161515;
}

/*-----------------
	10.Home Four
-----------------------*/

.home-four {
	background-color: #FFEBE0;
    padding: 0;
	min-height: 625px;
}
.home-banner.home-four {
	position: relative;
}
.banner-float-img {
	position: absolute;
	right: 0;
	top: 0;
	width: 700px;
	height: 100%;
}
.banner-float-img img {
	width: 100%;
	height: 100%;
}
.home-four:before {
	content: "";
	position: absolute;
	background-image: url(../../assets/img/bg/bg-24.svg);
    background-repeat: no-repeat;
	background-position: left center;
	width: 120px;
    height: 250px;
    background-size: 95%;
	top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
.banner-blk-four {
	width: 100%;
	padding-top: 100px;
	display: inline-grid;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.home-four .banner-content h5 {
    font-weight: 600;
    font-size: 18px;
    color: #E65425;
    text-transform: unset;
	margin-bottom: 15px;
}
.home-four .banner-content .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #6B7385;
}
.home-four .banner-content h1 {
    font-weight: 700;
    font-size: 40px;
    margin-bottom: 10px;
}
.home-four .banner-content p {
	color: #6B7385;
	font-size: 18px;
	margin-bottom: 40px;
}
.home-four .banner-content .form-control::placeholder {
	color: #9d9d9d;
}
/* .home-four .banner-content .sub-btn {
    background: #E65425;
    border-radius: 5px !important;
    font-size: 16px;

}
.home-four .banner-content .sub-btn:hover {
	background: #E65425;
	border: 1px solid #E65425;
} */
.home-four .banner-content .form-inner,
.home-four .banner-content .sub-btn {
	border-radius: 5px !important;
}
.home-four-wrapper .header {
	position: relative;
}
.home-four-wrapper .header-navbar-rht li::after {
    position: absolute;
    content: "";
    height: 19px;
    width: 1px;
    background: #BFBFBF;
    right: -13px;
    top: 13px;
}
.home-four-wrapper .header-navbar-rht li:last-child:after {
	display: none;
}
.home-four-wrapper .header-navbar-rht li:nth-child(4):after {
 	display: none;
}
.fullstack-blk {
	background: #FFFFFF;
	box-shadow: 0px 4px 64px rgba(143, 143, 143, 0.25);
	border-radius: 10px;
	padding: 15px;
	max-width: 234px;
	text-align: center;
	position: absolute;
    top: 120px;
    right: 30px;
}
.stacker-active {
	background-color: #fff;
    color: #38B653;
    font-size: 14px;
    position: absolute;
    right: 10px;
    bottom: 0px;
    border-radius: 100%;
    z-index: 2;
    width: 19px;
    height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.fullstacker-img {
	width: 90px;
	height: 90px;
	position: relative;
	margin: auto;
	margin-bottom: 20px;
}
.fullstacker-img img {
	border-radius: 50px;
}
.fullstacker-name h4 {
	color: #2B2B2B;
	font-weight: 600;
}
.fullstacker-name p {
	color: #515151;
	font-weight: 500;
	font-size: 14px;
	margin-bottom: 2px;
}
.coplete-job {
	background: #FFFFFF;
	border-radius: 10px;
	position: absolute;
    top: 40px;
    left: 42%;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 15px;
}
.complete-icon {
	width: 60px;
	height: 60px;
	background: #E65425;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.complete-icon i {
	font-size: 20px;
	color: #fff;
}
.complete-content h3 {
	font-weight: 700;
	color: #2E2E2E;
	margin-bottom: 5px;
}
.complete-content p {
	font-weight: 500;
	color: #5F5F5F;
	font-size: 14px;
	margin-bottom: 0;
}
.register-profesion {
	background: #FFFFFF;
	border-radius: 10px;
	position: absolute;
    bottom: 40px;
    left: 37%;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding: 20px;
	gap: 20px;
}
.register-profesion p {
	margin-bottom: 5px;
	font-weight: 600;
	margin-right: 16px;
}
.register-profesion span {
	color: #6B7385;
	font-size: 14px;
}
.register-profesion .avatar-group .avatar {
	width: 48px;
	height: 48px;
	border: 3px solid #fff;
	border-radius: 50px;
}
.register-profesion .avatar-group .avatar-xs + .avatar-xs {
    margin-left: -20px;
}
.browse-categories {
	padding: 80px 0px;
}
.browse-circle-blk {
	background: #F5F4F8;
	padding: 5px;
	margin-bottom: 25px;
	border-radius: 100px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.browse-circle-blk:hover {
	box-shadow: 0px 4px 24px rgba(215, 215, 215, 0.25);
}
.move-box {
	-webkit-transition: top ease .5s;
   transition: top ease .5s;
   position: relative;
   top:0;
}
.move-box:hover {
   top: -6px;
}
.browse-icon {
	background: #FFFFFF;
	width: 50px;
	height: 50px;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.browse-content h6 {
	color: #2E2E2E;
	font-size: 15px;
	font-weight: 500;
	margin-bottom: 0;
}
.browse-content p {
	color: #5F5F5F;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0;
}
.what-about-it {
	background: #FFF8F5;
	padding: 80px 0px;
}
.about-it-item {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	box-shadow: 0px 4px 24px rgba(215, 215, 215, 0.25);
	border-radius: 9px;
	padding: 24px;
	width: 100%;
	margin-bottom: 25px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.home-four-wrapper .about-it-item {
	margin-bottom: 0;
}
.about-it-item:hover {
	box-shadow: 0px 15px 30px rgb(215 215 215 / 50%);
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.about-it-icon {
	background: linear-gradient(270.05deg, #F8ECF8 -28.55%, #FFF3EA 112.03%);
	width: 70px;
	height: 70px;
	margin-bottom: 15px;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.about-great-content h4 {
	color: #2E2E2E;
	font-weight: 600;
	margin-bottom: 14px;
}
.about-great-content p {
	color: #6B7385;
	margin-bottom: 0px;
	font-size: 14px;
}
.feaure-for-developer {
	padding: 80px 0px;
}
.hour-dollr.develop-dollr {
	right: unset;
	left: 10px;
}
.develop-dollr img {
	width: auto;
} 
.foot-profile-btn .btn-primary {
	border-radius: 11px;
	font-size: 15px;
	font-weight: 600;
	padding: 8px 15px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.foot-profile-btn .btn-primary:hover {
	background-color: #01243f ;
    border: 1px solid #01243f ;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.join-marketplace {
    background: #FFF8F5;
    padding: 80px 0;
  
}
.market-place-group.join-place-blk h2 span {
    color: #2B2B2B;
}
.popular-projects {
	padding: 80px 0px;
}
.popular-group {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	border-radius: 10px;
	padding: 15px;
}
.popular-group .blog-image {
	border-radius: 10px;
	margin-bottom: 20px;
}
.head-popular h4 {
	color: #101828;
	font-weight: 600;
	font-size: 18px;
}
.head-popular p {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	color: #5F5F5F;
	font-size: 14px;
	margin-bottom: 0;
}
.head-popular {
	margin-bottom: 25px;
}
.popular-list-box {
	margin-bottom: 20px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.popular-list-box ul li {
	background: #FFF5F1;
	border-radius: 4px;
	font-size: 14px;
	color: #474747;
	font-weight: 500;
	padding: 6px 15px;
	min-width: 88px;
	margin-right: 10px;
	text-align: center;
}
.popular-list-box ul li:last-child {
	margin-right: 0px;
}
.popular-list-box h4 {
	color: #E65425;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 0;
}
.popular-list-box h4 span {
	color: #5F5F5F;
	font-size: 15px;
	font-weight: 500;
	padding-left: 5px;
}
.popular-foot {
	color: #5F5F5F;
	font-weight: 500;
	margin-bottom: 0;
	font-size: 15px;
}
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-next, 
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-prev {
	transform: none;
	background: #fff;
	border: 1px solid #EBEBEB;
	width: 44px;
	height: 44px;
	border-radius: 50px !important;
	box-shadow: none;
	filter: drop-shadow(0px 4px 108px rgba(90, 90, 90, 0.10));
}
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-next:hover, 
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-prev:hover {
	background: #E65425;
	border: 1px solid #E65425;
}
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-next i, 
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-prev i {
	transform: none;
	color: #6B7385;
}
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-next:hover i, 
.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-prev:hover i {
	color: #fff;
}
.popular-slider.developers-slider .owl-nav {
    bottom: 100%;
    right: 0;
    margin-bottom: 30px;
}
.achive-feature {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.achive-feature .feature-content p {
	margin-bottom: 0;
}
.achive-feature .feature-content h4 {
	color: #fff;
	margin-bottom: 5px;
}
.achive-feature:hover .feature-content h4 {
	color: #101828;
}
.review-four {
	background: #FFF5F1;
	padding: 80px 0px;
	position: relative;
	background-image: url(../../assets/img/bg/bg-28.png);
    background-repeat: no-repeat, no-repeat;
    background-position: left center;
}
.review-four .testimonial-thumb {
	padding: 10px 0;
}
.review-four .testimonial-thumb img {
	width: 58px;
	border-radius: 60px; 
}
.review-four .slider-nav {
	width: 300px;
	margin: auto;
}
.review-four .slick-list {
	padding: 0 !important;
}
.review-four .slider-nav .slick-current .testimonial-thumb {
	transform: scale(1.1);
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transition: ease all 0.5s;
	-webkit-transition: ease all 0.5s;
	-ms-transition: ease all 0.5s;
}
.review-four .slider-nav .slick-current .testimonial-thumb img {
	border: 2px solid #E65425;
	border-radius: 50px;
	padding: 2px;
}
.review-four .slick-prev:before, 
.slick-next:before {
	color: #000;
}
.review-four .slick-prev {
    left: 0;
    z-index: 99;
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    top: 50%;
}
.review-four .slick-prev:hover {
	background: #E65425;
}
.review-four .slick-next {
    right: 0;
	background: #fff;
    width: 50px;
    height: 50px;
	border-radius: 50px;
    top: 50%;
}
.review-four .slick-next:hover {
	background: #E65425;
}
.review-four .slick-slide {
	width: 70px;
}
.review-four .slick-prev:hover, 
.review-four .slick-next:hover, 
.review-four .swiper-button-prev:hover, 
.review-four .swiper-button-next:hover {
    color: #fff;
    opacity: 1;
}
.review-four .slick-prev:hover:before, 
.review-four .slick-prev:focus:before, 
.review-four .slick-next:hover:before, 
.review-four .slick-next:focus:before, 
.review-four .swiper-button-prev:hover:before, 
.review-four .swiper-button-next:hover:before {
    color: #fff;
    opacity: 1;
}
.review-four .slick-next:focus,
.review-four .slick-prev:focus {
	color: #000;
    background: #E65425;
}
.review-four .slick-prev:before, 
.review-four .slick-next:before {
	color: #000;
}
.review-four .slick-prev:before {
    content: "\f053";
    font-family: "Font Awesome 5 Free";
    font-size: 18px;
    font-weight: 900;
}
.review-four .slick-next:before {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-size: 18px;
    font-weight: 900;
}
.review-four .client-img img {
	margin: auto
}
.review-four .client-img {
	position: absolute;
    left: 50%;
    bottom: 70px;
    -webkit-transform:  translate(-50%, 0) !important;
    -ms-transform:  ttranslate(-50%, 0) !important;
    transform: translate(-50%, 0) !important;
}
.say-name-blk {
	margin-bottom: 40px;
}
.say-name-blk h6 {
	color: #353535;
	font-weight: 600;
}
.say-name-blk p {
	color: #515151;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 0;
}
.review-four .review-passage {
	font-weight: 600;
}
.platform-location {
	background: #fff;
	padding: 80px 0;
}
.market-place-group h2 .platform-head {
	color: #2E2E2E;
	display: block;
}
.market-place-group .platform-pasage {
	color: #000000;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 25px;
}
.market-place-btn.platform-btn .btn-primary {
    min-width: 141px;
    padding: 10px 0px;
    font-weight: 600;
}
.market-place-btn.platform-btn .project-post {
	background: #101828;
	border: 1px solid #2E2E2E;
}
.market-place-btn.platform-btn .project-post:hover {
	background: #71d3a9;
	border: 1px solid #71d3a9;
}
.blog-article {
	padding: 80px 0px;
	background: #FFF8F5;
}
.blog-article-group {
	background: #FFFFFF;
	border: 1px solid #EBEBEB;
	box-shadow: 0px 4px 34px rgba(233, 233, 233, 0.25);
	border-radius: 10px;
	padding: 8px;
	transition: all 0.5s;
}
.blog-article-group:hover .article-Production span{
	background: #E65425;
	color: #fff;
}
.blog-article-group .blog-image {
	border-radius: 10px;
	margin-bottom: 0;
	position: relative;
}
.blog-article-group .blog-image:before {
	content: "";
	background: linear-gradient(358.04deg, rgba(0, 0, 0, 0.85) 20.78%, rgba(0, 0, 0, 0) 84.46%);
	position: absolute;
	height: 100%;
	width: 100%;
	border-radius: 10px;
	z-index: 1;
}
.article-blog-content {
	position: absolute;
	bottom: 0px;
	z-index: 2;
	padding: 20px;
}
.article-blog-content span {
	color: #FFFFFF;
	font-size: 16px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 14px;
	gap: 8px;
}
.article-blog-content h4 {
	font-size: 20px;
	margin-bottom: 0px;
}
.article-blog-content h4:hover a {
	color: #E65425;
}
.article-blog-content h4 a {
	color: #fff;
}
.article-blog-content p {
	color: #EBEBEB;
	font-size: 15px;
	margin-bottom: 0px;
	font-weight: 500;
}
.article-Production {
	position: absolute;
	top: 20px;
	left: 20px;
	z-index: 9;
}
.article-Production span {
	background: #FFF6F1;
	border-radius: 5px;
	color: #6B7385;
	font-size: 14px;
	font-weight: 400;
	padding: 8px 20px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.top-company-four {
	padding: 80px 0;
	background: #FFF8F5;
}
.markrt-place-img {
	border-radius: 10px;
	overflow: hidden;
	height: 100%;
}
.section-header h5 {
	color: #2E2E2E;
	font-size: 18px;
	font-weight: 500;
}
.top-company-four .company-logos {
    border-radius: 10px;
    height: 65px;
    box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F8F8F8;
	transition: all 0.5s;
}
.top-company-four .company-logos:hover {
	background: #fff;
}
.top-company-four .owl-carousel .owl-stage-outer {
	padding: 10px 0;
}
.top-company-four .owl-carousel .owl-item .company-logos img {
    width: 138px;
    margin: auto;
}
.next-great-job {
	background: linear-gradient(270.05deg, #F8ECF8 -28.55%, #FFF3EA 112.03%);
	padding: 80px 0px;
}
.next-great-job:before {
	content: "";
	position: absolute;
	width: 160px;
    height: 160px;
	background-image: url(../../assets/img/bg/bg-27.png);
    background-repeat: no-repeat, no-repeat;
	background-position: right center;
	right: 60px;
    background-size: 140px;
}
.next-great-job .job-next-blk h2 {
    color: #2E2E2E;
    font-size: 32px;
    margin-bottom: 20px;
}
.next-great-job .job-next-blk p {
    color: #515151;
}
.next-job-btn.next-with-job .next-register {
	background: #101828;
	border: 1px solid #FFFFFF;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.next-job-btn.next-with-job .next-register:hover {
	background: #fff;
	border: 1px solid #fff;
	color: #101828;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.next-job-btn.next-with-job .next-sign:hover {
	background: #131135;
	border: 1px solid #131135;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-next, 
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-prev {
	transform: none;
	background: #fff;
	border: 1px solid #EBEBEB;
	width: 44px;
	height: 44px;
	border-radius: 50px !important;
	box-shadow: none;
}
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-next:hover, 
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-prev:hover {
	background: #E65425;
	border: 1px solid #E65425;
}
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-next i, 
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-prev i {
	transform: none;
	color: #E65425;
}
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-next:hover i, 
.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-prev:hover i {
	color: #fff;
}
.trust-slider.developers-slider .owl-nav {
    bottom: 100%;
    right: 0;
    margin-bottom: 33px;
}

/*-----------------
	10.Home Five
-----------------------*/

.home-five.home-banner {
    background-image: url(../img/banner6.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding-top: 0px;
}
.home-five.home-banner:before {
	content: "";
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 86.88%, rgba(0, 0, 0, 0) 100%);
	position: absolute;
	width: 100%;
	height: 100%;
}
.banner-blk-five .rating .checked {
    color: #FDC232;
}
.banner-blk-five .rating {
	margin-bottom: 10px;
}
.banner-blk-five {
	min-height: 650px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.banner-blk-five .home-count ul li,
.banner-blk-five .home-count ul li .list-count {
	color: #FFFFFF;
}
.banner-blk-five .home-count ul {
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.home-five .banner-content h1 {
    color: #fff;
}
.home-five .banner-content h5 {
    color: #FFFFFF;
	margin-bottom: 0;
}
.home-five .banner-content p {
    color: #FFFFFF;
	margin-bottom: 40px;
}
.home-five .banner-content .form-inner,
.home-five .banner-content .input-group {
    border-radius: 4px;
}
.home-five .banner-content .sub-btn {
 
	border-radius: 6px !important;
	padding: 11px 24px;
}
.home-five .banner-content .sub-btn i {
	font-size: 25px;
}
.home-five .banner-content .form-control::placeholder {
	color: #9D9D9D;
}
.need-job {
	padding: 80px 0px 0px;
	background-image: url(../../assets/img/bg/bg-29.png);
    background-repeat: no-repeat, no-repeat;
    background-position: left 20%,top;
}
.section-header-five .header-title {
    margin-bottom: 5px;
	color: #2e2e2e;
}
.section-header-five p {
    color: #515151;
    margin-bottom: 0px;
    padding-bottom: 20px;
}
.need-job-group {
	padding-bottom: 55px;
	border-bottom: 1px solid #ebebeb;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.need-job-group .about-it-item {
    background: #FFFFFF;
    border: 0;
	border-right: 1px solid #EBEBEB;
    box-shadow: none;
    border-radius: 0;
    padding: 0px 30px;
    width: 100%;
    margin-bottom: 25px;
}
.need-job-group .about-it-item:last-child {
	border-right: 0;
}
.need-job-group .about-it-item:hover {
	box-shadow: none;
}
.need-job-icon {
    margin-bottom: 20px;
}
.need-job-group .about-great-content h4 {
	color: #2e2e2e;
}
.need-job-group .about-great-content p {
    color: #353535;
}
.popular-projects-job {
	padding: 80px 0px;
	    background-image: url(../../assets/img/bg/bg-30.png), url(../../assets/img/bg/bg-31.png);
    background-repeat: no-repeat, no-repeat;
    background-position: right top, left center;
}
.section-header-five:before {
	content: "";
    width: 31px;
    height: 4px;
    background: #E65425;
    bottom: 0;
    left: 49%;
    position: absolute;
    border-radius: 50px;
	-webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}
.section-header-five:after {
	content: "";
    width: 14px;
    height: 4px;
    background: #E65425;
    bottom: 0;
    left: 52%;
    position: absolute;
    border-radius: 50px;
	-webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}
.project-item.project-popular-item {
	background: #FFFFFF;
	border: 1px solid #FFF5F1;
	box-shadow: 0px 4px 44px rgba(220, 220, 220, 0.25);
	border-radius: 10px;
	padding: 10px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.project-item.project-popular-item:hover {
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 20%);
}
.project-item.project-popular-item .project-img {
    padding: 0;
}
.project-item .feature-foot p {
	color: #5F5F5F;
	font-weight: 500;
	margin-bottom: 0;
	font-size: 15px;
}
.project-item .feature-foot p span {
	color: #E65425;
	font-weight: 700;
}
.recent-categories {
	padding: 80px 0px;
    background: linear-gradient(113.89deg, #FFFCFB 51.94%, #FFDCD4 100.96%, #FFFFFF 100.97%);
    position: relative;
	z-index: 1;
}
.recent-categories:before {
    content: "";
    background-image: url(../../assets/img/bg/bg-32.png);
    background-repeat: no-repeat, no-repeat;
    background-position: left center;
    position: absolute;
	width: 170px;
    height: 220px;
    left: 0;
    top: 20%;
    z-index: -1;
	background-size: 145px;
}
.update-icon-end i {
	color: #9D9D9D;
}
.profesion-job {
	padding: 80px 0px;
	background-image: url(../../assets/img/bg/bg-34.png);
    background-repeat: no-repeat;
    background-position: right center;
	background-size: 145px;
}
.demand-post-job {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-bottom: 20px;
}
.demand-post-img {
	margin-right: 20px;
	flex-shrink: 0;
	width: 45px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.demand-professional {
	flex-grow: 1;
}
.demand-professional h2 {
	color: #2E2E2E;
	font-size: 40px;
	font-weight: 700;
	margin-bottom: 15px;
}
.demand-professional p  {
	color: #515151;
	font-weight: 500;
	margin-bottom: 20px;
}
.demand-content h6 {
	color: #000000;
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 10px;
}
.demand-content p {
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 0;
	color: #6B7385;
}
.market-place-join {
	padding: 0px 0px 80px
}
.market-place-bg {
	background: #FBFBFB;
	padding-top: 45px;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0px 8px 16px 0px rgba(142, 141, 208, 0.12);
}
.join-market-world {
	padding-left: 100px;
}
.join-market-world h2 {
	color: #101828;
	font-size: 28px;
	font-weight: 700;
	margin-bottom: 30px;
}
.join-market-world p {
	color: #515151;
	font-weight: 600;
	margin-bottom: 50px;
}
.market-place-btn.bidding-btn .btn-primary {
	min-width: 157px;
}
.market-place-btn.bidding-btn .project-post {
	background: #101828;
	border: 1px solid #101828;
}
.market-place-btn.bidding-btn .project-post:hover {
    background: #E65425;
    border: 1px solid #E65425;
}
.market-place-btn.bidding-btn .market-project:hover {
    background: #131135;
    border: 1px solid #131135;
}
.join-platform-img:before {
	content: "";
	position: absolute;
	background-image: url(../../assets/img/bg-market.png);
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
    background-position: right bottom;
}
.join-platform-img,
.join-platform-img img {
	position: relative;
}
.talent-developer {
	padding: 80px 0px;
	background: #FBFBFB;
}
.talent-developer:before {
	content: "";
	background-image: url(../../assets/img/bg/bg-33.png);
    background-repeat: no-repeat;
    background-position: right top;
	background-size: 250px;
    width: 260px;
    height: 220px;
    position: absolute;
    right: 40px;
}
.freelance-widget.talent-widget {
	background: #FFFFFF;
	box-shadow: 0px 4px 34px rgba(220, 220, 220, 0.25);
	border-radius: 10px;
	border: 0;
}
.talent-widget .freelance-img img {
    margin: auto;
    width: 106px;
    height: 106px;
    border-radius: 50%;
    border: 0;
    padding: 0;
}
.talent-widget .freelance-tags {
	border-bottom: 1px solid #EBEBEB;
	padding-bottom: 10px;
}
.talent-widget .freelance-content {
    padding-bottom: 10px;
}
.talent-widget.freelance-widget .rating {
    margin-bottom: 15px;
}
.talent-widget .freelance-specific {
    margin-bottom: 5px;
}
.talent-foot-list ul {
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.talent-foot-list ul li {
	text-align: left;
	margin-right: 20px;
	color: #2E2E2E;
	font-size: 14px;
	font-weight: 500;
}
.talent-foot-list ul li:last-child {
	margin-right: 0px;
}
.talent-foot-list ul li span {
	display: block;
	color: #515151;
	font-weight: 400;
}
.compay-hire {
	padding: 80px 0px;
	background-image: url(../../assets/img/bg/bg-35.png);
    background-repeat: no-repeat;
    background-position: right center;
}
.compay-hire-blk h3 {
	color: #2E2E2E;
	font-weight: 700;
	margin-bottom: 15px;
}
.compay-hire-blk p {
	font-weight: 500;
	margin-bottom: 25px;
}
.market-place-btn.company-register-btn .market-project {
	min-width: 177px;
}
.market-place-btn.company-register-btn .project-post {
	min-width: 206px;
	background: #101828;
	border: 1px solid #101828;
}
.market-place-btn.company-register-btn .project-post:hover {
    background: #E65425;
    border: 1px solid #E65425;
}
.market-place-btn.company-register-btn .market-project:hover {
    background: #131135;
    border: 1px solid #131135;
}
.hire-company-list ul li {
	padding: 10px 30px;
	width: 180px;
	min-height: 80px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-evenly;
	-webkit-justify-content: space-evenly;
	-ms-flex-pack: space-evenly;
	border: 1px solid #EBEBEB;
}
.hire-company-list ul li:nth-child(1),
.hire-company-list ul li:nth-child(2),
.hire-company-list ul li:nth-child(3),
.hire-company-list ul li:nth-child(4),
.hire-company-list ul li:nth-child(5),
.hire-company-list ul li:nth-child(6),
.hire-company-list ul li:nth-child(7),
.hire-company-list ul li:nth-child(8),
.hire-company-list ul li:nth-child(9) {
	border-top: 0;
	border-left: 0;
}
.hire-company-list ul li:nth-child(3),
.hire-company-list ul li:nth-child(6),
.hire-company-list ul li:nth-child(9) {
	border-right: 0;
}
.hire-company-list ul li:nth-child(7),
.hire-company-list ul li:nth-child(8),
.hire-company-list ul li:nth-child(9) {
	border-bottom: 0;
}
.review-five {
	padding: 80px 0 80px 0px;
	background: #FFF8F5;
	position: relative;
}
.review-five:before {
    content: "";
    background-image: url(../../assets/img/bg/bg-32.png);
    background-repeat: no-repeat, no-repeat;
    background-position: left center;
    position: absolute;
    width: 170px;
    height: 220px;
    left: 0;
    top: 20%;
    background-size: 145px;
	display: none;
}
.review-five .testimonial-all {
	margin-top: 30px;
	padding-bottom: 50px;
	position: relative;
}
.review-five .testimonial-thumb {
	padding: 20px 0;
}
.review-five .testimonial-thumb img {
	width: 60px;
	border-radius: 60px; 
}
.testimonial-two-head {
	max-width: 100%;
    background: transparent;
    border-radius: 10px;
	position: relative;
}
.testimonial-two-head h3 {
	margin: 0px 0px 10px;
	font-size: 24px;
	font-weight: 700;
	color: #2b2b2b;
}
.testimonial-two-head span {
	margin-bottom: 20px;
	color: #515151;
	font-weight: 500;
	display: block;
}
.testimonial-two-head p {
	font-weight: 500;
	line-height: 1.7;
	margin-bottom: 20px;
}
.img-reviews img {
	max-width: 193px;
	margin: auto;
}
.review-quote img {
	position: absolute;
    max-width: 60px;
    top: -27px;
}
.review-five .slider-nav {
	width: 370px;
	margin-left: auto;
}
.review-five .slick-list {
	padding: 0 !important;
}
.review-five .client-img {
	position: absolute;
	right: 0px;
    bottom: -50px;
}
.review-five .client-img img {
	margin: auto
}
.slid-review-group {
	position: relative;
}
.review-five .slider-nav .slick-current .testimonial-thumb {
	transform: scale(1.4);
	-webkit-transform: scale(1.4);
	-ms-transform: scale(1.4);
	transition: ease all 0.5s;
	-webkit-transition: ease all 0.5s;
	-ms-transition: ease all 0.5s;
}
.review-five .slick-prev:before, 
.review-five .slick-next:before {
	color: #000;
}
.review-five .slick-prev {
    left: 0;
    z-index: 99;
    background: #fff;
    width: 44px;
    height: 44px;
    border-radius: 50px;
    bottom: 0px;
    top: inherit;
	filter: drop-shadow(0px 4px 4px rgba(209, 209, 209, 0.25));
}
.review-five .slick-prev:hover,
.review-five .slick-next:hover {
	background: #71d3a9;
}
.review-five .slick-next {
    right: auto;
	left: 55px;
	background: #fff;
    width: 44px;
    height: 44px;
	border-radius: 50px;
	bottom: 0px;
    top: inherit;
	filter: drop-shadow(0px 4px 4px rgba(209, 209, 209, 0.25));
}
.review-five .slick-next:before .testimonial-two .slick-next:hover {
	color: #fff;
}
.review-five .slick-slide {
	width: 70px;
}
.review-five .slick-prev:hover, 
.review-five .slick-next:hover, 
.review-five .swiper-button-prev:hover, 
.review-five .swiper-button-next:hover {
    color: #fff;
    opacity: 1;
}
.review-five .slick-prev:hover:before, 
.review-five .slick-prev:focus:before, 
.review-five .slick-next:hover:before, 
.review-five .slick-next:focus:before, 
.review-five .swiper-button-prev:hover:before, 
.review-five .swiper-button-next:hover:before {
    color: #fff;
    opacity: 1;
}
.review-five .slick-next:focus,
.review-five .slick-prev:focus {
	color: #000;
    background: #71d3a9;
}
.review-five .slick-prev:before, 
.review-five .slick-next:before {
	color: #000;
}
.review-five .slick-prev:before {
    content: "\f060";
    font-family: "Font Awesome 5 Free";
    font-size: 18px;
    font-weight: 900;
}
.review-five .slick-center .testimonial-thumb img {
	border: 2px solid #FE4A23;
}
.review-five .slick-next:before {
    content: "\f061";
    font-family: "Font Awesome 5 Free";
    font-size: 18px;
    font-weight: 900;
}
.review-five .slick-center .say-client .say-client-name {
	display: block;
}
.img-reviews img {
	border-radius: 50%;
}
.blog-tricks-five {
	padding: 80px 0px 230px;
}
.blog-trick-five .owl-dots {
	display: none;
}
.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-prev {
    top: inherit;
    left: 48%;
}
.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-next {
    top: inherit;
    right: 52%;
}
.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-prev, 
.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-next {
	background: #FFF5F1;
}
.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-prev:hover, 
.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-next:hover {
    background: #E65425;
    color: #fff;
}
.grid-blog.blog-five {
	padding: 20px;
	border: 1px solid #EBEBEB;
	box-shadow: 0px 4px 24px rgba(204, 204, 204, 0.25);
	border-radius: 10px;
}
.blog-trick-read {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.grid-blog.blog-five .blog-trick-read p {
    margin-bottom: 0;
	color: #E65425;
}
.grid-blog.blog-five .blog-content {
	padding: 20px 0px 0px;
}
.blog-trick-read {
	padding-bottom: 15px;
}
.blog-trick-read a {
	font-size: 14px;
	font-weight: 500;
}
.grid-blog.blog-five .blog-title {
	color: #2b2b2b;
}
.grid-blog.blog-five .blog-reead {
	color: #5f5f5f;
}
.great-opportunity {
	padding: 80px 0px;
	background: linear-gradient(113.89deg, #FFFCFB 51.94%, #FFDCD4 100.96%, #FFFFFF 100.97%);
}
.great-find-job.job-next-blk h2 {
	color: #2B2B2B;
}
.great-find-job.job-next-blk p {
	color: #515151;
}
.next-job-btn.next-reg-now {
	justify-content: start;
	-webkit-justify-content: start;
	-ms-flex-pack: start;
}
.next-job-btn.next-reg-now .next-sign {
	background: #E65425;
	border: 1px solid #E65425;
	color: #fff;
}
.next-job-btn.next-reg-now .next-sign:hover {
	background: #131135;
    border: 1px solid #131135;
}
.next-job-btn.next-reg-now .next-register {
	background: #101828;
	border: 1px solid #101828;
	color: #fff;
}
.next-job-btn.next-reg-now .next-register:hover {
	background: #E65425;
    border: 1px solid #E65425;
	color: #fff;
}
.great-opportunity {
	position: relative;
}
.opportunity-img {
	position: absolute;
	bottom: 0;
	right: 0;
}

/*-----------------
	Loader
-----------------------*/

div#global-loader {
    background: #FFF5F1;
    position: fixed;
    z-index: 999999;
    width: 100%;
    height: 100vh;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	top: 0;
}
.whirly-loader {
	background: #fff;
	border: 4px solid #fff;
	border-radius: 50%;
	border-top: 4px solid #E65425;
	width: 100px;
	height: 100px;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}
.loader-img {
	position: absolute;
    z-index: 9;
}
@-webkit-keyframes spin {
	0% { 
		-webkit-transform: rotate(0deg); 
	}
	100% { 
		-webkit-transform: rotate(360deg); 
	}
}

@keyframes spin {
	0% { 
		transform: rotate(0deg);
	}
	100% { 
		transform: rotate(360deg); 
	}
}

/*-----------------
	9. Slick Slider
-----------------------*/

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;
    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide {
    float: right;
}
.home-three .slick-slide img {
    display: block;
    height: 800px;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-prev,
.slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: -50px;
    display: block;
    width: 32px;
    height: 32px;
    padding: 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
	border-radius: 10px;	
    cursor: pointer;	
    border: none;
    outline: none;
    background: #131135;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    background-color: #71d3a9;
	color: #fff;
	opacity: 1;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
	color: #fff;
    opacity: 1;
	filter: invert(1) brightness(200);
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}
.slick-prev:before,
.slick-next:before {
    font-size: 18px;
    line-height: 1;
    opacity: .75;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-prev {
    left: auto;
    z-index: 1;
    right: 52px;
}
[dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
}
.slick-prev:before {
    content: '\003c';
    display: inline-block;
    transform: rotate(-45deg);
}
[dir='rtl'] .slick-prev:before {
    content: '\25C0';
}
.slick-next {
    right: 10px;
}
[dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
}
.slick-next:before {
    content: '\003e';
	transform: rotate(-45deg);
	display: inline-block;
}
[dir='rtl'] .slick-next:before {
    content: '\25B6';
}
.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}
.slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: auto;	
    transform: translate(-50px, 0);
    left: 50%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}
.slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover, .slick-dots li button:focus {
    outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
    opacity: 1;
}
.slick-dots li button:before {
    font-family: 'slick';
    font-size: 40px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #E65425;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #E65425;
}

/*-----------------
	11. Login
-----------------------*/

.account-page {
	background-color: #fff;
}
.account-page .footer {
	display: none;
}
.account-page .content {
    /* padding: 144px 0 60px; */
	background: transparent;
}
.login-header {
	margin-bottom: 20px;
}
.account-page .login-wrapper {
    background-image: url(../img/login-bg\ \(2\).jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    overflow: auto;
	display: flex;
    align-items: center;
    justify-content: center;
	flex-wrap: wrap;
	padding: 10px;
}
.account-page .account-content {
    background-color: #fff;
    border-radius: 30px;
    max-width: 480px;
    margin: auto;
    padding: 30px;
    box-shadow: 0px 8px 16px 0px rgba(142, 141, 208, 0.12);
	width: 480px;
}
.login-header h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 9px;
    color: #1B1B1B;
}
.form-focus .focus-label {
    font-size: 14px;
    pointer-events: none;
    transition: 240ms;
    z-index: 1;
    color: #131135;
    font-weight: 600;
}
.login-or p::after {
    content: "";
    width: 100%;
    height: 1px;
    background: #D9D9D9;
    position: absolute;
    left: 0;
    bottom: 11px;
    z-index: -1;
}
.login-right .user-tabs ul li .nav-link {
    padding: 7px 22px;
    border-radius: 6px;
    border: 1px solid #131135;
    background: #FFF;
    color: #131135;
}
.login-right .user-tabs ul li .nav-link:hover{
	background: #131135;
    border-color: #131135;
	color: #fff;
}
.login-right .user-tabs ul li .nav-link.active {
    background: #131135;
    border-color: #131135;
}
.login-right .user-tabs ul {
    justify-content: center;
}
.login-right .nav-justified .nav-item,.login-right  .nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 0;
    text-align: center;
}
.form-focus .form-control {
    height: 40px;
    padding: 10px;
    border: 1px solid #CED4E6;
}
.login-or {
    color: #d4d4d4;
    padding-top: 25px;
    position: relative;
    text-align: center;
}
.login-or p {
    font-size: 16px;
    color: #161c2d;
    margin-bottom: 0;
    position: relative;
    z-index: 1;
}
.login-or p span {
    color: #6B7385;
    font-weight: 600;
    display: inline-block;
    background: #fff;
    padding: 0 20px;
}
.login-or p span {
    color: #6B7385;
    font-weight: 600;
    display: inline-block;
    background: #fff;
    padding: 0 20px;
}
.label-star {
    color: #FF3B30;
}
.account-content .focus-label{
	font-weight: 600;
	color: #131135;
}
.social-login .btn-block img {
    margin-right: 8px;
}
.social-login .btn-block {
    border: 1px solid #CED4E6;
    border-radius: 6px;
    color: #6B7385;
    display: inline-block;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
}
.account-content .form-control{
	min-height: 40px;
}
.password-icon {
    position: absolute;
    top: 7px;
    right: 10px;
}
.toggle-password {
    color: #6E727D;
    font-size: 14px;
}
.password-icons {
    position: absolute;
    top: 7px;
    right: 10px;
}
.toggle-passwords {
    color: #6E727D;
    font-size: 14px;
}
.toggle-password1 {
    color: #6E727D;
    font-size: 14px;
}
.login-right .dont-have label.custom_check{
	font-weight: 400;
}
.social-login .btn-block {
    border: 1px solid #CED4E6;
    border-radius: 6px;
    color: #6B7385;
    display:flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
}
.account-content .btn-block:hover{
	background: #f5f5f5;
	color: #333;
}
.login-header img{
	margin-bottom: 15px;
}
.account-item .dropdown-toggle::after {
    display: none;
}
.login-header p {
	font-size: 16px;
	color: #17191A;
	margin-bottom: 20px;
	font-weight: 500;
}
.login-header h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #161c2d;
}
.login-header h3 a {
    color: #71d3a9;
    float: right;
    font-size: 15px;
    margin-top: 2px;
}
.login-right .dont-have {
    color: #161c2d;
	font-weight: 600;
    margin-top: 30px;
    font-size: 16px;
}
.login-right .dont-have label {
	color: #161c2d;
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 30px;
}
.login-right .dont-have a {
	color: #71d3a9;
}
.login-right .dont-have a:hover {
	text-decoration: underline;
}
.login-btn {
    font-size: 16px;
    font-weight: 600;
    background: #71d3a9;
    border: 1px solid #71d3a9;
    border-radius: 5px;
    padding: 10px;
}
.login-header span {
    color: #808080;
}
.digit-group {
    margin-bottom: 15px;
    justify-content: center;
}
.digit-group input {
    width: 60px;
    height: 60px;
    background: #F6F6FF;
    border: 1px solid #CED4E6;
    border-radius: 50%;
    margin-right: 8px;
    text-align: center;
}
.resend-code {
    color: #0A0A0A;
    text-align: center;
    margin-bottom: 15px;
}
.resend-code a {
    color: #295FCC;
    margin-left: 5px;
}
.expire-time-count {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: #F6F6FF;
    padding: 5px 12px;
    font-size: 12px;
    color: #1B1B1B;
    border-radius: 4px;
    margin-bottom: 30px;
}
.login-header h2 {
    font-size: 24px;
    font-weight: 600;
}
.login-header h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 9px;
    color: #1B1B1B;
}
.login-or {
	color: #d4d4d4;
	padding-bottom: 25px;
	padding-top: 25px;
	position: relative;
	text-align: center;
}
.login-or p{
	font-size: 16px;
	color: #161c2d;
	margin-bottom: 0;
}
.or-line {
	background-color: #e5e5e5;
	height: 1px;
	margin-bottom: 0;
	margin-top: 0;
	display: block;
}
.span-or {
	background-color: #fff;
	display: block;
	left: 50%;
	margin-left: -20px;
	position: absolute;
	text-align: center;
	top: -3px;
	width: 42px;
}
.forgot-link {
    color: #161c2d;
    font-weight: 600;
    margin-top: 30px;
    font-size: 16px;
    display: inline-block;
}
.btn-facebook {
    background: #3F76E4;
	border-radius: 165px;
    color: #fff;
    font-size: 14px;
	font-weight: bold;
    padding: 10px 12px;
}
.btn-google {
    background-color: #dd4b39;
	border-radius: 165px;
    color: #fff;
    font-size: 14px;
	font-weight: bold;
    padding: 10px 12px;
}
.btn-twitter {
    background: #29B2FF;
	border-radius: 165px;
    color: #fff;
    font-size: 14px;
	font-weight: bold;
    padding: 10px 12px;
}
.empty-content {
	padding: 50px 0;
}
.empty-content img {
	margin-bottom: 30px;
}
.empty-content h2 {
	text-transform: uppercase;
	color: #1B1B1B;
	margin-bottom: 30px;
	font-size: 40px;
}
.empty-content p {
	font-size: 14px;
	color:#6F6F6F;
	margin-bottom: 0;
	font-weight: 500;
}
.empty-content .btn-item {
    margin-top: 40px;
}
.title-detail .custom-file, .title-detail .custom-file-input {
	height: 48px;
}
.title-box {
	background: #fff;
	box-shadow: 0px 16px 24px 0px rgba(142, 141, 208, 0.12);
	border-radius: 5px;
	border: 1px solid #F4F7FF;
}
.title {
	border-bottom: 1px solid #F3F1F1;
	padding: 30px;
}
.detailshead {
    font-size: 22px;
    margin-bottom: 15px;
}
.projecthead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid #E7EBF7;
    margin-bottom: 24px;
}
.projecthead ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    margin: 0;
}
.projecthead h5 {
    font-size: 24px;
    margin: 0;
}
.projecthead ul li + li {
    margin-left: 20px;
}
.catergyset ul {
	list-style: none;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.catergyset ul h5 {
    font-size: 16px;
    color: #343348;
	margin: 0;
}
.scuess-popup {
    text-align: center;
}
.scuess-popup img {
    margin-bottom: 10px;
}
.scuess-popup h4 {
    font-size: 28px;
}
.scuess-popup a{
	background: #081733;
    color: #fff;
	padding: 10px 24px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
	display: inline-block;
}
.scuess-popup h5 {
    font-size: 16px;
    color: #343348;
    font-weight: 500;
	display: inline-block;
	margin-bottom: 20px;
}
.category-para p{
	margin: 0;
	color: #6B7385;
}
.title-box .freelance-tags a{
	margin-right: 10px;
}
.catergyset ul li {
	margin: 0 40px 10px 0;
}
.catergyset ul h5 span {
    font-weight: 400;
}
.title-box .freelance-tags {
    display: flex;
    align-items: center;
}
.title-box .freelance-tags h6 {
    margin: 0 10px 0 0;
}
.title h3{
	font-weight: 600;
	font-size: 20px;
	text-transform: uppercase;
	color: #161c2d;
	margin-bottom: 0;	
	position: relative;
	padding-bottom: 8px;
}
.title h3::before {
    height: 3px;
    content: "";
    position: absolute;
    width: 29px;
    background: #E65425;
    bottom: 0;
    left: 0;
}
.title h3::after {
    height: 3px;
    content: "";
    position: absolute;
    width: 11px;
    background: #E65425;
    bottom: 0;
    left: 32px;
}
.title-content {
	padding: 30px;
}
.select-project .title-content {
	padding: 0 0 30px;
}
.next-btn {
	background: #71d3a9;
	border-radius: 90px;
	border-color: #71d3a9;
	font-weight: bold;
    font-size: 17px;
    padding: 13px 50px;
	color: #fff;
}
.next-btn:hover {
	color: #fff;
	background-color: #01243F;
    border-color: #01243F;
}
.cancel-btn {
	background: #131135;
	border-radius: 90px;
	font-weight: bold;
    font-size: 17px;
    padding: 13px 50px;
	color: #fff;
	margin-right: 15px;
	text-transform: uppercase;
}
.cancel-btn:hover {
	color: #fff;
	background-color: #E65425;
    border-color: #E65425;
}
.technology {
	padding: 30px 0;
}
.technology .btn-item {
    margin: 25px 0;
}
.title-content h3 {
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 15px;
	color: #161c2d;
}
.title-content p {
	font-size: 14px;
	color: #494949;
	padding-top: 12px;
}
.upload-document {
    position: relative;
    width: 125px;
}
.upload-document img {
	border-radius: 4px;
	height: 80px;
	width: auto;
}
.upload-wrap .upload-document:not(:last-child) {
	margin-right: 25px;
}
.upload-document .btn-danger {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 17px;
    width: 28px;
	height: 28px;
	background: #F5365C;
	border-radius: 50%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.add-links.more-link {
	width: auto;
	height: auto;
	background: transparent;
	color: #898989;
	display: inline-block;
}
.add-links.more-link:hover {
	color: #898989;
	opacity: 0.8;
}
.back {
    margin-top: 35px;
	color: #E65425;
	padding-left: 20px;
}
.back p a{
	color: #E65425;
	font-weight: bold;
	font-size: 18px;
}
.back p::before {
    content: "\f104";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    left: 0;
    margin-right: 5px;
	font-size: 18px;
	font-weight: bold;
    position: absolute;
	color: #E65425;
	padding-left: 20px;
}
.add-links {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	height: 46px;
	width: 46px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	background-color: #E65425;
	color: #fff;
}
.add-links:hover {
	color: #fff;
	opacity: 0.8;
}

/*-----------------
	12. FAQ
-----------------------*/

.faq {
	padding: 30px 0;
}
.faq .card {
	border: 1px solid #ffe6e1;
	box-shadow: 0px 4px 4px rgba(244, 244, 244, 0.25);
	border-radius: 5px;
}
.faq .card .card-header {
    border-bottom: 1px solid #ffe6e1;
}
#accordion  h4 {
	font-weight: 600;
	font-size: 20px;
	text-transform: uppercase;
	color: #E65425;    
	margin-bottom: 22px;
}
#accordion  i {
	float: right;
}
#accordion a[aria-expanded="true"] i {
	transform: rotate(90deg);
}
#accordion a[aria-expanded="true"] .card-header, #accordion a[aria-expanded="true"] .card-header i {
	color: #E65425;
}
#accordion .card-header {
	font-size: 18px;
	color: #161c2d;
}
#accordion .card-body {
	font-size: 16px;
	color: #161c2d;
}
.basics-faq {
	margin-bottom: 40px;
}

/*-----------------
	12. Select2
-----------------------*/

.select2-results__option {
	padding: 6px 15px;
}
.select2-container .select2-selection--single {
	border: 1px solid #E7E8EA;
	height: 40px;
	outline: 0;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 40px;
	right: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #353535 transparent transparent;
	border-style: solid;
	border-width: 6px 6px 0;
	height: 0;
	left: 50%;
	margin-left: -10px;
	margin-top: -2px;
	position: absolute;
	top: 50%;
	width: 0;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #353535;
	border-width: 0 6px 6px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
	padding-right: 30px;
	padding-left: 15px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #353535;
	font-size: 16px;
	font-weight: normal;
	line-height: 44px;
	font-weight: 500;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #161c2d;
	border: 0;
}
.select2-container--default .select2-selection--multiple {
	border: 1px solid #dcdcdc;
	min-height: 46px;
}
.select2-dropdown {
	border-color: #dcdcdc;
}
.result .select2-container .select2-selection--single {
    border: 0;
	background-color: unset;
    height: 40px;
}
.result .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #E65425;
	font-weight: 500;
	font-size: 14px;
}
.result .select2-container--default .select2-selection--single .select2-selection__rendered:focus {
	outline: 0;
	border: 0;
	-webkit-appearance: none;
}
.select2-container:focus, .select2-container--below .select2-container--focus {
	border: 0;
	outline: 0;
	-webkit-appearance: none;
}
.search-filter .card-header h2 {
	font-weight: 500;
	font-size: 20px;
	text-transform: uppercase;
	color: #161c2d;
}
.search-filter .card-header a {
    font-weight: 600;
    font-size: 14px;
    color: #E65425;
}
.search-filter .select2-container .select2-selection--single, .search-filter .form-control {
    border: 1px solid #ecf0f9;
    border-radius: 6px;
}

/*-----------------
	13. Search
-----------------------*/

.breadcrumb-bar {
    background: url(../../assets/img/bg/bg_13.jpg) center center;
    background-size: cover;
    padding-top: 84px;
	background-repeat: no-repeat;
	min-height: 304px;
}
.breadcrumb-bar .breadcrumb-title {
    font-weight: bold;
    font-size: 40px;
    color: #71d3a9;
    margin-bottom: 15px;
}
.bread-crumb-bar {
	background-color: #71d3a9;
	padding-top: 119px;
}
.bread-crumb-bar .inner-banner {
    padding: 60px 0;
	/* background: url(../../assets/img/bg/breadcrumb-bg.png); */
    background-size: cover;
}
.breadcrumb-list .page-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
    color: #fff;
    font-size: 14px;
    content: "\f101";
    font-weight: 600;
    line-height: 1.5;
    font-family: "Font Awesome 5 Free";
    padding-right: 16px;
	margin: 0px 15px 0;
	background: none;
}
.breadcrumb-list h2 {
	color: #1B1B1B;
	font-size: 40px;
	font-weight: 700;
	text-transform: capitalize;
}
.breadcrumb-list {
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.breadcrumb-list .page-breadcrumb ol {
	display: flex;
}
.breadcrumb-list .page-breadcrumb ol li {
	font-size: 14px;
	color: #fff;
	font-weight: 400;
}
.inner-content {
	margin-top: 40px;
}
label.file-upload.image-btn {
    background: #fff;
	border: 1px solid #E65425;
    border-radius: 2px;
    color: #E65425;
    padding: 12px 15px;
    font-size: 16px;
	text-transform: uppercase;
    font-weight: 500;
	cursor: pointer;
}
.content {
    min-height: 200px;
    padding: 80px 0 30px;
	background-color: #fff;
	
}
.inner-banner {
	padding: 70px 0;
}
.rating i.filled {
    color: #FF9C07;
}
.best-company ul{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.best-company ul li{
	width: 15%;
	margin-right:15px;
	background: #fff;
	border-radius: 10px;
	height: 79px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);
	transition: all 0.5s;
	margin-bottom: 14px;
}
.best-company ul li:last-child{
	margin-right: 0;
}
.best-company ul li:hover{
	transform: scale(1.1);
	background: #01243f ;
}
.rating i {
    color: #EBEBEB;
	font-size: 12px;
	margin-right: 3px;
}
.rating .average-rating {
    font-size: 14px;
    color: #6e727d;
    font-weight: 500;
}
.popular-catergories {
    display: flex;
    align-items: center;
    border: 1px solid #CED4E6;
    padding: 20px;
    border-radius: 10px;
	margin-bottom: 24px;
}
.popular-catergories:hover{
	border: 1px solid #E65425;
}
.popular-catergories:hover .popular-catergories-img span {
	background:  #E65425;
	transform: scale(1.1) rotate(360deg);
	
}
.popular-catergories:hover .popular-catergories-img span  img{
	filter: brightness(0) invert(1);
}
.popular-catergories-content {
    margin-left: 20px;
}
.drop-detail .select2-container--default .select2-selection--single .select2-selection__arrow b {
    position: absolute;
    top: 50%;
    left: 50%;
    border-color: #637381;
    border-style: solid;
    border-width: 0 2px 2px 0;
    padding: 3px;
    height: 0;
    margin-left: -10px;
    margin-top: -3px;
    width: 0;
    transform: rotate(45deg) translateY(-50%);
    -webkit-transform: rotate(45deg) translateY(-50%);
    -ms-transform: rotate(45deg) translateY(-50%);
}
.drop-detail .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: #000;
    border-width: 2px 0 0 2px;
    margin-top: 3px;
    padding: 3px;
}
.popular-catergories-img span {
	width: 60px;
	height: 60px;
	background: #FFF6F1;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	transition: all 0.5s;
}
.popular-catergories-content h5 {
    color: #1B1B1B;
    font-size: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 150px;
}
.popular-catergories-content a {
    font-size: 14px;
    color: #6B7385;
    display: flex;
    align-items: center;
}
.page-breadcrumb ol {
    background-color: transparent;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
    padding: 0;
	display: inline-flex;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
}
.page-breadcrumb ol li a {
    color: #6B7385;
    font-weight: 400;
	font-size: 14px;
}
.bread-crumb-bar .page-breadcrumb ol li a,
.bread-crumb-bar .breadcrumb-list .page-breadcrumb ol li,
.bread-crumb-bar .breadcrumb-list .page-breadcrumb ol {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.bread-crumb-bar .page-breadcrumb ol li a img {
	margin-right: 10px;
}
.bread-crumb-bar .breadcrumb-list .page-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
	line-height: 5px;
	margin: 0px 20px 0px 10px;
	padding-right: 0;
}
.page-breadcrumb .breadcrumb a:hover {
    color:#01243f 
	;
}
.page-breadcrumb ol li.active {
    color: #161c2d;   
}
.page-breadcrumb .breadcrumb-item + .breadcrumb-item:before {
    color: #71d3a9;
    font-size: 16px;
    content: "";
    width: 5px;
    height: 5px;
    background: #71d3a9;
    border-radius: 20px;
    margin: 10px 15px 0;
    display: block;
    padding: 0;
}
.showmore a {
    color: #71d3a9;
	font-weight: 600;
}
.page-breadcrumb .breadcrumb-item + .breadcrumb-item {
    padding-left: 0;
}
.search-filter .filter-widget .custom_check {
    line-height: normal;
    color: #01243f;
    font-weight: 400;
}
.sort-by {
    margin-right: 14px;
}
.sort-title {
    color: #fff;
    font-size: 14px;
    margin-right: 10px;
}
.sortby-fliter {
	display: inline-block;
	width: 130px;
}
.cal-icon {
	position: relative;
	width: 100%;
}
.focus-label {
    color: #131135;
    font-size: 14px;
    font-weight: 600;
}
.cal-icon:after {
	color: #6E727D;
    content: '\f073';
	display: block;
    font-family: "Font Awesome 5 Free";
    font-size: 16px;
    font-weight: 400;
    margin: auto;
    position: absolute;
    right: 15px;
    top: 10px;
}
.custom_check {
	color: #01243f;
	display: inline-block;
	position: relative;
	font-size: 14px;
	font-size: .9375rem;
	padding-left: 30px;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.custom_check input {
	position: absolute;
	opacity: 0;
	cursor: pointer
}
.custom_check input:checked ~ .checkmark {
	background-color: #01243f;
    border-color: #01243f;
}
.custom_check .checkmark {
	position: absolute;
	top: 4px;
	left: 0;
	height: 20px;
	width: 20px;
	border: 1px solid #ecf0f9;
	background-color: #fff;
	border-radius: 3px;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}
.custom_check .checkmark::after {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    position: absolute;
    display: none;
    left: 4px;
    top: 1px;
    color: #fff;
    font-size: 11px;
}
.custom_check input:checked ~ .checkmark:after {
	display: block
}
.custom_radio {
	color: #555;
	display: inline-flex;
    align-items: center;
	position: relative;
	font-size: 14px;
	font-size: 0.9375rem;
	padding-left: 20px;
	margin-bottom: 10px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none
}
.radio_input .custom_radio + .custom_radio {
	margin-left: 15px;
}
.custom_radio input {
	position: absolute;
	opacity: 0
}
.custom_radio input:checked ~ .checkmark:after {
	opacity: 1
}
.custom_radio .checkmark {
	position: absolute;
	left: 0;
	height: 15px;
    width: 15px;
	background-color: #fff;
	border: 1px solid #ffe6e1;
	border-radius: 50%
}
.custom_radio .checkmark:after {
	display: block;
	content: "";
	position: absolute;
	opacity: 0;
	top: 2px;
    left: 2px;
	width: 9px;
	height: 9px;
	border-radius: 50%;
	background: #E65425;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out
}
.custom-file {
	position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
}
.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 0;
    overflow: hidden;
    opacity: 0;
}
.custom-file-label::after {
	margin: 0.1rem;
	height: 41px;    
	border-radius: 5px;
	padding: 10px 25px;
	border-left: 0;
	position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
}
.custom-file-label {
    height: 48px;
    border: 1px solid #E7E8EA;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    padding: 0.375rem 0.75rem;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border-radius: 0.25rem;
}
.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Upload";
}
.filter-widget {
	margin-bottom: 24px;
}
.filter-title {
	font-size: 16px;
    font-weight: 600;
}
.filter-widget h4 {
	font-size: 16px;
    font-weight: 600;
	margin-bottom: 15px;
}
.filter-widget .custom_check {
	line-height: 18px;
}
.filter-widget .select2-container {
	z-index: unset;
}
.btn-search {
	display: flex;
	gap: 10px;
	justify-content: center;
	flex-wrap: wrap;
}

.btn-search .btn {
	padding: 10px 24px;
    width: 48%;
    border-radius: 6px;
}
.search-filter .custom_check .checkmark {
    height: 18px;
    width: 18px;
}
.search-filter .custom_check .checkmark::after{
	top: 2px;
}
.company-detail {
    display: flex;
    gap: 18px;
    align-items: center;
	margin-bottom: 15px;
}
.company-detail-image {
    width: 80px;
    height: 80px;
    border: 1px solid #CED4E6;
    border-radius: 50%;
	overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.company-detail-image img{
	height: 100% !important;
}
.company-title p {
    margin-bottom: 0;
    color: #6B7385;
}
.company-title h4 {
    color: #2B2B2B;
    font-size: 24px;
}
.company-address {
	margin-bottom: 30px;
}
.company-address ul {
    list-style: none;
    display: flex;
    gap: 14px;
	margin-bottom: 0;
}
.company-address ul li {
    color: #6B7385;
    padding-right: 14px;
    position: relative;
}
.company-address ul li i {
    margin-right: 8px;
}
.company-address ul li::after {
    position: absolute;
    content: "|";
    right: -3px;
    color: #CED4E6;
}
.company-address ul li:last-child {
    padding-right: 0;
}
.company-address ul li:last-child::after {
    display: none;
}
.project-proposal-detail ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    align-items: center;
    flex-wrap: wrap;
	row-gap: 24px;
	margin-bottom: 0;
}
.project-proposal-detail li {
    width: 33.33%;
    display: flex;
    align-items: center;
    gap: 14px;
}
.proposal-detail-img {
    height: 52px;
    width: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFF6F1;
    border-radius: 50%;
}
.proposal-detail span {
	color: #6B7385;
    font-size: 14px;
	margin-bottom: 5px;
}
.proposal-detail p {
    font-weight: 600;
    font-size: 18px;
}
.separator {
	margin: 30px 0;
	background-color: #CED4E6;
	height: 2px;
}
.company-detail-title {
	font-size: 22px;
	margin-bottom: 20px;
}

.company-description p {
	color: #6B7385;
	margin-bottom: 10px;
	line-height: 26px;
}

.company-detail-block .badge-design {
	border-radius: 4px;
	padding: 8px 10px;
	margin-right: 14px;
	color: #6B7385;
	font-size: 12px;
	background: #F4F7FF;
	margin-bottom: 0;
}

.attachment-file {
    background: #e8edf8;
    padding: 16px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.attachment-files-details h6 {
    font-weight: 600;
}
.attachment-files-details span {
    color: #6B7385;
    font-size: 14px;
}
a.file-download-btn:hover {
    background: #71d3a9;
    color: #fff;
}
a.file-download-btn {
    width: 35px;
    height: 35px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #6B7385;
}
a.file-download-btn i {
    line-height: inherit;
}
.row-gap {
	row-gap: 24px;
}

.project-proposals-block {
    border-radius: 10px;
    border: 1px solid #CED4E6;
    padding: 20px;
    background: #fff;
    display: flex;
    align-items: flex-start;
    gap: 22px;
	margin-bottom: 14px;
}
.project-proposals-img {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    overflow: hidden;
    display: inline-flex;
}
.proposals-user-detail {
    display: flex;
    justify-content: space-between;
}
.proposals-user-detail ul {
    list-style: none;
	margin-bottom: 14px;
}
.proposals-user-detail ul li {
    padding: 0 14px;
    border-right: 1px solid;
}
.proposals-user-detail ul li .proposals-user-review span i.fa {
	color: #FF9C07;
}
.proposals-user-detail ul li .proposals-user-review span i {
	margin-right: 5px;
	color: #808080;
}
.proposals-user-detail ul li .proposals-user-review span  {
	color: #808080;
}
.proposals-user-detail ul li:last-child {
    border: none;
    padding-right: 0;
}
.proposals-user-detail ul li:first-child {
    padding-left: 0;
}
.project-proposals-description p {
	line-height: 24px;
	color: #6B7385;
}
.proposals-pricing h4 {
	font-size: 22px;
}
.proposals-pricing span {
	color: #6B7385;
}
.company-detail-block {
	padding: 30px 0;
	border-bottom: 1px solid #CED4E6;
}
.company-detail-block:last-child {
	border: none;
}
.card.budget-widget {
    padding: 24px;
    justify-content: center;
    box-shadow: 0px 8px 16px 0px #8E8DD01F;
    border-radius: 10px;
    border-color: #CED4E6;
}
.budget-widget h6 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
}
.budget-widget .tags a {
	width: 28%;
    display: inline-block;
    margin: 0 14px 14px 0;
}
.budget-widget .tags a span {
    width: 100%;
}
.card.budget-widget .proposal-btn.btn-primary {
	border-radius: 6px;
}
.card.budget-widget .proposal-btn.btn-primary:hover {
	background: #131135;
    border-color: #131135;
    color: #fff;
}
.budget-widget-details {
    text-align: center;
    margin-bottom: 24px;
}
.budget-widget-details h6 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 14px;
}
.budget-widget-details h4 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
}
.budget-widget-details p {
    color: #6B7385;
}
.list-style {
	list-style: none;
}
.budget-widget-details .company-detail-image {
	margin: auto;
	margin-bottom: 5px;
}
.budget-widget-details span {
    font-size: 14px;
    color: #6B7385;
}
.budget-widget-details h5 {
    font-size: 16px;
    margin-bottom: 5px;
}
.budget-widget-detail .rating {
	margin-bottom: 14px;
}
.social-list {
	gap: 10px;
	padding-bottom: 14px;
	border-bottom: 1px solid #E7EBF7;
	justify-content: center;
}
.social-list li {
    width: 35px;
    height: 35px;
    background: #FFF6F1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.social-list li a i {
	color: #6B7385;
	transition: all .5s;
}
.social-list li a:hover i {
	color: #FE4A23;
}
.client-detail-list {
	padding-top: 14px;
}
.client-detail-list li {
	width: 50%;
	text-align: start;
}
.client-detail-list p {
	font-weight: 600;
}
.job-list-block {
	flex-wrap: wrap;
	row-gap: 10px;
}
.job-list-block li {
	width: 33.33%;
}
.job-list-block li p {
	font-weight: 600;
}
.feedback-form .input-group .input-group-text {
    background: #fff;
    border: 1px solid #E7E8EA;
	color: #6B7385;
	font-size: 14px;
}
.feedback-form .input-group > .form-control {
	border-right: 0;
}
.form-control:focus + .input-group-text {
	border-color: #C7C8CA;
}
.modal label.form-label {
	color: #131135;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 8px;
}
.last-group {
	justify-content: space-between;
	gap: 14px;
	align-items: flex-end;
}
.modal-info {
	padding-bottom: 30px;
}
.modal label {
	color: #131135;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 8px;
}
.modal .input-block {
	margin-bottom: 14px;
}
.proposal-modal-info .proposal-form {
	margin-bottom: 34px;
}
.proposal-modal-info .proposal-widget {
	border-radius: 5px;
	border: 1px solid #CED4E6;
	padding: 24px;
}
.proposal-warning {
	background: #FFF8F5;
}
.floating-icon {
	position: relative;
}
.floating-icon .form-control{
	padding-right: 30px;
}
.floating-icon span {
	position: absolute;
	right: 21px;
	bottom: 11px;
	color: #6B7385;
	font-size: 14px;
}
.suggested-milestones-form .new-addd {
	min-height: auto;
}
.suggested-milestones-form {
	margin-bottom: 34px;
}
.suggested-milestones-form .input-block {
	margin-bottom: 10px;
}
.suggested-milestones-form .new-addd .add-new {
	color: #FF3B30;
}
.suggested-milestones-form .new-addd .add-new i{
	font-size: 16px;
}
.proposal-widget .proposal-text {
	font-size: 18px;
}
.proposal-widget .proposal-text.float-end {
	color: #E65425;
}
.proposal-widget p {
	color: #6B7385;
}
.proposal-blue {
	background: #F6F6FF;
}
.proposal-modal-info .submit-section .submit-btn {
    padding: 10px 24px;
    min-width: auto;
	border-radius: 6px;
}
.proposal-modal-info .submit-section .submit-btn.btn-cancel {
	color: #081733;
	background: #F4F7FF;
}
.proposal-modal-info .modal-title {
	color: #1B1B1B;
}
.custom-modal .modal-body {
    padding: 1.5rem;
}
.accordion .collapsed i {
    transition: 0.2s;
    transform: rotate(-90deg);
}
/* .btn-search .btn:hover, .btn-search .btn:focus {
    background-color: #E65425;
    border: 1px solid #E65425;
} */
.btn-block {
    border: 1px solid #131135;
    color: #131135;
}
.btn-block:hover {
    background: #131135;
    color: #fff;
}
.provider-widget {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.pro-info-left {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.pro-info-right {
    margin-left: auto;
    -ms-flex: 0 0 200px;
    flex: 0 0 200px;
    max-width: 200px;
}
.provider-img {
    -ms-flex: 0 0 100px;
    flex: 0 0 100px;
    margin-right: 70px;
    width: 100px;
	height: 154px;
	position: relative;
}
.provider-img img {
	border-radius: 5px;
}
.provider-widget .pro-name {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 3px;
}
.pro-info-left .rating {
    list-style: none;
    margin: 0 0 7px;
    padding: 0;
    width: 100%;
}
.provider-widget .rating i {
	font-size: 14px;
}
.provider-widget .average-rating {
	font-size: 14px;
	font-weight: 500;
}
.load-more {
	margin-bottom: 30px;
}
.load-more .btn {
	background-color: #71d3a9;
    border-color: #71d3a9;
}
.invited-freelancer-label {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0;
}

/*-----------------
	14. Profile
-----------------------*/

.provider-action {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 15px;
}
.provider-action a + a {
	margin-left: 8px;
}
.provider-action .fav-btn:hover {
	background-color: #fb1612;
	border-color: #fb1612;
	color: #fff;
}
.msg-btn:hover, .msg-btn:focus {
	background-color: #71d3a9;
	border-color: #71d3a9;
	color: #fff;
}
.call-btn:hover, .call-btn:focus {
	background-color: #71d3a9;
	border-color: #71d3a9;
	color: #fff;
}
.user-tabs .nav-tabs > li > a {
	border: 0;
	border-bottom: 3px solid transparent;
    color: #161c2d;
    font-weight: 600;
    padding: 20px;
}
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active, 
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:hover, 
.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:focus {
	border-bottom-width: 3px;
	color: #71d3a9;
}
.user-tabs .med-records {
	display: inline-block;
	min-width: 130px;
}
.user-tabs .nav-tabs > li > a:hover {
	background-color: unset;
	color: #081733;
}
.widget {
	margin-bottom: 30px;
}
.widget-title {
	margin-bottom: 15px;
}
.before-circle {
    background-color: rgb(255 49 21 / 20%);
    border-radius: 50%;
    height: 12px;
    width: 12px;
    border: 2px solid #71d3a9;
}
.awards-widget .experience-list > li:last-child p {
	margin-bottom: 0;
}
.service-list {
    margin-bottom: 30px;
}
.service-list:last-child {
	border-bottom: 0;
	margin-bottom: 0;
	padding-bottom: 0;
}
.service-list ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.service-list ul li {
	float: left;
	margin: 6px 0;
	padding-left: 25px;
	position: relative;
	width: 33%;
}
.service-list ul li::before {
    color: #ccc;
    content: '\f30b';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    left: 0;
    position: absolute;
}
.dropzone .dz-preview.dz-error:hover .dz-error-message {
	display: none;
}
.profile .select2-container {
	z-index: unset;
}

/*-----------------
	15. Profile Settings
-----------------------*/

.profile-image img {
	margin-bottom: 1.5rem;
}
.change-photo-btn {
    background-color: #71d3a9;
    border-radius: 50px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 13px;
    font-weight: 600;
    margin: 0 auto;
    padding: 10px 15px;
    position: relative;
    transition: .3s;
    -moz-transition: .3s;
    -webkit-transition: .3s;
    -o-transition: .3s;
    text-align: center;
    width: 220px;
}
.change-photo-btn input.upload {
	bottom: 0;
    cursor: pointer;
    filter: alpha(opacity=0);
	left: 0;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
	width: 220px;
}
.dropzone {
    background-color: #fbfbfb;
    border: 2px dashed rgba(0, 0, 0, 0.1);
}
.btn-icon {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    text-align: center;
    position: absolute;
    padding: 0;
    font-size: 10px;
    width: 20px;
    height: 20px;
	display: -ms-inline-flexbox;
	display: inline-flex;
    right: 3px;
    top: 3px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.upload-images {
    position: relative;
    width: 80px;
}
.upload-images img {
	border-radius: 4px;
	height: 80px;
	width: 80px;
}
.upload-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.upload-wrap .upload-images + .upload-images {
	margin-left: 20px;
}
.btn.btn-danger.trash {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    height: 46px;
    width: 46px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.add-more a { 
	color: #71d3a9;
}
label.file-upload {
    position: relative;
    overflow: hidden;
	color: #E65425;
	font-size: 15px ;
	border: 1px solid #ffbe9d;
    border-radius: 5px;
    padding: 12px 6px;
}
label.file-upload  input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}
.bootstrap-tagsinput {
    border-color: #E7E8EA;
    box-shadow: inherit;
    min-height: 46px;
    width: 100%;
    border-radius: 5px;
}
.bootstrap-tagsinput.focus {
	border-color: #C7C8CA;
}
.bootstrap-tagsinput .tag {
    background-color: #E65425;
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    margin-right: 2px;
    padding: 8px 15px;
    border-radius: 0;
}
.bootstrap-tagsinput .tag.badge-info {
	border-radius: 5px;
	margin-bottom: 0;
	border: 0;
}
.submit-section .submit-btn {
    padding: 12px 30px;
    font-weight: 600;
    font-size: 16px;
    min-width: 120px;
}
.submit-section .submit-btn + .submit-btn {
    margin-left: 15px;
}
.info-widget {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 30px;
    margin-bottom: 30px;
}
.payment-radio {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 600;
    color: #161c2d;
    text-transform: capitalize;
}
.payment-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.payment-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 19px;
    height: 19px;
    margin: 3px 0 0 0;
    border: 2px solid #ddd;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.payment-radio input:checked ~ .checkmark {
    border-color: #71d3a9;
}
.payment-radio .checkmark::after {
    position: absolute;
    left: 3px;
    top: 3px;
    content: '';
    width: 9px;
    height: 9px;
    background-color: #71d3a9;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: scale(0.1);
    -moz-transform: scale(0.1);
    -ms-transform: scale(0.1);
    -o-transform: scale(0.1);
    transform: scale(0.1);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    -ms-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.payment-radio input:checked ~ .checkmark::after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
}
.submit-btn-bottom {
	margin-bottom: 30px;
}
.btn-file {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -ms-inline-flexbox;
	display: inline-flex;
    font-size: 20px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    overflow: hidden;
    padding: 0 0.75rem;
    position: relative;
    vertical-align: middle;
}
.btn-file input {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 100%;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

/*-----------------
	16. Calendar
-----------------------*/

#calendar-events {
    background-color: #fcfcfc;
}
.calendar-events {
    border: 1px solid transparent;
    cursor: move;
    padding: 10px 15px;
}
.calendar-events:hover {
	border-color: #e9e9e9;
    background-color: #fff;
}
.calendar-events i {
    margin-right: 8px;
}
.calendar {
	float: left;
	margin-bottom: 0;
}
.fc-toolbar.fc-header-toolbar {
    margin-bottom: 1.5rem;
}
.none-border .modal-footer {
	border-top: none;
}
.fc-toolbar h2 {
	font-size: 18px;
	font-weight: 600;
	font-family: 'Roboto', sans-serif;
	line-height: 30px;
	text-transform: uppercase;
}
.fc-day-grid-event .fc-time {
	font-family: 'Roboto', sans-serif;
}
.fc-day {
	background: #fff;
}
.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar button:focus,
.fc-toolbar button:hover,
.fc-toolbar .ui-state-hover {
	z-index: 0;
}
.fc th.fc-widget-header {
	background: #eeeeee;
	font-size: 14px;
	line-height: 20px;
	padding: 10px 0;
	text-transform: uppercase;
}
.fc-unthemed th,
.fc-unthemed td,
.fc-unthemed thead,
.fc-unthemed tbody,
.fc-unthemed .fc-divider,
.fc-unthemed .fc-row,
.fc-unthemed .fc-popover {
	border-color: #f3f3f3;
}
.fc-basic-view .fc-day-number, 
.fc-basic-view .fc-week-number {
    padding: 2px 5px;
}
.fc-button {
	background: #f1f1f1;
	border: none;
	color: #797979;
	text-transform: capitalize;
	box-shadow: none !important;
	border-radius: 3px !important;
	margin: 0 3px !important;
	padding: 6px 12px !important;
	height: auto !important;
}
.fc-text-arrow {
	font-family: inherit;
	font-size: 16px;
}
.fc-state-hover {
	background: #f3f3f3;
}
.fc-state-highlight {
	background: #f0f0f0;
}
.fc-state-down,
.fc-state-active,
.fc-state-disabled {
	background-color: #71d3a9 !important;
	color: #fff !important;
	text-shadow: none !important;
}
.fc-cell-overlay {
	background: #f0f0f0;
}
.fc-unthemed .fc-today {
	background: #fff;
}
.fc-event {
	border-radius: 2px;
	border: none;
	color: #fff !important;
	cursor: move;
	font-size: 13px;
	margin: 1px 7px;
	padding: 5px 5px;
	text-align: center;
}
.fc-basic-view td.fc-week-number span {
	padding-right: 8px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
}
.fc-basic-view td.fc-day-number {
	padding-right: 8px;
	font-weight: 700;
	font-family: 'Roboto', sans-serif;
}
.event-form .input-group .form-control {
	height: 40px;
}

/*-----------------
	17. Profile List
-----------------------*/

.profile-sidebar {
    background-color: #fff;
	border: 1px solid #f0f0f0;
    border-radius: 4px;
	margin-bottom:30px;
	overflow: hidden;
}
.pro-widget-content {
    padding: 20px;
    text-align: center;
}
.profile-info-widget {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    text-align: left;
}
.profile-info-widget .booking-pro-img {
    margin-right: 15px;
}
.profile-info-widget .booking-pro-img img {
    border-radius: 4px;
    height: 90px;
    width: 90px;
    object-fit: cover;
}
.profile-det-info {
	overflow: hidden;
}
.profile-det-info h3 {
	font-size: 16px;
	font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
	margin-bottom: 12px;
}
.profile-det-info h4 {
	font-weight: 500;
	font-size: 14px;
	color: #161c2d;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
	margin-bottom: 12px;
}
.profile-det-info p {
    color: #696969;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 8px;
	margin-bottom: 12px;
}
.customer-details h5 {
    color: #AAAAAA;
    font-size: 12px;
    font-weight: normal;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	margin-bottom: 12px;
}
.customer-details h5 i {
	width: 18px;
}
.customer-job {
	padding: 20px 0;
}
.customer-job i {
	color: #F5365C;
	font-size: 18px;
	margin-right: 15px;
}
.pat-widget-profile {
	background: #fff;
	border: 1px solid rgba(239, 239, 239, 0.42);
	box-sizing: border-box;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
}
.card-top {
    position: relative;
}
.card-top::before {
	content: '';
    background-color: rgba(20, 20, 20, 0.7);;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}
.card-top .dev-user {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -80%);
    -webkit-transform: translate(-50%, -80%);
    -ms-transform: translate(-50%, -80%);
    display: flex;
    display: -webkit-flex;
}
.card-top .dev-user::before {
    background-color: #159C5B;
	position: absolute;
    right: 0;
    bottom: 0;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    content: "\f00c";
	font-weight: 600;
    font-family: "Font Awesome 5 Free";
	font-size: 13px;
	justify-content: center;
    align-items: center;
    color: #fff;
    display: inline-flex;
	display: -ms-inline-flexbox;
}
.card-top .bg-none.dev-user::before {
	visibility:hidden;
}
.card-top .dev-text {
    position: absolute;
    top: 0;
    transform: none;
    text-align: center;
    padding: 15px;
    width: 100%;
    left: 0;
    right: 0;
}
.card-top .dev-user img {
    margin: auto;
    width: 86px;
    height: 86px;
    border-radius: 30px;
}
.card-top .dev-text .left {
	font-weight: 500;
	font-size: 10px;
	text-transform: uppercase;
	color: #fff;
}
.left-bid {
	padding-top: 20px;
}
.card-top .dev-text .right {
    color: #fff;
    font-weight: bold;
    background: #E65425;
    border: 1px solid #E65425;
    border-radius: 130px;
    font-size: 14px;
    width: 37px;
    height: 37px;
    line-height: 34px;
	padding: 0;
}
.card-top .dev-text .right.bg-grey {
	background: #EEEEEE;
	border: 1px solid #EEEEEE;
}
.card-top .dev-text a:hover .right.bg-grey  {	
    background: #E65425;
    border: 1px solid #E65425;
}
.vote {
	background: #EEEEEE;
	border-radius: 5px;
	font-weight: 600;
	font-size: 9px;
	color: #7D7D7D;
	padding: 5px 10px;
}
.pro-widget-profile .dev-user {
    transform: translate(-50%, -50%);
}
.pro-widget-profile .dev-text .left  {
	text-align: left;
}
.pro-widget-profile .dev-text .left img {
	display: block;
    float: left;
    width: 66px;
	border-radius: 12px;
}
.left .left-img {
    position: absolute;
}
.left .left-img::before {
    background-color: #159C5B;
    position: absolute;
    right: 2px;
    bottom: 0;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    content: "\f00c";
    font-weight: 600;
    font-family: "Font Awesome 5 Free";
    font-size: 13px;
    justify-content: center;
    align-items: center;
    color: #fff;
    display: inline-flex;
	display: -ms-inline-flexbox;
}
.pro-widget-profile .dev-text .left .left-content img {
	width: auto;
}
.pro-widget-profile .dev-text .left .left-content {
    margin-left: 75px;
}
.client.pro-widget-profile .dev-text .left .left-content {
    margin-left: 0;
}
.client.pro-widget-profile .card-img-top{
	border-radius: 5px 0 5px 0;
}
.left-content h6 {
	font-weight: 500;
	font-size: 12px;
	color: #fff;
}
.left-content h6 i {
	color: #159C5B;
}
.left-content h4 {
	font-weight: bold;
	font-size: 16px;
	color: #fff;
}
.left-content h4 a {
	color: #fff;
}
.left-content p {
	font-weight: 500;
	font-size: 11px;
	color: #fff;    
	margin-bottom: 5px;
}
.dev-right {
	margin-left: 20px;
}
.dev-right p {
	font-weight: bold;
	font-size: 17px;
	color: #fff;
}
.dev-right h6 {
	font-weight: 600;
	font-size: 14px;
	color: #fff;
	margin-top: 5px;
}
.profile-box .bid-btn {
	font-size: 14px;
}
.profile-freelance .provider-img img {
	border-radius: 15px;
}
.profile.profile-freelance {
	margin: 0px 0 25px 0;
}
.bid-btn {
	background: #FF5E3B;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
	white-space: nowrap;
}
.bid-btn:hover {
    color: #fff;
    background-color: #fb451d;
    border-color: #fb451d;
}
.pro-widget-profile  {
	border: 1px solid rgba(239, 239, 239, 0.42);
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
}
.pro-widget-profile .customer-info {
    margin-top: 0;
}
.pro-widget-profile .slot p {
    font-size: 12px;
}
.pro-widget-profile .slot h5 {
    font-size: 13px;
	margin: 0;
}
.pro-widget-profile  .badge-design {
    font-size: 12px;    
	padding: 5px 15px;    
	margin-bottom: 5px;
}
.pro-widget-profile .customer-info ul {
    padding: 0;
}
.pro-widget-profile .card-header {
    border-bottom: 1px solid #F8F8F8;
	padding: 1.5rem;
}
.star {
    color: #fff;
    font-weight: bold;
    background: #E65425;
    border: 1px solid #E65425;
    border-radius: 130px;
    font-size: 14px;
    width: 37px;
    height: 37px;
    line-height: 37px;
	padding: 0;
	text-align: center;
}
.customer-details .rating .average-rating {
    font-size: 9px;
    background-color: #FEBE42;
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    width: 22px;
    height: 16px;
    line-height: 16px;
    display: inline-flex;
	display: -ms-inline-flexbox;
    margin-right: 5px;
    align-items: center;
    justify-content: center;
}
.bootstrap-tags {
	padding: 0 15px 15px;
    text-align: center;
    border-top: 1px solid #FFFBFB;
}
.badge-tech {
	font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #161c2d;
	background: rgba(19, 17, 53, 0.1);
	border-radius: 40px;
	margin-right: 4px;
}
.badge-tech:last-child {
	margin-right: 0;
}
.badge-design {
	font-weight: 500;
    font-size: 12px;
    color: #2e2e2e;
    background: #F4F7FF;
    border-radius: 4px;
    margin-right: 3px;
    display: inline-block;
    padding: 7px 10px;
}
.badge-design:last-child {
	margin-right: 5px;
}
.badge-design:hover {
	background-color: #081733;
	color: #fff;
}
.slots {
	padding-bottom: 10px;
}
.slot p {
    font-size: 12px;
    color: #6e727d;
    margin-bottom: 3px;
    font-weight: normal;
}
.slot h5 {
    font-size: 12px;
    font-weight: 500;
    color: #161c2d;
}
.slot h5 i {
	color: #E65425;
}
.profile-btn {
	background: #E65425;
	border-radius: 5px;
	font-weight: 500;
	font-size: 12px;
	color: #fff;
}
.profile-btn:hover {
    color: #fff;
    background-color: #fb451d;
    border-color: #fb451d;
}
.job-btn {
	background: #8553EE;
	border-radius: 5px;
	font-weight: 500;
	font-size: 14px;
	color: #fff;
}
.job-btn:hover {
    color: #fff;
    background-color: #7d46ef;
    border-color: #7d46ef;
}	
.result.bg-white {
	background: #fff;
	border: 1px solid rgba(239, 239, 239, 0.42);
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
}
.result {
	background: #FFFDFD;
	margin: 0 0 20px 0;
}
.about-content {
    max-width: 1100px;
    margin: auto;
    text-align: center;
}
.result .sort-title {
    font-weight: 500;
	font-size: 14px;
	color: #161c2d;
}
.search-filter .card-header {
	background: #01243f ;
    align-items: center;
    -webkit-align-items: center;
	padding: 15px 20px;
}
.search-filter .card-body {
	padding: 20px;
}
.search-filter .card-footer {
	padding: 0 20px 20px 20px;
	border: none;
}
.badge-skill {
    background: #E7E8EA;
    border-radius: 6px;
    font-weight: 500;
    font-size: 12px;
    color: #6e727d;
    padding: 10px;
    margin: 0 7px 7px 0;
}
.tags .badge-skills {
    width: 195px;
}
.badge-skills:hover {
	background-color: #FFF0F0;
}
.badge-skills:hover .tag-close {
	background-color: #E65425;
	color: #fff;
}
.badge-skills {
    background-color: #edeef3;
    border-radius: 6px;
    font-size: 15px;
    font-weight: normal;
    margin: 0 2px 7px 0;
    padding: 10px 15px;
    color: #55545b;
}
.tag-close {
    width: 20px;
    height: 20px;
    display: inline-flex;
    background-color: #d3d4d9;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    font-size: 12px;
	cursor: pointer;
}
.result h4 {
	font-weight: 500;
	font-size: 14px;
	color: #E65425;
	margin-bottom: 0;
}
.result h4 span {
	font-weight: 700;
}
.search-filter {
    background: #fff;
    border: 1px solid #CED4E6;
	border-radius: 10px 10px 0px 0px;
	overflow: hidden;
}

/*-----------------
	18. User Details
-----------------------*/

.profile {
	background: #fff;
    box-shadow: 0px 4px 14px rgb(215 215 215 / 25%);
    border-radius: 5px;
	margin: -130px 0 25px 0;
}
.profile-box {
    padding: 30px;
}
.profile-title {
	font-weight: 600;
	font-size: 30px;
	color: #161c2d;
}
.profile-position {
	font-size: 16px;
	color: #E65425;
}
.prof-img, .camera-bg {
	position: relative;
}
.prof-img::before {	
    background-color: #159C5B;
    position: absolute;    
    left: 50%;
    transform: translate(-50%, -23%);
    bottom: 0;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    content: "\f00c";
    font-weight: 600;
    font-family: "Font Awesome 5 Free";
    font-size: 13px;
    justify-content: center;
    align-items: center;
    color: #fff;
    display: inline-flex;
	display: -ms-inline-flexbox;
}
.camera-bg {
    background-color: #71d3a9;
    position: absolute;
    left: 70%;
    transform: translate(-50%, 30%);
    bottom: 0;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    content: "\f00c";
    content: "\f030";
    font-weight: 600;
    font-family: "Font Awesome 5 Free";
    font-size: 18px;
    justify-content: center;
    align-items: center;
    color: #fff;
    display: inline-flex;
	display: -ms-inline-flexbox;
	cursor: pointer;
}
.full-btn {
	background: #129BE8;
	border-radius: 5px;
	font-weight: 500;
	font-size: 14px;
	color: #fff;
}
.full-btn:hover {
	color: #fff;
	opacity: 0.8;
}
.profile-list ul{
	margin: 0;
    padding-left: 0;
    list-style: none;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	border-top: 1px solid #E9E9E9;
}
.profile-list li {
	font-weight: 500;
	font-size: 16px;
	color: #161c2d;
	border-right: 1px solid #E9E9E9;
	text-align: center;
    margin: 0 auto;
	padding: 15px 5px;
	width: calc(100%/6);
}
.profile-list li:last-child {
	border-right: 0;
}
.profile-preword {
	margin: 15px 0 0 0;
    padding-left: 0;
    list-style: none;
    display: inline-flex;
	display: -ms-inline-flexbox;
    flex-wrap: wrap;
}
.profile-preword li {
	font-weight: 500;
	font-size: 16px;
	color: #8D8D8D;
	margin-right: 20px;
	margin-bottom: 5px;
}
.attach-file {
    background: #E65425;
    display: inline-flex;
    padding: 7px 15px;
    color: #fff;
    border-radius: 5px;
    position: relative;
    margin-bottom: 10px;
}
.attach-file input {
    position: absolute;
    left: 0;
    opacity: 0;
}
.filename ul {
    display: flex;
    list-style: none;
}
.filename ul li {
    margin: 0 5px 0 0;
    background: #F4F7FF;
    padding: 10px;
    border-radius: 5px;
}
.filename ul li h6 {
    margin: 0;
    font-size: 14px;
}
.filename ul li h6 a {
    margin-left: 10px;
    color: #FF3B30;
}
.profile-preword li img {
	box-shadow: 1px 0px 5px #aaaaaa;
	margin-right: 2px;
}
.profile-preword .rating .average-rating {
    font-size: 11px;
    background-color: #FEBE42;
    border-radius: 5px;
    color: #fff;
    font-weight: 600;
    width: 22px;
    height: 16px;
    line-height: 16px;
    display: inline-flex;
	display: -ms-inline-flexbox;
    margin-right: 5px;
    align-items: center;
    justify-content: center;
}
.profile-inf {
	text-align: right;
}
.profile-inf p i.heart {
	color: #F5365C;
	font-size: 30px;
	margin-right: 10px;
}
.bottom-text {
    position: absolute;
    right: 0;
    bottom: 0;
}
.profile-right {
    padding-left: 0;
    list-style: none;
    display: inline-flex;
	display: -ms-inline-flexbox;
    flex-wrap: wrap;
}
.profile-right li {
	font-weight: 600;
	font-size: 12px;
	color: #161c2d;
	margin-right: 20px;
}
.profile-right li:last-child {
	margin-right: 0;
	padding-top: 0;
}
.amt-hr {
	font-weight: 500;
	font-size: 36px;
	color: #F5365C;
}
.amt-hr p {
	font-size: 16px;
	color: #161c2d;
}
.profile-list .proj-title {
	font-weight: 500;
	font-size: 16px;
	color: #161c2d;
}
.pro-post {
    background: #fff;
    box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
    margin-bottom: 30px;
    border: 1px solid #e8e8e8;
}
.pro-title {
    font-weight: bold;
    font-size: 20px;
    position: relative;
    color: #161c2d;
    padding-bottom: 10px;
    margin-bottom: 15px;
}
.pro-title.without-border {
	border: 0;
	padding-bottom: 0;
	margin-bottom: 0;
}
.pro-title.without-border:before,
.pro-title.without-border:after {
	display: none;
}
.pro-list {
	list-style: none;
    padding: 0;
}
.project-widget {
	background: #fff;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);   
	margin-bottom: 30px;
}
.project-widget .select2-container {
	z-index: unset;
}
.project-widget .pro-image {	
	border-radius: 5px 5px 0px 0px;
}
.project-widget .pro-image {	
	border-radius: 5px 5px 0px 0px;
}
.project-widget .pro-detail {	
	text-align: center;
	padding: 15px;
}
.project-widget .pro-name {
	font-weight: 600;
	font-size: 13px;
	text-transform: uppercase;
	color: #161c2d;
	margin-bottom: 5px;
}
.project-widget .pro-designation {
	font-size: 12px;
	color: #636363;
	margin-bottom: 0;
}
.more-btn {
    background-color: #E65425;
    border-radius: 150px;
    color: #fff;
    padding: 9px 16px;
    font-size: 12px;
    margin-right: 8px;
    font-weight: 600;
    color: #fff;
}
.more-btn:hover {
	color: #fff;
	opacity: 0.8;
}
.widget-list ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.widget-list ul li {
	float: left;
	margin: 6px 0;
	padding-left: 25px;
	position: relative;
}
.widget-list ul li::before {
    color: #E65425;
    content: '\f30b';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    left: 0;
    position: absolute;
}	
.widget-list ul li  h4 {
	font-weight: 500;
	font-size: 16px;
	color: #161c2d;
}
.widget-list ul li  h5 {
	font-weight: normal;
	font-size: 14px;
	color: #F38247;
	margin-bottom: 15px;
}
.widget-list ul li  p {
	font-size: 14px;
	color: #000;
}
.bookmark h5 {
	font-weight: 500;
	font-size: 18px;
	color: #161c2d;
	margin-bottom: 15px;
}
.bookmark-btn {
    background-color: #FEBE42;
    border-radius: 6px;
    color: #fff;
    padding: 9px 16px;
    font-size: 18px;
    margin-right: 8px;
    font-weight: 600;
    color: #fff;
}
.bookmark-btn:hover {
    color: #fff;
    background-color: #fab530;
    border-color: #fab530;
}
.share-icon {
	display: inline-block;
    background-color: #131135;
    border-radius: 6px;
    color: #fff;
    padding: 9px 16px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}
.share-icon:hover {
	color: #fff;
	opacity: 0.8; 
}
.follow-btn {
    background-color: #8553EE;
    border-radius: 50px;
    color: #fff;
    padding: 9px 30px;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
}
.follow-btn:hover {
    color: #fff;
    background-color: #7a40f3;
    border-color: #7a40f3;
}
.follow-posts {
    margin: 30px auto 0;
    padding: 10px 0;
	list-style: none;    
	width: 76%;
}
.follow-posts li{
	border-right: 1px solid #E9E9E9;
	display: inline-block;    
	padding: 0 20px;
}
.follow-posts li:last-child {
	border-right: 0;
}
.follow-posts li p {
	font-size: 16px;
	color: #161c2d;
	margin-bottom: 2px;
}
.follow-posts li h6 {
	font-size: 20px;
	color: #161c2d;
	font-weight: 500;
}
.about-widget ul li p {
	font-size: 15px;
    color: #5c5c5c;   
	margin-bottom: 0;
}
.about-widget ul li h6 {
	font-size: 15px;
    color: #000;
    font-weight: 600;    
	margin-bottom: 3px;
}
.feedback-form {
	background: #FEFEFE;
}
.proposal-widget  {	
	padding: 20px;
}
.proposal-features .proposal-widget {
	margin-bottom: 20px;
}
.proposal-widget  p {	
	margin-bottom: 0;
	padding: 10px 0 0;
}
.proposal-widget .proposal-text {
	font-weight: 600;
	font-size: 16px;
}
.proposal-widget  .custom_check {	
	margin-bottom: 0;
	width: 100%;
	display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
	align-items: center;
}
.proposal-widget  .custom_check .checkmark {
    top: auto;
}
.proposal-success {
    background: rgb(250 255 251 / 30%);
	border: 1px solid rgb(0 190 95 / 30%);
}
.proposal-success .proposal-text  {	
	color: #00BE5F;
}
.proposal-light {
	background: rgba(255, 248, 189, 0.3);
	border: 1px solid rgba(185, 166, 0, 0.3);
}
.proposal-light .proposal-text  {	
	color: #E2BD00;
}
.proposal-danger {
	background: rgba(241, 92, 61, 0.2);
	border: 1px solid rgba(241, 92, 61, 0.3);
}
.proposal-danger .proposal-text  {	
	color: #CA2500;
}
.proposal-pink {
    background: rgba(255, 248, 189, 0.3);
    border: 1px solid rgb(255 248 189 / 30%);
}
.proposal-greens {
	background: #FAFFFB;
	border: 1px solid #CFFAD8;
}
.activity-list {
   list-style: none;
    padding: 0;
	margin: 0;
}
.activity-list li {
   font-weight: 600;
	font-size: 16px;
	color: #4B4B4B;
}
.activity-list li + li {  
	padding-top: 13px;
}
.activity-list li:last-child {
	padding-bottom: 0;
}
.activity-list li span {
	color: #000;
}
.tags .badge-design, .tags .badge-tech {
	font-size: 14px;    
	padding: 10px;
}
.project-list-card {
	border-radius: 10px;
	border: 1px solid #CED4E6;
	background: #FFF;
	padding: 20px;
	margin-bottom: 24px;
	display: flex;
	gap: 22px;
	position: relative;
}
.add-fav-list {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 28px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #FFF6F1;
	border-radius: 50%;
	border:1px solid #CED4E6;
}
.add-fav-list {
	color: #CED4E6;
}
.add-fav-list.checked i {
	color: #fff;
}
.add-fav-list.checked {
	background: #EE6E33;
}
.company-splits > div{
	width: 100%;
} 
.company-split-price{
	width: 29%;
	display: flex;
    justify-content: flex-end;
    align-items: end;
}
.company-splits{
	display:flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.project-list-card .company-detail-image {
	min-width: 65px;
	/* height: 65px; */
}
.company-split-price{
	text-align: right;

}
.company-split-price h5 {
    font-size: 22px;
    margin-bottom: 10px;
}
.company-split-price h6 {
    font-size: 16px;
    color: #6B7385;
    margin-bottom: 24px;
}
.btn-submits {
    background: #71d3a9;
    color: #01243F;
    padding: 10px 24px;
    display: inline-block;
}
.btn-submits:hover {
    color: #fff;
    background: #71d3a9;
}
.project-list-card .company-address {
	margin-bottom: 14px;
}
.project-list-card .company-description p {
	margin-bottom: 14px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;  
	overflow: hidden;
}
.payment-detail {
	padding: 30px;
}
.payment-detail img {
	margin-bottom: 10px;
}
.payment-detail h2 {
	font-size: 32px;
}
.payment-detail p {
	font-size: 18px;
	font-weight: 500;
	margin-bottom: 0;
}
.click-btn {
    font-weight: 500;
    border-radius: 3px;
    padding: 11px 35px;
	color: #fff;
	text-align: center;
	display: inline-block;
}
.transaction {
	padding: 35px;
	text-align: center;
	border-top: 1px solid #E7E7E7;
}
.transaction h5 {
	font-weight: 500;
	font-size: 20px;
	color: #161c2d;
	padding: 15px 0;
}
.billing {
	border: 0;
}
.billing .card-header {
	border-bottom: 1px solid #E7E7E7;
}
.billing .card-header p {
	font-size: 19px;
	color: #161c2d;
	margin-bottom: 0;
}
.payment-list h3 {
	font-weight: 700;
	color: #161c2d;
	margin-bottom: 25px;
	font-size: 20px;
}
.payment-list label {
	font-weight: 600;
    font-size: 15px;
    color: #161c2d;
}
.btn-pad {
	margin: 25px 0;
}
.billing-method {
	padding: 20px;
}
.review-content {
	border-bottom: 1px solid #ececec;
	padding: 30px;
}
.review-content h4 {
	font-weight: 600;
	font-size: 17px;
	color: #161c2d;
}
.review-content p {
	font-size: 15px;
	margin: 12px 0px;
	font-weight: 500;
}
.review-content.no-padding {
	padding: 0 0 1.5rem 0;
}
.review-content.no-padding + .review-content.no-padding {
	padding-top: 1.5rem;
}
.review-content:last-child {
	padding-bottom: 0;
	border: 0;
}
.review-btn {
	background: #E65425;
	border-radius: 5px;
	font-weight: 500;
	font-size: 14px;
	color: #fff;
}
.grey-btn {
	background: #D9D9D9;
}
.widget-profile {
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
}
.widget-profile .profile-info-widget {
	display: block;
	text-align: center;
}
.pro-widget-profile .profile-info-widget {
	text-align: left;
}
.pro-widget-profile .pro-widget-content {
	padding: 0;
}
.widget-profile .profile-info-widget .booking-pro-img {
    display: inline-block;
    margin: 0 0 15px;
    width: auto;
    padding: 8px;
    background-color: #f7f7f7;
    border-radius: 50%;
}
.widget-profile .profile-info-widget .booking-pro-img img {
    border-radius: 50%;
    height: 120px;
    width: 120px;
}
.info-details {
    list-style: none;
    margin: 0;
    padding: 0;
}
.info-details li .title {
    color: #161c2d;
    font-weight: 500;
}
.info-details li .text {
    color: #757575;
    display: block;
    font-size: 16px;
    overflow: hidden;
}
.info-details li {
	margin-bottom: 10px;
}
.info-details li:last-child {
	margin-bottom: 0;
}
.align-right {
	text-align: right;
}

/*-----------------
	19. Range
-----------------------*/

.slider-labels {
	margin-top: 10px;
}
.noUi-target,.noUi-target * {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-ms-touch-action: none;
	touch-action: none;
	-ms-user-select: none;
	-moz-user-select: none;
	user-select: none;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-target {
	position: relative;
	direction: ltr;
}
.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
}
.noUi-origin {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
}
.noUi-handle {
	position: relative;
	z-index: 1;
}
.noUi-stacking .noUi-handle {
	z-index: 10;
}
.noUi-state-tap .noUi-origin {
	-webkit-transition: left 0.3s,top .3s;
	-moz-transition: left 0.3s,top .3s;
	transition: left 0.3s,top .3s;
}
.noUi-state-drag * {
	cursor: inherit !important;
}
.noUi-base,.noUi-handle {
	-webkit-transform: translate3d(0,0,0);
	-moz-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}
.noUi-horizontal {
	height: 4px;
}
.noUi-horizontal .noUi-handle {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	left: -7px;
	top: -7px;
	background-color: #E65425;
	cursor: pointer;
}
.noUi-background {
	background: #EDEDED;
}
.noUi-connect {
	background: #E65425;
	-webkit-transition: background 450ms;
	transition: background 450ms;
}
.noUi-origin {
	border-radius: 2px;
}
.noUi-target {
	border-radius: 2px;
}
.noUi-draggable {
	cursor: w-resize;
}
.noUi-vertical .noUi-draggable {
	cursor: n-resize;
}
.noUi-handle {
	cursor: default;
	-webkit-box-sizing: content-box !important;
	-moz-box-sizing: content-box !important;
	box-sizing: content-box !important;
}
.noUi-handle:active {
	border: 8px solid #345DBB;
	border: 8px solid rgba(53,93,187,0.38);
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
	left: -14px;
	top: -14px;
}
.slider-labels .caption {
    background: #FFEEEA;
    border-radius: 80px;
    padding: 3px 8px;
    text-align: center;
    margin: 0 auto;
	position: relative;
	top: 10px;
	font-weight: bold;
	font-size: 16px;
	color: #E65425;
}
.slider-labels .caption::before {
    border: 5px solid #ffeeea;
    border-color: transparent transparent #ffeeea #ffeeea;
    box-shadow: -2px 2px 2px -1px rgb(0 0 0 / 10%);
    content: "";
    left: 38px;
    position: absolute;
    top: 2px;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}
[disabled].noUi-connect,[disabled] .noUi-connect {
	background: #B8B8B8;
}
[disabled].noUi-origin,[disabled] .noUi-handle {
	cursor: not-allowed;
}

/*-----------------
	21. Customer Profile
-----------------------*/

.add-new-btn {
    background-color: #71d3a9;
    border-radius: 30px;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 10px 20px;
}
.add-new-btn:focus, .add-new-btn:hover,.add-new-btn:active {
	background-color: #E65425;
    color: #fff;
}
.customer-info {
	margin-top: 0;
}
.customer-info ul {
    padding: 0 0 10px;
    list-style: none;
	font-size: .875rem;
	margin: 0;
}
.customer-info ul li {
    position: relative;
    font-size: .875rem;
    font-weight: 600;
    color: #161c2d;
    text-transform: capitalize;
}
.customer-info ul li span {
    color: #757575;
    float: right;
    font-weight: 400;
}
.add-more-item {
	margin-bottom: 10px;
}
.add-more-item a {
	color: #71d3a9;
	font-weight: 500;
}
.pat-widget-profile .pro-widget-content {
	padding: 0;
	margin-top: 0;
}
.pat-widget-profile .booking-date li {
	font-size: .875rem;
}
.pat-widget-profile .booking-date li span {
    font-size: .875rem;
}
.pat-widget-profile .card-body {
    padding: 35px 20px 20px;
}
.pat-widget-profile .card-footer {
	padding: 15px;
	background: #FBFBFB;
}
.widget-profile.pat-widget-profile .profile-info-widget .booking-pro-img {
	padding: 0;
}
.widget-profile.pat-widget-profile .profile-info-widget .booking-pro-img img {
    border-radius: 50%;
    height: 100px;
    width: 100px;
}

/*-----------------
	22. Blog
-----------------------*/

.blog {
	margin-bottom: 30px;
	padding: 1.5rem;
	position: relative;
	background: #FFFFFF;
	box-shadow: 0px 4px 48px rgba(160, 160, 160, 0.1);
	border-radius: 6px;
}
.blog-image {
	overflow: hidden;
}
.blog-image,
.blog-image > a,
.blog-image img {
	display: block;
	position: relative;
	width: 100%;
	height: auto;
}
.blog-image img {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.blog-image a:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.blog-image {
	margin-bottom: 30px;
}
.blog-content {
	position: relative;
}
.blog.grid-blog .blog-content {
	padding: 20px;
}
.testimonial-section .section-header {
	margin-bottom: 40px;
}
.blog-title {
	font-size: 22px;
	margin: 0 0 15px;
}
.blog-title a {
	color: #161c2d;
}
.blog-title a:hover {
	color: #71d3a9;
}
.blog-content p {
    font-size: 15px;
    margin: 0 0 20px;
	line-height: 1.7;
	font-weight: 500;
}
.blog-read {
    margin-top: 10px;
    color: #E65425;
}
.blog-read a {
	color: #E65425;
	font-size: 15px;
	font-weight: 600;
}
.blog-read a:hover {
	color: #131135;
}
.blog-read a i {
	font-size: 13px;
}
.read-more {
    display: inline-block;
    font-size: 16px;
    padding: 0;
    color: #E65425;
    text-decoration: underline;
    font-weight: 500;
}
.blog-info {
    color: #606060;
    font-size: 15px;
    margin-bottom: 15px;
}
.blog-info a {
	color: #606060;
}
.blog-info a:hover {
	color: #71d3a9;
}
.blog-info i {
    margin-right: 7px;
}
.entry-meta {
	color: #9D9D9D;
	font-size: 14px;
	list-style: none;
	margin-bottom: 15px;
	padding: 0;
}
.entry-meta li {
    display: inline-block;
    margin-right: 15px;
}
.entry-meta li:last-child {
	margin-right: 0;
}
.blog-pagination .pagination {
	margin-bottom: 30px;
}
.blog-pagination .pagination li a {
    padding: 12px 20px;
    font-weight: 600;
    color: #2E3842;
    margin-right: 10px;
    border-radius: inherit !important;
	border-color: #f0f0f0;
}
.blog-pagination .pagination li.disabled a {
	border-color: #f0f0f0;
}
.blog-pagination .pagination li.active a {
	background: #71d3a9;
	border-color: transparent;
	color: #fff;
}
.post-left ul {
	margin: 0;
	padding-left: 0;
	list-style: none;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.post-left ul li {
	margin-bottom: 5px;
	margin-right: 20px;
}
.post-left ul li:last-child {
	margin-right: 0;
}
.post-author a {
	display: flex;
	display: -webkit-box;
	display: -ms-flexbox;
	align-items: center;
}
.video {
	position: relative;
	padding-bottom: 56%;
	height: 0;
	overflow: hidden;
}
.video iframe,
.video object,
.video embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
}
.language-widget .latest-posts li {
	border-bottom: 0;
}
.latest-posts {
	margin: 0;
	padding: 0;
}
.latest-posts li {
	display: table;
	width: 100%;
    padding: 11px 0;
}
.latest-posts li:last-child {
	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: none;
}
.category-widget .latest-posts li {
    padding: 15px 30px;
}
.category-widget .latest-posts li:first-child {
	padding-top: 0;
}
.category-widget .latest-posts li:last-child {
	margin-bottom: 0;
	border-bottom: none;
}
.post-thumb {
	border-radius: 10px;
	width: 77px;
	float: left;
	overflow: hidden;
}
.b-0 {
	border: 0 !important;
}
.post-thumb a img {
	border-radius: 10px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
}
.post-thumb a:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.post-info {
	margin-left: 95px;
}
.post-info h4 {
    font-size: 16px;
    font-weight: bold;
    line-height: 25px;
    margin-bottom: 0;
}
.post-info h4 a {
	color: #161c2d;
}
.post-info h4 a:hover {
	color: #71d3a9;
}
.post-info p {
	color: #757575;
	font-size: 12px;
	margin: 0;
}
.post-info .posts-date {
    color: #6e727d;
    font-size: 14px;
}
.post-info p i {
	color: #73379a;
	font-size: 14px;
	margin-right: 4px;
}
.category-widget .category-link {
	list-style: none;
	padding: 0;
	margin: 0;
}
.category-widget .category-link li {
    margin-bottom: 17px;
}
.category-widget .category-link li a {
    color: #2E3842;
    font-size: 15px;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    text-transform: capitalize;
    display: block;
    position: relative;
}
.category-widget .category-link li a:after {
    display: inline-block;
    position: absolute;
    right: 0;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    color: #2E3842;
}
.category-widget .category-link li:last-child {
    margin-bottom: 0;
}
.category-widget .categories {
	list-style: none;
	margin: 0;
	padding: 0;
}
.category-widget .categories li {
    margin-bottom: 20px;
}
.category-widget .categories li:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
}
.category-widget .categories li a {
    color: #2E3842;
    font-size: 15px;
    font-weight: 500;
}
.category-widget .categories li a span {
    float: right;
    color: #757575;
}
.category-widget .categories li a:hover {
	color: #71d3a9;
}
.category-widget .categories > li > a > i {
	color: #73379a;
	font-size: 18px;
	margin-right: 10px;
}
.tags-widget .card-body {
	padding-bottom: 1rem;
}
.tags {
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
}
.btn-primary:focus,.btn-primary:active {
	background: #71d3a9 !important;
	color: #fff !important;
}
.tags li {
	float: left;
}
.tag {
    display: inline-block;
	padding: 3px 15px;
    background-color: #F4F7FF;
    border-radius: 5px;
    font-size: 15px;
    color: #6e727d;
    margin: 0 10px 10px 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.tag:hover {
	background-color: #E65425;
	border-color: #E65425;
	color: #fff;
}
.comment-by {
	display: block;
	font-size: 14px;
	line-height: 21px;
	margin: 0 0 10px;
}
.comments-list .comment-block p {
    margin-bottom: 5px;
    color: #6e727d;
    line-height: 28px;
}

/*-----------------
	23. Blog Details
-----------------------*/

.blog-view .blog-title {
	font-size: 24px;    
	font-weight: bold;
}
.blog-content p:last-child {
	margin-bottom: 0;
}
.blog-view .blog-info {
	border: 0;
	margin-bottom: 20px;
	padding: 0;
}
.social-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.social-links ul li {
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    display: inline-block;
    margin-right: 15px;
}
.social-links li a {
    display: block;
    color: #606060;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
.social-links li a:hover {
    color: #E65425;
}
.social-share {
	float: left;
	list-style: none;
	margin: 0;
	padding: 0;
}
.social-share > li {
	display: inline-block;
	float: left;
	margin-left: 10px;
	text-align: center;
}
.social-share > li:first-child {
	margin-left: 0;
}
.social-share > li > a {
	border: 1px solid #dfdfdf;
	color: #666;
	display: inline-block;
	font-size: 22px;
	height: 40px;
	line-height: 40px;
	width: 40px;
}
.social-share > li > a:hover {
	background-color: #73379a;
	color: #fff;
	border-color: #73379a;
}
.blog-reply > a {
	color: #999;
	font-size: 12px;
	font-weight: 500;
}
.blog-date {
	color: #999;
	font-size: 12px;
}
.blog-comments .comments-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.blog-comments .comments-list li {
	clear: both;
	padding-left: 80px;
}
.blog-comments .comments-list li .comment {
	margin-bottom: 40px;
}
.blog-comments .comments-list li .comment-author {
	left: 0;
	position: absolute;
}
.blog-comments .comments-list li img.avatar {
	height: 58px;
	width: 58px;
	border-radius: 58px;
}
.blog-comments .comment-btn {
    color: #606060;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
}
.blog-author-name {
    color: #161c2d;
    font-size: 18px;
    font-weight: bold;
}
.blog-author-name{
    color: #161c2d;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 5px;
    font-weight: 700;
}
.new-comment label {
	font-weight: 500;
}
.comment-submit .btn {
	background-color: #E65425;
	border-color: #E65425;
	border-radius: 0;
	font-size: 18px;
	padding: 8px 26px;
	color: #fff;
}
.proposal-widget  .about-author + .about-author { 
	margin-top: 20px;
}
.proposal-widget  .about-author-img {
    border-radius: 0;
    background-color: transparent;
}
.about-author {
	border-bottom: 1px solid #EAEAEA;    
	padding: 30px;
}
.about-author:last-child {
	border-bottom: 0;
}
.about-author-img {
	background-color: #fff;
	height: 86px;
	overflow: hidden;
	position: absolute;
	width: 86px;
	border-radius: 50%;
}
.author-details {
	margin-left: 116px;
}
.author-details .blog-author-name {
	display: inline-block;
	margin-bottom: 10px;
}
.author-details h4{
	font-weight: 500;
	font-size: 18px;
	color: #161c2d;
	margin-bottom: 6px;
}
.author-details p {
    font-size: 16px;
    color: #55545b;
}
.author-details h3{
	font-weight: 500;
	font-size: 24px;
	color: #F5365C;
}
.author-details h5 {
	font-weight: normal;
	font-size: 14px;
	color: #F38247;
}
.author-details .rating {
	margin-bottom: 6px;
}
.widget-box {
	padding: 20px;
}
.widget-title-box {
    padding: 30px 30px 0;
}
.r-0 {
	right: 0 !important;
}
.post-author img {
    border-radius: 100%;
    width: 28px !important;
    margin-right: 7px;
}
.follow-widget {
	padding: 30px;
    text-align: center;
    position: relative;
}
.custom-edit-btn {
	position: absolute;
	right: 30px;
	top: 30px;
}
.profile-group .input-group {
	background: #fff;
	border: 1px solid #E7E8EA;
	border-radius: 5px;
	padding: 2px;
}
.profile-group .input-group .form-control {
	border: transparent;
}
.profile-group .input-group .input-group-append { 
    margin: 5px 5px;
    justify-content: space-between;
    align-items: center;
    display: flex;
}
.profile-group .input-group .sub-btn {
	background: #71D3A9;
    border-radius: 10px;
}
.attach-img {
	position: relative;
}
.attach-detail {
	position: absolute;
    bottom: 12px;
    left: 12px;
}
.attach-detail p {
    margin-bottom: 2px;
	font-size: 14px;
	color: #161c2d;
}
.attach-detail h6 {
	font-size: 14px;
	color: #A3A3A3;
	font-weight: normal;
	margin-bottom: 0;
}
.client-list {
	padding: 15px;
    text-align: center;
}
.client-list p {
	font-weight: 500;
	font-size: 16px;
	color: #5C5C5C;
    margin-bottom: 10px;
}
.client-list h6 {
	font-weight: 600;
	font-size: 24px;
    margin-bottom: 0;
}
.job-type {
	padding: 15px;
    text-align: center;
}
.job-type p {
	font-weight: 500;
	font-size: 14px;
	color: #4E4E4E;
    margin-bottom: 10px;
}
.job-type h6 {
	font-weight: 500;
	font-size: 20px;
    margin-bottom: 0;
	color: #161c2d;
}
.exp-detail {
	margin-bottom: 30px;
}
.exp-detail img {
	float: left;
}
.exp-detail p {
	font-weight: 500;
	font-size: 14px;
	color: #4E4E4E;
	margin-bottom: 8px;
}
.exp-detail p {
	font-weight: 500;
	font-size: 16px;
	color: #161c2d;
}
.exp-detail .exp-info {	
	margin-left: 40px;
}
.exp-detail .exp-info h5 {	
	margin-bottom: 0;
}
.management-widget .profile-btn {
    padding: 12px 0;
	font-size: 18px;
	text-transform: uppercase;
	margin-top: 20px;
}
.sub-title {
	font-weight: 500;
	font-size: 14px;
	color: #161c2d;
}
.budget-info {
	margin-bottom: 20px;
}
.list-details {
	display: flex;
	display: -ms-flexbox;
    justify-content: space-between;
    flex-wrap: wrap;
	list-style: none;
    padding: 0;
}
.white-circle {
	background: #fff;
	border-radius: 110px;
    width: 25px;
    display: inline-block;
    height: 25px;
    line-height: 25px;
	color: #E65425;
}
.btn-view-outline {
    color: #E65425;
    border-color: #E65425;
    font-size: 14px;
}
.btn-view-outline:hover {
    color: #fff;
    background-color: #E65425;
    border-color: #E65425;
}
.w-80 {
	max-width: 80%;
}
.bg-none {
	background: transparent;
    border: 0;
}
.square-box {
	width: 34px;
    height: 34px;
    line-height: 34px;
    border: 1px solid;
    background: #F8F8F8;
	border: 1px solid #FFE6E0;
    text-align: center;
	border-radius: 5px;
}
.reply-group .input-group input {
    background: #F8F8F8;
    border: 0;
}
.reply-group  .sub-btn {
	padding: 8px 40px;
    border: 0;
    border-radius: 0;
}
.profile-widget {
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius:4px;
    margin-bottom:30px;
    position:relative;
    -webkit-transition:all .3s ease 0s;
    -moz-transition:all .3s ease 0s;
    -o-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
	padding: 15px;
}
.profile-widget .rating {
    color: #757575;
    font-size: 14px;
    margin-bottom: 15px;
}
.pro-img {
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 4px;
}
.pro-img img {
    border-radius: 4px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
	transform: translateZ(0);
	-moz-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-ms-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-o-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	-webkit-transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
	transition: all 2000ms cubic-bezier(.19,1,.22,1) 0ms;
    width: 100%;
}
.pro-img:hover img {
    -webkit-transform: scale(1.15);
    -moz-transform: scale(1.15);
    transform: scale(1.15);
}
.profile-widget .fav-btn {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #fff;
    width: 30px;
    height: 30px;
	display: -ms-inline-flexbox;
	display: inline-flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    border-radius: 3px;
    color: #2E3842;
    -webkit-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    opacity: 0;
    visibility: hidden;
	z-index: 99;
}
.add-btn-form{
	margin-bottom: 24px;
	margin-right: 40px;
}
.profile-widget:hover .fav-btn {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.profile-widget .fav-btn:hover {
	background-color: #fb1612;
	color: #fff;
}
.pro-content {
    padding: 15px 0 0;
}
.pro-content p {
	font-size: 15px;
	font-weight: 500;
}
.pro-content p:last-child {
	margin-bottom: 0;
}
.pro-content .title {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 5px;
}
.profile-widget .pro-content .title a {
    display: inline-block;
}
.profile-widget .verified {
	color: #28a745;
	margin-left: 3px;
}
.profile-widget p.speciality {
    font-size: 13px;
    color: #757575;
    margin-bottom: 5px;
    min-height: 40px;
}
.available-info {
    font-size: 13px;
    color: #757575;
    font-weight: 400;
    list-style: none;
    padding: 0;
    margin-bottom: 15px;
}
.available-info li + li {
	margin-top: 5px;
}
.available-info li i {
	width: 22px;
}
.row.row-sm {
    margin-left: -3px;
    margin-right: -3px;
}
.row.row-sm > div {
    padding-left: 3px;
    padding-right: 3px;
}
.view-btn {
    color: #115241;
    font-size: 13px;
    border: 2px solid #115241;
    text-align: center;
    display: block;
    font-weight: 500;
	padding: 6px;
}
.view-btn:hover, .view-btn:focus {
	background-color: #115241;
	color: #fff;
}
.pro-content .book-btn {
	background-color: #115241;
    border: 2px solid #115241;
    color: #fff;
    font-size: 13px;
    text-align: center;
    display: block;
    font-weight: 500;
	padding: 6px;
	border-radius: .25rem;
}
.book-btn:hover, .book-btn:focus {
	background-color: #0d5341;
	border-color: #115241;
	color: #fff;
}
.view-icons {
    display: inline-flex;
	display: -ms-inline-flexbox;
    float: right;
}
.view-icons a {
	align-items: center;
	background-color: #fff;
	border: 1px solid #ddd;
	border-radius: 4px;
	color: #161c2d;
	display: flex;
	display: -webkit-box;
	display: -ms-flexbox;
	font-size: 20px;
	justify-content: center;
	padding: 4px 10px;
	text-align: center;
	margin-left: 10px;
	width: 44px;
	height: 44px;
}
.view-icons a:hover {
	border-color: #71d3a9;
	color: #71d3a9;
}
.view-icons a.active {
    background-color: #71d3a9;
	border-color: #71d3a9;
	color: #fff;
}

/*-----------------
	24. Blog Grid
-----------------------*/

.grid-blog .blog-title {
    font-size: 22px;
    margin: 5px 0;
    color: #2e2e2e;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.grid-blog .blog-title a {
	display: block;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-bottom: 10px;
}
.grid-blog .blog-content p {
	margin: 0 0 15px;
	color: #6B7385;
}
.grid-blog .blog-info {
	margin-bottom: 15px;
}
.grid-blog .blog-image {
	margin-bottom: 0px;
	border-radius: 6px 6px 0px 0px;
}
.blog.grid-blog {
	padding: 0px;
	box-shadow: 0px 8px 16px 0px rgba(142, 141, 208, 0.12);
}
.grid-blog .entry-meta {
    display: flex;
    align-items: center;
    justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 0;
}
.grid-blog .entry-meta li {
	margin-bottom: 10px;
}
.grid-blog .entry-meta li .fa-calendar-alt {
	font-weight: 500;
	color: #E65425;
}
.grid-blog .entry-meta li .feather-calendar{
	font-weight: 500;
	color: #E65425;
}
.grid-blog .entry-meta li:last-child {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: flex-end;
	-webkit-justify-content: end;
	-ms-flex-pack: end;
}
.grid-blog .entry-meta li i {
	margin-right: 3px;
}
.grid-blog .post-author {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.grid-blog .post-author a {
	color: #5F5F5F;
	font-size: 14px;
	font-weight: 500;
}
.grid-blog .post-author a:hover {
	color: #71d3a9;
}

/*-----------------
	25. Terms and Conditions
-----------------------*/

.terms-text {
    margin-bottom: 20px;
}
.terms-text h3 {
	font-weight: 600;
	font-size: 20px;
	line-height: 30px;
	text-transform: uppercase;
	color: #71d3a9;
	margin-bottom: 25px;
}
.terms-text h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
	color: #000;
}
.terms-text p {
    color: #000;
    display: inline-block;
    font-size: 15px;
    line-height: 30px;
}
.terms-text  ul  {
	padding-left: 20px;
	margin-bottom: 25px;
}
.terms-text  ul li {
	padding-left: 5px;
	color: #000;
}
.terms-text  ul li + li {
	margin-top: 10px;
}
.terms-text  ul li::marker {
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    left: 0;
    margin-right: 5px;
    position: absolute;
    top: 0;
	color: #71d3a9;
}
.list-widget{
	box-shadow: 0px 2px 4px rgba(189, 189, 189, 0.25);
}
.list-widget .card-body {
    padding: 0;
}
.list-group-item {
	border: 1px solid #ffe6e1;
}
.list-group-item a {
	font-size: 16px;
	color: #161c2d;
}
.list-group-item a:hover{
	color: #71D3A9;
}
.list-group-item ul li::marker{
	content: "";
}
.list-group-item ul li {
	padding: 0.75rem 0;
}
.list-group-item ul li:last-child {
	padding: 0;
}

/*-----------------
	26. Category Detail
-----------------------*/

.bookmark {
	margin-bottom: 0;
}
.web-project h4 {
	font-weight: 500;
	font-size: 22px;
	color: #E65425;
}
.stone-btn {
    font-size: 16px;
    font-weight: bold;
    background: #E65425;
    color: #fff;
    border-radius: 30px;
    padding: 18px 25px;
}
.nav-tabs-bottom .nav-link {
    padding: 1rem 1rem;
}
.quick-title {
	margin-bottom: 30px;
}
.btn-group button, .btn-group .btn {
	border-radius: 130px;
}
.in-btn {
	background: #E65425;
	border: 1px solid transparent;
	padding: 10px 15px;
}
.out-btn {
	background: #FAFAFA;
	color: #161c2d;
	border: 1px solid transparent;
	padding: 10px 15px;
}
.btn-cancel {
	background: #E65425;
	border-radius: 4px;
	font-weight: 600;
	font-size: 16px;
	padding: 12px 20px;
	color: #fff;
}
.sort-show .select2-container .select2-selection--single {
	background: #fff;
	border: 1px solid #FFF5F3;
	box-shadow: 0px 4px 14px rgba(239, 239, 239, 0.35);
}
.sort-show .sort-title {
	color: #161c2d;
}
.sec-line {
    position: relative;
	display: inline-flex;
}
.sec-line::before {
    height: 3px;
    content: "";
    position: absolute;
    width: 29px;
    background: #FF3F00;
    bottom: 0;
    left: 0;
}
.sec-line::after {
    height: 3px;
    content: "";
    position: absolute;
    width: 11px;
    background: #FF3F00;
    bottom: 0;
    left: 32px;
}
.stats-box {
	background: #FBFCFF;
	border-radius: 5px;
	padding: 25px;
	height: 100%;
}
.stats-content {
	margin-top: 25px;
	display: -ms-flexbox;
    display: flex;
}
.stats-detail {
	background: #fff;
	box-shadow: 0px 4px 4px rgba(236, 236, 236, 0.25);
	border-radius: 5px;
	padding: 30px;
	text-align: center;
	width: 50%;
	margin: 0 5px;
}
.stats-detail h3 { 
	color: #E65425;
	font-family: Roboto;
	font-weight: 500;
	font-size: 34px;
}
.stats-detail p { 
	font-family: Roboto;
	font-weight: 500;
}
.table-borderless.table tbody tr {
	border-bottom: 0;
}
.table-borderless.table tbody tr td {
	font-weight: 500;
	font-size: 22px;
	padding: 20px;
}
.grey-text {
	color: #8C8C8C;
	padding: 10px 20px;
}
.milestone-payment, .profit-transactions, .quick-stat {
	margin-bottom: 35px;
}
.position-title .sort-by {
	padding-left: 15px;
}
.finance-title .sort-show .sort-title {
	color: #E65425;
}
.finance-title .sort-show {
	float: right;
}
.bg-bluelight {
	background: #F5F9FF;
}
.bg-light {
	background: #FCFCFC;
}
.text-rbold {
	font-weight: bold;
	font-size: 24px;
	color: #E65425;
}
.transaction-position {
    background: #FBFCFF;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 30px;
}
.transaction-position h3 {
	font-size: 22px;
}
.transaction-payment {
	box-shadow: 0px 4px 14px rgba(206, 206, 206, 0.25);
	margin-bottom: 50px;
}
.transaction-payment  .transaction-table {
	box-shadow: none;
}
.transaction-table .main-head {
	background: #FBFCFF;
}
.transaction-table .main-head th, .transaction-table .main-head td {
	border: 0;
}
.transaction-table .main-head .sort-show {
	padding-left: 15px;
}
.transaction-table .head {
	background: #F5F9FF;
}
.transaction-table .head-title {
	color: #E65425;
	font-weight: 600;
	font-size: 24px;
}
.transaction-table .sort-title {
	font-size: 15px;
    font-weight: 500;
}
.export-item {
	font-weight: 500;
    font-size: 17px;
	color: #E65425;
}
.transaction-payment .tab-content {
	 padding-top: 0;
}
.transaction-payment ul.nav-tabs-solid {
	padding: 10px;
}
.pink-btn {
	background: #FFF8F8;
	padding: 5px 10px;
}
.green-btn {
	background: #F0FFF3;
	padding: 5px 10px;
}
.dol-btn {
	background: #F8F8F8;
	border-radius: 5px;
	padding: 10px 30px;
}
.wallet, .payment-process {
	padding: 30px;
    background: #fff;
    box-shadow: 0px 4px 14px rgb(198 198 198 / 25%);
    border-radius: 0px 0px 10px 10px;
}
.payment-process .select2-container {
	z-index: unset;
}
.wallet-detail {
	background: #fff;
	box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
	border-radius: 0px 0px 10px 10px;
}
.wallet-title {
	padding: 25px;
}
.wallet-bal p {
	margin-bottom: 10px;
}
.wallet-bal h3 {
	font-size: 22px;
}
.wallet-bal {
	display: flex;
    align-items: center;
}
.dol-circle {
	width: 80px;
    height: 80px;
    background: #FF4117;
    border-radius: 50%;
	text-align: center;
    line-height: 80px;
    padding: 0;
	font-size: 38px;
	color: #FFF;
    display: block;
	float: left;
	margin-right: 30px;
}
.wallet-content {
	display: flex;
    flex-wrap: wrap;
}
.wallet-pay {
	width: 50%;
    text-align: center;
    padding: 25px;
}
.wallet-pay:first-child {
	border-right: 1px solid #EAEAEA;
}
.wallet-pay p {
	margin-bottom: 5px;
}
.wallet-pay h3 {
	font-weight: 600;
	font-size: 22px;
}
.payment-process ul {
	padding: 0;
    margin: 0;
}
.payment-process ul li {
	display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding: 12px 0;
	align-items: center;
}
.payment-process ul li:last-child {
    font-size: 18px;
    padding-bottom: 0;
    font-weight: 600;
}
.payment-process .sortby-fliter {
	width: 160px;
}
.payment-process .input-group.dep-amt {
	width: 160px;
}
.payment-process .input-group.dep-amt .form-control {
    border-color: #e9ecef;
    border-radius: 0;
    text-align: right;
}
.payment-process .input-group.dep-amt .input-group-text {
    border-radius: 0;
	padding: 10px 15px;
}
.pay-btn {
	background: #E65425;
    border-radius: 5px;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
	padding: 12px 0;
}
.pay-btn:hover {
	color: #fff;
	opacity: 0.8;
}
.tot-border {
	border-bottom: 1px solid #ebebeb;
}
.payment-process p {
	padding-top: 20px;
	font-size: 14px;
    color: #777777;
}
.card-list {
	margin: 0;
	padding: 0;
}
.card-list li {
	display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding: 10px 0;
    align-items: center;
}
.column-5 {
	 width: calc(100%/5);
}
.price-features {
	list-style-type: none;
	border: 1px solid #eee;
	margin: 0;
	padding: 0;
	background: #FDFFFE;
	border: 1px solid #F0EAEA;
	box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
	border-radius: 10px;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	margin: 30px 5px 0 5px;
}
.plus-price {
	background: #fffcfc;
	border: 1px solid #E65425;
	margin-top: 0;
}
.price-features li {
	padding: 15px;
	text-align: center;
	color: #646464;
	font-weight: 500;
	font-size: 16px;
}
.price-features .header-price h3 {
	font-weight: 500;
	font-size: 30px;
	color: #E65425;
}
.price-features .header-price p {
	font-size: 14px;
	margin-bottom: 0;
}
.price-features .yr-price h3 {
	font-weight: 500;
	font-size: 26px;
}
.price-features .yr-price p {
	font-size: 14px;
	margin-bottom: 0;
}
.price-features .yr-price {
	background: #FBFBFB;
}
.price-features.plus-price .yr-price {
	background: transparent;
}
.price-features .subheader-price p {
	margin-bottom: 0;
	font-weight: 500;
}
.tab-btn {
	display: inline-flex;
}
.tab-btn .in-btn a {
	color: #fff;
}
.tab-btn .out-btn a {
	color: #161c2d;
}
.plan-btn {
	background: #131135;
	border-radius: 10px;
	font-weight: 500;
	font-size: 20px;
	color: #fff;
}
.plan-btn:hover {
    color: #fff;
    background: #fb451d;
}
.plus-price .plan-btn {
	background: linear-gradient(0deg, #E65425, #E65425), #00A171;
}
.change-btn {
	background: linear-gradient(113.98deg, #E65425 19.08%, #E65425 68.11%), #E65425;
	border-radius: 10px;
	font-weight: 600;
	font-size: 20px;
	color: #fff;
}
.plan-detail {
	background: #fff;
	box-shadow: 0px 4px 24px 10px rgba(231, 231, 231, 0.3);
	border-radius: 15px;
}
.bt-0 {
	border: 0;
}
.plan-left h2 {
	color: #E65425;
}
.plan-right {
	text-align: right;
}
.plan-body {
	padding: 30px;
}
.transaction-table {
	box-shadow: 0px 4px 14px rgb(206 206 206 / 25%);
}
.transaction-table .table td, .transaction-table .table th {
	border-top: 0;
}
.transaction-table .table td {
	font-weight: 500;
}
.myprofile-widget {
	border: 1px solid #E8E8E8;
	box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
	height: 100%;
}
.myprofile-header {
	background: #E65425;
	padding: 20px;
}
.myprofile-header h2 { 
	color: #fff;
}
.myprofile-header p { 
	color: #fff;
	margin-bottom: 12px;
}
.member-btn {
	background: #fff;
	border-radius: 50px;
	font-weight: 500;
	font-size: 16px;
	color: #E65425;
}
.myprofile-body {
	background: #fff;
	padding: 20px;
}
.prog-card {
	background-color: #F4F4F4;
    padding: 12px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
}
.prog-acc {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.prog-acc h5 {
	font-weight: 500;
	font-size: 14px;
	color: #A8A8A8;
}
.icon-circle {
    background: #E65425;
    width: 29px;
    height: 29px;
    display: inline-block;
    line-height: 29px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
}
.icon-grey {
    background: #F4F4FA;
    width: 32px;
    height: 32px;
    display: inline-block;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
    color: #C8C8D8;
	font-size: 18px;
}
.progress {
	margin-bottom: 25px;
	width: 100%;
	margin-bottom: 0;
	height: 12px;
	border-radius: 50px;
}
.progress-bar {
	background: #159C5B;
	width: 25%;
}
.progress-info {
	width: 20%;
}
.progress-info h5 {
	color: #343434;
	font-size: 15px;
	font-weight: 500;
}
.progress-info p {
	margin-bottom: 0;
	margin-left: 10px;
}
.fund-btn {
	background: #131135;
	border-radius: 5px;
	color: #fff;
	font-weight: bold;
}
.pro-head {
	border-bottom: 1px solid #F8F8F8;
	padding: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.pro-head h2 {
	font-weight: 500;
	font-size: 20px;
	margin-bottom: 0;
}
.news-feature {
	position: relative;
	display: flex;
    align-items: center;
    padding: 0.75rem 1.5rem;
}
.news-feature img {
    margin-right: 15px;
}
.news-feature p {
	font-size: 14px;
	margin-bottom: 0;
}
.news-feature h5 {
	font-weight: 600;
	font-size: 14px;
}
.table-budget {
	font-weight: 500;
	font-size: 14px;
	color: #161c2d;
}
.text-expiry {
	color: #161c2d;
	font-weight: 600;
	font-size: 12px;
}
.detail-text {
	font-size: 15px;
	color: #161c2d;
}
.table-job .table td {
	font-weight: 500;
    font-size: 14px;
    color: #5F5F5F;
}
.dev-feature {
	padding: 20px;
}
.dev-feature:not(:last-child), .news-feature:not(:last-child) {
	border-bottom: 1px solid #F3F3F3;
}
.dev-feature h4 {
	font-weight: 500;
	color: #E65425;
}
.dev-feature p {
	font-size: 14px;
	margin-bottom: 10px;
}
.dev-feature h5 {
	font-weight: bold;
	font-size: 16px;
	margin-bottom: 15px;
	color: #808080;
}
.bgg-yellow {
	background: #FF7A03;
	color: #fff;
    border-radius: 50%;
    width: 17px;
    line-height: 17px;
    padding: 0;
    height: 17px;
}
.notification-set {
    box-shadow: 0px 8px 16px 0px #8E8DD01F;
    border: 1px solid #CED4E6;
    border-radius: 10px;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
	margin-bottom: 24px;
}
.notification-set-content {
    display: flex;
}
.btn-delete {
    background: #FFF2F2;
    color: #FF3B30;
}
.btn-delete:hover {
    color: #FFF;
    background: #FF3B30;
}
.notification-setimg img {
	width: 40px;
	height: 40px;
	border-radius: 50px;
}
.notification-setimg {
    margin-right: 12px;
}
.notification-set-contents h4 {
    font-size: 16px;
    margin-bottom: 5px;
}
.notification-set-contents p {
    font-size: 14px;
    color: #5F5F5F;
	margin-bottom: 5px;
}
.notification-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}
.notification-btn ul {
    display: flex;
    margin: 0;
}
.btn-invite{
	background: #FFF6F1;
	color: #E65425;
	padding: 10px;
	font-size: 16px;
	border-radius: 5px;
}
.freelance-rating{
	margin-left: 35px;
}
.btn-invite:hover{
	background: #E65425;
	color: #fff;
}
.freelance-widget .freelance-tags {
    margin-bottom: 20px;
    padding-bottom: 0px; 
	border-bottom: 0px solid #CED4E6;
}
.notification-btn ul li + li {
    margin-left: 8px;
}
.btn-line:hover {
    background: #6B7385;
    color: #fff !important;
}
.notification-set-contents ul {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.btn-line {
    border: 1px solid #CED4E6;
    color: #6B7385;
}
.notification-set-contents ul li + li {
    margin-left: 10px;
}
.notification-set-contents span {
    color: #6B7385;
    font-size: 12px;
}
.filter-form {
	background: #fff;
	box-shadow: 0px 4px 24px rgba(200, 200, 200, 0.25);
	padding: 30px;
	margin-bottom: 40px;
}
.rate-box {
	background: #E65425;
	border-radius: 0px 0px 30px 30px;
	width: 54px;
	height: 66px;
	line-height: 66px;
	text-align: center;
	font-weight: 600;
	font-size: 20px;
	color: #fff;
}
.rating.red i.filled {
    color: #febe42;
}
.bg-black {
	background: #131135;
	border: 1px solid #131135;
}
.setting-tab .nav-link {
	padding: 15px 30px;	
	font-weight: 600;
	font-size: 16px;
	color: #E65425;
}
.setting-tab .nav-link.active {
	background: #E65425;
    border-radius: 80px 0px 0px 80px;
	color: #fff;
}
.setting-content {
	padding-top: 0;
	margin-bottom: 30px;
}
.setting-content .pro-card {
	background: #fff;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
	border: 0;
}
.setting-content .pro-pad {
	padding: 20px;
}
.setting-content .pro-head h2 {
	font-size: 28px;
}
.pay-tab .nav-link {
	background: #FFF8F7;
	padding: 35px;
	color: #161c2d;
}

/*-----------------
	27. Chat
-----------------------*/

.chat-page .content {
	padding: 110px 0 0 0;
}
.chat-page .footer {
	display: none;
}
.chat-window {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
}
.chat-cont-left {
    border-right: 1px solid #f0f0f0;
    -ms-flex: 0 0 34%;
    flex: 0 0 34%;
    left: 0;
	margin-right: 1%;
    max-width: 35%;
    position: relative;
    z-index: 4;
	background: #fff;
	box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
	border-radius: 0px 0px 10px 10px;
}
.chat-cont-left .chat-header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
    color: #161c2d;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    height: 72px;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    padding: 0 15px;
}
.chat-cont-left .chat-header span {
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
}
.chat-cont-left .chat-header .chat-compose {
    color: #8a8a8a;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
}
.chat-cont-left .chat-search {
    background-color: #fff;
    width: 100%;
}
.chat-cont-left .chat-search .input-group {
    width: 100%;
}
.chat-cont-left .chat-search .input-group .form-control {
    background-color: #fff;
    border-radius: 50px;
    padding-left: 36px;
}
.chat-cont-left .chat-search .input-group .form-control:focus {
    border-color: #ccc;
    box-shadow: none;
}
.chat-cont-left .chat-search .input-group .input-group-prepend {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    bottom: 0;
    color: #666;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    right: 15px;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 4;
}
.chat-window .chat-scroll {
    min-height: calc(100vh - 328px);
    max-height: calc(100vh - 328px);
    overflow-y: auto;
}
.chat-cont-left .chat-users-list {
    background-color: #fff;
}
.chat-cont-left .chat-users-list a.media {
    background: #fff;
    box-shadow: 0px 4px 4px #f5f8ff;
    margin: 10px;
    padding: 10px 15px;
    transition: all 0.2s ease 0s;
}
.chat-cont-left .chat-users-list a.media:last-child {
	border-bottom: 0;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap {
    margin-right: 15px;
    position: relative;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
    height: 45px;
    width: 45px;
}
.chat-cont-left .chat-users-list a.media .media-img-wrap .status {
	bottom: 7px;
	height: 10px;
	right: 4px;
	position: absolute;
	width: 10px;
	border: 2px solid #fff;
}
.chat-cont-left .chat-users-list a.media .media-body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name {
    color: #E65425;
	font-weight: 600;
	font-size: 14px;
    text-transform: capitalize;
}
.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    color: #232323;
    font-size: 12px;
    line-height: 24px;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child {
    text-align: right;
}
.chat-cont-left .chat-users-list a.media .media-body > div:last-child .last-chat-time {
    color: #8a8a8a;
    font-size: 13px;
}
.chat-cont-left .chat-users-list a.media:hover {
	background-color: #f5f5f6;
}
.chat-cont-left .chat-users-list a.media.read-chat .media-body > div:last-child .last-chat-time {
	color: #8a8a8a;
}
.chat-cont-left .chat-users-list a.media.active {
	background-color: #f5f5f6;
}
.chat-cont-right {
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
	background: #fff;
	box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
	border-radius: 0px 0px 10px 10px;
	padding: 10px;
}
.chat-cont-right .chat-header {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    background: #fff;
	border: 1px solid #F4EEFF;
	box-shadow: 0px 4px 4px #F5F8FF;
	border-radius: 5px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    height: 72px;
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between;
    padding: 0 15px;
}
.chat-cont-right .chat-header .back-user-list {
    display: none;
    margin-right: 5px;
    margin-left: -7px;
}
.chat-cont-right .chat-header .media {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.chat-cont-right .chat-header .media .media-img-wrap {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	margin-right: 15px;
}
.chat-cont-right .chat-header .media .media-img-wrap .avatar {
	height: 50px;
	width: 50px;
}
.chat-cont-right .chat-header .media .media-img-wrap .status {
    border: 2px solid #fff;
    bottom: 0;
    height: 10px;
    position: absolute;
    right: 3px;
    width: 10px;
}
.chat-cont-right .chat-header .media .media-body .user-name {
    color: #161c2d;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
}
.chat-cont-right .chat-header .media .media-body .user-status {
    color: #666;
    font-size: 14px;
}
.chat-cont-right .chat-header .chat-options {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}
.chat-cont-right .chat-header .chat-options > a {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
    border-radius: 50%;
    color: #8a8a8a;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    height: 30px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    margin-left: 10px;
    width: 30px;
}
.chat-cont-right .chat-body {
    background-color: #fff;
}
.chat-cont-right .chat-body ul.list-unstyled {
    margin: 0 auto;
    padding: 15px;
    width: 100%;
}
.chat-cont-right .chat-body .media .avatar {
    height: 30px;
    width: 30px;
	bottom: 0;
    position: absolute;
}
.chat-cont-right .chat-body .media .media-body {
	margin-left: 45px;
}
.chat-cont-right .chat-body .media .media-body .msg-box > div {
	padding: 10px 15px;
	border-radius: 15px;
	display: inline-block;
	position: relative;
}
.chat-cont-right .chat-body .media .media-body .msg-box > div p {
    color: #fff;
    margin-bottom: 0;
	font-size: 15px;
}
.chat-cont-right .chat-body .media .media-body .msg-box + .msg-box {
	margin-top: 5px;
}
.chat-cont-right .chat-body .media.received {
	margin-bottom: 20px;
	position: relative;
}
.chat-cont-right .chat-body .media:last-child {
	margin-bottom: 0;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box > div {
	background-color: #E65425;
}
.chat-cont-right .chat-body .media.sent {
    margin-bottom: 20px;
	position: relative;
}
.chat-cont-right .chat-body .media.sent .avatar {
    height: 30px;
    width: 30px;
    bottom: 0;
    right: 0;
    position: absolute;
}
.chat-cont-right .chat-body .media.sent .media-body {
	-webkit-box-align: flex-end;
	-ms-flex-align: flex-end;
	align-items: flex-end;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	justify-content: flex-end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: flex-end;
    margin-left: 0;
	margin-right: 45px;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div {
    background-color: #E8EFFF;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div p {
    color: #232323;
}
.chat-cont-right .chat-body .chat-date {
    font-size: 14px;
    margin: 1.875rem 0;
    overflow: hidden;
    position: relative;
    text-align: center;
    text-transform: capitalize;
}
.chat-cont-right .chat-body .chat-date:before {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    margin-right: 28px;
    position: absolute;
    right: 50%;
    top: 50%;
    width: 100%;
}
.chat-cont-right .chat-body .chat-date:after {
    background-color: #e0e3e4;
    content: "";
    height: 1px;
    left: 50%;
    margin-left: 28px;
    position: absolute;
    top: 50%;
    width: 100%;
}
.chat-cont-right .chat-footer {
    background-color: #fff;
    border-top: 1px solid #f0f0f0;
    padding: 10px 0px;
    position: relative;
}
.chat-cont-right .chat-footer .input-group {
    width: 100%;
	background-color: #f5f5f6;
    border: none;
    border-radius: 50px;
	padding: 5px;
}
.chat-cont-right .chat-footer .input-group .form-control {
    background-color: #f5f5f6;
    border: none;
    border-radius: 50px;
}
.chat-cont-right .chat-footer .input-group .form-control:focus {
    background-color: #f5f5f6;
    border: none;
    box-shadow: none;
}
.chat-cont-right .chat-footer .input-group .btn, 
.chat-cont-right .chat-footer .input-group .btn {
    background-color: transparent;
    border: none;
    color: #9f9f9f;
}
.chat-cont-right .chat-footer .input-group .btn.msg-send-btn {
    background-color: #E65425;
    border-color: #E65425;
    border-radius: 48px;
    width: 48px;
    height: 48px;
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    -webkit-align-items: center;
}
.msg-typing {
	width: auto;
	height: 24px;
	padding-top: 8px
}
.msg-typing span {
	height: 8px;
	width: 8px;
	float: left;
	margin: 0 1px;
	background-color: #a0a0a0;
	display: block;
	border-radius: 50%;
	opacity: .4
}
.msg-typing span:nth-of-type(1) {
	animation: 1s blink infinite .33333s
}
.msg-typing span:nth-of-type(2) {
	animation: 1s blink infinite .66666s
}
.msg-typing span:nth-of-type(3) {
	animation: 1s blink infinite .99999s
}
.chat-cont-left .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #ff5837;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box {
	position: relative;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box h6, .chat-cont-right .chat-body .media.sent .media-body .msg-box h6 {
    font-size: 10px;
    margin-bottom: 0;
	color: #E65425;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box h6 {
	text-align: right;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box:first-child:before {
    border-bottom: 15px solid #E65425;
    border-right: 15px solid #E65425;
    border-top: 15px solid transparent;
    border-left: 15px solid transparent;
    content: "";
    height: 0;
    left: -10px;
    position: absolute;
    right: auto;
    bottom: 12px;
    width: 0;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box {
    padding-left: 50px;
    position: relative;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box:first-child:before {	
    border-bottom: 15px solid #e8efff;
    border-left: 15px solid #e8efff;
    border-top: 15px solid transparent;
    border-right: 15px solid transparent;
    content: "";
    height: 0;
    left: auto;
    position: absolute;
    right: -10px;
    bottom: 12px;
    width: 0;
}
.chat-msg-info {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    clear: both;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 5px 0 0;
}
.chat-msg-info li {
    font-size: 13px;
    padding-right: 16px;
    position: relative;
}
.chat-msg-info li:not(:last-child):after {
	position: absolute;
	right: 8px;
	top: 50%;
	content: '';
	height: 4px;
	width: 4px;
	background: #d2dde9;
	border-radius: 50%;
	transform: translate(50%, -50%)
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box .chat-msg-info li:not(:last-child)::after {
    right: auto;
    left: 8px;
    transform: translate(-50%, -50%);
    background: #aaa;
}
.chat-cont-right .chat-body .media.received .media-body .msg-box > div .chat-time {
    color: #f6f6f6;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-time {
    color: #919191;
}
.chat-msg-info li a {
	color: #777;
}
.chat-msg-info li a:hover {
	color: #2c80ff
}
.chat-seen i {
	color: #00d285;
	font-size: 16px;
}
.chat-msg-attachments {
	padding: 4px 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin: 0 -1px
}
.chat-msg-attachments > div {
	margin: 0 1px
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info {
    flex-direction: row-reverse;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-attachments {
	flex-direction: row-reverse
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info li {
    padding-left: 16px;
    padding-right: 0;
    position: relative;
}
.chat-attachment img {
    max-width: 100%;
}
.chat-attachment {
	position: relative;
	max-width: 130px;
	overflow: hidden;
}
.chat-attachment {
	border-radius: .25rem;
}
.chat-attachment:before {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: #000;
	content: "";
	opacity: 0.4;
	transition: all .4s;
}
.chat-attachment:hover:before {
	opacity: 0.6;
}
.chat-attach-caption {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	color: #fff;
	padding: 7px 15px;
	font-size: 13px;
	opacity: 1;
	transition: all .4s;
}
.chat-attach-download {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: all .4s;
	color: #fff;
	width: 32px;
	line-height: 32px;
	background: rgba(255, 255, 255, 0.2);
	text-align: center;
}
.chat-attach-download:hover {
	color: #495463;
	background: #fff;
}
.chat-attachment:hover .chat-attach-caption {
	opacity: 0;
}
.chat-attachment:hover .chat-attach-download {
	opacity: 1;
}
.chat-attachment-list {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	margin: -5px;
}
.chat-attachment-list li {
	width: 33.33%;
	padding: 5px;
}
.chat-attachment-item {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border: 5px solid rgba(230, 239, 251, 0.5);
	height: 100%;
	min-height: 60px;
	text-align: center;
	font-size: 30px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.chat-cont-right .chat-body .media.sent .media-body .msg-box > div:hover .chat-msg-actions {
	opacity: 1;
}
.chat-msg-actions {
	position: absolute;
	left: -30px;
	top: 50%;
	transform: translateY(-50%);
	opacity: 0;
	transition: all .4s;
	z-index: 2;
}
.chat-msg-actions > a {
    padding: 0 10px;
    color: #495463;
    font-size: 24px;
}
.chat-msg-actions > a:hover {
	color: #2c80ff;
}
@keyframes blink {
	50% {
		opacity: 1
	}
}
.btn-file {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
    font-size: 20px;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
    overflow: hidden;
    padding: 0 0.75rem;
    position: relative;
    vertical-align: middle;
}
.btn-file input {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 100%;
    margin: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
.main-chat-blk .sidebar-group {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    position: relative;
    z-index: 99;
    
}
.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
}
.main-chat-blk .chat-page-wrapper .sidebar {
    position: initial;
}
.main-chat-blk .chat-page-wrapper .left-chat-title {
    padding: 24px;
    border-bottom: 1px solid #E0E0E0;
    min-height: 72px;
}
.main-chat-blk .all-chats {
    position: relative;
}
.main-chat-blk .chat-page-wrapper .setting-title-head h4 {
    font-size: 18px;
    color: #092C4C;
    font-weight: 700;
}
.main-chat-blk .add-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.main-chat-blk .add-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.main-chat-blk .add-section ul li a {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    font-size: 20px;
}
.main-chat-blk .top-online-contacts {
    padding: 24px 24px 0px;
}
.main-chat-blk .fav-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 15px;
}
.main-chat-blk .top-contacts-box .profile-img {
    position: relative;
    width: 46px;
    height: 46px;
}
.main-chat-blk .top-contacts-box .profile-img.online:before {
    content: "";
    position: absolute;
    right: 0;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    top: 0px;
    background-color: #49E073;
    width: 10px;
    height: 10px;
    border: 2px solid #fff;
    border-radius: 50px;
}
.main-chat-blk .top-contacts-box .profile-img img {
    max-width: 100%;
    height: auto;
    border-radius: 50px;
}
.main-chat-blk .sidebar-body {
    flex: 1;
    overflow: auto;
    padding: 24px 24px 10px;
    width: 100%;
}
.main-chat-blk .fav-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: 15px;
}
.main-chat-blk .fav-title h6 {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.main-chat-blk .user-list {
    border-radius: 0.25rem;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}
.main-chat-blk .user-list {
    border-radius: 0.25rem;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}
.main-chat-blk .user-list li .avatar {
    margin-right: 0.8rem;
    display: inline-block;
    margin-bottom: 0;
    height: 46px;
    width: 46px;
    border-radius: 50%;
    position: relative;
    z-index: 0;
}
.main-chat-blk .avatar-online::before {
    background-color: #00e65b;
}
.main-chat-blk .avatar-away::before, .main-chat-blk .avatar-offline::before, .main-chat-blk .avatar-online::before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 25%;
    height: 25%;
    border-radius: 50%;
    content: "";
    border: 2px solid #fff;
}
.main-chat-blk .sidebar .user-list li .users-list-body {
    flex: 1;
    position: relative;
    min-width: 0px;
    display: flex;
}
.main-chat-blk .sidebar .user-list li .users-list-body > div:first-child {
    min-width: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
}
.main-chat-blk .sidebar .user-list li .users-list-body h5 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 3px;
    color: #0A0A0A;
}
.main-chat-blk .sidebar .user-list li .users-list-body p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
    color: #232323;
    font-size: 14px;
}
.main-chat-blk .sidebar .user-list li .users-list-body .last-chat-time {
    padding-left: 15px;
    position: relative;
}
.main-chat-blk .sidebar .user-list li .users-list-body .last-chat-time .text-muted {
    color: #424242 !important;
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 5px;
    display: block;
    text-align: right;
}
.main-chat-blk .chat-pin {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: flex-end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
}
.main-chat-blk .chat .chat-header {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    padding: 6px 24px;
    align-items: center;
    border-bottom: 1px solid var(--Stroke, rgba(145, 158, 171, 0.3));
    background: var(--White, #FFF);
    box-shadow: 0px 4px 60px 0px rgba(231, 231, 231, 0.47);
    position: relative;
}
.main-chat-blk .chat .chat-header .user-details {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
}
.header-navbar-rht li .dropdown-menu.show {
    opacity: 1;
    visibility: visible;
}

.main-chat-blk .chat .chat-body .messages {
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    -webkit-flex-direction: column;
    align-items: flex-start;
    -webkit-align-items: flex-start;
}
.main-chat-blk .chat .chat-body .messages .chats {
    max-width: 75%;
    margin-bottom: 1.5rem;
    display: flex;
    display: -webkit-flex;
}
.main-chat-blk .chat-avatar {
    padding: 8px 15px 0 8px;
    display: flex;
    display: -webkit-flex;
    align-items: flex-start;
    -webkit-align-items: flex-start;
    flex-shrink: 0;
}
.main-chat-blk .dreams_chat {
    width: 40px;
    height: 40px;
    object-fit: cover;
}
.main-chat-blk .dreams_chat {
    width: 40px;
    height: 40px;
    object-fit: cover;
}
.main-chat-blk .chat .chat-body .messages .chat-profile-name h6 {
    margin-bottom: 0;
    color: #0A0A0A;
    font-weight: 600;
    font-size: 15px;
}
.main-chat-blk .chat-line {
    margin: 0px 0 20px;
    position: relative;
    text-align: center;
    width: 100%;
}
.main-chat-blk .chat-line:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    background-color: #EEEEEE;
    width: 100%;
    height: 1px;
}
.main-chat-blk .chat-date {
    border-radius: 100px;
    background:  #E8EBED;
    color: #0A0A0A;
    font-size: 14px;
    padding: 4px 20px;
    display: inline-block;
    font-weight: 400;
    position: relative;
    border: 1px solid #E0E0E0;
}
.topnav-dropdown-header {
    text-align: center;
    font-size: 14px;
    height: 45px;
    line-height: 45px;
    padding: 0 12px;
	box-shadow: 0px 7px 4px 0px #00000005;
}
.user-menu.nav .dropdown-heads > a {
    background-color: #F7F7FA;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    justify-content: center;
    font-size: 20px;
}
.topnav-dropdown-header .notification-title {
    display: block;
    float: left;
    font-weight: 600;
    font-size: 18px;
	display: flex;
	align-items: center;
}
.topnav-dropdown-header .clear-noti {
    color: #E65425;
    float: right;
	font-weight: 500;
}
.notifications .noti-content {
    height: 290px;
    width: 440px;
    overflow-y: auto;
    position: relative;
}
.notifications .notification-list li {
    margin-top: 0;
    box-shadow: 0px 1px 0px 0px #E4E8EE;
    font-size: 14px;
	width: calc(100% - 15px);
	margin: 0;
	justify-content: flex-start;
}
.notifications .notification-list li .media-body .notification-btn {
	margin-bottom: 20px;
}
.notifications .notification-list li .media-body .notification-btn .btn-primary:hover {
	color: #01243f ;
}
.notifications .notification-list li .media-body .notification-btn .btn-outline-primary:hover {
	color: #fff !important;
}
.msg-count-badge {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: #E65425;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-size: 14px;
	line-height: 0;
	margin-left: 5px;
}
.pro-pos .card {
    box-shadow: none;
    border: 0;
    /* border-bottom: 1px solid #e8e8e8; */
    border-radius: 0;
}
.pro-pos .form-label {
    color: #131135;
    font-weight: 600;
    font-size: 14px;
}
.notifications .notification-list li:first-child:after {
	background: transparent;
}
.notifications .notification-list li a {
    display: block;
    padding: 16px;
    border-radius: 2px;
}
.header .notifications .notification-list li a {
	color: #fff;
}
.notification-list li .avatar:before {
    content: "";
    position: absolute;
    left: -6px;
    top: -6px;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background-color: #19A855;
    opacity: 0;
}
.notifications .notification-list li .media-body {
    width: calc(100% - 44px);
	margin-left: 14px;
}
.notifications .notification-list li .noti-details {
    color: #3F4254;
    margin: 0;
}
.notification-btn  .btn{
    padding: 0.375rem 0.75rem !important;
    margin: 0 5px;
    border-radius: 5px !important;
}
li.notification-message {
    padding: 10px 0;
}
.notification-btn  .btn.btn-outline-primary{
    color: #E65425 !important;
}
.experience-set {
    display: flex;
	margin-bottom: 15px;
	width: 100%;
}
.experience-set-content {
    margin-left: 15px;
}
.experience-set-content h5 {
    font-size: 18px;
    margin-bottom: 5px;
}
.experience-set-content h5 span {
    color: #6B7385;
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
    display: inline-block;
}
ul.buget-profiles li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

ul.buget-profiles li h6 {
    font-size: 14px;
    margin: 0;
    color: #6B7385;
    font-weight: 400;
}
ul.buget-profiles li h5 {
    color: #1B1B1B;
    font-weight: 400;
    font-size: 16px;
    margin: 0;
}
ul.buget-profiles {
    margin: 15px 0 0;
}
.experience-set-content span {
    color: #6B7385;
    margin-bottom: 5px;
    display: block;
}
.experience-set-content p {
    margin: 0;
    color: #6B7385;
}
.notifications .notification-list li .notification-btn {
    padding: 10px 0;
}
.notifications .notification-list li p.noti-time {
    margin: 0;
	color: #6B7385;
    font-size: 12px;
    font-weight: 400;
}
.topnav-dropdown-footer {
    border-top: 1px solid #eeeeee;
    font-size: 14px;
    height: 45px;
    line-height: 45px;
    padding: 0 12px;
	display: flex;
    align-items: center;
    justify-content: center;
}
.topnav-dropdown-footer a {
    display: block;
    text-align: center;
    color: #E65425 !important;
    font-weight: 500;
}
.notifications .notification-list li blockquote {
    color: #3F4254;
    margin: 10px 0;
    padding-left: 12px;
    position: relative;
}
.notifications .notification-list li .noti-details {
	color: #5F5F61;
    margin: 0;
    font-size: 12px;
    font-weight: 400;
}
.notifications .notification-list li .noti-title {
    color: #1B1B1B;
    font-weight: 400;
}

/*-----------------
	28. Outgoing Call
-----------------------*/

.call-box .call-wrapper {
	height: auto;
	text-align: center;
}
.call-box .call-wrapper .call-avatar {
	margin-bottom: 30px;
	cursor: pointer;
	animation: ripple 2s infinite;
}
.call-box .call-wrapper .call-user {
	margin-bottom: 30px;
}
.call-box .call-wrapper .call-user span {
	display: block;
	font-weight: 500;
	text-align: center;
}
.call-box .call-wrapper .call-items {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.call-box .call-wrapper .call-items .call-item {
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid transparent;
    border-radius: 100%;
    color: #fff;
    line-height: 0;
    margin: 0 5px;
    padding: 15px;
}
.call-box .call-wrapper .call-items .call-item:hover {
	opacity: 0.9;
}
.call-box .call-wrapper .call-items .call-item:first-child {
	margin-top: -30px;
}
.call-box .call-wrapper .call-items .call-item:last-child {
	margin-top: -30px;
}
.call-box .call-wrapper .call-items .call-item.call-end {
	padding: 20px;
	margin: 30px 20px 0;
	background: #f06060;
	border: 1px solid #f06060;
	color: #fff;
	line-height: 0;
	border-radius: 100%;
}
.call-box .call-wrapper .call-items .call-item.call-start {
	padding: 20px;
	margin: 30px 20px 0;
	background: #55ce63;
	border: 1px solid #55ce63;
	color: #fff;
	line-height: 0;
	border-radius: 100%;
}
.call-box.incoming-box .call-wrapper .call-items .call-item.call-start {
	margin: 0 10px;
}
.call-box.incoming-box .call-wrapper .call-items .call-item.call-end {
	margin: 0 10px;
}
.call-box .call-avatar {
    border-radius: 100%;
    height: 140px;
    max-width: 140px;
    min-width: 140px;
    position: relative;
    width: 100%;
    border: 10px solid #fafafa;
}
.call-box .btn {
    background: rgba(0, 0, 0, 0);
    transition: all 0.3s ease 0s;
}
@-webkit-keyframes ripple {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
	}
	100% {
		-webkit-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
	}
}
@keyframes ripple {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
	}
	100% {
		-moz-box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
		box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
	}
}

/*-----------------
	29. Incoming Call
-----------------------*/

.incoming-btns {
	margin-top: 20px;
}
.call-wrapper {
    position: relative;
    height: calc(100vh - 145px);
}
.call-page .footer {
	display: none;
}
.dropdown-action .dropdown-toggle::after {
	display: none;
}
.call-modal .modal-body {
	padding: 40px;
}
.call-modal .modal-content {
    border: 0;
    border-radius: 10px;
}
.call-box .call-wrapper .call-user h4 {
	font-size: 24px;
}

/*-----------------------
	30. Profile-resume-edit
------------------------*/

.pro-edit {
	background: #F9F9F9;
	height: 560px;
	overflow-y: hidden;
	margin-top: 20px;
	padding: 20px;
}
.profile-update-btn, .profile-cancel-btn {
	font-size: 15px;
	font-weight: 600;
	padding: 12px 25px;
	border-radius: 4px;
	margin-top:10px;
}
.profile-cancel-btn {
	background: #DEDEDE;
	color: #161c2d;
}
.edit-sub-title {
	background: #F8F8F8;
	padding: 10px;
	border-radius: 50%;
	color: #161c2d;
}
.profile-edit-form .form-control , .profile-edit-form .select2-container .select2-selection--single {
	background: #fff;
	border: 1px solid rgba(239, 239, 239, 0.42);
	box-sizing: border-box;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
}
.form-check-label {
	font-size: 14px;
	color: #616161;
}
.submit-profile-sec a {
	padding: 8px 20px;
}
.input-group-text {
	background: #f5f5f5;
	border: 1px solid #f5f5f5;
}
.pro-new,.pro-new1,.pro-new2,.pro-new3 {
	display: none
}
.pro-text,.pro-text1,.pro-text2,.pro-text3 {
	display: block;
	line-height: 30px;
}
.dropdown.profile-action {
	position: absolute;
	right: 5px;
	text-align: right;
	top: 10px;
}
.profile-action .dropdown-toggle::after {
	display: none;
}
.profile-action .dropdown-menu {
	background: #fff;
	border: 1px solid #FFE6E0;
	box-sizing: border-box;
	box-shadow: 0px 4px 4px rgba(165, 165, 165, 0.25);
	color: #E65425;
}
.profile-action .dropdown-menu .dropdown-item {
	color: #E65425;
}
.add-skills .modal-header {
	background: #FFF8F8;
	padding:25px;
	margin: 30px;
	text-align: center;
	border-bottom: none;
	border-radius: 5px 5px 0px 0px;
	text-transform: uppercase;
	display: flex;
    align-items: center;
    flex-direction: column;
}
.modal-header .close {
	width: 30px;
    height: 30px;
    line-height: 30px;
    padding: 0px;
    background: #fff;
    color:  #E65425;
    border: 1px solid #E65425;
    border-radius: 50%;
    margin: -1rem -1rem -1rem auto;
}
.modal-header .close:hover {
	opacity: .75;
}
.add-skills .modal-title {
	font-size: 24px;
	font-weight: 700;
}
.hr-text {
	line-height: 1em;
	position: relative;
	outline: 0;
	border: 0;
	color: black;
	text-align: center;
	height: 1.5em;
	opacity: 1;
	margin-bottom: 5rem;
}
.hr-text:before {
	content: "";
	background: #E9E9E9;
	position: absolute;
	left: 0;
	top: 30px;
	width: 100%;
	height: 1px;
}
.hr-text:after {
	content: attr(data-content);
	position: relative;
	display: inline-block;
	padding: 0 0.5em;
	color: #fff;
	background-color: #E65425;
  	width: 66px;
    height: 66px;
    border-radius: 50%;
    line-height: 66px;
}
.skill-list-group {
	padding:20px;
}
.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #E65425;
    border-color: #E65425;
}
.f-7 {
	font-size: 7px;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    z-index: 2;
    color: #E65425;
    background-color: #FFF7F7;
    border-color: #FFF7F7;
}
.nav-link {
	color: #444444;
}
.add-skills .list-group-item {
    border: 0px solid #ffe6e1;
}
.add-skills .menu-arrow {
	-webkit-transition: -webkit-transform 0.15s;
	-o-transition: -o-transform 0.15s;
	transition: transform .15s;
	position: absolute;
	right: 15px;
	display: inline-block;
	font-family: 'FontAwesome';
	text-rendering: auto;
	line-height: 40px;
	font-size: 18px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	line-height: 18px;
	top: 11px;
}
.add-skills .menu-arrow:before {
	content: "\f105";
}
.add-skills li a.subdrop .menu-arrow {
	-ms-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
.add-skills .card {
	background: #fff;
	border: 1px solid rgba(239, 239, 239, 0.42);
	box-sizing: border-box;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
    background-color: #E65425;
    border-color: #E65425;
}
.btn-outline-primary:hover {
    background-color: #E65425;
    border-color: #E65425;
}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #E65425;
    border-color: #E65425;
}
.btn-outline-primary {
    color: #E65425;
    border-color: #E65425;
}
.f-20 {
	font-size: 20px;
}

/*-------------------------
	31. Profile-details-view
--------------------------*/

.project-details-tab .nav-tabs {
    border-bottom: 1px solid #E65425;
    background: #E65425;
}
.project-details-tab .nav-tabs .nav-item.show .nav-link, .project-details-tab .nav-tabs .nav-link.active {
	color: #fff;
}
.project-details-tab .nav-tabs.nav-tabs-bottom > li > a.active, .project-details-tab .nav-tabs.nav-tabs-bottom > li > a.active:hover, .project-details-tab .nav-tabs.nav-tabs-bottom > li > a.active:focus { 
	border-bottom-width: 5px;
    border-color: transparent;
    border-bottom-color: #fff;
}
.project-details-tab .nav-tabs > li > a {
	color: #fff;
	font-weight: normal;
}
.project-details-tab .nav-tabs .nav-link:focus, .project-details-tab .nav-tabs .nav-link:hover {
    background-color: #E65425;
    border-color: transparent;
    color: #fff;
    border-bottom-width: 5px;
    border-bottom-color: #fff;
}
.project-details-tab .profile-action {
	width: 30px;
	height: 30px;
	line-height: 30px;
	background: #fff;
	text-align: center;
	border-radius: 50%;
	right: 20px;
	top: 14px;
}
.btn-purple {
	background: #1E1A60;
	border-radius: 5px;
	color: #fff;
}
.project-details-table .select2-container .select2-selection--single {
	height:40px;
	line-height: 40px;
}
.project-details-table .select2-container--default .select2-selection--single .select2-selection__arrow b {
	top: 40%;
}
.task-btn {
	align-items: center;
    border: 3px dashed #dedede;
    border-radius: 0;
    color: #4d525b;
    cursor: pointer;
    display: flex;
    height: 96px;
    justify-content: center;
    margin-bottom: 20px;
}
.task-form {
	background: #FAFAFA;
	margin-top: 40px;
	padding: 30px;
}
.close-task {
	position: absolute;
	right: 10px;
	top: 10px;
	color: #c7c5c5;
}
.action-task span {
	margin-right: 15px;
}

/*-----------------
	32. Freelancer
-----------------------*/

.settings-widget {
    background: #fff;
    border: 1px solid #E8E8E8;
    box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
    margin-bottom: 24px;
}
.settings-header {
	background: #E65425;
	padding: 20px;
}
.settings-header h3, .settings-header h3 a {
	color: #fff;
	font-size: 22px;
}
.settings-header p {
	color: #fff;
}
.settings-menu ul {
	display: block;
	border: 0;
	padding: 20px;
	margin: 0;
	list-style: none;
}
.settings-menu ul li + li {
	margin-top: 25px;
}
.settings-menu ul li a {
    color: #161c2d;
    font-size: 15px;
    font-weight: 600;
    padding: 0;
    border: 0 !important;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    line-height: normal;
	border-radius: 5px;
	transition: 0.5s all;
}
.settings-menu ul li a:hover, .settings-menu ul li a:focus {
    color: #E65425;
	background-color: transparent !important;
	border: 0 !important;
}
.settings-menu ul li a.active {
	color: #E65425 !important;
	border: 0;
}
.sub-menu-ul li a.active {
	color: #E65425;
}
.settings-menu ul li a i {
	margin-right: 8px;
	font-size: 20px;
}
.dash-widget {
	background: #fff;
	border: 1px solid #f0f0f0;
	box-shadow: 0px 4px 4px rgba(200, 200, 200, 0.25);
	margin-bottom: 30px;
}
.dash-info {
	padding: 20px;
	border-bottom: 1px solid #f0f0f0;
}
.dash-widget-info {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 5px;
    color: #161c2d;
}
.dash-widget-count {
    font-weight: bold;
    font-size: 28px;
    color: #E65425;
    line-height: 1.2;
}
.dash-widget-more {
	padding: 20px;
}
.dash-widget-more a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 500;
}
.plan-details h4 {
	font-weight: 600;
	font-size: 24px;
	margin-bottom: 0;
	color: #E65425;
}
.plan-details h3 {
	font-weight: 500;
	font-size: 30px;
	margin-bottom: 1px;
	color: #E65425;
}
.plan-details .expiry {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 25px;
}
.yr-amt {
	font-size: 16px;
	color: #6B7385;
	margin-bottom: 10px;
}
.yr-duration {
	font-size: 14px;
}
.change-plan-btn .btn{
	padding: 11px 24px;
	border-radius: 6px;
}
.change-plan-btn {
	display: flex;
	gap: 14px;
}
.btn-primary-lite {
	background: #01243f52;
	color: #01243F;
}
.btn-primary-lite:hover {
	background: #01243F;
	color: #fff;
}
.btn-plan {
	border-radius: 10px;
}
.plan-feature ul {
    list-style: none;
    margin: 0;
    padding: 0;
    outline: none;
}
.plan-feature ul li {
    margin-bottom: 23px;
    position: relative;
    font-size: 14px;
    padding-left: 25px;
}
.plan-feature ul li:last-child {
    margin-bottom: 0;
}
.plan-feature ul li::before {
    content: "\f00c";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
	color: #4B9F18;
    left: 0;
    margin-right: 5px;
    position: absolute;
    top: 0;
}
.plan-feature a {
	font-weight: 600;
	font-size: 14px;
	color: #E65425;
}
.on-project {
	border-bottom: 1px solid #F7F7F7;
	padding: 20px;
}
.on-project h5 {
	font-weight: 500;
	font-size: 16px;
	color: #E65425;
	margin-bottom: 5px;
}
.on-project p {
	font-weight: 500;
	font-size: 12px;
	color: #948E8E;
	margin-bottom: 10px;
}
.pro-info .list-details {
	margin-bottom: 0;
}
.pro-info .slot p {
	font-weight: 500;
	font-size: 12px;
	color: #161c2d;
	margin-bottom: 5px;
}
.pro-info .slot h5 {
	font-weight: 600;
	font-size: 13px;
	color: #838383;
}
.verify-box {
	border: 1px solid #E65425;
	border-radius: 2px;
	padding: 25px;
}
.verify-box img {
	margin-bottom: 20px;
}
.verify-box h5 {	
    font-size: 20px;
	margin-bottom: 20px;
}
.verify-box a {
	font-weight: 600;
	color: #E65425;
	text-decoration: underline;
}
.chat-page footer {
	display: none;
}
.back-text {
	text-align: right;
	margin-bottom: 20px;
	padding-right: 25px;
}
.back-btn {
    padding: 5px 20px;
    border-radius: 50px;
    font-weight: bold;
	font-size: 14px;
}
.fund-btn:hover {
    color: #fff;
}
.click-btn + .click-btn {
    margin-left: 10px;
}
.btn-secondary.click-btn {
    background: #F1F0FE;
    border: 1px solid transparent;
    color: #2A2760;
}
.btn-secondary.click-btn:hover {
    background: #747379;
    color: #fff;
}
label.file-upload.image-upbtn {
    background: #71d3a9;
    border-radius: 6px;
    color: white;
    padding: 8px 24px;
    font-size: 16px !important;
    font-weight: 500;
    cursor: pointer;
	margin-bottom: 0;
}
.trash-icon {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 30px;
    width: 30px;
    display: -webkit-box;
    border-radius: 50%;
	background: #FF3B30;
	color: #fff;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.trash-icon:hover {
	color: #fff;
	opacity: 0.8;
}
.avatar-medium {
	border-radius: 5px;
	width: 76px;
	height: 46px;
}
.pro-pic {
	margin-bottom: 0;
}
.pro-pic p {
	font-weight: 500;
	margin-top: 15px;
	margin-bottom: 0;
}
.light-pink-text {
	font-size: 12px;
	color: #F38247;
	padding-top: 10px;
}
.btn-list {
	background: rgba(255, 91, 55, 0.1);
    padding: 20px;
    border-radius: 5px;
}
.proedit-widget {
	background: #fff;
	border: 1px solid #D6D6D6;
	box-shadow: 0px 4px 14px 10px rgba(241, 241, 241, 0.3);
	border-radius: 5px;
	padding: 15px;
}
.proedit-header {
	padding-bottom: 40px;
}
.proedit-header h4 {
	font-weight: 500;
	font-size: 20px;
}
.proedit-body {
	list-style: none;
    padding: 0;
    margin: 0;
}
.proedit-body li {
	padding-bottom: 40px;
	display: -ms-flexbox;
	display: flex;
}
.proedit-body li span {
	color: #E65425;
	margin-right: 12px;
}
.proedit-widget .btn {
	height: 50px;
}
.small-text {
	font-size: 12px;
	color: #E65425;
	margin-bottom: 0;
}
label.award-upload.image-upbtn {
	border: 1px solid #F15C3D;
	border-radius: 5px;
	width: 76px;
    height: 46px;	
	cursor: pointer;
    justify-content: center;
    display: flex;
    align-items: center;
	color: #F15C3D;
}
label.award-upload input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}
.del-modal i {
	color: #DFDFDF;
	margin-bottom: 35px;
}
.black-btn {
	background: #131135;
	border: 1px solid #131135;
}
.portfolio-img {
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-radius: 0;
}
.portfolio-img .portfolio-live {
    transition: .5s ease;
    position: absolute;
    opacity: 0;
	width: 100%;
	height: 100%;
	top: 0;
    visibility: hidden;
    z-index: 99;
	color: #fff;
}
.portfolio-img .portfolio-content {	
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
	position: absolute;
}
.portfolio-img:hover .portfolio-live {	 
    opacity: 1;
    visibility: visible;
	background: rgba(255, 91, 55, 0.7);
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.port-icon {
	width: 34px;
    height: 34px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    background: #fff;
    border-radius: 50%;
    color: #E65425;
}
.portfolio-detail {
    text-align: center;
    padding: 30px;
}
.portfolio-detail .pro-name {
    font-weight: 600;
    font-size: 16px;
	margin-bottom: 0;
	text-transform: none;
}
.hire-select .select2-container .select2-selection--single {
    background: #E65425;
    color: #fff;
    border-color: #E65425;
}
.hire-select .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #fff;
}
.hire-select .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #fff transparent transparent;
}
.br-0 {
	border-radius: 0 !important;
}
.port-title h3 {
	font-size: 22px;
    font-weight: 500;
	margin-bottom: 35px;
}
.projects-list h2 {
    font-size: 24px;
    margin-bottom: 20px;
}
.prolist-details {
	display: flex;
    list-style: none;
    margin: 0;
	flex-wrap: wrap;
    display: flex;
    display: -ms-flexbox;
    padding: 0;
}
.prolist-details h5 {
    font-weight: normal;
    font-size: 16px;
    margin-bottom: 5px;
    color: #6e727d;
	font-weight: 500;
}
.pro-overview h4 {
	font-size: 18px;
}
.pro-overview p {
	font-size: 15px;
	font-weight: 500;
}
.prolist-details p {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0;
}
.prolist-details li:not(:last-child) {
	margin-right: 40px;
}
.red-text {
	color: #FF0000;
}
.prolist-details .price {
	font-size: 23px;
	color: #F5365C;
	font-weight: 600;
	margin-bottom: 0;
}
.hire-box h6 {
	font-weight: bold;
	font-size: 14px;
	color: #E65425;
}
.hire-box p {
	font-weight: 600;
	font-size: 14px;
}
.btn-chat {
	font-weight: 500;
	font-size: 12px;
	color: #E65425;
	background: #fff;
	border: 1px solid #D8D8D8;
	border-radius: 70px;
}
.bg-success-dark {
	background-color: #4B9F18;
	border: 1px solid #4B9F18;
	padding: 10px 15px;
    color: #fff;
    width: 80px;
	border-radius: 10px;
}
.bg-grey-dark {
	background-color: #808080;
	border: 1px solid #808080;
	padding: 10px 15px;
    color: #fff;
    width: 80px;
	border-radius: 10px;
}
.bg-pink-dark {
	background: #F5365C;
	border-radius: 5px;
	padding: 10px 20px;
    color: #fff;
	border-radius: 10px;
}
.bg-grey-light {
	background: #DCDCDC;
	border-radius: 5px;
	padding: 10px 20px;
    color: #161c2d;
	border-radius: 10px;
}
.bg-outline-red {
	border: 1px solid #E65425;
	border-radius: 20px;
	padding: 10px 15px;
	font-size: 14px;
	color: #E65425;
}
.bg-outline-green {
	border: 1px solid #4B9F18;
	border-radius: 20px;
	padding: 10px 15px;
	font-size: 14px;
	color: #4B9F18;
}
.table .thead-pink th {
	font-size: 14px;
}
.table-box {
	background: #fff;
	border: 1px solid #E7E7E7;
}
.table-box div.dataTables_wrapper div.dataTables_info {
	padding-left: 15px;
	padding-bottom: 15px;
}
.table-box  div.dataTables_wrapper div.dataTables_paginate {
	padding-right: 15px;
	padding-bottom: 15px;
	padding-top: 15px;
}
.bg-pink .input-group-text {
	background: #ffe6e1;
	border-color: #ffe6e1;
    color: #E65425;
}
.project-table  h4 {
	font-weight: 600;
	font-size: 16px;
	color: #E65425;
	margin-bottom: 20px;
}
.project-table  h3 {
	font-weight: 500;
	font-size: 24px;
	color: #161c2d;
	margin: 20px 20px 0;
}
.read-text {
	font-weight: 500;
	font-size: 14px;
	color: #E65425;
}
.br-30 {
	border-radius: 30px;
}
.file-circle {
	width: 33px;
	height: 33px;
	background: #FDF4F3;
	color: #E65425;
	display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.dataTables_wrapper .dataTables_filter, .dataTables_wrapper .dataTables_length {
	padding: 20px 20px 5px;
}
.dataTables_wrapper .dataTables_length select, .dataTables_wrapper .dataTables_filter input {
    border: 1px solid #E7E7E7;
    appearance: auto;
    -webkit-appearance: auto;
    -moz-appearance: auto;
}
.reply-box {
	padding: 20px;
	margin-bottom: 20px;
}
.reply-box h4 {
	margin-bottom: 0;
	color: #fff;	
}
.dashboard-sec .pro-box {
	margin-bottom: 40px;
	padding: 15px;
	border-radius: 6px;
	border: 1px solid #CED4E6;
	background: #FFF;
}
.dashboard-sec .card-title {
	font-weight: 500;
}
.text-violet {
	color: #7B46BE;
}
.text-pink {
	color: #FA6CA4;
}
.text-yellow {
	color: #FACD3A;
}
.text-blue {
	color: #24C0DC;
}
.static-list {
	list-style: none;
    margin: 0;
}
.static-list li {
	display: flex;
    align-items: center;
    justify-content: space-between;
	margin-bottom: 15px;
}
.static-list li:last-child {
	margin-bottom: 0;
}
.earn-feature {
	border: 1px solid #F7F7F7;
	padding: 20px;
}
.earn-info p {
	font-size: 16px;
	margin-bottom: 12px;
	font-weight: 600;
}
.earn-info .date {
	font-size: 14px;
	font-weight: 500;
}
.earn-img {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}
.earn-img span img {
	margin-right: 15px;
}
.earn-img span {
	font-weight: 600;
	font-size: 14px;
}
.earn-feature .price {
	font-weight: 600;
	font-size: 24px;
	color: #E65425;
	margin-left: auto;
}
.pro-box {
	background: #fff;
	box-shadow: 0px 4px 24px 10px rgba(231, 231, 231, 0.3);
	border-radius: 5px;
}
.action {
	align-items: center;
    display: inline-flex;
}
.rangslider {
	-webkit-appearance: none;
	width: 100%;
	height: 15px;
	background: #ffe6e1;
	border-radius: 5px;
	outline: none;
	overflow: hidden;
	-webkit-transition: .2s;
	transition: opacity .2s;
}
.rangslider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 62px;
	height: 18px;
	background: #fff;
	border-radius: 5px;
	box-shadow: -100vw 0 0 100vw #E65425, 0px 4px 24px rgba(0, 0, 0, 0.25);
	cursor: pointer;
}
.rangslider::-moz-range-thumb {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: #04AA6D;
	cursor: pointer;
}

/*-----------------
	33. Employer
-----------------------*/

.paginations {
	padding: 0;
	margin-bottom: 0;
}
.paginations li {
	list-style: none;
	display: inline-block;
	padding-right: 20px;
}
.paginations li a {
	font-weight: 600;
	font-size: 14px;
	color: #161c2d;
}
/* .paginations li a.active {
    background: #E65425;
    color: #fff;
    padding: 5px 11px;
    border-radius: 50%;
    display: inline-block;
} */
.paginations li:hover a.active {
    color: #fff;
}
.paginations li:hover a {
    color: #71D3A9 !important;
}
.guide-account {
    border: 1px solid #71D3A9;
    border-radius: 2px;
    padding: 30px 20px;
    position: relative;
    margin-bottom: 1.875rem;
    width: 100%;
}
.account-verify img {
    width: 65px;
}
.account-verify h2 {
    font-size: 20px;
    margin: 15px 0;
}
.account-verify p {
	color: #797979;
}
.verify-link {
    color: #71D3A9;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 4px solid #71D3A9;
}
.projects-card {
	background: #fff;
	border: 1px solid #71D3A9;
	box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
}
.project-info span {
    font-weight: 500;
    font-size: 13px;
    color: #71D3A9;
    display: inline-block;
    margin-bottom: 5px;
}
.project-info h2 {
    font-weight: 700;
    margin: 0 0 10px;
    font-size: 16px;
}
.project-info {
    width: 50%;
    display: block;
    padding-right: 20px;
}
.project-info .customer-info ul {
    padding: 0;
}
.project-info .customer-info ul li h5 {
    margin: 0;
    display: flex;
    align-items: center;
}
.project-info .customer-info ul li h5 img {
    margin-right: 3px;
}
.projects-btn {
    font-size: 12px;
    font-weight: 600;
    background: #71D3A9;
    border-radius: 3px;
    padding: 8px 10px;
    color: #fff;
    text-align: center;
    width: 140px;
    display: block;
    margin: 0 auto 10px;
}
.projects-btn:hover {
	background-color: #131135;
	color: #fff;
}
.projects-action .projects-btn ~ .projects-btn {
    margin: 0 auto 0;
}
.projects-details {
    display: flex;
    width: 100%; 
}
.projects-action {
	width: 50%;
	padding-left: 20px;
}
.projects-amount {
	width: 50%;
    display: block;
    text-align: center;
}
.prj-proposal-count {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.projects-card .card-body {
	height: 100%;
}
.prj-proposal-count span {
    color: #E65425;
    font-weight: 600;
    font-size: 34px;
    line-height: 1.2;
}
.my-projects-list {
	margin-bottom: 30px;
}
.prj-proposal-count h3 {
	font-size: 16px;
}
.content-divider {
    border-right: 1px solid #F5F5F5;
	height: 96px;
}
.content-divider-1 {
    border-right: 1px solid #EAEAEA;
	height: 96px;
}
.projects-amount p {
	font-weight: 500;
	font-size: 14px;
	color: #161c2d;
	margin-bottom: .25rem;
}
.projects-amount h3 {
	font-size: 15px;
	font-weight: 600;
}
.projects-amount h5 {
    font-weight: 600;
    font-size: 13px;
    color: #E65425;
    margin-bottom: 0;
}
.hired-detail {
	font-size: 13px;
	color: #000;
}
.prj-proposal-count.hired h3 {
	font-weight: 600;
    font-size: 14px;
    color: #E65425;
	margin-bottom: 12px;
}
.prj-proposal-count.hired img {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    margin-bottom: 8px;
    object-fit: cover;
}
.prj-proposal-count.hired p {
	font-weight: 500;
    font-size: 14px;
}
.project-hire-info {
	display: flex;
    width: 50%;
    align-items: center;
}
.projects-amount.proposals {
	width: 100%;
}
.page-title h3 {
    font-size: 25px;
    margin-bottom: 1.5rem;
}
.page-subtitle {
	font-size: 20px;
	color: #161c2d;
	margin-bottom: 1rem;
}
.proposal-card {
    border: 1px solid #E8E8E8;
    box-shadow: 0px 4px 4px rgb(204 204 204 / 25%);
    padding: 12px;
}
.project-proposals {
    background: #FFF5F3;
    border: 1px solid rgba(255, 91, 55, 0.1);
    padding: 12px;
	margin-bottom: 15px;
}
.proposer-img img {
    height: 54px;
    width: 54px;
    border-radius: 50%;
}
.proposer-info {
	display: flex;
	align-items: center;
	width: 60%;
}
.proposer-detail {
	margin-left: 15px;
	list-style: none;
	padding: 0;
}
.proposer-detail li {
	display: inline-block;
	color: #808080;
    font-size: 12px;
    border-right: 1px solid #ADADAD;
    padding-right: 12px;
    padding-left: 10px;
}
.proposer-detail li:first-child {
	padding-left: 0;
}
.proposer-detail li:last-child {
	padding-right: 0;
	border-right: 0;
}
.proposal-details {
    margin-bottom: 0;
}
.proposer-detail h4 {
	font-weight: 500;
    font-size: 16px;
    color: #000;
	margin-bottom: .25rem;
}
.font-semibold {
	font-weight: 500;
}
.proposals-info {
	display: flex;
	margin-bottom: 15px;
}
.proposer-bid-info {
	display: flex;
	align-items: center;
	width: 40%;
}
.proposer-confirm .projects-btn {
	margin: 0 0 0 auto;
}
.proposer-bid {
	width: 50%;
}
.proposer-confirm {
	width: 50%;
}
.description-proposal {
	padding: 12px;
	background: #fff;
}
.description-proposal p {
	margin-bottom: 0;
	font-size: 14px;
}
.proposer-bid h3 {
	font-size: 20px;
    color: #161c2d;
	margin-bottom: .25rem;
}
.proposer-bid h5 {
	font-size: 13px;
	color: #E65425;
	margin-bottom: 0;
}
.font-bold {
	font-weight: 600;
}
.warning-card {
	padding: 12px;
	background: rgba(255, 0, 0, 0.1);
	color: #FF0000;
	margin-bottom: 30px;
}
.warning-card p {
	color: #FF0000;
	font-size: 14px;
	margin-bottom: 0;
}
.projects-action.single-btn {
    width: 100%;
}
.completed-badge {
	font-size: 14px;
	color: #4B9F18;
	font-weight: 700;
}
.cancelled-badge {
	font-weight: 600;
	font-size: 15px;
	color: #FF0000;
}
.projects-delete-details {
    padding: 20px;
    background: rgba(255, 0, 0, 0.04);
    display: flex;
    box-shadow: 0px 4px 4px rgba(204, 204, 204, 0.25);
    border: 1px solid #e8e8e8;
}
.projects-delete-details .content-divider {
    border-right: 1px solid rgba(255, 0, 0, 0.1);
}
.profile-edit-btn {
	background: #fff;
    font-weight: 500;
    font-size: 16px;
    color: #E65425;
    border: 1px solid #E65425;
	border-radius: 2px;
}
.profile-edit-btn:hover {
	background: #E65425;
    color: #FFF;
    border: 1px solid #E65425;
}
.my-projects-view .pro-post {
	margin-bottom: 0;
}
.pro-pos .select2-container {
	z-index: unset;
}

/*-----------------
	34. Membership
-----------------------*/

.package-detail {
	background: #fff;
	border: 1px solid #CED4E6;
	box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);
	border-radius: 10px;
	padding: 24px;
}
.package-detail h4 {
	font-weight: 600;
	font-size: 22px;
}
.package-detail p {
	font-size: 16px;
	margin-bottom: 10px;
}
.package-detail .package-price {
	font-weight: 700;
	font-size: 32px;
	margin-bottom: 0;
	padding-bottom: 20px;
	border-bottom: 1px solid #CED4E6;
}
.package-detail .package-price span{
	font-weight: 400;
	font-size: 16px;
	color: #6B7385;
	margin-bottom: 0;
}
.sort-by .select2-container {
    min-width: 190px;
}
.package-feature ul {
    list-style: none;
    margin: 20px 0 30px 0;
    padding: 0;
    outline: none;
}
.package-feature ul li {
    margin-bottom: 14px;
    position: relative;
	font-size: 16px;
	color: #6B7385;
	font-weight: 600;
	padding-left: 25px;
}
.package-feature ul li:last-child{
	margin-bottom: 0;
}
.package-feature ul li.non-check::before {
	content: "\f057";
	color: #F5365C;
}
.package-feature ul li::before {
    content: "\f058";
    font-family: "Font Awesome 5 Free";
    font-weight: 600;
	color: #4B9F18;
    left: 0;
    position: absolute;
    top: 0;
}
.price-btn {
	background: #131135;
	border: 1px solid #131135;
	border-radius: 5px;
	font-weight: 500;
	color: #fff;
	padding: 10px 20px;
}
.member-detail h4  {
	font-weight: 600;
	font-size: 22px;
	color: #E65425;
}
.member-detail h5 {
	margin-bottom: 5px;
}
.member-detail h3  {
	font-weight: 600;
	font-size: 22px;
	color: #71D3A9;
	margin-bottom: 2px;
}

.member-detail .expiry-on  {
	font-size: 14px;
	color: #6B7385 ;
}
.member-detail .expiry-on span {
	color: #1B1B1B;
	font-weight: 500;
}
.member-detail .expiry-on span i {
	margin-right: 8px;
}
.member-detail .expiry  {
	font-weight: 500;
	font-size: 16px;
}
.member-plan  {
	margin-bottom: 25px;
}
.freelance-title {
	margin-bottom: 40px;
}
.freelance-title h3 {
	font-weight: 600;
	font-size: 26px;
}
.freelance-title p {
	font-size: 16px;
	color: #757575;
}
.change-plan {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.fav .filled, .fav i {
	color: #F5365C;
}
.fav i:hover {
	color: #F5365C;
	font-weight: 700;
}
.tab-imgcircle::before {
    background-color: #159C5B;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    content: "\f00c";
    font-weight: 600;
    font-family: "Font Awesome 5 Free";
    font-size: 9px;
    justify-content: center;
    align-items: center;
    color: #fff;
    display: inline-flex;
    display: -ms-inline-flexbox;
}

/*-----------------
	35. Freelancer Proposals
-----------------------*/

.freelancer-proposals {
	background: #fff;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
	padding: 8px;
	margin-bottom: 13px;
}
.project-proposals.freelancer {
	margin-bottom: 0;
	padding: 18px;
}
.proposals-title {
	font-weight: 700;
	font-size: 18px;
	color: #161c2d;
	margin-bottom: 1rem;
	line-height: 1.3;
}
.proposals-detail {
	display: block;
	width: 50%;
}
.proposer-content {
	display: block;
}
.info-btn {
	background: #fff;
	border: 1px solid #D8D8D8;
	border-radius: 70px;
	font-weight: 500;
	font-size: 12px;
	color: #ADADAD;
	padding: 3px 35px;
}
.proposal-img {
	display: block;
	margin-right: 60px;
}
.proposal-client {
    margin-right: 60px;
}
.proposal-img img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}
.proposer-content {
	width: 50%;
    display: block;
    padding-right: 20px;
}
.proposals-content {
	display: flex;
}
.proposal-delete {
	font-weight: 500;
	font-size: 12px; 
	color: #F5365C;
}
.title-info {
	font-weight: 500;
	font-size: 15px;
	color: #161c2d;
	margin-bottom: .25rem;
}
.client-price {
	font-weight: 600;
	font-size: 20px;
	margin-bottom: .25rem;
	color: #F5365C;
}
.price-type {
	font-size: 16px;
	color: #161c2d; 
}
.proposal-type h3 {
	font-weight: 600;
	font-size: 16px;
	color: #F5365C;
	margin-bottom: 0;
}
.proposal-img h4 {
    font-weight: 600;
    font-size: 14px;
    color: #161c2d;
    margin: .5rem 0;
}
.amnt-type {
	font-size: 16px;
    color: #161c2d;
    margin-bottom: 0;
    padding-top: 5px;
}
.project-info.project {
    width: 45%;
}
.project-hire-info.project {
    width: 55%;
}
.proposer-info.project {
    width: 65%;
    padding-left: 25px;
}
.projects-action.project {
    width: 35%;
    padding-left: 20px;
}
.hired-detail {
	font-size: 12px;
	color: #6e727d;
	font-weight: 700;
	margin-bottom: 0;
}
.hired-date {
	font-weight: 500;
	font-size: 13px;
	color: #161c2d;
	margin-bottom: 0;
}
.projects-btn.project {
	margin: 0 0 10px;
}
.projects-cancelled-card {
	background: rgba(255, 0, 0, 0.07);
}
.paginations li {
	list-style: none;
	display: inline-block;
	padding-right: 0;
}
.paginations li:last-child {
	padding-right: 0;
}
.paginations li a {
	font-weight: 200;
	font-size: 16px;
	color: #6c757d;
	padding: 0.375rem 0.75rem;
}
/* .paginations li a.active {
    background: #E65425;
    color: #fff;
    padding: 0.375rem 0.75rem;
    border-radius: 0;
} */
.paginations li:hover a.active {
    color: #fff;
}
.paginations li:hover a {
    color: #E65425;
}
.paginations.freelancer {
	text-align: right;
}
.paginations.freelancer li:last-child {
	padding-right: 0;
}
#more {
	display: none;
}
.readmore:hover {
	cursor: pointer;
}
ul {
	padding: 0;
}
.modal-title {
	font-weight: 600;
	font-size: 24px;
	color: #161c2d;
}
.modal label {
	font-weight: 500;
}
.rating-text {
	font-weight: 500;
	font-size: 16px;
	color: #161c2d;
}
.modal-close a i {
	color: #71D3A9;
}
.modal-close a {
    width: 30px;
    height: 30px;
    background: #fff;
    border: 1px solid #71D3A9;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}
.modal-close a:hover{
	background: #71D3A9;
}
.modal-close a:hover i{
	color: #fff;
}
.update-btn {
    padding: 10px 20px;
    font-weight: bold;
    font-size: 15px;
    border-radius: 10px;
    min-width: 120px;
    text-transform: uppercase;
}
.rating-btn {
	font-weight: 500;
	font-size: 14px;
	color: #161c2d;
}
.bids-card {
	border-bottom: 1px solid #D9D9D9;
	padding: 20px 0;
}
.bids-card:last-child {
	border: 0;
	padding-bottom: 0;
}
.author-img-wrap img {
	border-radius: 20px;
}
.author-detail p {
	margin-top: 15px;
}
.proposal-amnt h3 {
	font-weight: 500;
	font-size: 24px;
	color: #F5365C;
}
.average-bids {
	background: #FFF6F4;
	padding: 15px 20px;
}
.average-bids p {
	margin-bottom: 0;
	color: #000;
	font-size: 16px;
}
.text-highlight {
	font-weight: 700;
	color: #E65425;
}
.pro-btn {
	background: #E65425;
	border-radius: 10px;
	font-weight: 500;
	font-size: 16px;
	color: #fff;
	padding: 15px 30px;
	margin: auto;
}
.pro-btn:hover {
	color: #fff;
	background: #fb451d;
}
.proposal-btns {
	padding: 20px;
	background: #FFF9F9;
	display: flex;
	display: -webkit-flex;
	border-radius: 5px;
}
.custom-breadcrumb {
    padding: 50px 0 160px;
}
.profile-cmpny {
	font-weight: 500;
	font-size: 24px;
	color: #E65425;
}
.bck-btn {
	font-weight: 500;
	font-size: 20px;
	color: #fff;
	background: #E65425;
	border-radius: 5px;
	padding: 10px 15px;
	float: right;
}
.note-editor.card {
    padding: 0.5rem;
}
.note-editor.note-frame {
    border: 1px solid #E7E8EA;
}
.note-toolbar .btn-group button, .note-toolbar  .btn-group .btn {
	border-radius: 0;
}
.note-toolbar {
	z-index: 2;
}
.map {
	width:100%;
	height: 314px;
}
.table-width {
	width: 35%;
}

/*-----------------
	36. Invoices
-----------------------*/

.invoice-item .invoice-logo {
    margin-bottom: 30px;
}
.invoice-item .invoice-logo img {
    width: auto;
    max-height: 52px;
}
.invoice-item .invoice-text h2 {
	color:#161c2d;
	font-size:36px;
	font-weight:600;
}
.invoice-item .invoice-details {
	text-align: right;
	font-weight: 500
}
.invoice-item .invoice-details strong {
	color:#161c2d
}
.invoice-item .invoice-details-two {
	text-align:left
}
.invoice-item .invoice-text {
	padding-top:42px;
	padding-bottom:36px
}
.invoice-item .invoice-text h2 {
	font-weight:400
}
.invoice-info {
	margin-bottom: 30px;
}
.invoice-info p {
	margin-bottom: 0;
}
.invoice-info.invoice-info2 {
    text-align: right;
}
.invoice-item .customer-text {
	font-size: 18px;
	color: #6B7385;
	font-weight: 600;
	margin-bottom: 8px;
	display: block
}
.footer .footer-menu ul li a i{
	font-size: 12px;
	margin-right: 6px !important;
}
.footer-bottom-logo a.menu-logo img{
    margin-bottom: 24px;
}
.settings-menu ul li:last-child a{
	margin: 0;
}
.footer-bottom-logo p {
    color: #6B7385;
	margin-bottom: 30px;
}
.footer-bottom-logo ul{
	display: flex;
	align-items: center;
	list-style: none;
	margin-bottom: 30px;
}
.footer-bottom-logo ul li{
	padding-right: 16px;
}
.btn-connectus {
    background: #2E2E2E;
    color: #fff;
    padding: 10px 18px;
	transition: all 0.5s;
}
.btn-connectus:hover{
	box-shadow: 0px 60px 0 0 #71d3a9 inset;
	color: #fff;
	border: 1px solid #71d3a9;
}
.footer-bottom-logo ul li a{
	color: #6B7385;
	border: 1px solid #CED4E6;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 5px;
	transition: all 0.5s;
}
.footer-bottom-logo ul li a:hover{
	background: #71d3a9;
	border-radius: 50px;
	color: #fff;
	transform: scale(1.2) rotate(360deg);
}
.invoice-table tr th,
.invoice-table tr td,
.invoice-table-two tr th,
.invoice-table-two tr td {
	color: #161c2d;
	font-weight: 600;
	padding: 10px 20px;
	line-height: inherit
}
.invoice-table tr td,
.invoice-table-two tr td {
	font-weight: 500;
}
.invoice-table-two {
	margin-bottom:0
}
.invoice-table-two tr th,
.invoice-table-two tr td {
	border-top: 0;
}
.invoice-table-two tr td {
	text-align: right
}
.invoice-info h5 {
    font-size: 16px;
    font-weight: 500;
}
.other-info {
    margin-top: 10px;
}

/*-----------------
	37. Footer
-----------------------*/

.footer {
    background-image: url(../img/bg/bg-18.png);
    background-position: top;
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    overflow: hidden;
	background: #FBFBFB;
}
.footer-user {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    top: 75px;
}
.footer-top {
    padding: 60px 0;
}
.footer .footer-widget .footer-about-content p {
    color: #E5E5E5;
}
.footer-contact p {
	color: #E5E5E5;
}
.footer-title {
    color: #1B1B1B;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
    position: relative;
    padding-bottom: 10px;
}
h2.footer-title:after {
    content: "";
    width: 35px;
    height: 2px;
    background: #71d3a9;
    bottom: 0;
    left: 0px;
    right: 0;
    bottom: 0;
    position: absolute;
    border-radius: 50px;
}
.footer .footer-menu ul li {
	margin-bottom: 10px;
}
.footer .footer-menu ul li:last-child {
	margin-bottom: 0;
}
.review-set{
	position: relative;
}
.review-set::after{
	position: absolute;
	content: "";
	background: #FFF8F5;
	height: 50%;
	width: 100%;
	bottom: 0;
	left: 0;
	z-index: 0;
}
.footer .footer-menu ul li a {
    color: #6B7385;
    font-size: 16px;
    font-weight: 400;
    position: relative;
    transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
}
.footer .footer-menu ul li a:hover {
    color: #71d3a9;
	letter-spacing: 1.1px;
}
.footer-menu p {
	color: #515151;
	font-weight: 500;
	font-size: 14px;
}
.footer .banner-content .form-inner {
    border-radius: 50px;
    border: 0;
	height: 50px;
	margin-bottom: 50px;
}
.footer .banner-content .form-control {
    background: #fff;
    border-radius: 50px;
	height: 50px;
	margin-right: 3px;
}
.footer .banner-content .form-control::placeholder {
	font-size: 14px;
	color: #9D9D9D;
}
.footer .banner-content .sub-btn {
	min-width: 112px;
	height: 38px;
	margin: 6px;
	background: #101828;
	border: 1px solid #101828;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.home-four-wrapper .footer .banner-content .sub-btn {
	min-width: 120px;
	background: #E65425;
	border: 1px solid #E65425;
	font-weight: 600;
}
.footer .banner-content .sub-btn:hover {
	background: #E65425;
	border: 1px solid #E65425;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.social-icon ul li a i {
    width: 25px;
	height: 25px;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
/*.social-icon ul li:hover {
	top: -5px;
	position: relative;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.footer .footer-menu .social-icon ul li {
	margin-bottom: 5px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}*/
.social-icon ul li a i {
	color: #fff;
}
.footer .social-icon ul li .fa-facebook-f:hover,
.footer .social-icon ul li .fa-instagram:hover,
.footer .social-icon ul li .fa-twitter:hover {
	opacity: .7;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.social-icon ul li .fa-facebook-f {
	background: #337FFF;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.social-icon ul li .fa-instagram {
	background: linear-gradient(315deg, #FBE18A 0.96%, #FCBB45 21.96%, #F75274 38.96%, #D53692 52.96%, #8F39CE 74.96%, #5B4FE9 100.96%);
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.social-icon ul li .fa-twitter {
	background: #33CCFF;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.footer-three .social-icon ul li .fa-facebook-f,
.footer-three .social-icon ul li .fa-linkedin-in,
.footer-three .social-icon ul li .fa-twitter,
.footer-four .social-icon ul li .fa-facebook-f,
.footer-four .social-icon ul li .fa-instagram,
.footer-four .social-icon ul li .fa-twitter,
.footer-five .social-icon ul li .fa-facebook-f,
.footer-five .social-icon ul li .fa-linkedin-in,
.footer-five .social-icon ul li .fa-twitter {
	background: transparent;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.footer-three .social-icon ul li .fa-facebook-f:hover,
.footer-three .social-icon ul li .fa-linkedin-in:hover,
.footer-three .social-icon ul li .fa-twitter:hover {
	background: #5F5F5F;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.footer-five .social-icon ul li .fa-facebook-f:hover,
.footer-five .social-icon ul li .fa-linkedin-in:hover,
.footer-five .social-icon ul li .fa-twitter:hover {
	background: #fff;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.social-icon span {
	color: #000000;
	font-size: 14px;
	font-weight: 500;
	margin-right: 10px;
}
.social-icon ul li+li {
    margin-left: 10px;
}
.social-icon ul li .icon {
    -webkit-transition: all .3s;
    -o-transition: all .3s;
    transition: all .3s;
}
.social-icon ul li a {
	font-weight: 900;
	font-size: 15px;
	color: #E65425;
}
.social-icon ul li .icon:hover, .social-icon ul li .icon:focus {
    color: #fff;
}
.footer .footer-widget.footer-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    outline: none;
}
.footer-newsletter input {
    box-shadow: 0px 4px 4px rgb(194 194 194 / 25%);
    border-radius: 200px;
    height: 43px;
    width: 100%;
    padding: 6px 15px;
	border: 0;
}
.footer-newsletter input:focus {
	outline: none;
}
.btn-newsletter {
	background: #71d3a9;
	border-radius: 200px;
	position: absolute;
	height: 43px;
	right: 0;
	width: 80px;
}
.btn-success {
    color: #fff;
    background-color: #71d3a9;
    border-color: #71d3a9;
}
.btn-success:hover {
    background-color: #71d3a9;
    border-color: #71d3a9;
}
.footer .form-control {
	line-height: 40px;
	border-right-color: transparent;
}
.footer .input-group .input-group-append  {
	padding: 3px;
    background: #fff;
}
.footer .input-group .input-group-append .btn{
	border-radius: 0;
	padding: 10px 30px;
}
.footer-contact-info p {
	color: #fff;
	font-size: 16px;	
	font-weight: 800;
}
.copyright {
    padding: 20px 0;
    border-top: 1px solid #EBEBEB;
}
.copyright-text p, .copyright-text p a {
	color: #6B7385;
	font-size: 16px;
}
.copyright-text  a {
	color: #FFBA80 !important;
	font-size: 16px;
}
.copyright-text  a:hover {
	color: #B35F49 !important;
	font-size: 16px;
}

.footer-bottom-nav {
	justify-content: end;
	-webkit-justify-content: end;
	-ms-flex-pack: end;
	margin-bottom:5px;
}
.footer-bottom-nav li a {
	color: #515151;
	font-weight: 500;
	font-size: 14px;
}
.footer-bottom-nav li a:hover {
	color: #E65425 !important;
}
.footer-bottom-nav li+li {
	margin-left: 10px;
}
.footer-two .footer-bottom-nav li+li {
	margin-left: 10px;
	padding-left: 10px;
	border-left: 1px solid #2B2B2B;
}
.center-text {
	text-align: center;
}
.copyright-text p a:hover {
	color: #71d3a9;
}
.footer-contact-info .social-icon ul {
	padding: 0;
	margin-top: 10px;
}
.footer-contact-info .social-icon ul li .icon {
	width: 26px;
    height: 26px;
	font-size: 12px;
}
.footer-address .off-address + .off-address {
	margin-top: 20px;
}
.footer-address .off-address p {
	margin-bottom: 0;
}

/*-----------------
	37. Footer Two
-----------------------*/

.footer.footer-two {
	background: #161515;
}
.footer-two .footer-title {
	margin-bottom: 10px;
}
.footer-two .footer-title,
.footer-two .footer-menu p {
    color: #fff;
}
.footer.footer-two .footer-menu ul li a {
	color: #FFF5F1;
}
.footer.footer-two .footer-menu ul li a:hover {
	color: #E65425;
}
.footer.footer-two .banner-content .form-inner {
	box-shadow: none;
}
.footer-two h2.footer-title:before,
.footer-two h2.footer-title:after {
	display: none;
}
.footer-two .footer-bottom-nav li a,
.footer-two .copyright-text p {
    color: #fff;
}
.footer.footer-two .banner-content .form-inner {
    border-radius: 8px;
    height: 50px;
	padding: 0;
}
.footer.footer-two .banner-content .form-control,
.footer.footer-two .banner-content .input-group {
    height: 50px;
}
.footer.footer-two .banner-content .sub-btn {
    height: 50px;
    margin: 0;
	background: #E65425;
	border: 1px solid #E65425;
}
.footer.footer-two .banner-content .sub-btn:hover {
	background: #131135;
	border: 1px solid #131135;
}
.footer-two .banner-content .sub-btn {
    background: #E65425;
	border-radius: 0px 5px 5px 0px !important;
}

/*-----------------
	37. Footer Three
-----------------------*/

.footer.footer-three {
	background: #FFF5F1;;
}
.footer-top-blk {
	border-bottom: 1px solid #EBEBEB;
	padding: 25px 0px;
}
.footer-social-group .footer-bottom-nav {
	justify-content: start;
	-webkit-justify-content: start;
	-ms-flex-pack: start;
}
.footer-social-group .social-icon {
	justify-content: end;
	-webkit-justify-content: end;
	-ms-flex-pack: end;
}
.footer-social-group .social-icon ul li a {
    background: transparent;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.footer-social-group .social-icon ul li a:hover {
    background: #5F5F5F;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.footer-social-group .social-icon ul li i {
	color: #000;
}
.footer-social-group .social-icon ul li a:hover i {
    color: #fff;
}
.footer-social-group .footer-bottom-nav li a {
    color: #353535;
}
.footer-three h2.footer-title:before,
.footer-three h2.footer-title:after {
	display: none;
}
.footer-three .footer-title {
	margin-bottom: 20px;
	padding-bottom: 0px;
	color: #2B2B2B;
	font-size: 16px;
}
.footer-menu .applicate-mobile-blk h6 {
	color: #2B2B2B;
	font-weight: 600;
}
.footer.footer-three .banner-content .sub-btn {
	background: transparent;
    border: 0;
    color: #E65425;
	padding: 10px 12px;
}
.footer.footer-three .banner-content .sub-btn:hover {
	color: #2e2e2e;
}
.footer.footer-three .banner-content .form-inner,
.footer.footer-three .banner-content .input-group {
    border-radius: 4px;
}
.footer.footer-three .copyright-text p {
    color: #353535;
}

/*-----------------
	37. Footer Four
-----------------------*/

.footer-four {
	background: #081733;
}

.footer-four h2.footer-title:before,
.footer-four h2.footer-title:after {
	display: none;
}
.footer-four .footer-title {
	margin-bottom: 20px;
	padding-bottom: 0;
	color: #FFFFFF;
	font-size: 22px;
	font-weight: 600;
}
.footer.footer-four .footer-menu ul li a {
	color: #CED4E6;
}
.footer.footer-four .footer-menu ul li a:hover i,
.footer.footer-four .footer-menu ul li a:hover {
	color: #E65425;
}
.footer.footer-four .footer-menu .social-icon ul li {
	margin-bottom: 0;
}
.subscribe-four h4 {
	color: #FFFFFF;
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 20px;
}
.footer.footer-four .banner-content .form-inner, 
.footer.footer-four .banner-content .input-group {
    border-radius: 4px;
	padding: 0;
	max-width: 600px;
}
.footer.footer-four .banner-content .form-control {
    border-radius: 4px;
    height: 48px;
}
.footer.footer-four .banner-content .sub-btn {
    height: 38px;
	border-radius: 4px !important;
}
.play-app img {
	max-width: 142px;
}
.footer-four .footer-menu .applicate-mobile-blk h6 {
	color: #FFFFFF;
	margin-bottom: 20px;
}
.footer-four .footer-menu .applicate-mobile-blk p {
	color: #CED4E6;
	font-size: 16px;
	font-weight: 400;
}
.free-customer p {
	color: #5F5F5F;
	font-size: 14px;
	font-weight: 500;
}
.free-customer h6 {
	color: #FFFFFF;
	font-weight: 600;
	font-size: 16px;
}
.free-customer p {
	margin-bottom: 5px;
}
.free-customer h6 {
	font-size: 16px;
	margin-bottom: 0;
}
.footer-four .applicate-mobile-blk {
	margin-top: 50px;
}
.free-customer {
	margin-bottom: 10px;
}
.footer-four .social-icon {
	margin-top: 50px;
}
.footer-four .footer-menu .free-customer p {
	color: #888888;
	font-size: 15px;
	font-weight: 500;
}
.footer-four .footer-menu .social-icon p {
	color: #FFF5F1;
}
.footer-four .social-icon ul li:nth-child(1) a,
.footer-four .social-icon ul li:nth-child(2) a,
.footer-four .social-icon ul li:nth-child(3) a {
    background: transparent;
}
.footer-four .social-icon ul li a {
    width: auto;
}
.footer-four .copyright-text p {
    color: #FFF5F1;
}
.footer-four .copyright {
    padding: 15px 0;
    border-top: 1px solid #2E2E2E;
}

/*-----------------
	37. Footer Five
-----------------------*/

.footer.footer-five {
	background: #101828;
}
.footer-five .footer-social-group .footer-bottom-nav li a,
.footer-five .social-icon span,
.footer-five .footer-social-group .social-icon ul li i,
.footer-five .footer-menu p {
    color: #FFF5F1;
}
.footer-five .footer-social-group .social-icon ul li a:hover {
    background: #EBEBEB;
}
.footer-five .footer-social-group .social-icon ul li a:hover i {
    color: #515151;
}
.footer-five .footer-title,
.footer-five .footer-menu .applicate-mobile-blk h6 {
    color: #fff;
}
.footer.footer-five .footer-menu ul li a {
	color: #CED4E6;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.footer.footer-five .footer-menu ul li a:hover,
.footer.footer-five .footer-menu ul li a:hover i {
	color: #E65425;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.footer.footer-five .footer-menu ul li a i {
	font-size: 6px;
	color: #474747;
	margin-right: 10px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.play-icon-store {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.footer-five h2.footer-title:before,
.footer-five h2.footer-title:after {
	display: none;
}
.footer-five .footer-title {
	margin-bottom: 20px;
	font-size: 16px;
	font-weight: 600;
	padding-bottom: 0;
}
.footer-five .copyright-text p {
    color: #CED4E6;
    font-size: 14px;
}
.footer-five .copyright {
    border-top: 1px solid #2E2E2E;
}
.footer-five .footer-top-blk {
	border-bottom: 1px solid #2E2E2E;
}
.footer.footer-five .banner-content .sub-btn {
    background: #E65425;
	border: 1px solid #E65425;
	border-radius: 0px 6px 6px 0px !important;
}
.footer.footer-five .banner-content .sub-btn:hover {
	background: #131135;
	border: 1px solid #131135;
}
.footer.footer-five .banner-content .form-inner, 
.footer.footer-five .banner-content .input-group {
    border-radius: 8px;
}
.footer.footer-five .banner-content .sub-btn {
    height: 50px;
    margin: 0px;
}
.footer.footer-five .form-inner {
	background: transparent;
}
.transaction-shortby .sort-show {
	padding-left: 10px;
}
.freelance-widget {
    background-color: #fff;
	text-align: center;
    position: relative;
	margin-bottom: 25px;
    z-index: 1;
	border: 1px solid #EBEBEB;
	border-radius: 6px;
	padding: 10px;
}
.freelance-widget:hover .btn-cart{
	box-shadow: 0  50px 0 0 #71d3a9 inset;
	color: #fff;
}
.subscribe{
	background: #081733;
	padding: 80px 0;
}
.subscribe .header-title{
	color: #fff;
}
.subscribe .section-header p{
	color: #fff;
}
.subscribe-form {
    width: 793px;
    max-width: 100%;
    margin: auto;
    position: relative;
}
.subscribe-form input {
    width: 100%;
    border-radius: 5px;
    height: 60px;
    padding: 10px 15px;
}
.subscribe-form input:focus{
	outline: none;
}
.bg-img{
	position: relative;
}
.bg-img3{
	position: absolute;
	right: 0;
	top: 40px;
}
.btn-sub {
    position: absolute;
    right: 20px;
    background: #E65425;
    padding: 8px 17px;
    top: 12px;
    color: #fff;
    border: 1px solid  #E65425;
    border-radius: 5px;
}
.btn-sub:hover{
	box-shadow: 0px 60px 0 0 #131135 inset;
	color:#fff ;
	border: 1px solid #131135;
}
.freelance-img {
    position: relative;
    width: 106px;
	height: 106px;
    margin:20px auto 15px;
}
.freelance-content{
	position: relative;
}
.freelance-content::after{
	position: absolute;
	content: "";
	background: #71d3a936;
	width: 100%;
	height: 84px;
	top: 0;
	left: 0;
	z-index: -1;
	border-radius: 6px;
}
.freelance-img img {
	margin: auto;
	width: 106px;
	height: 106px;
	border-radius: 50%;
	padding: 5px;
}
.freelance-img .verified {
    background-color: #fff;
    color: #159C5B;
    font-size: 18px;
    position: absolute;
    right: 5px;
    bottom: 5px;
    border-radius: 100%;
    z-index: 2;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.freelance-info h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 2px;
    color: #6B7385;
}
.freelance-info h3 a {
	color: #353535;
}
.freelance-info h3 a:hover {
	color: #E65425;
}
.freelance-specific {
    font-size: 14px;
	font-weight: 500;
    color: #6B7385;
	margin-bottom: 5px;
}
.freelance-location {
    color: #515151;
    font-size: 14px;
	margin-bottom: 10px;
	font-weight: 500;
	display: flex;
    align-items: center;
    justify-content: center;
}
.favourite {
    color: #9D9D9D;
    font-weight: bold;
    background: #fff;
    border: 1px solid #EBEBEB;
    border-radius: 50px;
    font-size: 12px;
    width: 30px;
    height: 30px;
    padding: 0;
    display: inline-flex;
    position: absolute;
    right: 15px;
    top: 15px;
    justify-content: center;
    align-items: center;
}
.favourite:hover {
	color: #fff;
	background: #E65425;
	border: 1px solid #E65425;
}
.favourited {
	color: #fff;
	background: #E65425;
	border: 1px solid #E65425;
}
.cart-hover {
    text-align: center;
}
.btn-cart {
    width: 100%;
    display: inline-block;
    border-radius: 5px;
    border-color: transparent;
    text-transform: capitalize;
    background: transparent;
	border: 1px solid #71d3a9;
    color: #71d3a9;
    padding: 9px 15px;
	font-weight: 700;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
	font-size: 14px;
}
.btn-cart:hover, 
.btn-cart:focus {
    background: #71d3a9;
	color: #fff;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.freelance-content {
	padding: 20px 0px;
}
.freelance-widget .favourite.color-active {
    animation: size .4s;
    background:#71d3a9;
    color: #fff;
}
.sort-tab {
	margin-bottom: 40px;
}
.freelance-view h4 {
    font-size: 16px;
    font-weight: 600;
    color: #1B1B1B;
    display: inline-block;
    margin-bottom: 0;
}
.sort-by select {
    border: none;
    outline: none;
    width: 150px;
    padding: 0 20px;
    font-size: 15px;
    color: #53545b;
    font-weight: 500;
    height: 36px;
}
.freelance-widget .rating {
	margin-bottom: 15px;
}
.freelance-tags {
    margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #CED4E6;
}
.freelancers-price {
	font-size: 18px;
	font-weight: 600;
	color: #101828;
	max-width: 200px;
	overflow-wrap: break-word;
	word-break:break-all;
}
.freelance-blk-location {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content:center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
}
.freelance-widget:hover .freelancers-price {
	color: #159C5B;
}
.widget-author {
    background-color: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
}
.widget-author {
    text-align: center;
    position: relative;
	background-color: #fff;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border: 1px solid #CED4E6;
	text-align: center;
	position: relative;
	margin-bottom: 30px;
}
.widget-author .cover-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.widget-author .cover-img img {
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: 100px;
}
.widget-author .profile-img {
    box-shadow: 0 6px 21px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    /* padding: 15px; */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin: 0 auto 15px;
}
.widget-author .profile-img a {
    border-radius: 50%;
    position: relative;
	display: inline-block;
}
.widget-author .profile-img a img {
    width: 100%;
}
.widget-author .author-name {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
}
.widget-author .author-name a {
    color: #161c2d;
}
.widget-author .author-location {
    font-size: 20px;
    color: #1B1B1B;
    font-weight: 600;
}
.freelance-time{
	position: absolute;
	top: 10px;
    left: 10px;
    color: #6B7385;
	margin: 0;
}
.widget-author .dev-text {
	position: relative;
}

.widget-author .left-content h6 {
	color: #000;
}
.widget-author .left-content h4 {
	color: #000;
}
.widget-author .left-content h4 a {
	color: #000;
}
.widget-author .dev-text .left img {
    display: block;
    float: left;
    width: 66px;
    border-radius: 12px;
}
.widget-author .dev-text .left .left-content {
    margin-left: 75px;
}
.counter-stats {
    margin-top: 15px;
}
.counter-stats ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
    list-style: none;
    padding: 0;
}
.counter-stats ul li {
    position: relative;
    width: 33%;
    text-align: left;
}
.counter-stats ul li h3 {
    color: #1B1B1B;
    font-weight: 400;
    font-size: 16px;
}
.counter-stats ul li h5 {
    color: #6B7385;
    font-size: 14px;
	font-weight: 400;
    margin-bottom: 3px;
}
.developers-slider .owl-nav {
    position: absolute;
    overflow: hidden;
    bottom: 100%;
    right: -5px;
    margin-bottom: 50px;
}
.developers-slider.owl-carousel .owl-nav button.owl-next, .developers-slider.owl-carousel .owl-nav button.owl-prev, .developers-slider.owl-carousel button.owl-dot {
    display: block;
    float: left;
    width: 40px;
    height: 40px;
    font-size: 13px;
    color: #6B7385;
    border: 0;
    background: #fff;
    text-align: center;
    border-radius: 50px !important;
    border: 1px solid #CED4E6;
    transform: rotate(45deg);
}
.developers-slider.owl-carousel .owl-nav button.owl-next i, .developers-slider.owl-carousel .owl-nav button.owl-prev i, .developers-slider.owl-carousel button.owl-dot i {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.developers-slider .page-prev, .developers-slider .owl-nav .owl-prev {
    border-right-width: 0px;
    border-radius: 3px 0 0 3px;
}
.testimonial-section .owl-nav {
    overflow: hidden;
    bottom: -30px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
	margin-top: 0;
}
.testimonial-section .owl-carousel .owl-nav button.owl-next, .testimonial-section .owl-carousel .owl-nav button.owl-prev, .testimonial-section .owl-carousel button.owl-dot {
    display: block;
    float: left;
    width: 32px;
    height: 32px;
    font-size: 13px;
    color: #6B7385;
    border: 1px solid #CED4E6;
    background:#fff;
    text-align: center;
  	border-radius: 50px !important;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.testimonial-section .owl-carousel .owl-nav button.owl-next i, .testimonial-section .owl-carousel .owl-nav button.owl-prev i, .testimonial-section .owl-carousel button.owl-dot i {
	-webkit-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	transform: rotate(-45deg);
}
.testimonial-section .page-prev, .testimonial-section .owl-nav .owl-prev {
    border-right-width: 0px;
    border-radius: 3px 0 0 3px;
}
.testimonial-section .owl-theme .owl-nav [class*="owl-"]:hover, .developers-slider.owl-theme .owl-nav [class*="owl-"]:hover {
	background: #71d3a9;
	color: #fff;
}
.header.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 999;
    display: block;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
    -moz-box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
    box-shadow: 0px 10px 20px 0px rgba(8, 0, 42, 0.08);
}
.note-editor.note-frame .note-status-output {
	display: none;
}
.dashboard-page .content {
    padding: 144px 0 30px;
}
.dashboard-page .content-page {
    padding: 60px 0 30px;
}
.dashboard-page .header {
	border-bottom: 1px solid #e3e3e3;
}
/* .dashboard-page .footer .container {
	max-width: 100%;
	padding-left: 30px;
	padding-right: 30px;
} */
/* .list-pagination {
    margin-bottom: 0;
    float: right;
    border: 1px solid #dee2e6;
	border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
} */
.user-tabs.project-tabs {
	margin-bottom: 30px;
}
.pro-status {
    border-top: 1px solid #F5F5F5;
    padding-top: 20px;
    margin-top: 20px;
}
.pro-list-view .project-info h2 {
	font-size: 22px;
    line-height: 1.3;
}
.pro-list-view .project-info {
	width: 70%;
}
.pro-list-view .slot p {
	font-size: 15px;
}
.pro-list-view .slot h5 {
    font-size: 18px;
}
.pro-list-view .projects-amount h3 {
    font-size: 23px;
    font-weight: bold;
    color: #F5365C;
}
.pro-list-view .projects-amount h5 {
    color: #6e727d;
}
table.dataTable {
	margin: 0 !important;
}
.off-address a {
	color: #fff;
}
.invoice-id {
	color: #71d3a9;
}
.widget-author.pro-post {
	box-shadow: 0px 4px 4px rgb(204 204 204 / 25%);
    border: 1px solid #e8e8e8;
    border-radius: 0;
}
.header-three.sticky .header-navbar-rht li a i{
    color: #555;
}
.header-three.sticky  .header-navbar-rht .login-btn i{
	color: #fff;
}

/*-----------------
	Back to Top 
-----------------------*/

button.scroll-top:focus{
    outline: none;
}
.scroll-top.open {
    bottom: 30px;
}
.scroll-top {
    width: 50px;
    height: 50px;
    line-height: 50px;
    position: fixed;
    bottom: -40%;
    right: 35px;
    border-radius: 10px;
    z-index: 9;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    background: #E65425;
    transition: 1s ease;
    border: none;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.scroll-top:hover {
	background: #131135;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.scroll-top span {
    color: #fff;
	line-height: 0;
}

/*-----------------
	38. Company Profile
-----------------------*/

.company-profile h4 {
	font-size: 16px;
	color: #000;
	text-transform: uppercase;
}
.company-profile .widget-box {
    padding: 0px;
}
.profile-head {
	padding: 30px 30px 6px 30px;
}
.profile-head .pro-title {
	border: 0;
}
.company-profile .widget-box .latest-posts li {
	padding: 13px 30px 13px;
}
.company-profile .widget-box .latest-posts li span {
	color: #E65425;
}
.about-widget.profile-overview p {
	margin-bottom: 10px;
}
.profile-overview .latest-posts li {
	border-bottom: 1px solid #EDEDED;
	padding: 13px 0;
}
.profile-overview .latest-posts li:last-child {
	border-bottom: 0;
	padding-bottom: 0;
}
.profile-overview .latest-posts li:last-child h6 {
	margin-bottom: 0;
}
.profile-overview,
.working-days,
.location-widget,
.social-widget {
	border-radius: 5px;
}
.map-location iframe {
	width: 100%;
	height: 200px;
}
.contact-btn {
	margin-top: 0px;
	padding: 30px;
}
.contact-btn .btn-primary {
	width: 100%;
	color: white;
	padding: 11px 15px;
	font-size: 20px;
	background: #71d3a9;
	border: 0;
	border-radius: 50px;
}
.contact-btn .btn-primary i {
	margin-right: 12px;
}
.working-days .latest-posts li {
	color: #6D7E8F;
	font-size: 14px;
	font-weight: 400;
	border-bottom: 1px solid #EDEDED;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.working-days .latest-posts li p {
	margin-bottom: 0;
}
.map-location {
	padding: 30px;
	padding-top: 0;
}
.working-days .latest-posts li h6 {
	margin-left: auto;
	color: #131135;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 0;
}
.social-link-profile {
	display: flex;
	padding: 30px;
	padding-top: 10px;
	margin: 0;
}
.social-link-profile li {
	list-style: none;
	margin-right: 15px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.social-link-profile li:hover {
	box-shadow: 0px 4px 18px rgb(0 0 0 / 15%);
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.social-link-profile li:last-child {
	margin-right: 0px;
}
.social-link-profile li a i {
	width: 50px;
	height: 50px;
	border: 1px solid #71D3A9;
	border-radius: 5px;
	font-size: 24px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.social-link-profile li:nth-child(1) a i {
	color: #1877F2;
}
.social-link-profile li:nth-child(2) a i {
	color: #1DA1F2;
}
.social-link-profile li:nth-child(3) a i {
	color: #F5365C;
}
.social-link-profile li:nth-child(4) a i {
	color: #0A66C2;
}
.social-link-profile li:nth-child(5) a i {
	color: #229ED9
}
.location-widget .pro-title {
	margin-bottom: 40px;
}
.abouts-view li a {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
	position: relative;
}
.abouts-view li {
	margin-right: 10px;
}
.abouts-view li:last-child {
	margin-right: 0px;
}
.abouts-view .nav-justified .nav-item, 
.abouts-view  .nav-justified>.nav-link {
    flex-basis: inherit;
    flex-grow: inherit;
}
.provider-tabs.abouts-view .nav-tabs.nav-tabs-solid li .active-about p {
	color: #71d3a9;
	font-weight: 500;
}
.abouts-view ul li .active-about {
	background-color: rgb(55 255 159 / 15%);
	border-radius: 5px;
}
.abouts-view ul li .active-about:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 5px;
    background: #71d3a9;
    top: -10px;
    left: 0;
    width: 100%;
	border-radius: 10px;
}
.provider-tabs.abouts-view .nav-tabs.nav-tabs-solid > li > a:hover {
	background-color: rgb(44 217 120 / 15%);
	border-radius: 5px;
}
.provider-tabs.abouts-view .nav-tabs.nav-tabs-solid > li > a p {
	color: #131135;
}
.abouts-view li a img {
	margin-right: 10px;
}
.company-post {
	border: 1px solid #E8E8E8;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
}
.company-post .pro-title {
	color: #000;
	margin-bottom: 38px;
	border-bottom: 0;
}
.abouts-view {
	border: 1px solid #E8E8E8;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
	margin-bottom: 27px;
}
.abouts-view .nav-tabs.nav-tabs-solid {
	padding: 20px;
	padding-top: 28px;
}
.profile-baner {
	background: url(../../assets/img/bg/bg_13.jpg) center center;
    background-size: cover;
    background-repeat: no-repeat;
	padding: 60px 0px;
}
.profile-img img {
	border-radius: 10px;
}
.header-bg {
	background: #fff;
}
.profile-main h2 {
	color: #fff;
	text-transform: uppercase;
	margin-bottom: 5px;
	font-size: 22px;
}
.profile-main h2 i {
	color: #159C5B;
	background: #fff;
    border-radius: 50px;
	font-size: 16px;
}
.about-list ul {
	list-style: none;
	display: flex;
	margin-bottom: 0;
}
.profile-main p {
	margin-bottom: 6px;
	font-size: 12px;
    font-weight: 400;
}
.profile-main .rating .average-rating {
	font-weight: 400;
}
.about-list ul li,
.profile-main p {
	color: #fff;
}
.about-list ul li {
	padding: 0px 16px;
	border-right: 1px solid #FFFFFF;
	font-size: 12px;
    font-weight: 400;
}
.about-list ul li i {
	margin-right: 7px;
}
.about-list ul li:last-child {
	border-right: 0;
}
.about-list ul li:first-child {
	padding-left: 0;
}
.about-list ul li:first-child img {
	border-radius: 50px;
    width: 20px;
    height: 20px;
	margin-right: 12px;
}
.about-list {
	margin-bottom: 8px;
}
.profile-main .rating span,
.profile-main .rating i {
	color: #fff;
}
.profile-main .rating i.filled {
    color: #febe42;
}
.project-company .slot p,
.project-company .slot h5,
.project-company .project-info span {
	font-size: 14px;
}
.project-company .project-info .customer-info ul li h5 img {
    margin-right: 5px;
}
.project-company .projects-action .hired-detail {
	color: #E65425;
	font-size: 13px;
}
.project-company .projects-action .hired-detail span {
	color: #000;
}
.project-company .projects-action .projects-btn:nth-child(2) {
	margin-bottom: 11px;
}
.project-company .projects-action .projects-btn {
	font-size: 14px;
}
.project-company {
	margin-bottom: 15px;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
}
.project-company .project-info span,
.project-company .project-info h2 {
	margin-bottom: 15px;
}
.project-company .project-info h2,
.project-company .projects-amount h3 {
	color: #000;
	font-size: 14px;
}
.company-gallery .project-widget {
	position: relative;
	margin-bottom: 25px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.company-gallery .project-widget .pro-image a:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	opacity:0;
	background: linear-gradient(0deg, rgba(133, 83, 238, 0.87), rgba(133, 83, 238, 0.87));
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.company-gallery .project-widget .pro-image a:hover:before {
	opacity:1;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.company-gallery .pro-image img {
	width: 100%;
}
.view-gallery i {
	position: absolute;
	top: 50%;
    left: 50%;
	color: #fff;
	display: none;
    transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.company-gallery .project-widget:hover .view-gallery i {
	display: block;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.review-heading {
	padding: 25px;
}
.company-gallery .more-btn,
.company-review .more-btn {
	background: #F5365C;
	border-radius: 5px;
	min-width: 135px;
	padding: 9px 15px;
	font-size: 14px;
	margin-top: 20px;
}
.company-gallery .more-btn img,
.company-review .more-btn img {
	margin-right: 10px;
}
.company-review .review-content {
	border: 1px solid #E8E8E8;
	border-radius: 5px;
	padding: 15px;
	margin-bottom: 20px;
}
.company-review .review-content.no-padding +  .review-content.no-padding {
    padding-top: 15px;
}
.company-review .review-content h4 {
	font-size: 16px;
	color: #444444;
}
.company-review p {
	font-size: 14px;
}
.tab-reviews {
	margin-top: 15px;
}
.tab-reviews .review-img {
	width: 40px;
}
.tab-reviews .review-img img {
	border-radius: 50px;
}
.tab-reviews .review-info h3 a {
	font-size: 14px;
	color: #000;
	margin-bottom: 3px;
	font-weight: 600;
}
.tab-reviews .review-info h5 {
	font-size: 11px;
	color: #646464;
	margin-bottom: 0;
	font-weight: 400;
} 
.tab-reviews .review-info {
	margin: 0px 20px;
}
.tab-reviews .rating i {
    color: #6E727D;
}
.tab-reviews .rating .average-rating {
	font-weight: 400;
	margin-left: 10px;
}
.tab-reviews .rating i.filled {
    color: #febe42;
}
.post-comment label {
	color: #343434;
	font-size: 16px;
}
.post-comment .form-control {
    border-color: #FFE6E1;
}
.post-btn .more-btn {
	background: #E65425;
	border-radius: 5px;
	font-size: 18px;
	min-width: 160px;
	padding: 12px 15px;
}
.abouts-detail .pro-title {
	margin-bottom: 29px;
	font-size: 18px;
	text-transform: uppercase;
}
.abouts-detail .pro-content {
	padding: 0;
}
.company-post .list-pagination {
	margin: 0;
}
.company-post .project-company {
	margin-bottom: 30px;
}
.company-post .project-company .project-info span {
	font-weight: 600;
}
.bootstrap-datetimepicker-widget table th {
    height: 30px;
    line-height: 30px;
    width: 30px;
	background: #f5f5f5;
    border-radius: 0;
}
.chat-drop-menu .dropdown-item{
    display: flex;
}
.chat-action-col .dropdown-item{
    display: flex;
}

/*-----------------
	38. Developer Detail 
-----------------------*/

.developer-view .pro-title,
.develop-view .pro-title {
	border: 0;
	text-transform: uppercase;
	color: #000;
	font-size: 16px;
}
.develop-view .widget-box {
    padding: 20px;
}
.developer-profile-img {
	position: relative;
	margin-bottom: 25px;
}
.developer-view .progress-bar {
	background: #3398FB;
}
.developer-view .bg-success {
	background: #5BB054 !important;
}
.developer-view .follow-widget .follow-btn {
	padding: 10px 44px;
}
.img-profile img {
	border-radius: 50%;
    position: absolute;
    top: 36px;
    right: 43px;
	width: 150px;
    height: 150px;
    border: 4px solid #E0E0E0;
}
.developer-view .widget-box {
    padding: 20px;
	border: 1px solid #E8E8E8;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
}
.about-field table td {
	padding: 20px 0 20px;
	font-size: 15px;
}
.about-field .table tr td:first-child {
    padding-left: 0px;
	color: #5C5C5C;
}
.about-field .table tr:last-child td {
	padding-bottom: 0;
}
.about-field .pro-title {
	margin-bottom: 15px;
	font-size: 16px;
	color: #000;
}
.developer-view .follow-posts {
    margin: 0;
    width: 100%;
	margin-bottom: 20px;
	box-shadow: 0px 4px 14px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
	border: 0;
}
.developer-view .follow-posts li:first-child {
	width: 50%;
}
.developer-view .follow-widget {
	text-align: inherit;
}
.developer-box h2 {
	font-size: 26px;
}
.developer-box h3,
.developer-box h3 a {
	font-size: 24px;
	font-weight: 500;
	color: #E65425;
}
.developer-box h3 i {
	color: #159C5B;
	font-size: 24px;
}
.head-develop h2 {
	font-size: 28px;
	margin-bottom: 15px;
	color: #000;
}
.head-develop h2 i {
	color: #159C5B;
	font-size: 28px;
	margin-left: 10px;
}
.developer-box p {
	font-size: 16px;
	color: #000000;
}
.develope-list-rate {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	margin-bottom: 27px;
}
.developer-box p span {
	background: #129BE8;
	padding: 7px 17px;
	color: #fff;
	font-size: 14px;
	border-radius: 5px;
}
.head-develop p span {
	margin-left: 11px;
}
.head-develop .rating i.filled {
	width: 20px;
	height: 20px;
}
.head-develop .develope-list-rate li {
    font-size: 16px;
    font-weight: 500;
}
.project-box-view h2 {
	color: #373737;
	margin-bottom: 30px;
}
.project-box-view h2 i {
	margin-left: 5px;
}
.project-box-view h3 {
	margin-bottom: 17px;
}
.project-box-view .develope-list-rate li {
	color: #131135;
	font-size: 14px;
	font-weight: 500;
}
.project-details-view .pro-post {
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.project-details-view .pro-post:hover {
    box-shadow: 0px 6px 6px rgb(204 204 204 / 40%);
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.proposal-box .favour-border:hover {
	background-color: #ff431a;
    border-color: #ff431a;
    color: #fff;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.proposal-box .favour-border {
	border: 1px solid #F5365C;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.develope-list-rate li {
	padding: 0px 20px;
	list-style: none;
	border-right: 1px solid #000000;
	color: #000000;
}
.project-rate li i {
	font-weight: 500;
	margin-right: 10px;
}
.develope-list-rate .full-time {
	background: #129BE8;
	padding: 4px 15px;
	color: #fff;
	font-size: 14px;
	border-radius: 5px;
	margin-left: 20px;
	min-width: 87px;
}
.project-box-view .develope-list-rate .full-time {
	color: #fff;
}
.develope-list-rate .rating .average-rating {
	background: #FEBE42;
	padding: 5px 5px;
	font-size: 11px;
	color: #000;
	border-radius: 5px;
	margin-right: 10px;
}
.develope-list-rate li:last-child {
	border: 0;
}
.develope-list-rate .emp-edit {
	margin-left: auto;
}
.develope-list-rate li:last-child img {
	margin-right: 8px;
}
.develope-list-rate li:first-child {
	padding-left: 0;
}
.proposal-box {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	 -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
	margin-bottom: 22px;
}
.proposal-value h4 {
	font-size: 25px;
	color: #E65425;
	font-weight: 600;
	margin-bottom: 0;
}
.proposal-value span {
	font-size: 16px;
}
.favourites-btn.btn-primary,
.proposal-btn.btn-primary {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
	min-height: 47px;
	font-size: 16px;
}
.favourites-btn.btn-primary {
	background: transparent;
	color: #71d3a9;
	margin: 0px 15px;
	min-width: 174px;
}
.favourites-btn.btn-primary i {
	margin-left: 7px;
}
.proposal-btn.btn-primary {
	background: #71d3a9;
	min-width: 194px;
}
.proposal-btn.btn-primary:hover {
	background: #01243f ;
}
.proposal-btn.btn-primary i {
	margin-left: 4px;
}
.feed-back-detail {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
	margin-bottom: 30px;
}
.feed-back-detail li {
	list-style: none;
	padding: 0px 10px;
	border-right: 1px solid #131135;
	font-size: 15px;
	color: #5C5C5C;
	font-weight: 500;
}
.feed-back-detail li span {
	padding-left: 10px;
	color: #F15C3D;
}
.feed-back-detail li:last-child {
	border: 0;
}
.feed-back-detail li:first-child,
.watch-list li:first-child {
	padding-left: 0;
}
.feed-back-detail li img,
.watch-list li img {
	margin-right: 10px;
}
.watch-list {
	margin: 22px 0px 30px;
}
.watch-list li {
	padding: 0px 10px;
	list-style: none;
	text-transform: uppercase;
	color: #000;
	font-size: 14px;
	font-weight: 600;
}
.experiance-list {
	background: #F9F9F9;
	border-radius: 20px;
	padding: 20px;
}
.experiance-list h4 {
	font-size: 16px;
	color: #292929;
	font-weight: 500;
	margin-bottom: 15px;
}
.experiance-list h5 {
	color: #F38247;
	margin-bottom: 5px;
}
.experiance-list p {
	color: #000000;
}
.experiance-list h5,
.experiance-list p {
	font-size: 14px;
}
.experiance-logo {
	background: #E65425;
	border-radius: 10px;
	width: 50px;
	height: 50px;
	margin-bottom: 10px;
}
.experiance-logo.logo-bg {
	background: #F5365C;
}
.develop-feedback .author-details h6 {
	color: #F38247;
	font-size: 14px;
}
.feedback-view {
	margin-left: auto;
	color: #E65425;
	font-size: 14px;
	font-weight: 500;
}
.develop-feedback .author-details .blog-author-name {
	font-size: 14px;
}
.develop-feedback .author-details p {
    font-size: 14px;
	color: #000;
}
.develop-feedback {
	border: 1px solid #E8E8E8;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
}
.project-gallery .project-widget {
	position: relative;
}
.project-gallery .pro-image img {
	width: 100%;
	border-radius: 5px;
}
.view-image {
	margin-left: auto;
}
.view-image i {
	width: 28px;
	height: 28px;
	background: #fff;
	color: #E65425;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.project-footer .pro-name,
.project-footer .pro-designation {
	color: #fff;
}


.project-footer .pro-name {
	font-size: 13px;
	margin-bottom: 0;
}
.project-footer .pro-designation {
	font-size: 12px;
}
.project-footer .pro-detail {
	padding: 0;
	text-align: left;
}
.project-gallery .project-widget .pro-image a:before {
	content: "";
    position: unset !important;
    width: 100%;
    height: 100%;
    border-radius: 5px;
	opacity: 0;
    background: linear-gradient(0deg, rgba(251, 84, 0, 0.79), rgba(251, 84, 0, 0.79));
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.project-gallery .project-widget .pro-image a:hover:before {
	opacity: 1;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.project-gallery .project-widget:hover .project-footer {
	opacity: 1;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.project-footer {
	position: absolute;
	bottom: 0;
    width: 100%;
	background: rgba(0, 0, 0, 0.47);
	border-radius: 0 0 5px 5px;
	padding: 10px;
	opacity: 0;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.project-gallery .more-btn {
	min-width: 135px;
    padding: 10px 15px;
    font-size: 15px;
}
.develop-view .widget-box {
	border: 1px solid #E8E8E8;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
}
.develop-view .abouts-view .nav-link {
	padding: 8px;
}
.develop-view .provider-tabs.abouts-view .nav-tabs.nav-tabs-solid li  p {
	margin: 0;
}
.technical-skill .tags .badge-skills {
	width: auto;
	cursor: pointer;
}
.technical-skill .badge-skills {
	background: rgba(255, 91, 55, 0.1);
	border-radius: 40px;
	color: #E65425;
	font-size: 15px;
	font-weight: 500;
	padding: 10px 15px;
	margin-bottom: 0;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.technical-skill .badge-skills:hover {
	background: rgba(255, 91, 55, 0.3);
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.develop-social-link .profile-head {
    padding: 0;
}
.develop-social-link .social-link-profile {
	padding: 0;
}
.develop-social-link .pro-title {
	margin-bottom: 34px;
}
.develop-social-link .social-link-profile li a i {
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.develop-social-link .social-link-profile li a i:hover {
	background: #131135;
	border: 1px solid #131135;
	color: #fff;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.link-box {
	border-radius: 5px;
}
.link-box .latest-posts li {
	border-bottom: 1px solid #EDEDED;
	padding: 15px 20px;
}
.link-box .latest-posts li a {
	font-size: 14px;
	color: #6D7E8F;
}
.link-box .latest-posts li:last-child {
	padding-bottom: 5px;
}
.link-box .widget-title-box {
    padding: 20px 20px 0;
}
.profile-link .input-group .form-control {
	color: #868686;
	font-size: 14px;
}

.develop-list-pro {
	margin-left: auto;
	text-align: right;
}
.develop-list-pro .freelancers-price {
	margin: 19px 0px;
}
.develop-list-pro .btn-cart {
	min-width: 164px;
	padding: 8px 15px;
}
.develop-list-pro .favourite {
	position: relative;
	top: inherit;
	right: inherit;
}
.about-list-author .about-author-img {
	overflow: inherit;
}
.about-list-author .about-author-img img {
	border-radius: 50%;
}
.about-list-author .verified {
    background-color: #fff;
    color: #159C5B;
    font-size: 18px;
    position: absolute;
    right: 0px;
    bottom: 5px;
    border-radius: 100%;
    z-index: 2;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.list-develop-group .freelance-location {
	margin-bottom: 0px;
}
.wallet-group {
	background: #EDF8FF;
	border-radius: 5px;
	padding: 20px;
	width: 100%;
}
.wallet-history .table .thead-pink th {
	color: #131135;
	font-size: 15px;
}
.wallet-btn .btn-primary {
	min-width: 131px;
	padding: 8px 15px;
	font-size: 12px;
	border-radius: 130px;
	font-weight: 600;
}
.wallet-img {
	width: 45px;
	height: 45px;
	margin-right: 15px;
	background: #129BE8;
	border-radius: 50px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.wallet-rupee .profile-group .input-group {
    border: 1px solid #FFE6E1;
	border-radius: 5px;
    padding: 0;
}
.wallet-rupee .dol-btn {
    background: #EDEDED;
    border-radius: 5px;
	padding: 0;
    width: 64px;
    height: 64px;
}
.wallet-rupee .badge-skills {
	padding: 10px 37px;
	margin-right: 10px;
	font-size: 14px;
	color: #6E727D;
}
.wallet-rupee .form-control {
	border-color: #ffe6e1;
	height: 64px;
}
.wallet-rupee label {
    font-weight: 400;
    color: #343434;
}
.wallet-rupee .input-block,
.wallet-rupee .custom_radio {
    margin-bottom: 15px;
}
.wallet-rupee .custom_radio .checkmark:after {
    top: 5px;
    left: 5px;
    width: 13px;
    height: 13px;
}
.wallet-rupee .custom_radio .checkmark {
    height: 25px;
    width: 25px;
}
.wallet-rupee .bootstrap-tags {
    padding: 0;
	margin-bottom: 15px;
}
.balance-total h3 {
	font-size: 16px;
	color: #131135;
	font-weight: 400;
	margin-bottom: 5px;
}
.balance-total h2 {
	font-size: 18px;
	color: #129BE8;
	font-weight: 600;
	margin-bottom: 0;
}
.total-credit {
	background: #E4FFEA;
}
.total-depit {
	background: #FFF0F3;
}
.total-credit .wallet-img {
	background: #159C5B;
}
.total-depit .wallet-img {
	background: #FF2C00;
}
.total-credit .balance-total h2 {
	color: #159C5B;
}
.total-depit .balance-total h2 {
	color: #FF2C00;
}
.wallet-history .table tr td span {
	padding: 0;
	color: #646464;
	font-weight: 400;
	font-size: 11px;
}
.wallet-history .table tr td {
	color: #131135;
	font-size: 14px;
}
.wallet-history .table tr td:last-child {
	color: #4AA44D;
}
.wallet-history .table tr td:nth-child(4) i {
	color: #4AA44D;
}
.wallet-list {
	padding: 0;
	box-shadow: none;
	background: transparent;
}
#wallet .modal-header {
	border-bottom: 0;
	padding: 0;
}
#wallet .modal-header span {
	position: absolute;
    right: 30px;
	top: 30px;
	z-index: 9;
}
.wallet-list .dol-btn {
	color: #000;
    background: #EDEDED;
	border: 1px solid #EDEDED;
}
.wallet-head h2 {
	font-size: 24px;
	margin: 23px 0px 40px;
}

/*-----------------
	38. Freelancer Favourites
-----------------------*/

.freelance-box .freelance-tags span {
	margin-bottom: 10px;
}
.book-mark .freelance-img,
.book-mark .freelance-img img {
	width: 86px;
    height: 86px;
}
.book-mark .freelance-info h3 {
    font-size: 20px;
    font-weight: 600;
}
.book-mark .freelance-specific,
.book-mark .freelance-location,
.book-mark .rating i,
.book-mark .rating .average-rating,
.book-mark .btn-cart {
    font-size: 12px;
}
.book-mark .freelance-tags span {
   font-size: 10px;
}
.book-mark .freelance-img .verified {
	width: auto;
	height: auto;
	border: 3px solid #FFFFFF;
	border-radius: 50%;
	color: #34C759;
}
.book-mark .freelance-img .verified i {
	font-size: 16px;
}
.book-mark .favourite {
	width: 27px;
	height: 27px;
}
.favour-book .freelance-info h3 a{
	color: #000;
}
.book-mark .freelance-info h3 a:hover,
.tab-reviews .review-info h3 a:hover {
	color: #E65425;
}
.favour-book .freelancers-price {
	color: #55545B;
}
.book-mark .freelance-specific {
	margin: 5px 0px;
}
.book-mark .freelance-location {
	margin-bottom: 6px;
	justify-content: start;
}

.book-mark .freelance-content {
    padding: 15px;
}
.book-mark .freelance-specific,.book-mark  .freelance-location{
	font-size: 14px;
}
.freelance-price {
    display: flex;
    align-items: center;
    margin-left: 15px;
}
.freelance-price-content {
    margin-left: 15px;
}
.freelance-price-content h6 {
    font-size: 14px;
    color: #6B7385;
}
.freelance-price-content h5 {
    font-size: 18px;
    color: #1B1B1B;
    font-weight: 600;
}
.book-mark .freelance-widget {
	border: 1px solid #EFEFEF;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	margin-bottom: 25px;
}
.book-mark {
	padding-bottom: 0px;
}
.book-mark .rating i {
    color: #EBEBEB;
}
.book-mark .rating i.filled {
    color: #febe42;
}
.list-grid {
	margin-bottom: 0;
}
.list-book-mark .about-author {
	padding: 15px;
}
.list-book-mark .freelance-tags {
	margin-bottom: 0;
}
.list-book-mark .freelance-tags span {
    margin-bottom: 5px;
}
.list-book-mark .verified i {
	font-size: 16px;
}
.list-book-mark .verified {
	width: 16px;
	height: 16px;
	border: 1px solid #FFFFFF;
	border-radius: 50%;
	right: 5px;
}
.develop-list-select .list-grid {
	margin-right: 14px;
}
.list-grid li {
	list-style: none;
}
.list-grid li:first-child {
	margin-right: 14px;
}
.list-grid li i {
	background: #FFFFFF;
	border: 1px solid #E9E9E9;
	width: 40px;
	height: 40px;
	border-radius: 5px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.breadcrumb-list h3 {
    font-size: 40px;
}
.list-grid li .favour{
	border: 1px solid #71D3A9;
	padding: 10px;
}
.list-grid li .favour-active span {
	background: #71D3A9;
	border: 1px solid #71D3A9;
	color: #fff;
	padding: 10px;
}
.relavance-rate {
	margin-left: auto;
}
.relavance-result {
	margin-bottom: 20px;
}
.relavance-result h4 {
	font-size: 14px;
	color: #E65425;
}
.relavance-rate .sort-by {
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.relavance-rate .sort-by select {
	padding: 0 4px;
	max-width: 110px;
}
.relavance-rate .sort-by label {
	margin-bottom: 0;
	margin-right: 4px;
}
.bookmark-projects .counter-stats ul li {
    padding-left: 0;
    padding-right: 0;
}
.bookmark-projects .counter-stats ul li h3,
.bookmark-projects .counter-stats ul li h5 {
    font-size: 10px;
}
.bookmark-projects .freelance-content {
    padding: 10px;
} 
.bookmark-projects .widget-author .profile-img {
	width: 86px;
    height: 86px;
	padding: 0;
	border: 1px solid #C4C4C4;
	background: transparent;
	margin-bottom: 5px;
}
.bookmark-projects .widget-author .author-location a {
    font-size: 16px;
    margin-bottom: 5px;
    text-transform: capitalize;
	color: #E65425;
}
.bookmark-projects .freelance-info h3 a {
	font-size: 12px;
	color: #6E727D;
	font-weight: 400;
}
.bookmark-projects .freelance-info h3 {
	line-height: 1.3;
	margin-bottom: 5px;
}
.bookmark-projects .post-now i {
	font-weight: 400;
}
.bookmark-projects .freelance-tags {
    margin-bottom: 0px;
}
.book-mark .badge-design {
    background: #F4F7FF;
}
.book-mark .badge-design:hover {
    background: #01243F;
	color: white;
}
.bookmark-projects .freelance-tags span {
    margin-bottom: 5px;
}
.bookmark-projects .freelancers-price {
	margin: 15px 0px;
	color: #55545B;
}
.bookmark-projects .counter-stats {
    margin-top: 0px;
}
.bookmark-projects .counter-stats ul li h5 {
	margin-bottom: 3px;
}
.book-mark .btn-cart {
    padding: 10px 15px;
    font-size: 14px;
}
.list-book-mark .author-details {
    margin-left: 108px;
}
.media-body .notification-btn {
    justify-content: start;
}
.list-develop-group {
	margin-bottom: 20px;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
}
.book-mark .rating .average-rating {
	font-weight: 400;
}
.book-mark .list-pagination {
	margin-top: 20px;
}
.favourite-group .card-header {
	-webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

/*-----------------
	38. Project Details
-----------------------*/

.post-job-icon {
	margin-left: auto;
	width: 34px;
	height: 34px;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
	display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.blue-color {
	background: #3398FB;
}
.orange-color {
	background: #E65425;
}
.pink-color {
	background: #F5365C;
}
.green-color {
	background: #5BB054;
}
.project-details-view .pro-post-head p {
	color: #4E4E4E;
	font-size: 14px;
}
.project-details-view .pro-post-head h6 {
	color: #131135;
	font-size: 17px;
	font-weight: 600;
}
.project-details-view .job-type {
	text-align: left;
	width: 100%;
	margin-bottom: 30px;
	border: 1px solid #efefef;
}
.project-duration .job-type {
	margin-bottom: 25px;
}
.project-duration .pro-content {
	padding-top: 0;
}
.project-details-view .widget-box {
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
	border: 0;
	padding: 15px;
}
.requirement-img {
	position: relative;
}
.file-name {
	position: absolute;
    bottom: 10px;
	left: 10px;
}
.file-name h4,
.file-name h5 {
	font-size: 14px;
	font-weight: 400;
}
.file-name h4 {
	color: #000;
}
.file-name h5 {
	color: #A3A3A3;
}
.project-details-view .pro-post .pro-title,
.project-client-view .pro-post .pro-title {
	border-bottom: 0;
	text-transform: uppercase;
	font-size: 17px;
}
.project-details-view .job-type  {
	border-radius: 5px;
}
.project-client-view .pro-post {
	border: 1px solid #E8E8E8;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
}
.client-about .pro-post {
	border: 0;
	box-shadow: 0px 4px 34px rgba(215, 215, 215, 0.25);
	border-radius: 5px;
}
.client-about .client-list {
	margin-bottom: 25px;
}
.link-project .pro-title {
	margin-bottom: 26px;
}
.project-client-view .widget-author .author-location a {
    font-size: 22px;
    color: #E65425;
    margin-bottom: 5px;
    text-transform: capitalize;
    font-weight: 500;
}
.project-client-view .freelance-info h4 {
	color: #6E727D;
	font-size: 14px;
	font-weight: 500;
}
.project-client-view .rating i {
    color: #6E727D;
}
.project-client-view .rating i.filled {
    color: #febe42;
}
.project-client-view .rating .average-rating {
	font-weight: 400;
}
.project-client-view .rounded-pill {
	    padding: 8px 20px;
		font-size: 18px;
}
.project-client-view .follow-details {
	margin: 19px 0px;
}
.pro-member h6 {
	font-size: 15px;
	font-weight: 600;
}
.pro-member span {
	font-weight: 500;
}
.project-verified i {
	color: #00cc52;
    background: #fff;
    border-radius: 50%;
}
.project-verified {
	position: absolute;
    bottom: 0;
    right: 5px;
}
.project-client-view .widget-author .profile-img {
    box-shadow: none;
    background-color: transparent;
    padding: 0;
    border-radius: 50%;
	border: 1px solid #C4C4C4;
    width: 86px;
    height: 86px;
    margin: 0 auto 15px;
}
.project-details-view .widget-box .badge-design {
	background: rgba(255, 91, 55, 0.1);
	color: #E65425;
	padding: 10px;
	margin-right: 15px;
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.project-details-view .widget-box .badge-design:hover {
	background: rgba(255, 91, 55, 0.3);
	transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
}
.client-about .client-list {
	width: 100%;
}

/*-----------------
	38. Onboard Screen
-----------------------*/
.btn-get {
	font-weight: 900;
	font-size: 24px;
	color: #FFF;
	text-transform: uppercase;
	min-width: 300px;
	border-radius: 50px;
	padding: 16px;
  }
  
  .multistep-form {
	background: #F9F9F9;
	border-radius: 10px;
  }
  .multistep-form .on-board {
	display: none;
	width: 100%;
  }
  .multistep-form .on-board .form-focus.focused .focus-label {
	z-index: 9;
  }
  .multistep-form .group-select {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
  }
  .multistep-form > .card-body {
	padding: 50px;
  }
  .multistep-form .acc-title {
	color: #3C00F2;
	margin-bottom: 40px;
	font-weight: 900;
	font-size: 36px;
	text-align: center;
  }
  .multistep-form .input-block {
	margin-bottom: 15px;
	width: 100%;
  }
  a.link-danger {
    color: #FF3B30;
    font-size: 16px;
    font-weight: 600;
    display: block;
    margin-bottom: 35px;
}
  img.checks-set {
    position: absolute;
    right: 10px;
    margin: 0;
    top: 10px;
}
.onboard-head a {
    position: relative;
}
.multistep-form .form-control {
	height: 40px;
}
.multistep-form label {
	font-size: 14px !important;
	color: #131135 !important;
	font-weight: 600;
}
.workin-check {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.workin-check .form-check-input {
	border: 1px solid #FFE6E1;
	width: 25px;
	height: 25px;
	margin-right: 15px;
}
.workin-check .form-check-input:focus {
	box-shadow: none;
}
.form-switch .form-check-input {
	cursor: pointer;
}
.on-board .form-focus.focused .focus-label {
	z-index: 99999;
}
.account-onborad {
	max-width: 700px;
	text-align: center;
	margin: 0 auto;
}
.account-onborad a {
	font-weight: 900;
	font-size: 24px;
	color: #8A2BE2;
	display: inline-block;
}
.account-onborad.complte-board {
	padding: 80px;
}
.onboard-head h2 {
	font-size: 28px;
	font-weight: 600;
	margin-bottom: 8px;
}
.onboard-head h2 span {
	color: #8A2BE2;
}
.onboard-head p {
	font-size: 16px;
	color: #6B7385;
	font-weight: 500;
	margin-bottom: 30px;
}
.onboard-head a {
	padding: 21px;
	width: 200px;
	height: 200px;
}
.back-home img {
	margin-bottom: 20px;
}
.back-home h3 {
	font-size: 18px;
	color: #081733;
	margin-bottom: 20px;
}
.check-radio ul{
	list-style: none;
}
.custom_radio input:checked ~ .checkmark {
    border-color: #E65425;
}
.back-home .btn-primary {
	background: #71d3a9;
	border-radius: 5px;
	color: #fff;
	font-size: 16px;
	min-width: 177px;
}
.back-home h2 {
	font-size: 32px;
    color: #000000;
    font-weight: 700;
	margin-bottom: 20px;
}
.account-onborad .link-danger{
	color:#FF3B30;
	font-size: 16px;
	font-weight: 600;	
	margin-top: 20px;
}
.back-home p {
	font-size: 16px;
	color: #343434;
	font-weight: 400;
	margin: 0;
}
.multi-step {
	width: 40px;
	height: 40px;
	background: #E8E8E8;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-radius: 50%;
	color: #000000;
	font-weight: 700;
	font-size: 14px;
	margin-right: 15px;
	line-height: 0;
}
.multistep-progress {
	background: #FFFFFF;
	box-shadow: 0px 0px 50px rgba(132, 39, 225, 0.2);
	border-radius: 50px;
	padding: 10px 10px 0;
	margin: 0 auto 50px;
	max-width: 1046px;
	width: fit-content;
}
.progressbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: auto;
	margin-top: 0;
}
.progressbar li {
	display: flex;
	align-items: center;
	margin: 0;
	position: relative;
}
.progressbar li::after {
	content: "";
	position: absolute;
	width: 90px;
	height: 1px;
	border: 1px dashed #CED4E6;
	left: 60px;
	top: 50%;
	transform: translateY(-50%);
}
.progressbar li:last-child::after {
	display: none;
}
.progressbar li h5 {
	margin-bottom: 0;
	font-size: 16px;
	font-family: "Inter", sans-serif;
	color: #1B1B1B;
	font-weight: 500;
}
.progress-active .multi-step {
	background: #71D3A9;
	color: #fff;
}
.progress-active .multi-step img {
	filter: brightness(100) blur(0);
}
.progress-active .steps-count {
	display: block;
}
.progress-active::after {
    left: auto !important;
    width: 50px !important;
    right: -70px;
}
.progress-activated .multi-step {
	background: #E65425;
	font-size: 0;
	color: #fff;
}
 .select2-container--default .select2-selection--single .select2-selection__arrow b {
    position: absolute;
    top: 50%;
    left: 50%;
    border-color: #637381;
    border-style: solid;
    border-width: 0 1px 1px 0;
    padding: 3px;
    height: 0;
    margin-left: -10px;
    margin-top: -3px;
    width: 0;
    transform: rotate(45deg) translateY(-50%);
    -webkit-transform: rotate(45deg) translateY(-50%);
    -ms-transform: rotate(45deg) translateY(-50%);
}
 .select2-container .select2-selection--single {
    border: 1px solid #CED4E6;
    height: 40px;
    outline: 0;
}
 .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #353535;
    font-size: 16px;
    font-weight: normal;
    line-height: 40px;
    font-weight: 500;
}
 .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: #000;
    border-width: 2px 0 0 2px;
    margin-top: 3px;
    padding: 3px;
}
.media-set{
	/* border-bottom: 1px solid #E7EBF7; */
	padding: 15px 0;
}
  .progress-activated .multi-step img {
	filter: brightness(100) blur(0);
  }
  .progress-activated h5 {
	color: #E65425;
  }
  
  .progress-25 {
	display: block;
  }
  
  .progress-50, .progress-75, .progress-100 {
	display: none;
  }
  
  .progress-50 .progress-bar {
	background: #159C5B;
	width: 50%;
  }
  
  .progress-75 .progress-bar {
	background: #159C5B;
	width: 75%;
  }
  
  .progress-100 .progress-bar {
	background: #159C5B;
	width: 100%;
  }
  
  .circle-bar1 {
	height: 55px;
	margin-bottom: 10px;
  }
  
  .circle-graph1 {
	height: 55px;
  }
  
  .circle-bar > div {
	display: inline-block;
	position: relative;
	text-align: center;
  }
  .circle-bar > div p {
	left: 0;
	position: absolute;
	top: 50%;
	right: 0;
	text-align: center;
	margin: 0 auto;
	transform: translateY(-50%);
	font-weight: 500;
	font-size: 14px;
	color: #19D1AF;
  }
  .circle-bar > div canvas {
	width: 55px !important;
	height: 55px !important;
  }
  
  .circle-percent-0 p {
	color: #8B8E97 !important;
  }
  
  .dropzone {
	background: #FFFFFF;
	border: 1px dashed #8A2BE2;
	border-radius: 10px;
	min-height: 130px;
	text-align: center;
  }
  .dropzone p {
	font-size: 16px;
	color: #8A2BE2;
  }
  .dropzone span {
	display: none;
  }
  
  .btn-prev {
	border: 0;
	/* background: url(../img/icons/arrow-left.svg) no-repeat; */
	padding-left: 30px;
	font-weight: 900;
	font-size: 24px;
	background-position: left;
	margin-right: 30px;
  }
  
  .field-card {
	padding: 30px;
  }
  
  .acc-title-01 {
	display: block;
  }
  
  .acc-title-02, .acc-title-03, .acc-title-04, .acc-title-05 {
	display: none;
  }
  
  .account-wrap .header {
	box-shadow: none;
	background: transparent;
  }
  .account-wrap .page-wrapper {
	padding-top: 0;
	height: 100%;
  }
  .account-wrap .page-wrapper > .content {
	padding: 30px;
	height: 100%;
  }
  .account-wrap .acc-content {
	height: 100%;
	background: #F9F9F9;
	border-radius: 10px;
  }
  
  .board-screen .content {
	padding: 0;
  }
  .board-screen .multistep-progress {
	width: 100%;
	max-width: 100%;
	margin: 0;
	padding: 15px;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
	border-radius: 0;
  }
  .board-screen .progressbar {
	margin-bottom: 0;
  }
  .board-screen .multistep-form {
	background: #fff;
  }
  
  .first-progress,
  .second-progress {
	height: 53px;
  }
  
  .select-account {
	padding: 80px 0px;
  }
  .select-account .pro-form-img .btn-primary {
	min-width: 191px;
	background: transparent;
	border: 1px solid #FFE6E1;
	color: #E65425;
	padding: 12px 15px;
	font-size: 16px;
  }
  .select-account .pro-form-img .profile-pic {
	width: 100px;
	height: 100px;
	border: 1px dashed #C2C2C2;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	margin-right: 20px;
  }
 .btn.prev_btn {
    min-width: 107px;
    padding: 10px 10px 10px 20px ;
    font-size: 16px;
    text-transform: capitalize;
    background-color: #081733;
    border-radius: 5px;
    color: #fff;
	margin-right: 15px;
	background-image: url(../img/icon/arrow-left.svg) ;
	background-repeat: no-repeat;
	background-position: 15% 50%;
  }
  .btn.prev_btn:hover{
	background-color: #71D3A9;
	color: #fff;
  }
  .media-set h4 {
    font-size: 22px;
    color: #000000;
	margin-bottom: 24px;
}
.multistep-form label.custom_radio{
	font-weight: 400;
}
  .on-board .cal-icon:after {
	content: "\e927";
	font-family: 'feather' !important;
	color:#6E727D;
  }
  .select-account .btn-primary {
	min-width: 107px;
	padding: 9px 20px 9px 10px;
	font-size: 16px;
	text-transform: capitalize;
	background: #71d3a9;
	border-radius: 5px;
	font-weight: 600;
	background-image: url(../img/icon/arrow-right.svg) ;
	background-repeat: no-repeat;
	background-position: 85% 50%;
  }
  .select-account .btn-primary:hover{
	background-color:#01243f ;
	border-color:#01243f ;
	color: #fff;
  }
  .select-box {
	margin-bottom: 40px;
  }
  .select-box .frelance-img:first-child {
	margin-right: 30px;
  }
  .select-box input {
	margin: 0;
	padding: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
  }
  .select-box input:active + .employee-level {
	opacity: 0.9;
  }
  .select-box input:checked + .employee-level {
	border: 1px solid #159C5B;
  }
  .select-box .employee-level:first-child {
	margin-right: 25px;
  }
  .upload-sets {
    background: #EEF3FA;
    padding: 24px;
    text-align: center;
    margin-bottom: 10px;
}
label.upload-filesview {
    position: relative;
    background: #081733;
    color: #fff !important;
    width: 130px;
    height: 40px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 14px;
}
label.upload-filesview input {
    position: absolute;
    opacity: 0;
    left: 0;
    width: 130px;
    height: 40px;
}
.upload-sets h6 {
    font-size: 16px;
    color: #6B7385;
    margin: 0;
}
  .personal-info {
	background: #FFFFFF;
	max-width: 1050px;
	margin: auto;
	margin-bottom: 40px;
	padding: 30px;
  }
  .personal-info .form-control {
	border-color: #CED4E6;
  }
  .personal-info .form-control::placeholder {
	color: #888888;
  }
  .personal-info label {
	color: #343434;
	font-size: 16px;
  }
  .personal-info label span {
	color: #FF0000;
  }
  
  .space-info {
	padding-bottom: 5px;
  }
  
  .pro-form-img {
	margin-bottom: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
  }
  .pro-form-img span {
	display: block;
	font-size: 14px;
	color: #6B7385;
  }
  .pro-form-img img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	margin-right: 15px;
  }
  .pro-form-img .file-upload {
	margin: 0;
	min-width: 170px;
	text-align: center;
  }
  .pro-form-img label.file-upload.image-upbtn {
	background: #71d3a9;
	color: #fff !important;
	border: 1px solid #71d3a9;
	width: 150px;
  }
  
  .min-characters p {
	color: #888888;
	margin-bottom: 0;
	margin-top: 5px;
  }
  .min-characters .form-control {
	height: auto;
  }
  
  .select-level {
	width: 100%;
	color: #888888;
	position: relative;
	background-image: url(../../assets/img/icon/icon-18.svg);
	background-repeat: no-repeat, no-repeat;
	background-position: 95%;
  }
  
  .select-edu {
	background-position: 88%;
  }
  
  .new-addd {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	margin-top: 10px;
  }
  .new-addd .add-new {
	margin-bottom: 0;
	color: #FF3B30;
	margin-left: 7px;
	cursor: pointer;
	font-size: 16px;
	font-weight: 500;
  }
  .btn-item .btn + .btn{
	margin-left: 15px;
  }
  .new-addd .add-new i {
	font-size: 12px;
	margin-right: 5px;
  }
  .new-addd .remove_row {
	color: #FF0000;
  }
  
  .remove_row {
	cursor: pointer;
  }
  
  .employe-cal-icon:after {
	color: rgba(20, 20, 20, 0.6980392157);
  }
  
  .other-info-list ul {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
  }
  .other-info-list ul li {
	width: 50px;
	height: 50px;
	background: #C4C4C4;
	border-radius: 50px;
	list-style: none;
	color: #fff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 12px;
	font-weight: 600;
	margin-right: 26px;
	cursor: pointer;
  }
  .other-info-list ul .active-hour {
	background: #34C759;
  }
  .multistep-form .media-set .input-block {
    margin-bottom: 15px;
    width: 100%;
}
  .time-box .input-block {
	margin-right: 20px;
	max-width: 118px;
  }
  
  .check-hour {
	display: none;
  }
  
  .employee-level {
	cursor: pointer;
	background-size: contain;
	background-repeat: no-repeat;
	display: inline-block;
	width: 200px;
	height: 200px;
	border: 1px solid #FFE6E1;
	border-radius: 5px;
	margin-bottom: 0;
	font-weight: 600;
  }
  .employee-level span {
	color: #343434;
	font-size: 14px;
	font-weight: 600;
  }
  .employee-level img {
	margin-bottom: 20px;
  }
  
  .free-lance-img {
	margin-right: 25px;
  }
  
  .select-btn {
	padding: 80px 0px;
  }
  
  .work-check {
	margin-bottom: 0;
  }
  .work-check .form-check-input {
	width: 39px;
	height: 22px;
	position: absolute;
	right: 0;
    top: -3px;
  }
  .work-check .form-check-input:checked {
	background-color: #71d3a9;
	border-color: #71d3a9;
	right: 0;
    top: -3px;
  }
  .work-check .form-check-input:focus {
	box-shadow: none;
  }
  
  .input-block.working-position {
	position: relative;
	margin-bottom: 0;
  }
  
  label.file-upload {
	position: relative;
	overflow: hidden;
	color: #E65425;
	font-size: 15px !important;
	border: 1px solid #ffbe9d;
	border-radius: 5px;
	padding: 8px 6px;
  }
  label.file-upload input[type=file] {
	position: absolute;
	top: 0;
	right: 0;
	min-width: 100%;
	min-height: 100%;
	font-size: 100px;
	text-align: right;
	filter: alpha(opacity=0);
	opacity: 0;
	outline: none;
	display: block;
  }
  
  .steps-count {
	display: none;
  }
  .steps-count span {
	font-family: "Inter", sans-serif;
	color: #6B7385;
	margin-bottom: 0;
}
.project-list-card .badge-design {
	border-radius: 4px;
}
.project-list-card .tags {
	display: flex;
    gap: 14px;
    flex-wrap: wrap;
}





































































































.section-header-four.section-locate {
	justify-content: center;
}
.section-header.section-header-four {
	margin-bottom: 40px;
}
.browse-categories .popular-catergories {
    flex-direction: column;
    justify-content: center;
	margin-bottom: 0;
}
.browse-categories .popular-catergories .popular-catergories-img{
	margin-bottom: 14px;
}
.browse-categories .popular-catergories-content {
	margin-left: 0;
}
.browse-categories .popular-catergories-content a {
	justify-content: center;
}
.browse-categories-content {
	margin-bottom: 40px;
}
.home-four-viewall {
	display: flex;
	justify-content: center;
}
.home-four-viewall-btn {
	border-radius: 6px;
	color: #fff;
	border: 1px solid #E65425;
	background-image: -webkit-linear-gradient(30deg, #E65425 50%, #fff 50%);
	background-image: linear-gradient(30deg, #E65425 50%, #fff 50%);
	background-size: 500px;
	background-repeat: no-repeat;
	background-position: 0%;
	padding: 10px 24px;
	
}
.home-four-viewall-btn:hover {
	background-position: 100%;
	color: #E65425;
}
.home-four-wrapper .register-job-blk {
	flex-direction: column;
	gap: 40px;
	justify-content: center;
}
.home-four-wrapper .feature-counts .home-four-viewall {
	gap: 10px;
}
.home-four-wrapper .feature-counts .home-four-viewall .home-four-viewall-btn:nth-child(1){
	border: 1px solid #081733;
	background-image: -webkit-linear-gradient(30deg, #081733 50%, #fff 50%);
	background-image: linear-gradient(30deg, #081733 50%, #fff 50%);
}

.home-four-wrapper .feature-counts .home-four-viewall .home-four-viewall-btn:nth-child(2){
	border: 1px solid #fff;
	background-image: -webkit-linear-gradient(30deg, #fff 50%, #081733 50%);
	background-image: linear-gradient(30deg, #fff 50%, #081733 50%);
	color: #081733;
}
.home-four-wrapper .feature-counts .home-four-viewall .home-four-viewall-btn:nth-child(1):hover{
	color: #081733;
	border: 1px solid #fff;
}
.home-four-wrapper .feature-counts .home-four-viewall .home-four-viewall-btn:nth-child(2):hover{
	color: #fff;
	border: 1px solid #081733;
}
.home-four-wrapper .job-content-blk h2 {
	font-size: 40px;
}
.home-four-wrapper .job-content-blk p {
	font-size: 18px;
	font-weight: 600;
}
.home-four-wrapper .project-item.project-item-feature {
    border-radius: 5px;
    box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);
    padding: 24px;
	margin-bottom: 0;
	transition: all 0.5s;
}
.home-four-wrapper .project-item-feature .project-img {
	max-height: 316px;
	border-radius: 0;
	margin-bottom: 24px;
}
.home-four-wrapper .project-img img {
	height: 100%;
	object-fit: cover;
	border-radius: 0;
}
.home-four-wrapper .hour-dollr {
    border-radius: 5px;
    bottom: auto;
    right: auto;
    font-size: 20px;
    font-weight: 600;
    padding: 8px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    top: 10px;
    left: 10px;
    gap: 5px;
	z-index: 9;
}
.home-four-wrapper .hour-dollr small{
	font-size: 14px;
    color: #6B7385;
}
.home-four-wrapper .project-item-feature .project-img::after {
	content: "";
	position: absolute;
	height: 316px;
	width: 100%;
	left: 0;
	top: 0;
	background: rgba(0, 0, 0, 0.4);
}
.home-four-wrapper .feature-project-content {
	padding: 0;
	font-weight: 400;
	font-size: 12px;
  }
  
  .cal-icon {
	position: relative;
	width: 100%;
  }
  
  .cal-icon:after {
	content: "\f073";
	display: block;
	font-family: "Font Awesome 5 Free";
	font-size: 16px;
	font-weight: 400;
	margin: auto;
	position: absolute;
	right: 15px;
	top: 10px;
  }
  .home-four-wrapper .project-item.project-item-feature:hover {
	transform: translateY(-10px);
	box-shadow: 0px 16px 24px 0px rgba(142, 141, 208, 0.12);
}
.home-four-wrapper .project-item.project-item-feature:hover .hour-dollr.develop-dollr {
	color: #fff;
	background: #E65425;
}
.home-four-wrapper .project-item.project-item-feature:hover .hour-dollr.develop-dollr small {
	color: #fff;
}
.developer-detail-card {
	display: flex;
	justify-content: space-between;
	padding-bottom: 14px;
	border-bottom: 1px solid #EBEBEB;
	margin-bottom: 14px;
}
.developer-detail-card h4 {
	font-size: 22px;
	color: #353535;
	margin-bottom: 5px;
}
.developer-detail-card p {
	color: #6B7385;
}
.bookmark-check i {
	font-size: 24px;
	transition: all .5s;
}
.bookmark-check i.fa-regular {
	color: #6B7385;
}
.bookmark-check i.fa {
	color: #E65425;
}
.rate-block {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.rate-block a {
	width: 30px;
    height: 30px;
    border: 1px solid #EBEBEB;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 18px;
	color: #808080;
}
.rate-block a:hover {
	background: #E65425;
	color: #fff;
}
#dev-slider .owl-stage-outer {
	padding: 0 0 40px 0;
}
.feaure-for-developer .section-header-four.section-locate {
	justify-content: flex-start;
}
.row-gap {
	row-gap: 24px;
}
.feaure-for-developer .home-four-viewall-btn {
	background-image: linear-gradient(30deg, #E65425 60%, #fff 50%);
	margin-top: 25px;
}
.feaure-for-developer .home-four-viewall-btn:hover {
    background-position: 131%;
}
.market-list-out li i {
	margin-right: 8px;
	color: #E65425;
}
.banner-note {
	background: #fff;
	padding: 14px 25px;
	box-shadow: 0px 4px 108px 0px rgba(153, 153, 153, 0.10);
	border-radius: 10px;
	display: inline-block;
	margin-bottom: 30px;
}
.freelance-favourites .widget-author .profile-img{
	width: 95px;
	height: 95px;
	position: relative;
	box-shadow: none;
	border: 1px solid #CED4E6;
}
.freelance-favourites .freelance-content{
	padding: 0 ;
}
.freelance-favourites .widget-author .author-location,
.freelance-favourites .freelance-info h3 {
	margin-bottom: 5px;
}
.freelance-favourites .counter-stats ul {
	row-gap: 14px;
}
.freelance-favourites .widget-author {
	margin-bottom: 0;
	box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);
}
.freelance-favourites .counter-stats {
	margin-bottom: 24px;
}
.freelance-favourites .freelance-tags {
	margin-bottom: 14px;
    padding-bottom: 14px;
}
.freelance-favourites .freelance-content::after {
	height: 110px;
}
.freelance-top-content {
	align-items: center;
	justify-content: space-between;
	padding: 16px 12px;
}
.freelance-top-content span {
	color: #6B7385;
	font-size: 14px;
}
.freelance-top-content .favourite {
	position: inherit;
}
.paginations.list-pagination {
	margin-top: 30px;
	border: none;
	display: flex;
    align-items: center;
	justify-content: center;
}
/* .paginations li {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	display: flex;
    align-items: center;
    justify-content: center;
} */
.paginations li a {
	padding: 0;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	display: flex;
    align-items: center;
    justify-content: center;
	overflow: hidden;
	font-weight: 500;
}
.paginations li a.active {
	display: flex;
	background: #71D3A9;
    color: #fff;
}
.paginations li:first-child a {
	margin-right: 10px;
}
.paginations li:last-child a {
	margin-left: 10px;
}
.paginations li:first-child a,
.paginations li:last-child a{
	border: 1px solid #CED4E6;
}
.review-card {
    border-radius: 10px;
    border: 1px solid #CED4E6;
    background: #FFF;
    padding: 20px;
	margin-bottom: 20px;
}
.review-card:last-child {
	margin-bottom: 0;
}
.review-card h5 {
	margin-bottom: 8px;
}
.review-card p {
	color: #6B7385;
	margin-bottom: 14px;
}
.reviewer-img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	overflow: hidden;
}
.reviewer-details .rating {
    padding-right: 14px;
    border-right: 1px solid #EBEBEB;
}
.reviewer-details h6 {
    font-weight: 600;
}
.reviewer-details .d-flex {
	gap: 14px;
	align-items: center;
}
.reviewer-details-block {
    display: flex;
    gap: 20px;
    align-items: center;
}
.reviewer-details-block .rating i {
	margin-right: 0;
}
.reviewer-log {
    color: #808080;
	font-size: 14px;
}
.reviewer-log i {
    margin-right: 5px;
}
.portfolio-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
}
.btn-primary.title-btn {
	padding:8px 15px;
}
.btn-primary.title-btn i{
	margin-right: 4px;
}
.portfolio-page .project-widget {
    margin-bottom: 0;
    border-radius: 10px;
    border: 1px solid #CED4E6;
    background: #fff;
    box-shadow: 0px 8px 16px 0px rgba(142, 141, 208, 0.12);
    padding: 14px;
}
.portfolio-page .portfolio-img {
	border-radius: 8px;
	margin-bottom: 14px;
}
.portfolio-page .portfolio-detail {
	padding: 0;
}
.portfolio-page .port-icon {
	color: #6B7385;
}
.portfolio-page .portfolio-img .portfolio-content {
	display: flex;
    gap: 10px;
}
.portfolio-page .portfolio-detail .pro-name {
	font-size: 18px;	
	color: #1B1B1B;
}

  /*-----------------------
		Home 2
------------------------*/

body.bg-two {
	color: #6B7385;
}
.top-header {
	background: #081733;
	padding: 13px 0;
}
.top-head-items {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.top-header .user-menu {
	float: unset;
}
.top-header .user-menu.nav > li > a {
	line-height: normal;
	height: auto;
}
.header-two.sticky .top-header {
	display: none;
}
.header .top-header .has-arrow .dropdown-toggle:after {
	border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
	margin-left: 5px;
}
.top-header .dropdown-menu.show {
	margin-top: 10px !important;
}
ul li {
	list-style-type: none;
}
.top-header-links {
	display: flex;
	align-items: center;
}
.top-header-links li ul {
	display: flex;
	align-items: center;
}
.header.header-two .header-navbar-rht li:first-child:after {
	display: none;
}
.top-header-links > li {
	margin-right: 15px;
	padding-right: 15px;
	border-right: 1px solid #EBEBEB;
}
.top-header-links > li:last-child {
	margin-right: 0;
	padding-right: 0;
	border-right: none;
}
.top-header-links {
	margin-bottom: 0;
	line-height: normal;
}
.top-header-links li ul li a i {
	color: #EBEBEB;
}
.top-header-links li a{
	color: #EBEBEB;
}
.top-header-links li ul li {
	margin-right: 8px;
}
.top-header-links li ul li:last-child {
	margin-left: 35px;
}
.top-header-links > li + li a i {
	margin-right: 5px;
}
.header-select {
	display: flex;
	align-items: center;
}
.header-select .navbar-brand {
	flex-shrink: 0;
	margin-right: 24px;
}
.header-select .select2-container .select2-selection--single .select2-selection__rendered {
	padding-left: 30px;
}
.header-select .input-group img {
	position: absolute;
	z-index: 9;
	top: 12px;
	left: 7px;
}
.header-select .select2-container {
	width: 145px !important;
}
.home-banner.home-two {
	margin-top: 110px;
	padding: 100px 0 130px;
}
.banner-two-img {
	position: relative;
}
.banner-two-img .best-marketing-img {
	position: absolute;
	left: 30px;
    max-width: 130px;
    top: 30px;
}
.banner-two-img .trusted-user-img {

}
.market-place h3 {
	background: #fff;
	font-size: 18px;
	padding: 14px 25px;
	display: inline-flex;
	border-radius: 10px;
	margin-bottom: 25px;
}
.home-count ul li {
	position: relative;
}
.home-count ul {
	justify-content: space-between;
}
.home-count ul li+li::before {
	content: "";
	width: 12px;
	height: 12px;
	border-radius: 12px;
	background: #71d3a9;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translatey(-50%);
}
.home-two-projects .project-item.feature-project-item {
	box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);
	padding: 24px;
}
.home-two-projects .feature-time-blk .btn-primary {
	padding: 8px 10px;
	min-width: auto;
}
.home-two-projects .feature-time-blk .green-active {
	background: #EBF9EE;
	border-color: #EBF9EE;
	color: #34C759;
}
.home-two-projects .feature-time-blk .sky-active {
	background: #EEF3FA;
	border-color: #EEF3FA;
	color: #529CFF;
}
.btn-primary {
	background: #71d3a9;
	border-color: #71d3a9;
}
.section.feature-developer {
	background: #FFF8F5;
}
.hour-rate ul li {
	color: hsl(222, 11%, 47%);
	font-size: 15px;
}
.home-two-developers .img-developer img {
	width: 70px;
	height: 70px;
}
.home-two-developers .developer-content p {
	color: #6B7385;
}
.home-two-developers .developer-content h4 {
	font-size: 18px;
}
.home-two-developers .rating i.filled {
	color: #FF9C07;
}
.home-two-developers .developer-sub-blk {
	padding: 0 0 14px;
}
.home-two-developers .hour-rate ul {
	padding: 14px 0 0;
}
.home-two-developers .feature-develop-blk {
	padding: 20px;
	box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);
	border: 0;
	position: relative;
	top: 0;
	border: 1px solid transparent;
	transition: all 0.3s;
}
.home-two-developers .feature-develop-blk:hover{
	position: relative;
	top:-10px;
	border: 1px solid #71d3a9;
}
.home-two-developers .rating .average-rating {
	margin: 0 5px;
}
.home-two-developers .review-bottom p {
	color: #1B1B1B;
}
.home-two-developers .review-bottom {
	margin-top: 20px;
}
.home-two-developers .client-rate {
	margin-bottom: 0;
}
.section.top-skill {
	background: #fff;
}
.skills-feature {
	display: flex;
	align-items: center;
	margin-bottom: 24px;
}
.skill-custom-col {
	width: 20%;
	float: left;
}
.skills-feature .skill-icon {
	padding: 10px;
}
.skills-feature  span {
	font-weight: 600;
	color: #6B7385;
}
.great-blk {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	transition: all 0.5s;
}
.great-blk:hover .great-icon{
	background: #71d3a9;
	transform: scale(1.1) rotate(360deg);
}
.great-blk:hover .great-icon img{
	filter: brightness(0) invert(1);
}
.about-project {
	min-height: 240px;
}
.section.great-about {
	background: linear-gradient(270.05deg, #FFFFFF -60.55%, #71d3a9 174.03%);
}
.great-about:before {
	background: linear-gradient(270.05deg, #FFFFFF -60.55%, #71d3a9 174.03%);
	right: unset;
	left: 0;
	top: unset;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-position: left bottom;
}
.job-location {
	background: #fff;
	padding-top: 0;
}
.home-two-jobsec .job-locate-blk {
	border-color: #CED4E6;
	box-shadow: 0px 16px 24px 7px rgba(142, 141, 208, 0.11);
	padding: 24px;
	text-align: center;
	transition: all 0.3s;
	position: relative;
    top: 0px;
}
.home-two-jobsec .job-locate-blk:hover{
	position: relative;
    top: -10px;
	border-color: #71d3a9;
}
.job-it-content {
	margin-top: 15px;
}
.home-two-jobsec .job-locate-foot {
	justify-content: center;
}
.home-two-jobsec .job-it-content h6 {
	color: #1B1B1B;
	font-size: 18px;
}
.home-two-jobsec .job-locate-foot li {
	color: #6B7385;
}
.review-blog.user-review .review-img {
	text-align: center;
}
.review-blog .review-content p {
	max-width: 320px;
	margin: auto;
}
.review.review-two {
	background: #FFF8F5;
}
.blog-tricks {
	padding-bottom: 56px;
}
.review.review-two {
	padding-bottom: 56px;
}
.review-two .review-blog {
	margin-bottom: 24px;
} 
.blog-tricks .grid-blog {
	margin-bottom: 24px;
}
.top-company-two .company-logos {
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);
	border: 0;
	border-radius: 10px;
	padding: 0;
	height: 80px;
	margin: 10px 0 ;
}
.feature-time-blk .badge.bg-pink {
	background: #FFEBE0;
	color: #1B1B1B;
	padding: 10px;
}
.feature-time-blk span i.feather-tag {
	display: inline-block;
	transform: rotatey(180deg);
}
.job-register-two {
	position: relative;
}
.job-register-two .job-right-bg {
	position: absolute;
	right: 30px;
	top: 0;
	max-width: 200px;
}
.job-register-two .job-left-bg {
	position: absolute;
	left: 100px;
	top: 50px;
}
.footer.footer-two { 
	background: #FBFBFB;
	
}
.footer.footer-two .footer-top {
	padding: 40px 0;
}
.footer-two .footer-title {
	color: #1B1B1B;
}
.footer.footer-two .footer-menu ul li a {
	color: #6B7385;
}
.footer .footer-widget.footer-menu ul.info-contact {
	margin-bottom: 15px;
}
.footer.footer-two .banner-content .form-inner {
	background: transparent;
	border-radius: 0;
	border: 0;
	height: auto;
}
.footer-two .copyright-text p {
	color: #6B7385;
}
.footer.footer-two .banner-content .input-group {
	background: transparent;
	height: 40px;
	align-items: center;
}
.footer.footer-two .banner-content .sub-btn {
	height: 40px;
	border-radius: 6px !important;
	margin-left: 15px !important;
}
.footer.footer-two .banner-content .form-control {
	background: transparent;
	border: 1px solid #CED4E6;
	height: 40px;
	min-height: 40px;
	border-radius: 6px !important;
}
.more-project .btn-primary:hover,.market-place-btn .btn-primary:hover {
	color: #fff;
}

/*-----------------------
	Home 3
------------------------*/
.header-three .top-header {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #E65425;
}
.header-three .input-group img{
	filter: blur(0) brightness(100);	
}
.header-three.sticky .input-group img{
	filter: none;	
}
.header.header-three.sticky .select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #999;
    margin-left: -3px;
}
.header-three .top-header p {
	color: #fff;
	margin-bottom: 0;
	font-weight: 600;
	display: flex;
	align-items: center;
	position: relative;
}
header.header-three.sticky .top-header {
	display: none;
}
.header-three .top-header a {
	color: #fff;
	position: absolute;
	right: 20px;
}
.header-three .top-header p img {
	margin-right: 8px;
}
.header-three .top-header h5 {
	color: #fff;
	font-size: 18px;
	margin-bottom: 0;
	margin-left: 10px;
}
.header.header-three {
	background: transparent;
	border: 0;
    border-bottom: 1px solid rgba(235, 235, 235, 0.5);
    box-shadow: none;
}
.home-three .slick-list{
	height:700px !important;
}
.header.header-three.sticky {
	background: #fff;
}
.home-three .dot-slider.slick-vertical .slick-slide {
    height: 700px;
	border: 0px solid transparent;
}
.header.header-three .navbar-header {
	display: flex;
	align-items: center;
}
.header.header-three .navbar-header .navbar-brand {
	flex-shrink: 0;
}
.header.header-three .select2-container--default .select2-selection--single {
	background: transparent;
}
.header.header-three .select2-container--default .select2-selection--single .select2-selection__rendered {
	color: #fff;
} 
.header.header-three .select2-container .select2-selection--single {
	border: 0;
}
.header.header-three .select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #fff;
}
.header.header-three .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #fff;
}
.header.header-three .input-group {
	position: relative;
	margin-left: 25px;
    border-left: 1px solid rgba(235, 235, 235, 0.5);
}
.header.header-three .input-group img {
	position: absolute;
	top: 13px;
    left: 10px;
}
.header-three .header-nav {
	padding: 0;
}
.header-three.sticky .select2-container .select2-selection--single {
    border: 1px solid #CED4E6 !important;
    height: 40px;
    outline: 0;
}
.header.header-three.sticky .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #2e2e2e;
}
.header.header-three .select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-color: #fff;
    margin-left: -3px;
}
.home-three .banner-kofe h5 {
	background: #fff;
	color: #E65425;
	padding: 10px 30px;
	display: inline-flex;
	font-size: 16px;
	border-radius: 6px;
	margin-bottom: 20px;
}
.home-three .banner-content h1 {
	font-size: 40px;
	max-width: 500px;
}
.home-three .banner-content h1 span {
	color: #FE4A23;
}
.home-three .banner-content p {
	color: #fff;
	max-width: 600px;
}
.banner-join-users {
	display: flex;
	align-items: center;
	margin-top: 40px;
}
.banner-join-users ul {
	display: flex;
	align-items: center;
	margin-bottom: 0;
}
.banner-join-users ul li {
	margin-left: -15px;
}
.banner-join-users ul li:first-child {
	margin-left: 0;
}
.banner-join-users ul li a img {
	width: 50px;
	height: 50px;
	display: inline-block;
	border-radius: 50%;
	border: 3px solid #fff;
}
.banner-join-users .join-user-content {
	color: #fff;
	margin-left: 20px;
}
.banner-join-users .join-user-content span {
	font-size: 14px;
}
.section-header-three {
	justify-content: center;
	text-align: center;
}
.section-header-three .title-badge {
	color: #E65425;
	font-size: 16px;
	display: inline-flex;
	background: #FFF6F1;
	padding: 10px 20px;
	border-radius: 60px;
	font-weight: 600;
	margin-bottom: 10px;
}
.different-categories-blk {
	padding: 24px;
	box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);
	border-color: #CED4E6;
	position: relative;
}
.different-categories-blk .differ-img {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 65px;
	height: 65px;
	background: #FE4A23;
	border-radius: 5px;
}
.differ-img-right img{
	position: absolute;
	right: 5px;
	top: 5px;
	max-width: 100px;
}
.different-categories-blk .differ-content h6 {
	color: #1B1B1B;
	font-size: 20px;
	font-weight: 600;
}
.different-categories-blk .category-foot span {
	color: #1B1B1B;
	font-weight: 600;
	font-size: 16px;
}
.feature-items {
    background: #fff;
    padding: 24px;
    border: 1px solid #CED4E6;
    border-radius: 5px;
	width: 100%;
}
.blog-article-group.job-loc {
    padding: 0;
}
.job-loc .article-blog-content h4 {
    font-size: 16px;
    font-weight: 400;
}
.blog-article-group.job-loc:hover .article-blog-content{
	bottom: 40px;
}
.blog-article-group.job-loc .blog-image:before{
	background: transparent;
}
.review-four .owl-carousel .owl-item .review-img img {
    width: 80px;
}
.job-loc .article-blog-content{
	width: 100%;
	transition: all 0.5s;
}
.job-loc .article-blog-content span {
    font-size: 22px;
    margin: 0;
}
.great-card {
    box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);
    background: #fff;
    padding: 24px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}
.great-card .feature-content {
	margin: 0;
}
.feature-items .feature-icon {
    width: 60px;
    height: 60px;
    background: #71d3a930;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
}
.feature-items h3 {
    font-size: 22px;
}
.section.best-marketplace {
	background: #FFF8F5;
}
.market-place-group.section-header-three {
	justify-content: flex-start;
	text-align: left;
}
.market-place-group.section-header-three .title-badge {
	background: #FFEBE0;
}
.market-place-group.section-header-three h2 {
	font-size: 40px;
	color: #1B1B1B;
	max-width: 540px;
}
.market-place-group p {
	color: #6B7385;
}
.market-list-out li {
	font-size: 16px;
	color: #6B7385;
	max-width: 500px;
	display: flex;
	align-items: baseline;
}
.market-list-out li i {
	color: #E65425;
}
.market-place-btn .market-developer.btn-primary {
	padding: 10px 15px;
	background: #081733;
	color: #fff;
}
.market-place-btn .market-developer.btn-primary:hover {
	background: #71d3a9;
	border-color: #71d3a9;
}
.company-hire .company-logos {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
}
.company-hire {
	background: #081733;
}
.hire-heading.section-header-three .title-badge {
	background: rgba(255, 255, 255, 0.20);
	color: #fff;
	font-size: 16px;
}
.hire-heading.section-header-three h2 {
	color: #fff;
	font-size: 40px;
}
.trend-projects-three .project-item.trend-project-item {
	padding: 0;
	border: 0;
	box-shadow: 0px 4px 108px 0px rgba(90, 90, 90, 0.10);
}
.trend-projects-three .trend-project-item .project-img {
	border-radius: 10px 10px 0 0 ;
}
.trend-projects-three .trend-project-item .project-img img{
	border-radius: 10px 10px 0 0 ;
}
.trend-projects-three .trend-project-content {
	padding: 24px;
}
.trend-projects-three .trend-project-item .project-img {
	margin-bottom: 0;
}
.trend-project-content .star-rated {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
}
.trend-projects-three .trend-project-content h4 {
	font-size: 22px;
	color: #1B1B1B;
}
.trend-projects-three .trend-project-list {
	display: flex;
	align-items: center;
}
.trend-projects-three .trend-project-list li {
	margin: 0;
	color: #6B7385;
}
.trend-projects-three .trend-project-list li + li {
	margin-left: 20px;
}
.trend-projects-three .trend-rate ul {
	align-items: center;
}
.trend-projects-three .trend-rate li span {
	font-size: 22px;
	font-weight: 600;
	color: #081733;
}
.trend-projects-three .trend-rate li a {
	font-size: 16px;
	font-weight: 600;
	color: #E65425;
}
.trend-projects-three .owl-carousel.trend-slider .owl-dots {
	position: relative;
	bottom: 0;
	top: 0;
}
.trend-projects-three .trend-slider.owl-theme .owl-dots .owl-dot.active span {
	background: #E65425;
}
.trend-projects-three .owl-carousel.trend-slider .owl-nav {
	display: none;
}
.section-header-three.feature-count-head .header-title {
	font-size: 40px;
}
.section-header-three.feature-count-head p {
	color: #E7EBF7;
}
.feature-count.feature-count-three {
	background-color: #081733;
    background-image: url(../img/bg-achievement.png);
    background-repeat: no-repeat;
}
.feature-count-three .feature-item {
	box-shadow: 0px 8px 16px 0px rgba(142, 141, 208, 0.12);
	border-bottom: 2px solid #E65425;
	background: #fff;
}
.feature-count-three.feature-count .feature-content h3 {
	color: #1B1B1B;
}
.feature-count-three.feature-count .feature-content p {
	color: #6B7385;
}
.feature-count .feature-item:hover img {
	filter: none;
}
.feature-dev-three .feature-project-content p {
	color: #6B7385;
	font-size: 16px;
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid #EBEBEB;
}
.feaure-project {
	background: #FBFBFB;
}
.feature-dev-three .project-item.project-item-feature {
	padding: 24px;
}
.feature-dev-three .project-img img {
	border-radius: 0;
}
.feature-dev-three .project-img {
	border-radius: 0;
	position: relative;
	overflow: hidden;
}
.feature-dev-three .project-img:hover img {
	transform: scale(1.14);
}
.feature-dev-three .project-img:hover .hour-dollr {
	background: #E65425;
	color: #fff;
	transition: 0.5s all;
}
.feature-dev-three .project-img:hover .hour-dollr span {
	color: #fff;
}
.feature-dev-three .project-img a::after {
	content: "";
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%);
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
}
.feature-dev-three .hour-dollr {
	padding: 8px;
	font-size: 20px;
	font-weight: 600;
	display: flex;
	align-items: center;
	left: 10px;
	top: 10px;
	right: unset;
	bottom: unset;
	transition: 0.5s all;
}
.feature-dev-three .hour-dollr span {
	font-size: 14px;
	color: #6B7385;
	margin-left: 3px;
} 
.feature-dev-three .feature-project-content {
	padding-bottom: 0;
}
.quate {
	margin: 14px 0;
	padding-bottom: 14px;
	border-bottom: 1px solid #CED4E6;
}
.review-blog:hover {
	transform: none;
}
.review-people-three {
	background: #FFF8F5;
	padding-bottom: 56px;
}
.review-people-three .review-blog.user-review {
	border: 0;
}
.review-people-three .review-blog {
	margin-bottom: 24px;
	margin-top: 0;
}
.top-skill-three .skills-feature {
	box-shadow: 0px 4px 4px 0px rgba(209, 209, 209, 0.25);
	background: #fff;
	padding: 15px 0;
}
.section.top-skill-three {
	background: #FBFBFB;
}
.next-job-btn .btn-primary.next-register {
	background: #081733;
	border-color: #081733;
	color: #fff;
}
.entry-meta li {
	margin-right: 10px;
	padding-right: 10px;
	border-right: 1px solid #EBEBEB;
}
.entry-meta li:last-child {
	border: 0;
	margin: 0;
	padding: 0;
}
.blog-text span {
	color: #E65425;
	display: flex;
	align-items: center;
}
.blog-trick .more-option {
	color: #E65425;
	display: flex;
	align-items: center;
	margin-top: 20px;
	font-weight: 600;
}
.blog.blog-trick {
	padding: 0;
	border: 0;
	box-shadow: 0px 4px 108px 0px rgba(90, 90, 90, 0.10);
}
.blog-trick-content-main {
	padding: 0 24px 24px;
}
.blog-trick .blog-image {
	border-radius: 10px 10px 0 0;
}
.blog-trick .blog-image img {
	border-radius: 10px 10px 0 0;
}
.blog-trick-content-main.blog-list-main {
	padding: 15px 24px 15px 0;
}
.blog-trick .blog-list-img.blog-image {
	margin-bottom: 0;
	border-radius: 0;
}
.blog-trick .blog-list-img.blog-image img {
	border-radius: 0;
}
.blog-list-main .post-author.blog-hover-img img {
	width: 32px !important;
	height: 32px;
}
.blog.blog-trick {
	margin-bottom: 10px;
}
.faq-section-three {
	background: #FFF8F5;
	padding: 80px 0 60px;
}
.faq-card {
	background: #fff;
	border: 1px solid #CED4E6;
	padding: 20px;
	margin-bottom: 20px;
	border-radius: 6px;
}
.faq-card h4 {
	margin-bottom: 0;
}
.faq-card h4 a {
	font-size: 18px;
	font-weight: 600;
	display: block;
	width: 100%;
}
.faq-card p {
	color: #6B7385;
	margin: 17px 0 0 ;
	padding-top: 12px;
	border-top: 1px solid #EBEBEB;
}
.faq-title {
	position: relative;
}
.faq-title > a:after {
    font-family: "fontawesome";
    font-weight: 900;
    content: "\f078";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
    color: #1B1B1B;
}
.faq-title > a:not(.collapsed):after {
    content: "\f077";
    color: #FE4A23;
}
.faq-title > a:not(.collapsed) {
	color: #FE4A23;
}
.footer.footer-three {
	background: #101828;
}
.footer-three .footer-social-group .footer-bottom-nav li a {
	color: #CED4E6;
}
.footer-three .footer-title {
	color: #fff;
}
.footer.footer-three  .footer-menu ul li a {
	color: #CED4E6;
}
.footer-three .footer-menu .applicate-mobile-blk h6 {
	color: #fff;
	margin-bottom: 20px;
}
.footer-three .footer-menu p {
	color: #CED4E6;
}
.footer-three .footer-top-blk {
	border-bottom-color: #474747;
}
.footer.footer-three .copyright-text p {
	color: #CED4E6;
}
.footer.footer-three .copyright {
	border-top-color: #474747;
}
.footer.footer-three .social-icon span {
	color: #CED4E6;
}
.footer.footer-three .footer-social-group .social-icon ul li a i{
	color: #fff;
}
.footer.footer-three .footer-social-group .social-icon ul li a i:hover {
	background: #FE4A23;
	opacity: 1;
}


/*-----------------------
	Dashboard
------------------------*/
.dashboard-page .content-page {
	padding: 115px 0 30px;
}
.dashboard-page .header-navbar-rht li:first-child:after {
	top: 22px;
}
.settings-header {
	background: transparent;
}
.settings-header h3, .settings-header h3 a {
	color: #28283C;
}
.settings-header p {
	color: #6B7385;
}
.settings-menu ul {
	border-top: 1px solid #CED4E6;
}
.settings-menu ul.sub-menu-ul {
	border: 0;
	padding: 0;
}
.settings-menu ul.sub-menu-ul li {
	margin-top: 0;
}
.settings-widget {
	border-color: #CED4E6;
	border-radius: 10px;
}
.sidebar-menu ul ul {
    display: none;
    padding-top: 12px;
}
.sidebar-menu ul ul a {
	display: block;
    font-size: 14px;
    font-weight: 500;
    color: #6B7385;
    padding: 7px 10px 7px 25px !important;
    position: relative;
}
.settings-menu ul li a {
	position: relative;
	font-size: 16px;
	color: #6B7385;
}
.sidebar-menu .menu-arrow {
    -webkit-transition: -webkit-transform 0.15s;
    -o-transition: -o-transform 0.15s;
    transition: transform .15s;
    position: absolute;
    right: 15px;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    text-rendering: auto;
    line-height: 40px;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
    -transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    line-height: 15px;
    top: 15px;
}
.sidebar-menu > ul > li > a span {
    transition: all 0.2s ease-in-out 0s;
    display: inline-block;
    margin-left: 10px;
    white-space: nowrap;
}
.sidebar-menu .menu-arrow:before {
    content: "\f105";
}
.sidebar-menu li a.subdrop .menu-arrow {
    -transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
.settings-menu ul li a {
	padding: 10px 15px;
	margin-bottom: 5px;
}
.settings-menu ul li + li {
	margin-top: 0;
}
.settings-menu ul li a.active {
	background: #E65425;
	color: #fff !important;
	border-radius: 5px;
}
.settings-menu ul li a:hover, .settings-menu ul li a:focus {
	background: #E65425 !important;
	color: #fff !important;
	border-radius: 5px;
}
.dash-widget {
	padding: 10px;
	border: 1px solid #CED4E6;
	box-shadow: 0px 8px 16px 0px rgba(142, 141, 208, 0.12);
	border-radius: 6px;
}
.dash-widget .dash-info {
	display: flex;
	align-items: center;
	padding: 0;
	border: 0;
	margin-bottom: 10px;
}
.dash-widget .dash-widget-info {
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 400;
}
.dash-widget-more {
	padding: 0;
}
.dash-widget .dash-info .dashboard-icon {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: #EEF3FA;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
}
.dash-widget .dash-info .dashboard-icon.dashboard-icon-two {
	background: #EBF9EE;
}
.dash-widget .dash-info .dashboard-icon.dashboard-icon-three {
	background: #FDFAF0;
}
.dash-widget .dash-info .dashboard-icon.dashboard-icon-four {
	background: #FFF2F2;
}
.dash-widget-count {
	font-size: 18px;
	color: #1B1B1B;
}
.dash-widget-more a {
	color: #E65425;
	font-weight: 500;
	font-size: 14px;
}
.on-project span {
	color: #6B7385;
	font-size: 14px;
}
.on-project h5 {
	color: #1B1B1B;
	font-weight: 600;
	font-size: 18px;
	margin: 10px 0 14px;
}
.on-project .pro-info .slot p {
	color: #6B7385;
}
.on-project .pro-info .slot h5 {
	color: #1B1B1B;
	font-size: 16px;
	font-weight: 600;
}
.pro-head {
	border: 0;
	padding: 0;
	margin-bottom: 24px;
}
.ongoing-project-card {
	padding: 20px;
	border: 1px solid #CED4E6;
	border-radius: 6px;
}
.on-project {
	border: 1px solid #CED4E6;
	border-radius: 10px;
	padding: 15px;
}
.on-project .list-details h5 {
	margin-bottom: 0;
}
.fund-btn {
	background: #E65425;
}
.fund-btn:hover {
	background: #212529;
}
.pro-body .on-project {
	margin-bottom: 15px;
}
.pro-body .on-project:last-child {
	margin-bottom: 0;
}
.table-avatar .avatar {
	width: 38px;
	height: 38px;
}
.recent-earnings td {
	padding: 10px 10px;
	font-size: 14px;
	vertical-align: middle;
	color: #6B7385;
	border: 1px solid #CED4E6;
	border-left: 0;
	border-right: 0;
}
.recent-earnings .table tr td:first-child {
	padding-left: 15px;
}
.recent-earnings th {
	background: #F9F9F9;
	border-bottom: 1px solid #CED4E6 !important;
}
.table-avatar a img {
	border-radius: 50%;
}
.table-avatar a {
	color: #1B1B1B;
}
.recent-earnings table {
	border: 1px solid #CED4E6;
}
td.action-bg {
	color: #34C759;
}
.dropdown-menu .dropdown-toggle::after {
	display: none;
}
.dropdown-menu .dropdown-toggle p {
	margin-bottom: 0;
	color: #6B7385;
	font-weight: 400;
	font-size: 12px;
}
.dropdown-menu.emp .drop-head {
	border-bottom: 1px solid #E7EBF7;
	background: #fff;
	padding: 15px;
	border-top: 0;
}
.drop-head .user-img {
	margin-right: 15px;
}
.header-navbar-rht li.account-item .dropdown-menu.emp {
	padding: 0;
	border-radius: 8px;
	border: 1px solid #fff;
	margin-top: 10px;
	min-width: 235px;
	box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.06), 0px 3px 6px 0px rgba(0, 0, 0, 0.06), 0px 10px 10px 0px rgba(0, 0, 0, 0.05), 0px 23px 14px 0px rgba(0, 0, 0, 0.03), 0px 41px 17px 0px rgba(0, 0, 0, 0.01);
}
.dashboard-page .header .drop-head .nav-link {
	padding: 0;
}
.header-navbar-rht .account-item .emp .dropdown-item {
	border: 0;
}
.header-navbar-rht .account-item .dropdown-menu.emp .dropdown-item {
	padding: 7px 15px;
	color: #1B1B1B;
	font-size: 14px;
	border-radius: 3px;
}
.header-navbar-rht .account-item .dropdown-menu.emp .dropdown-item:hover {
	background: #eee;
}
.header-navbar-rht .account-item .dropdown-menu.emp .dropdown-item:last-child {
	margin-bottom: 0;
}
.settings-menu ul li a img {
	margin-right: 8px;
	min-width: 16px;
}
.dashboard-page .header-navbar-rht li a {
	position: relative;
}
.dashboard-page .header-navbar-rht li a::before {
	content: "";
    width: 6px;
    height: 6px;
    background: #34C759;
    border-radius: 20px;
    position: absolute;
    right: 2px;
    top: 2px;
}
.dashboard-page .header-navbar-rht li .topnav-dropdown-footer a::before {
	display: none;
}
.dashboard-page .header-navbar-rht li a.clear-noti::before {
	display: none;
}
.dashboard-page .header-navbar-rht .notifications li a::before {
	display: none;
}
.dashboard-page .header-navbar-rht li:nth-child(3) a::before {
	display: none;
}
.dashboard-page .header-navbar-rht li:nth-child(4) a::before {
	display: none;
}
.settings-menu ul li a:hover img {
	filter: blur(0) brightness(100);
}
.settings-menu ul li a:focus img {
	filter: blur(0) brightness(100);
}
.settings-menu ul li a.active img {
	filter: blur(0) brightness(100);
}
.settings-menu ul li:last-child a {
	margin-bottom: 0;
}
/* .static-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
} */
.static-list li 
{
	margin-bottom: 10px !important;
	font-size: 14px;
}
.static-list li i {
	font-size: 10px;
}
.pro-head .card-title,.pro-head h2 {
	font-size: 18px;
	font-weight: 600;
}
.header-three .select2-container {
	width: 140px !important;
}
.header-three  .select2-container .select2-selection--single .select2-selection__rendered {
	padding-left: 35px;
	padding-right: 25px;
}
.badge {
	display: inline-flex;
	padding: 6px 15px;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	font-size: 12px;
}
.badge-paid {
	color: #34C759;
	background: #EBF9EE;
}
.badge-pending {
	color: #FFCC00;
	background: #FDFAF0;
}
.payout-section .dash-widget {
    padding: 20px;
    border-radius: 10px;
	margin-bottom: 0;
}
.payout-section .dash-widget .dash-info{
	justify-content: space-between;
}
.payout-section .dash-widget .dash-info .dashboard-icon {
    width: 52px;
    height: 52px;
    border-radius: 6px;
    background: #FFF6F1;
	margin-right: 14px;
}
.payout-section .dash-widget .dash-widget-info span{
	color: #74788D;
	font-size: 14px;
	margin-bottom: 5px;
	display: inline-flex;
}
.payout-section .dash-widget .dash-widget-info h6{
	color: #28283C;
	margin-bottom: 0;
}
.dash-widget-date span{
	color: #6B7385;
	font-size: 14px;
}
.widget-section {
	padding-bottom: 30px;
}
.table-top-section {
    display: flex;
    justify-content: space-between;
	padding-bottom: 24px;
	align-items: center;
}
.table-options {
    display: flex;
    align-items: center;
    gap: 10px;
}
.table-option-btn {
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #CED4E6;
    background: #FFF;
	color: #6B7385;
}
.table-option-btn:hover {
	background: #E65425;
	color: #fff;
}
.data-search-input {
    min-width: 240px;
    position: relative;
}
.data-search-input .form-control{
	padding: 8px 16px 8px 42px;
}
.data-search-input::after {
    content: '\f002';
    font-family: 'FontAwesome';
    color: #9599a5;
    position: absolute;
    top: 10px;
    left: 16px;
    font-size: 14px;
}
.payout-method-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
	padding: 14px;
	border-radius: 6px;
	border: 1px solid #CED4E6;
	margin-bottom: 10px;
}
.payout-method-option:last-child {
	margin-bottom: 0;
}
.payout-icon {
    width: 52px;
    height: 52px;
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    background: #FFF6F1;
    margin-right: 14px;
}
.payout-method-content h5 {
	margin-bottom: 5px;
}
.payout-method-content p {
	color: #6B7385;
	font-size: 14px;
}
.freelancer-statements.payout-section .dash-widget {
	padding: 15px;
}
.freelancer-statements .dash-widget .dash-info {
	margin-bottom: 0;
}
.freelancer-statements .dash-widget .dash-widget-info h5{
	margin-bottom: 0;
}
.withdraw-btn {
	font-size: 12px;
    color: #E65425;
}
.badge-download {
    color: #6B7385;
    background: #F4F7FF;
}
.badge-download i {
	margin-right: 10px;
	font-size: 14px;
}
.noti-pop-detail li.noti-wrapper .notify-blk {
    width: 410px;
    height: 580px;
}
.plan-billing-section {
	padding-bottom: 40px;
}
.doc-btn {
	display: flex;
	justify-content: flex-end;
	gap: 14px;
}
.doc-btn .btn {
	padding: 11px 24px;
	border-radius: 6px;
}
.btn-gray {
	background: #F1F0FE;
}
.btn-gray:hover {
	background: #2E2E2E;
	color: #fff;
}
.verifi-badge {
	padding: 6px 10px;
	border-radius: 5px;
	color: #fff;
}
.verifi-badge.success {
	background: #34C759;
}
.verifi-badge.rejected {
	background: #FF3B30;
}
.verified-details {
	padding: 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 10px;
	box-shadow: 0px 4px 4px 0px rgba(142, 141, 208, 0.16);
	border: 1px solid #E7EBF7;
	gap: 20px;
}
.verified-rejected {
	border-radius: 10px;
	padding: 24px;
	box-shadow: 0px 4px 4px 0px rgba(142, 141, 208, 0.16);
	border: 1px solid #E7EBF7;
}
.verified-rejected .verified-details{
	padding: 0;
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-radius: 0;
	box-shadow: none;
	border: none;
	border-bottom: 1px solid #E7EBF7;
}
.doc-btn.rejected-btn {
	justify-content: flex-start;
}
.btn-black {
	background: #081733;
	color: #fff;
	border: 1px solid #081733;
}
.btn-black:hover {
	background: #fff;
	color: #081733;
}
.reject-reason p {
	color: #6B7385;
}
.badge-danger-lite {
	color: #FF3B30;
	background: #FFF2F2;
}
.bank-selection {
    position: relative;
}
.bank-selection input {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    opacity: 0;
}
.bank-selection label {
    margin-bottom: 20px;
}
.bank-selection label {
    background: #FFF6F1;
    border: 2px solid #E7EBF7;
    border-radius: 8px;
    padding: 5px;
    text-align: center;
    width: 100%;
    position: relative;
    height: 42px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.bank-selection input ~ label .role-check {
    display: none;
}
.bank-selection .role-check {
    position: absolute;
    top: -8px;
    right: -8px;
    color: #00CC45;
}
.bank-selection input:checked ~ label .role-check {
    display: block;
}
.basic-plan-details {
	background: #F4F7FF;
	border-radius: 6px;
	padding: 10px;
	margin-bottom: 30px;
}
.basic-plan-details ul {
	list-style: none;
	display: flex;
}
.basic-plan-details  ul li {
	margin-right: 50px;
}
.basic-plan-details  ul li:last-child {
	margin-right: 0;
}
.home-five-wrapper .feature-items .feature-icon {
	margin: auto;
}
.home-five-wrapper .feature-content {
	text-align: center;
}
.trusted-by-section {
	padding: 30px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 25px;
	background: #FFF8F5;
	flex-wrap: wrap;
}
.trusted-by-section span{
	color: #6B7385;
}
.trusted-by-section ul{
	list-style: none;
	display: flex;
	gap: 53px;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 0;
}
.project-by-category {
	background: #fff;
	padding: 80px 0;
}
.home-five-wrapper .section-header {
	margin-bottom: 40px;
}
.project-by-category-widget {
	border-radius: 5px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}
.category-widget-img {
	margin-bottom: 14px;
	border-radius: 5px;
	overflow: hidden;
	display: flex;
	justify-content: center;
}
.category-widget-img img {
	border-radius: 5px;
	transition: all 0.5s;
}
.category-widget-content {
	text-align: center;
}
.project-by-category .blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-prev,
.project-by-category .blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-next {
	filter: drop-shadow(0px 4px 4px rgba(209, 209, 209, 0.25));
	background: #fff;
	color: #101828;
}
.project-by-category .blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-prev {
    left: -25px;
    top: 28%;
}
.project-by-category .blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-next {
    top: 28%;
    right: -25px;
}
.home-five-section-btn {
	display: flex;
	justify-content: center;
	gap: 20px;
	align-items: center;
}
.home-five-section-btn .btn {
	padding: 11px 24px;
	border-radius: 6px;

}
.category-slider-section {
	padding-bottom: 40px	
}
.home-five-wrapper .feature-project-list {
	padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
}
.home-five-wrapper .project-item.project-popular-item {
	padding: 16px;
	border: 1px solid #CED4E6;
}
.home-five-wrapper .project-item.project-popular-item .feature-content {
    text-align: start;
	margin-top: 18px;
}
.home-five-wrapper.feature-content h4 {
	font-size: 22px;
}
.home-five-wrapper .feature-project-list li {
	color: #6B7385;
	font-size: 16px;
}
.home-five-wrapper .feature-project-list li+li {
	color: #081733;
	font-weight: 600;
	font-size: 22px;
}
.home-five-wrapper .review{
	background: #FFF6F1;
	padding: 80px 0 300px 0;
	position: relative;
}
.home-five-wrapper .demand-post-img{
	width: 60px;
}
.home-five-wrapper .demand-post-job{
	align-items: center;
}
.home-five-wrapper .market-place-join {
    padding: 0;
    margin-top: -220px;
    position: relative;
}
.most-hired-section {
	padding: 80px 0 56px;
}
.home-five-wrapper .feature-count.feature-count-three .header-title {
	color: #fff;
}
.home-five-wrapper .feature-count.feature-count-three .section-header p {
	color: #E7EBF7;
}
.home-five-wrapper .img-reviews {
	width: 193px;
}
.home-five-wrapper .img-reviews img{
	width: 100%;
}
.home-five-wrapper .review-quote img{
	top: 9px;
}
.home-five-wrapper .blog-tricks {
	padding-bottom: inherit;
	padding: 80px 0 56px;
}
.home-five-wrapper .feature-time-blk .badge.bg-pink {
	background: #fff;
	color: #6B7385;
}
.home-five-wrapper .grid-blog .blog-title {
	font-size: 18px;
	color: #1B1B1B;
	margin-bottom: 0;
}
.float-img img {
	position: absolute;
	right: 0;
	bottom: 0;
	max-width: 400px;
}
.home-five-wrapper .job-registers{
	padding-top: 80px;
}
.home-five-wrapper .job-register {
	padding: 0 0 80px;
}
.home-five-wrapper .job-content-blk .all-btn {
	margin-bottom: 0;
}
.job-registers {
	background: #E65425;
}
.footer-bottom-content ul {
	list-style: none;
	margin-bottom: 0;
	display: flex;
	align-items: center;
	gap: 10px;
}
.footer-bottom-content ul li{
	color: #6B7385;
	height: 35px;
	width: 35px;
	background: #FFF6F1;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.footer-bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.home-five-wrapper .job-content-blk h2 {
	font-size: 40px;
}
.header.header-five {
	background: transparent;
}
.category-widget-img:hover img {
    transform: scale(1.2);
}
.count-sec .feature-item {
    box-shadow: 0px 8px 16px 0px rgba(142, 141, 208, 0.12);
    border-bottom: 2px solid #E65425;
    background: #fff;
	border-top: 2px solid #fff;
}
.count-sec {
	background: #FFF8F5;
	margin-top: -100px;
}
.home-five-wrapper .feature-count.feature-count-three {
	padding: 80px 0 110px 0;
}


/*-----------------------
	Dashboard
------------------------*/
.dashboard-page .content-page {
	padding: 130px 0 30px;
}
.dashboard-page .header-navbar-rht .login-btn {
	padding: 7px 15px;
}
.dashboard-page .header-navbar-rht li:first-child:after {
	top: 19px;
}
.settings-header {
	background: transparent;
}
.settings-header h3, .settings-header h3 a {
	color: #28283C;
}
.settings-header p {
	color: #6B7385;
}
.settings-menu ul {
	border-top: 1px solid #CED4E6;
}
.settings-menu ul.sub-menu-ul {
	border: 0;
	padding: 0;
}
.settings-menu ul.sub-menu-ul li {
	margin-top: 0;
}
.settings-widget {
	border-color: #CED4E6;
	border-radius: 10px;
}
.sidebar-menu ul ul {
    display: none;
    padding-top: 12px;
}
.sidebar-menu ul ul a {
	display: block;
    font-size: 14px;
    font-weight: 500;
    color: #6B7385;
    padding: 7px 10px 7px 40px !important;
    position: relative;
}
.settings-menu ul li a {
	position: relative;
	font-size: 16px;
	color: #6B7385;
}
.sidebar-menu .menu-arrow {
    -webkit-transition: -webkit-transform 0.15s;
    -o-transition: -o-transform 0.15s;
    transition: transform .15s;
    position: absolute;
    right: 15px;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    text-rendering: auto;
    line-height: 40px;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
    -transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    line-height: 15px;
    top: 15px;
}
.sidebar-menu > ul > li > a span {
    transition: all 0.2s ease-in-out 0s;
    display: inline-block;
    margin-left: 10px;
    white-space: nowrap;
}
.sidebar-menu .menu-arrow:before {
    content: "\f105";
}
.sidebar-menu li a.subdrop .menu-arrow {
    -transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}
.settings-menu ul li a {
	padding: 10px 15px;
	margin-bottom: 5px;
}
.settings-menu ul li + li {
	margin-top: 0;
}
.settings-menu ul li.nav-item > a.active {
	background: #71d3a9;
	color: #fff !important;
	border-radius: 5px;
}
.settings-menu ul li a:hover, .settings-menu ul li a:focus {
	background: #71d3a9 !important;
	color: #fff !important;
	border-radius: 5px;
}
.dash-widget {
	padding: 10px;
	border: 1px solid #CED4E6;
	box-shadow: 0px 8px 16px 0px rgba(142, 141, 208, 0.12);
	border-radius: 6px;
}
.header-navbar-rht li.account-item a{
	padding-left: 0;
}
.dash-widget .dash-info {
	display: flex;
	align-items: center;
	padding: 0;
	border: 0;
	margin-bottom: 10px;
}
.dash-widget .dash-widget-info {
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 400;
}
.dash-widget-more {
	padding: 0;
}
.dash-widget .dash-info .dashboard-icon {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: #EEF3FA;
	display: inline-flex;
	align-items: center;
}
.proposal-img {
	margin-right: 0;
}
.proposal-ongoing .proposal-img span i {
	color: #FF9C07;
	margin: 0 8px;
}
.proposals-section .freelancer-proposals:last-child {
	margin-bottom: 0;
}
.project-action .projects-btn.completed-btn {
	background: #EBF9EE;
	color: #34C759;
}
.project-action span {
	font-size: 14px;
}
.project-action span i {
	color: #FF9C07;
	font-size: 14px;
}
.project-action span i:last-child {
	color: #CED4E6;
}
.project-action a.btn-write-review {
	color: #E65425;
}
.project-action a:hover {
	color: #E65425;
}
.project-action .projects-btn:hover {
	background: #E65425;
	color: #fff;
}
.ongoing-projects .project-action::before {
	height: 70px;
}
.cancel-projects .project-action::before {
	height: 70px;
}
.settings-menu ul li ul.sub-menu-ul li a.active {
	background: #fff;
	color: #E65425 !important;
}
.settings-menu ul li ul.sub-menu-ul li a:hover {
	background: #fff !important;
	color: #71d3a9 !important;
}


/*-----------------------
	Projects
------------------------*/
.proposal-info ul {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0;
	flex-wrap: wrap;
	row-gap: 20px;
}
.proposal-info .proposal-client h4 {
	font-size: 16px;
	color: #1B1B1B;
}
.proposal-info .proposal-client .info-btn {
	background: #FFF6F1;
	border: 1px solid #CED4E6;
	border-radius: 5px;
	color: #6B7385;
	font-weight: 500;
	padding: 5px 30px;
	display: inline-flex;
    line-height: normal;
}
.proposal-client {
	margin-right: 0;
}
.proposal-client-price .client-price {
	color: #1B1B1B;
	font-size: 18px;
	display: inline-flex;
	align-items: center;
	margin-bottom: 0;
}
.proposal-client-price .client-price span {
	color: #6B7385;
	font-size: 14px;
	display: inline-block;
	margin-left: 4px;
	font-weight: 400;
}
.project-amount h3 {
	font-size: 18px;
	color: #1B1B1B;
	display: inline-flex;
	align-items: center;
}
.project-amount h3 span {
	color: #6B7385;
	font-size: 14px;
	display: inline-block;
	margin-left: 4px;
	font-weight: 400;
}
.proposal-job-type h3 {
	font-size: 18px;
	color: #1B1B1B;
	margin-bottom: 0;
}
.proposal-info ul li .title-info {
	color: #6B7385;
	font-size: 14px;
	margin-bottom: 8px;
}
.project-action {
	padding: 0 0 0 15px;
	position: relative;
}
.project-action::before {
	content: "";
	width: 1px;
	height: 130px;
	background: #EAEAEA;
	position: absolute;
	left: 0;
	top: -12px;
}
.freelancer-proposals {
	border: 1px solid #CED4E6;
	border-radius: 10px;
	padding: 24px;
	margin-bottom: 15px;
}
.project-proposals.freelancer {
	padding: 0;
	background: #fff;
	border: 0;
}
.project-action a {
	color: #2A2760;
	display: block;
	font-size: 16px;	
	font-weight: 600;
	margin-bottom: 10px;
}
.project-action .projects-btn {
	background: #FFF6F1;
	border-radius: 6px;
	color: #E65425;
	padding: 8px 20px;
	width: 100%;
	margin-bottom: 10px;
}
.edit-proposal-modal .modal-dialog.modal-md {
	max-width: 800px;
}
.input-block {
	position: relative;
}
.input-block .input-group-text {
	position: absolute;
	right: 10px;
	top: 33px;
	background: #fff;
	border: 0;
	color: #6B7385;
	font-size: 14px;
	font-weight: 600;
}
.input-block .add-new a {
	display: inline-flex;
	align-items: center;
	color: #FF3B30;
}
.modal-check-item {
	border-radius: 5px;
	border: 1px solid #CED4E6;
	background: #FFF8F5;
	padding: 24px;
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	margin-bottom: 15px;
}
.modal-info h4 {
	font-size: 18px;
}
.modal-check-item .price span {
	color: #FF3B30;
	font-size: 18px;
	font-weight: 600;
}
.modal-check-item .custom_check {
	font-size: 18px;
	color: #1B1B1B;
	font-weight: 600;
}
.modal-check-item p {
	margin-bottom: 0;
	color: #6B7385;
}
.modal-check-item.bg-light-blue {
	background: #F6F6FF;
}
.submit-section .btn-cancel {
	background: #F4F7FF;
	color: #081733;
}
.submit-section .btn-cancel:hover {
	background: #081733;
	color: #fff;
}
.edit-proposal-modal .modal-header {
	padding: 0;
	border: 0;
	margin-bottom: 30px;
}
.edit-proposal-modal .modal-header .modal-title {
	font-size: 24px;
}
.edit-proposal-modal .modal-content {
	padding: 24px;
}
.edit-proposal-modal .modal-body {
	padding: 0;
}
.proposal-ongoing .proposal-img h4 {
	margin: 0 0 5px 0;
}
.proposal-ongoing .proposal-img span {
	font-size: 14px;
	color: #6B7385;
	margin-left: 3px;
} 
.overview-description {
	padding-top: 15px;
	margin-bottom: 30px;
	padding-bottom: 15px;
	border-bottom: 1px solid #CED4E6;
}
.dashboard-page .user-tabs ul li + li {
	margin-left: 0;
}
.manage-projects-table .progress.progress-md {
	width: 82px;
}
.manage-projects-table .badge {
	padding: 5px 10px;
	border-radius: 4px;
	font-size: 12px;
	font-weight: 500;
}
.manage-projects-table .badge.bg-danger-light {
	color: #FF3B30 !important;
	background: #FFF2F2 !important;
}
.action-table-data {
	display: flex;
	align-items: center;
}
.action-table-data .btn-request {
	background: #E65425;
	font-size: 12px;
	color: #fff;
	padding: 5px 10px;
	border-radius: 4px;
	flex-shrink: 0;
	margin-right: 10px;
}
.action-table-data .select2-container .select2-selection--single {
	height: 27px;
	width: 120px;
}
.action-table-data .select2-container--default .select2-selection--single .select2-selection__arrow {
	height: 27px;
}
.action-table-data .select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 24px;
	font-size: 14px;
	color: #6E727D;
}
.manage-projects-table td {
	white-space: nowrap;
}
.edit-delete-action {
	display: flex;
	align-items: center;
	margin-left: 20px;
}
.edit-delete-action a i {
	color: #6B7385;
	font-size: 16px;
}
.edit-delete-action a {
	display: inline-block;
}
.action-table-data .btn-request.disabled {
	background: #FFF6F1;
	color: #FFD9C3;
	opacity: 1;
}
 .select2-results {
	background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 8px;
    text-align: left;
    /* width: 170px; */
}
 .select2-results__option {
	font-size: 14px;
	color: #1B1B1B;
}
 .select2-container--default .select2-results__option[aria-selected=true] {
	background: #FFF6F1;
	color: #1B1B1B;
}
table.dataTable.no-sort thead .sorting_asc:before {
	display: none;
}
table.dataTable.no-sort thead .sorting:after {
	display: none;
}
table.dataTable.no-sort thead .sorting_asc:after {
	display: none;
}
table.dataTable.no-sort.sorting_desc:before {
	display: none;
}
table.dataTable.no-sort.sorting_desc:after {
	display: none;
}
table.dataTable.no-sort thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
	display: none;
}
table.dataTable.no-sort thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:before {
	display: none;
}
.manage-projects-table .dataTables_length {
	display: none;
}
.manage-projects-table .dataTables_filter {
	display: none;
}
.manage-projects-table .table .thead-pink th {
	background: #F9F9F9;
	color: #1B1B1B;
}
.dashboard-page .select2-dropdown {
	z-index: 1056;
}
.edit-delete-action .download-icon {
	border: 1px solid #CED4E6;
	border-radius: 6px;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.edit-proposal-modal.success-modal .modal-dialog.modal-md {
	max-width: 500px;
}
.success-msg-content h4 {
	color: #1B1B1B;
	font-size: 24px;
	margin-bottom: 20px;
}
.page-title h3 {
	font-size: 24px;
}
.dashboard-page .user-tabs ul li a {
	padding: 20px 8px;
	color: #6B7385;
	font-size: 14px;
}
.project-action.overview-action::before {
	height: 90px;
}
.dashboard-page .header-navbar-rht li a img::before {
	content: "";
	width: 6px;
	height: 6px;
	background: #34C759;
	border-radius: 20px;
	position: absolute;
	right: 0;
	top: 0;
}
.account-item .user-img > img {
	width: 40px;
	height: 40px;
}
.employee-project-card {
	width: 70%;
}
.ongoing-projects .employee-project .project-action::before {
	display: none;
}
.tech-name-badge {
	font-size: 14px;
	color: #6B7385;
	display: inline-block;
	margin-bottom: 10px;
}
.employee-project-card .proposal-job-type h3{
	font-size: 16px;
}
.proposal-job-type .flag-icon {
	display: flex;
	align-items: center;
}
.proposal-job-type .flag-icon img {
	border-radius: 3px;
	margin-right: 5px;
}
.project-action span {
	font-size: 14px;
	color: #6B7385;
}
.ongoing-projects .prj-proposal-count.hired h3 {
	color: #6B7385;
	font-weight: 400;
}
.ongoing-projects .prj-proposal-count.hired p {
	color: #1B1B1B;
	font-size: 16px;
	font-weight: 600;
}
.ongoing-projects .projects-card {
	border-radius: 10px;
	border-color: #CED4E6;
}
.my-projects-list {
	margin-bottom: 15px;
}
td .orange-text {
	color: #6B7385;
	font-size: 14px;
}
.action-table-data .view-icon {
	font-size: 14px;
	color: #6B7385;
	display: flex;
	align-items: center;
}
.milestone-view {
	background: #F4F7FF;
	padding: 24px;
	margin-bottom: 24px;
}
.milestone-view h5 {
	margin-bottom: 0;
	font-size: 22px;
}
.milestone-view span {
	color: #E65425;
	font-size: 18px;
}
ul.download-item li a {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #E7EBF7;
	border-radius: 10px;
	color: #000;
	padding: 15px;
}
ul.download-item li {
	margin-bottom: 10px;
}
ul.download-item li:last-child {
	margin-bottom: 0;
}
.input-block label {
	color: #131135;
	font-weight: 600;
}
.pro-form-img .profile-pic {
    width: 100px;
    height: 100px;
    border: 1px dashed #C2C2C2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-right: 20px;
}
.pending-proposal-profile {
	border: 1px solid #F38247;
	background: #FFF6F1;
	padding: 24px;
	border-radius: 10px;
}
.pending-proposal-profile .user-img-box img{
	width: 42px;
	height: 42px;
	border-radius: 50%;
}
.pending-proposal-profile .user-img-box {
	display: flex;
	align-items: center;
}
.pending-proposal-profile .user-img-box h6 {
	margin-bottom: 0px;
	color: #1B1B1B;
	font-weight: 600;
}
.pending-proposal-profile .user-img-box span {
	color: #6B7385;
	font-size: 14px;
}
.pending-proposal-profile .user-img-box span i {
	color: #FF9C07;
	font-size: 14px;
	margin-left: 8px;
	padding-left: 8px;
	border-left: 1px solid #CED4E6;
	margin-right: 3px;
}
.pending-proposal-profile .user-img-box .user-name {
	margin-left: 10px;
}
.payment-count {
	display: flex;
	align-items: center;
}
.payment-count h4 {
	font-size: 22px;
	margin-bottom: 0;
}
.payment-count span {
	color: #E65425;
	font-weight: 600;
}
.payment-count .cost {
	margin-right: 40px;
}
.pending-proposal-profile .describe-info {
	background: #fff;
	padding: 10px;
}
.pending-proposal-profile .describe-info p {
	color: #6B7385;
	font-size: 14px;
	margin-bottom: 0;
}
.pending-proposal-profile .describe-info a {
	color: #E65425;
	font-weight: 600;
	display: inline-block;
	margin-top: 5px;
}
.close-project {
	font-weight: 600 !important;
	color: #000;
}
.hire-modal .modal-dialog.modal-md {
	max-width: 500px;
}
.freelance-tags {
	margin: 0;
	padding: 0;
	border: 0;
}
.book-mark .badge-design {
	background: #F4F7FF;
}
.btn-invite-fav {
	background: #FFF6F1;
	display: flex;
	padding: 8px 20px;
	margin-bottom: 10px;
	border-radius: 6px;
	align-items: center;
	justify-content: center;
	color: #E65425;
	font-weight: 600;
}
.book-mark .btn-cart {
	padding: 8px 20px;
	font-size: 16px;
}
.bade.success-bg-light {
	padding: 6px 15px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: #EBF9EE;
	border-radius: 4px;
	color: #34C759;
}
.bade.danger-bg-light {
	padding: 6px 15px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: #FFF2F2;
	border-radius: 4px;
	color: #FF3B30;
}
.employee-profile-new .upload-images img {
	border-radius: 50%;
}
.employee-profile-new label.file-upload.image-upbtn {
	border: 0;
	padding: 5px 10px;
	width: 120px;
}
.employee-profile-new .pro-pic p {
	color: #6B7385;
	margin-top: 10px;
}
.reviewed-user {
	border-radius: 5px;
	background: #FFF;
	box-shadow: 0px 4px 8px 0px rgba(142, 141, 208, 0.16);
	padding: 24px;
	display: flex;
	align-items: center;
}
.reviewed-user img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin-right: 10px;
}
.reviewed-user span {
	font-weight: 600;
	color: #1B1B1B;
	display: inline-block;
}
.rating i.active {
    color: #FFBC35;
}
.about-us-page .review-four {
	background: #fff;
}
.section-bottom-cards {
	margin-bottom: -150px;
	position: relative;
	z-index: 1;
}
.feaure-project {
	padding-top: 170px;
}
.hired-developers {
	border: 1px solid #CED4E6;
	border-radius: 10px;
	padding: 15px;
	margin-bottom: 24px;
	position: relative;
}
.hired-developers .rating i {
	margin: 0;
}
.hired-developers-img-content  {
	margin-top: 15px;
}
.hired-developers-img-content h4 {
	margin-bottom: 5px;
}
.hired-developers-img-content span {
	color: #6B7385;
}
.hired-developers-img-content h6 {
	color: #E65425;
	margin: 5px 0 0;
}
.hired-developers-img {
	border-radius: 10px;
	overflow: hidden;
	position: relative;
	transition: 0.5s all;
}
.hired-developers-img img{
	border-radius: 10px;
	transition: 0.5s all;
	width: 100%;
}
.hired-developers-img-content .favourite {
	margin: 10px 10px 0 0;
}
.hired-developers-img-content .favourite.blue-active {
	background: #E65425;
	color: #fff;
	border-color: #E65425;
}
.hired-developers-img:hover img {
	transform: scale(1.14);
	transition: 0.5s all;
}
.freelancer-pic-upload label.file-upload.image-upbtn{
	border: 0;
}
.freelancer-pic-upload p {
	color: #6B7385;
	margin-top: 0;
}
.freelancer-pic-box {
	border: 1px solid #CED4E6;
	border-radius: 4px;
	width: 80px;
	height: 80px;
}
.freelancer-pic-box.upload-images {
	display: flex;
	align-items: center;
	justify-content: center;
}
.freelancer-pic-box.upload-images img {
	height: auto;
	width: auto;
}
.form-label {
	color: #131135;
	font-weight: 600;
}
.skill-add.add-btn-profile {
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: #FFF6F1;
	color: #E65425;
}
.skill-add.add-btn-profile:hover {
	background: #E65425;
	color: #fff;
	border-color: #E65425;
}
.trash-icon:hover {
	color: #E65425;
}
.bg-one .news {
	padding-bottom: 50px;
}
.entry-meta li:last-child {
    margin-bottom: 0;
    margin-left: 15px;
}
.blog .entry-meta li .feather-calendar{
	color: #FE4A23;
}
.entry-meta li {
	margin-right: 0;
	padding-right: 0;
	border-right: 0;
}
.add-btn-form {
	color: #FF3B30;
	font-weight: 600;
	font-size: 14px;
}
.lang-add {
	width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #FFF6F1;
    color: #E65425;
}
.paginate_button .page-link:hover {
	color: #fff;
}
/*-----------------
	38. Responsive
-----------------------*/

@media only screen and (min-width:768px) {
	.avatar-xxl {
		width: 8rem;
		height: 8rem;
	}
	.avatar-xxl .border {
		border-width: 4px !important;
	}
	.avatar-xxl .rounded {
		border-radius: 12px !important;
	}
	.avatar-xxl .avatar-title {
		font-size: 42px;
	}
	.avatar-xxl.avatar-away:before,
	.avatar-xxl.avatar-offline:before,
	.avatar-xxl.avatar-online:before {
		border-width: 4px;
	}
}
@media (min-width: 992px) {
	/* .container {
		max-width: 1440px;
	} */
	.main-nav > li {
		margin-right: 24px;
	}
	.main-nav > li:last-child {
		margin-right: 0;
	}
	.main-nav li {
		display: block;
		position: relative;
	}
	.main-nav > li > a {
		padding: 20px 0;
	}
	.main-nav > li > a > i {
		font-size: 12px;
		margin-left: 5px;
	}
	.main-nav li > ul {
		background-color: #fff;
		box-shadow: 0 0 3px  rgba(0, 0, 0, 0.1);
		display: block;
		font-size: 14px;
		left: 0;
		margin: 0;
		min-width: 200px;
		opacity: 0;
		padding: 0;
		position: absolute;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		-webkit-transform: translateY(20px);
		-ms-transform: translateY(20px);
		transform: translateY(20px);
		top: 100%;
		visibility: hidden;
		z-index: 1000;
		border-radius: 5px;
	}
	.main-nav li.has-submenu:hover > .submenu {
		visibility: visible;
		opacity: 1;
		margin-top: 0;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
	.main-nav .has-submenu.active .submenu li.active > a {
		color: #fff;
		background-color: #71d3a9;
	}
	.main-nav > li .submenu li:first-child a {
		border-top: 0;
	}
	.main-nav > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
		visibility: visible;
		opacity: 1;
		margin-top: -1px;
		margin-right: 0;
	}
	.main-nav > li .submenu > li .submenu {
		left: 100%;
		top: 0;
		margin-top: 10px;
	}
	.main-nav > li .submenu li a:hover,
	.header-three .main-nav > li .submenu li a:hover	{
		background-color: #71d3a9;
		color: #fff;
		border-color: transparent;
	}
	.main-nav > .has-submenu > .submenu > .has-submenu > .submenu::before {
		top: 20px;
		margin-left: -35px;
		box-shadow: 1px 1px 0 0 rgba(0,0,0,.15);
		border-color: transparent #fff #fff transparent;
	}
	.main-nav > li > i {
		color: #fff;
		line-height: 75px;
	}
	.main-nav > li > i:hover {
		cursor: pointer;
	}
	.searchbar {
		line-height: 60px;
	}
	.header-navbar-rht .dropdown-toggle.show + .dropdown-menu {
		visibility: visible;
		opacity: 1;
		margin-top: 0;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
	.header-navbar-rht li .dropdown-menu {
		border-radius: 5px;
		padding: 0;
		margin: 0;
		min-width: 200px;
		visibility: hidden;
		opacity: 0;
	 	top: 100%;
	    left: 0;
	    right: auto;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
		display: block;
		-webkit-transform: translateY(20px);
		-ms-transform: translateY(20px);
		transform: translateY(20px);
	}
}

@media only screen and (max-width: 1399px) {
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 180px;
	}
	.dash-widget {
		padding: 10px;
	}
	.header-two .header-navbar-rht .login-btn {
		padding: 12px 10px;
	}
	 .header-navbar-rht .login-btn {
		padding: 7px 10px;
		font-size: 15px;
	}
	.main-nav > li {
		margin-right: 15px;
	}
	.header-navbar-rht li {
		margin-right: 10px;
	}
	.dashboard-page .header  .nav-link {
		padding: 0.5rem 0.3rem;
	}
	.header-navbar-rht li:first-child:after {
		right: -6px;
	}
	.settings-header h3 a {
		font-size: 19px;
	}
	.employee-project-card {
		width: 70%;
	}
	.account-item .nav-link {
		padding: 0.5rem 0.3rem;
	}
}

@media only screen and (max-width: 1199.98px) {
	.header-nav {
		padding-left: 20px;
		padding-right: 20px;
	}
	.header-navbar-rht li.lang-item {
		display: none;
	}
	.main-nav li a {
		font-size: 13px;
	}
	.project-content h5 {
		font-size: 12px;
	}
	.work-content {
		width: 83%;
	}
	.work-content h2 {
		font-size: 26px;
	}
	.footer-title {
		font-size: 19px;
	}
	.footer .footer-menu ul li a {
		font-size: 15px;
	}
	.agency-content {
		padding: 35px;
	}
	.payment-detail {
		padding: 25px;
	}
	.payment-detail p {
		font-size: 18px;
	}
	.profile-list li {
		width: calc(100%/3);
	}	
	.btn-list {
		margin-bottom: 7px; 
	}
	.profile-title {
		font-size: 30px;
	}
	.follow-posts {
		width: 240px;
	}
	.left-content h4 {
		font-size: 12px;
	}
	.bid-btn, .btn-view-outline {
		font-size: 11px;
	}
	.profile-right {
		display: inline-block;
	}
	.profile-right li {
		margin-right: 0;
	}
	.job-type h6 {
		font-size: 17px;
	}
	.amt-hr {
		font-size: 28px;
	}
	.bid-btn, .dev-right h6 {
		font-size: 13px;	
	}
	.dev-right p {    
		font-size: 15px;	
	}
	.main-nav > li {
		margin-right: 15px;
	}
	.job-category p {
		font-size: 14px;
	}
	.stats-detail  {
		padding: 18px;
	}
	.stats-detail h3, .create-milestone h3  {
		font-size: 24px;
	}
	.table-borderless.table tbody tr td {
		font-size: 20px;
	}
	.dash-widget-info {
		font-size: 17px;
	}
	.header-navbar-rht li a {
		font-size: 12px;
		align-items: center;
	}
	.proposals-info {
		display: block;
	}
	.proposals-detail { 
		width: 100%;
	}
	.project-hire-info {
		width: 100%;
	}
	.content-divider-1 {
		display: none;
	}
	.projects-details {
		display: block;
	}
	.project-info.project {
		width: 100%;
	}
	.project-hire-info.project {
		width: 100%;
	}
	.content-divider { 
		display: none;
	}
	.proposer-info.project {
		padding-left: 0;
	}
	.projects-action.project {
		padding-left: 0;
	}
	.need-project h2 {
		font-size: 38px;
	}
	.develope-project,
	.news-two,
	.get-discount,
	.most-hired,
	.projects-two {
		padding: 60px 0px;
	}
	.perfect-group h2 {
		font-size: 20px;
	}
	.img-perfect img {
		width: 100%;
	}
	.develop-bottom h5 {
		padding: 16px 25px;
	}
	.board-logo {
		width: 10%;
	}
	.social-link-profile {
		flex-wrap: wrap;
	}
	.social-link-profile li {
		margin-bottom: 15px;
	}
	.develop-social-link.widget-box {
		margin-bottom: 5px;
	}
	.work:after {
		width: 35px;
		transform: inherit;
	}
	.banner-content h1 {
		font-size: 38px;
	}
	.banner-content p {
		font-size: 18px;
	}
	.work-content {
		padding: 70px 50px 50px 60px;
	}
	.all-btn {
		padding: 10px 35px;
		font-size: 14px;
	}
	.job-content-blk h1 {
		font-size: 28px;
	}
	.all-btn {
		padding: 10px 30px;
		min-width: 180px;
	}
	.job-content-blk p {
		font-size: 16px;
	}
	.owl-carousel .owl-item .review-blog .review-img img {
		width: 60px;
	}
	.home-two .market-place h3 {
		font-size: 20px;
	}
	.home-count ul li {
		font-size: 20px;
	}
	.home-count ul li span {
		font-size: 16px;
	}
	.platform-group h1 {
		font-size: 24px;
	}
	.feature-project-list li {
		font-size: 15px;
	}
	.feature-content h4 {
		font-size: 18px;
	}
	.about-it-content p {
		font-size: 15px;
	}
	.more-project .btn-primary {
		min-width: 183px;
		padding: 8px 15px;
		font-size: 14px;
	}
	.blog-slider.owl-carousel .owl-nav button.owl-prev {
		left: -5px;
	}
	.blog-slider.owl-carousel .owl-nav button.owl-next {
		right: -5px;
	}
	.blog-slider.owl-carousel .owl-nav button.owl-prev, 
	.blog-slider.owl-carousel .owl-nav button.owl-next {
		width: 35px;
		height: 35px;
		font-size: 12px;
	}
	.great-about {
		padding: 80px 0px 410px;
	}
	.dot-slider .slick-list {
		height: 445px !important;
	}
	.banner-content .form-control {
		height: 58px;
	}
	.banner-content .sub-btn {
		height: 40px;
	}
	.home-three .banner-content h1 {
		font-size: 28px;
	}
	.home-three .slick-prev:before, .home-three .slick-next:before {
		width: 15px;
		height: 15px;
		background-size: 91%;
	}
	.home-three .slick-prev, .home-three .slick-next {
		width: 40px;
		height: 40px;
	}
	.dot-slider.slick-vertical .slick-slide {
		height: 445px;
	}
	.owl-carousel.trend-slider .owl-nav {
		display: none;
	}
	.header-navbar-rht .login-btn {
		padding: 8px 30px;
		font-size: 14px;
	}
	.header-four .header-navbar-rht li a.reg-btn {
		font-size: 14px;
	}
	.home-four .banner-content h5 {
		font-size: 16px;
	}
	.home-four .banner-content h1 {
		font-size: 36px;
	}
	.stacker-active {
		font-size: 13px;
		bottom: -2px;
		width: 16px;
		height: 16px;
	}
	.fullstacker-img {
		width: 70px;
		height: 70px;
	}
	.fullstacker-name h4 {
		font-size: 16px;
	}
	.complete-content h3 {
		font-size: 18px;
	}
	.complete-icon {
		width: 45px;
		height: 45px;
	}
	.banner-content .sub-btn {
		font-size: 14px;
	}
	.market-list-out li {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.market-place-group h2 {
		font-size: 26px;
	}
	.market-place-btn .btn-primary,
	.market-place-btn .market-developer	{
		font-size: 14px;
	}
	.popular-slider.developers-slider .owl-nav {
		bottom: 100%;
		right: 0;
		margin-bottom: 30px;
	}
	.review-four {
		padding: 80px 0px 120px;
	}
	.market-place-btn.platform-btn .btn-primary {
		min-width: 180px;
	}
	.register-profesion .avatar-group .avatar {
		width: 38px;
		height: 38px;
		border: 2px solid #fff;
	}
	.header-five .header-navbar-rht li .reg-btn {
		padding: 8px 15px;
		font-size: 14px;
		min-width: 170px;
	}
	.banner-blk-five {
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
	.home-five .banner-content .sub-btn {
		
		padding: 10px 6px;
	}
	.home-five .banner-content .sub-btn i {
		font-size: 21px;
	}
	.home-five .banner-content .sub-btn {
		margin: 6px;
	}
	.need-job-group .about-great-content h4 {
		font-size: 18px;
	}
	.project-item .feature-foot p {
		font-size: 14px;
	}
	.demand-professional h2 {
		font-size: 24px;
	}
	.demand-post-img {
		margin-right: 20px;
	}
	.join-market-world h2 {
		font-size: 22px;
		margin-bottom: 20px;
	}
	.join-market-world p {
		margin-bottom: 30px;
	}
	.join-market-world {
		padding-left: 45px;
	}
	.join-platform-img:before {
		background-size: 100%;
	}
	.compay-hire .compay-hire-blk {
		margin-bottom: 25px;
	}
	.img-reviews img {
		max-width: 170px;
	}
	.review-quote img {
		max-width: 45px;
	}
	.testimonial-two-head h3 {
		font-size: 20px;
	}
	.testimonial-two-head p {
		font-size: 14px;
	}
	.grid-blog .blog-title {
		font-size: 18px;
	}
	.great-find-job.job-next-blk h2 {
		font-size: 24px;
	}
	.job-next-blk p {
		font-size: 15px;
	}
	.blog-tricks-five {
		padding: 80px 0px 120px;
	}
	.opportunity-img {
		max-width: 500px;
	}
	.hire-company-list ul li {
		border: 1px solid #EBEBEB !important;
	}
	.talent-developer:before {
		display: none;
	}
	.whirly-loader {
		width: 80px;
		height: 80px;
	}
	.loader-img img {
		width: 40px;
	}
	.scroll-top {
		width: 40px;
		height: 40px;
	}
	.header-two .header-navbar-rht .login-btn {
		padding: 12px 10px;
	}
	.ongoing-projects .projects-card {
		margin-top: 15px;
	}
	.home-three .dot-slider .slick-list {
		height: 700px !important;
	}
	.header-three .header-navbar-rht .login-btn {
		padding: 7px 10px;
		font-size: 13px;
	}
	.header-three .main-nav > li {
		margin-right: 6px;
	}
	.header.header-three .input-group {
		margin-left: 5px;
	}
	.header-three .header-navbar-rht li a i {
		margin-right: 5px;
	}
	.header-navbar-rht li:first-child:after {
		display: none;
	}
	.header-three .header-navbar-rht li {
		margin-right: 5px;
	}
	.home-four-wrapper .main-nav > li {
		margin-right: 8px;
	}
	.home-four-wrapper .header-navbar-rht .login-btn {
		padding: 8px 7px;
	}
	.dashboard-page .header-navbar-rht .login-btn {
		padding: 7px 7px;
	}
	.dashboard-page .main-nav > li {
		margin-right: 5px;
	}
	.dashboard-page .header-navbar-rht li {
		margin-right: 5px;
	}
	.project-by-category .blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-prev {
		left: 0;
	}
	.project-by-category .blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-next {
		right: 0;
	}
}

@media only screen and (max-width: 991.98px) {
	h1, .h1 {
		font-size: 32px;
	}
	h2, .h2 {
		font-size: 28px;
	}
	h3, .h3 {
		font-size: 20px;
	}
	h4, .h4 {
		font-size: 18px;
	}
	h5, .h5 {
		font-size: 16px;
	}
	h6, .h6 {
		font-size: 15px;
	}
	.banner-content h1 {
		font-size: 30px;
		line-height: 39px;
	}
	.banner-content p {
		font-size: 19px;
	}	
	.courses-btn, .get-btn {
		font-size: 15px;
		padding: 14px 28px;
	}
	.container {
		max-width: 100%;
	}
	.feature-item {
		padding: 30px 10px;
	}
	.feature .feature-content h3 {
		font-size: 26px;
	}
	.account-page .account-content{
		border-radius: 10px;
	}
	.feature .feature-content p {
		font-size: 18px;
	}
	.myprofile-header h2 {
		font-size: 20px;
	}
	.bg1, .bg2 {
		background-size: 150px;
	}
	.setting-tab .nav-link {
		padding: 15px 20px;
		font-size: 16px;
	}
	.setting-content .pro-head h2 {
		font-size: 22px;
	}
	.work-content {
		width: 90%;
		padding: 40px;
	}
	.work-content .long-arrow {
		width: 50px;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}
	.breadcrumb-list h3 {
		font-size: 30px;
	}
	.work-content h2 {
		font-size: 26px;
	}
	.header-three .top-header{
		display: none ;
	}
	.dot-slider.slick-dotted.slick-slider {
		margin-bottom: 0px;
		display: none;
	}
	.banner-kofe {
		position: relative;
		top: 0;
		-webkit-transform: translate(0, -50%);
		-ms-transform: translate(0, -50%);
		transform: translate(0, 0%);
		padding-top: 0;
	}
	.home-three {
		background-image: url(../img/slider/slider-01.jpg);
		padding: 100px 0 20px;
	}
	.blog-trick-content-main.blog-list-main {
		padding: 15px;
	}
	.work-content p, .section-header p  {
		font-size: 16px;
	}
	.all-btn {
		padding: 12px 24px;
		font-size: 16px;
	}	
	.opportunity{
		display: none;
	}
	.review-blog {
		padding: 20px;
	}
	.review-info h3 {
		font-size: 18px;
	}
	.review-blog .review-img img {
		width: 100px;
		height: 100px;
	}
	.header-select .input-group{
		display: none;
	}
	header.header.header-two {
		padding: 0;
	}
	.banner-two-img{
		display:none;
	}
	.home-banner.home-two {
		margin-top: 90px;
	}
	.review-blog .review-content p {
		font-size: 15px;
	}
	.home-count ul{
		justify-content: start;
	}
	.home-count ul li{
		margin-right: 15px;
	}
	.home-count ul li+li::before{
		display: none;
	}
	.review-bottom .review-img img {
		width: 50px;
		height: 50px;
	}
	.review-bottom .review-info h3 {
		font-size: 16px;
	}
	.news-content .entry-meta li.name i {
		font-size: 16px;
	}
	.news-content .entry-meta li {
		font-size: 14px;
	}
	.footer-bottom-logo a.menu-logo img,
	.footer-bottom-logo ul{
		margin-bottom: 12px;
	}
	.update-project,.platform,.feature-project,.feature-developer,.top-skill,
	.job-location{
		padding: 60px 0;
	}
	.header.sticky {
		position: relative;
	}
	.great-about:before{
		background: transparent;
	}
	.footer-bottom-logo{
		margin-bottom: 24px;
	}
	.footer-bottom-logo p {
		margin-bottom: 12px;
	}
	.service-item {
		padding: 25px;
	}
	.service-content h5 {
		font-size: 17px;
	}
	.social-icon ul li a {
		font-size: 13px;
	}
	.news-blog {
	    align-items: unset;
	}
	.news-blog .d-flex {
		display: block !important;
	}
	.news-content {
		margin-right: 0;
	}
	.news-content h2 {
		font-size: 16px;
	}
	.news-content p {
		font-size: 15px;
	}	
	.expert, .about, .job-counter {
		padding: 50px 0;
	}
	.about .about-img {
		text-align: center;
	}
	.agency-content {
		width: 100%;
		padding: 20px;
	}
	.agency-content h2 {
		font-size: 20px;
		margin-bottom: 8px;
		line-height: 24px;
	}
	.counter h2 {
		font-size: 28px;
	}
	.counter h4 {
		font-size: 18px;
	}
	.follow-posts {
		width: 280px;
	}
	.payment-detail h2 {
		font-size: 32px;
	}
	.profile-list li {
		width: calc(100%/3);
	}
	.profile-title {
		font-size: 28px;
	}
	.profile-right, .profile-preword {
		display: block;
	}
	.profile-right li {
		margin-right: 0;
	}
	.btn-list {
		margin-bottom: 7px; 
	}
	.home-page .header{
		padding:10px 0;
	}
	.bg-img{
		display: none;
	}
	.best-company ul li{
		width: 30%;
	}
	.work-set-image{
		display: none;
	}
	.payment-list label {
		font-size: 15px;
	}
	.click-btn {
		font-size: 16px;
		padding: 10px 35px;
	}
	.left-content h4 {
		font-size: 12px;
	}	
	#accordion .card-header {
		font-size: 17px;
	}
	.footer-user {
		top: 92px;
	}	
	.sidebar-overlay.opened {
		display: block;
	}
	.price-features {
		margin-top: 0;
	}
	.column-5 {
		width: calc(100%/3);
		margin-bottom: 20px;
	}
	.header.min-header {
		background-color: #fff4f0;
	}
	.main-nav {
		padding: 0;
		-ms-flex-direction: column;
		flex-direction: column;
		padding-left: 0;
		margin-bottom: 0;
		list-style: none;
	}
	.main-nav ul {
		background-color: #ffe7e1;
		display: none;
		list-style: none;
		margin: 0;
		padding-left: 0;
	}
	.main-nav > li {
		border-bottom: 1px solid #08173317;
		margin-left: 0;
		margin-right: 0;
	}
	.main-nav li + li {
		margin-left: 0;
	}
	.main-nav > li > a {
		line-height: 1.5;
		padding: 15px 20px !important;
		color: #263238 !important;
		font-size: 14px;
		font-weight: 500;
		background: transparent !important;
		border-radius: 0 !important;
	}
	.main-nav li a:hover {
		background: transparent;
		border-radius: 0;
		line-height: 1.5;
		margin: 0;
	}
	.main-nav > li > a > i {
		float: right;
		margin-top: 5px;
	}
	.main-nav > li .submenu li a {
		border-top: 0;
		color: #263238 ;
		padding: 10px 15px 10px 35px;
		font-size: 14px;
	}
	.main-nav > li .submenu ul li a {
		padding: 10px 15px 10px 45px;
	}
	.main-nav > li .submenu > li.has-submenu > a::after {
		content: "\f078";
	}
	.main-nav .has-submenu.active > a, .main-nav > li.active > a {
		color: #71d3a9 !important;
	}
	.main-nav .has-submenu.active .submenu li.active > a {
		color: #71d3a9;
	}
	.login-left {
		display: none;
	}
	.searchbar {
		display: none;
	}
	.main-menu-wrapper {
		order: 3;
		width: 260px;
		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		overflow-x: hidden;
		overflow-y: auto;
		z-index: 1060;
		transform: translateX(-260px);
		transition: all 0.4s;
		-moz-transition: all 0.4s;
		-webkit-transition: all 0.4s;
		-o-transition: all 0.4s;
		background-color: #ffffff;
	}
	.menu-header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.navbar-header {
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
	}
	#mobile_btn {
		display: inline-block;
		line-height: 0;
	}
	.main-nav > li.active > a:before {
		display: none;
	}
	.main-nav .log-select {
		display: none;
	}	
	.services, .projects, .subscribe, .developer, .review, .news {
		padding: 60px 0;
	}
	.section-text {
		margin-bottom: 40px;
	}
	.footer-top {
		padding: 60px 0 0 0;
	}	
	.footer-widget {
		margin-bottom: 25px;
	}	
	.transaction-table .head-title {
		font-size: 24px;
	}	
	.price-features .header-price h3 {
		font-size: 28px;
	}
	.change-btn, .plan-btn, .pay-btn {
		font-size: 16px;
	}	
	.table-borderless.table tbody tr td {
		font-size: 18px;
	}
	.stone-btn {
		font-size: 15px;
	    padding: 15px 25px;
	}
	.position-title h3 {
		margin-bottom: 10px !important;
	}
	.position-title .sort-by {
		float: left;
		padding: 0 15px 0 0;
	}
	.stats-content {
		display: block;
	}
	.stats-detail { 
		width: 100%;
		margin-bottom: 10px;
	}
	.chat-cont-left, .chat-cont-right {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		transition: left 0.3s ease-in-out 0s, right 0.3s ease-in-out 0s;
		width: 100%;
	}
	.chat-cont-left {
		border-right: 0;
	}
	.chat-cont-right {
		position: absolute;
		right: -100%;
		top: 0;
		opacity: 0;
		visibility: hidden;
	}
	.chat-cont-right .chat-header {
		justify-content: start;
		-webkit-justify-content: start;
		-ms-flex-pack: start;
	}
	.chat-cont-right .chat-header .back-user-list {
		display: block;
	}
	.chat-cont-right .chat-header .chat-options {
		margin-left: auto;
	}
	.chat-window.chat-slide .chat-cont-left {
		left: -106%;
	}
	.chat-window.chat-slide .chat-cont-right {
		right: 0;
		opacity: 1;
		visibility: visible;
	}
	.chat-footer .avatar {
		display: none;
	}
	.plan-details h4 {
		font-size: 24px;
	}
	.pro-head h2 {
		font-size: 18px;
	}
	.earn-info p {
		font-size: 15px;
	}
	.breadcrumb-bar .breadcrumb-title {
		font-size: 28px;
	}
	.projects-details {
		display: block;
	}
	.project-info {
		width: 100%;
	}
	.content-divider { 
		display: none;
	}
	.content-divider-1 { 
		display: none;
	}
	.projects-amount {
		width: 50%;
		text-align: left;
	}
	.projects-action {
		width: 50%;
		padding-left: 0;
		text-align: right !important;
	}
	.project-hire-info {
		display: flex;
		align-items: center;
		margin-top: 20px;
	}
	.bread-crumb-bar {
		padding-top: 64px;
	}
	.projects-btn {
		margin: 0 0 10px auto;
		margin: 0 0 10px auto;
	}
	.prj-proposal-count.hired {
		top: 0%;
		-ms-transform: translateY(0%);
		transform: translateY(0%);
	}
	.prj-proposal-count {
		top: 0;
		-ms-transform: translateY(0);
		transform: translateY(0);
	}
	.projects-card {
		margin-bottom: 15px;
	}
	.projects-btn {
		width: 120px;
	}
	.projects-btn.project {
		margin: 0 0 10px auto;
	}
	.projects-list h2 {
		font-size: 24px;
	}	
	.banner-content .sub-btn, .banner-content .form-control, .banner-content .select2-container .select2-selection--single .select2-selection__rendered {
		font-size: 15px;
	}
	.bookmark {
		margin-bottom: 0;
	}
	.chat-page .content {
		padding-top: 80px;
	}
	.dashboard-page .content {
		padding-top: 120px;
	}
	.dashboard-page .content-page {
		padding-top: 100px;
	}
	.dashboard-page .content > .container-fluid,
	.header-nav {
		padding-left: 15px;
    	padding-right: 15px;
	}
	.header-title {
		font-size: 24px;
	}
	.table-responsive {
		white-space: nowrap;
	}
	.project-company .projects-action .projects-btn {
		margin: 0 0 10px auto;
		margin: 0 0 10px auto;
	}
	.img-profile img {
		width: 100px;
		height: 100px;
	}
	.developer-box h2 {
		font-size: 30px;
		margin-bottom: 30px;
	}
	.developer-box h2 i {
		font-size: 28px;
	}
	.proposal-value h4 {
		font-size: 28px;
	}
	.favourites-btn.btn-primary, 
	.proposal-btn.btn-primary {
		font-size: 16px;
		min-height: 50px;
		min-width: 160px;
	}
	.feed-back-detail li {
		font-size: 13px;
	}
	.experiance-list {
		margin-bottom: 25px;
	}
	.technical-skill .badge-skills {
		margin-bottom: 10px;
	}
	.board-screen .progressbar {
		max-width: 100%;
		margin-left: 0px;
	}
	.progressbar li h5,
	.progress-info h5	{
		font-size: 14px;
	}
	.progress-info h5 {
		margin-bottom: 0;
	}
	.progressbar {
		justify-content: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.multi-step {
		width: 30px;
		height: 30px;
		margin-right: 10px;
		font-size: 12px;
	}
	.select-account {
		padding: 60px 0px;
	}
	.select-box {
		margin-bottom: 80px;
	}
	.multistep-form label {
		font-size: 15px !important;
	}
	.select-account .prev_btn {
		min-width: 135px;
		padding: 8px 15px;
		font-size: 16px;
		margin-right: 10px;
	}
	.select-account .btn-primary {
		min-width: 135px;
		padding: 7px 15px;
		font-size: 16px;
	}
	.btn-prev {
		margin-right: 0;
	}
	.new-addd .add-new,
	.new-addd .remove_row	{
		font-size: 12px;
	}
	.personal-info input::placeholder{
		font-size:13px;
	}
	.wallet-group {
		margin-bottom: 15px;
	}
	.onboard-head {
		padding: 0px 15px;
	}
	.select-account .personal-info .form-control {
		font-size: 13px;
	}
	.multistep-form label {
		font-size: 14px !important;
	}
	.onboard-head p {
		font-size: 15px;
	}
	.select-level {
		background-image: url(../../assets/img/icon/icon-19.png);
		background-position: 95%;
	}
	.wallet-rupee .form-control {
		border-color: #ffe6e1;
		height: 50px;
	}
	.wallet-rupee .dol-btn {
		width: 50px;
		height: 50px;
	}
	.work:after {
		display: none;
	}
	.bg1:before {
		width: 20px;
		top: 20px;
		left: 20px;
		height: 20px;
	}
	.want-works .work-content {
		width: 100%;
		padding: 40px;
	}
	.review-img {
		flex-shrink: 0;
		margin-right: 0px;
	}
	.review-info {
		flex-grow: 1;
	}
	.review-blog .review-img img {
		width: 60px;
		height: 60px;
		margin-right: 10px;
	}
	.owl-carousel .owl-item .review-blog .review-img img {
		width: 60px;
		height: 60px;
	}
	.register-job-blk {
		display: block;
	}
	.job-content-blk {
		margin-bottom: 15px;
	}
	.job-content-blk h1 {
		font-size: 28px;
	}
	.home-two {
		min-height: 490px;
	}
	.home-count ul li+li {
		margin-left: 10px;
		padding-left: 10px;
	}
	.home-count ul li {
		font-size: 20px;
	}
	.home-count ul li span {
		font-size: 15px;
	}
	.home-two .banner-content h1 {
		margin-bottom: 25px;
	}
	.home-count {
		margin-top: 35px;
	}
	.skill-head .nav-pills .nav-link {
		font-size: 20px;
	}
	.btn-cart {
		font-size: 14px;
	}
	.app-version-blk h2 {
		font-size: 22px;
	}
	.app-version-blk p {
		font-size: 15px;
	}
	.entry-meta li {
		margin-right: 0;
		font-size: 13px;
	}
	.grid-blog .blog-title {
		font-size: 18px;
	}
	.great-about {
		padding: 80px 0px 338px;
	}
	.home-count ul li .list-count {
		font-size: 15px;
	}
	.header-two .main-nav > li:last-child {
		display: none;
	}
	.owl-carousel .owl-item .project-img img {
		width: 100%;
	}
	.owl-carousel .owl-item .hour-dollr img {
		width: inherit;
	}
	.owl-carousel.trend-slider .owl-nav,
	.owl-carousel.trend-slider .owl-dots {
		min-width: 110px;
	}
	.dot-slider .slick-list,
	.dot-slider.slick-vertical .slick-slide	{
		height: 334px !important;
	}
	.home-four .banner-content h5 {
		font-size: 16px;
	}
	.home-four .banner-content h1 {
		font-size: 30px;
	}
	.market-place-group h2 {
		font-size: 24px;
	}
	.coplete-job ,.fullstack-blk,.markrt-place-img,.faq-imgs,.register-profesion,.banner-float-img{
		display: none;
	}
	.home-four {
		min-height: 500px;
	}
	.browse-categories{
		padding: 60px 0;
	}
	.view-locations .btn-primary {
		min-width: 175px;
	}
	.popular-slider.developers-slider .owl-nav {
		right: 0;
	}
	.platform-location .market-place-group {
		padding: 30px 0px;
	}
	.market-place-btn.platform-btn .btn-primary {
		min-width: 165px;
	}
	.next-great-job .job-next-blk h2 {
		color: #2E2E2E;
		font-size: 24px;
		margin-bottom: 20px;
	}
	.job-next-blk p {
		font-size: 15px;
	}
	.banner-blk-five .banner-content .form-control {
		height: 54px;
	}
	.header-navbar-rht .login-btn {
		padding: 6px 25px;
		font-size: 14px;
	}
	.header-five .header-navbar-rht li .reg-btn {
		padding: 6px 15px;
		min-width: 160px;
	}
	.section-header-five:before {
		left: 48%;
	}
	.need-job-group {
		flex-wrap: wrap;
	}
	.need-job-group .about-it-item {
		width: 50%;
		border: 0;
	}
	.recent-pro-img {
		margin-bottom: 20px;
	}
	.recent-pro-img img {
		width: 100%;
	}
	.talent-foot-list ul {
		justify-content: space-evenly;
		-webkit-justify-content: space-evenly;
		-ms-flex-pack: space-evenly;
	}
	.img-reviews img {
		max-width: 130px;
	}
	.review-quote img {
		max-width: 40px;
	}
	.review-five .slick-prev,
	.review-five .slick-next {
		width: 35px;
		height: 35px;
	}
	.testimonial-two-head span {
		margin-bottom: 10px;
	}
	.review-five .slick-prev:before,
	.review-five .slick-next:before	{
		font-size: 14px;
	}
	.hire-company-list ul li img {
		max-width: 80px;
	}
	.hire-company-list ul li {
		width: 150px;
		min-height: 70px;
	}
	.bread-crumb-bar .inner-banner {
		padding: 30px 0;
	}
	.breadcrumb-list h2 {
		font-size: 30px;
	}
	.company-detail-image {
		width: 60px;
		height: 60px;
	}
	.company-title h4 {
		font-size: 20px;
	}
	.proposal-detail p {
		font-size: 16px;
	}
	.company-detail-title {
		font-size: 20px;
		margin-bottom: 15px;
	}
	.skill-custom-col {
		width: 30%;
	}
	.blog.blog-trick {
		margin-bottom: 24px;
	}
	.trusted-by-section ul{
		gap: 23px;
	}
	.float-img img {
		display: none;
	}
	.home-five-wrapper .job-content-blk {
		text-align: center;
	}
	.home-five-wrapper .footer-widget a img {
		max-width: 200px;
	}
	.home-four-wrapper .header-navbar-rht li::after {
		display: none;
	}
	.bg2,.bg1 {
		background-image: none;
	}
}
@media only screen and (max-width:767.98px) {
	body {
		font-size: 14px;
	}		
	h1, .h1 {
		font-size: 28px;
	}
	h2, .h2 {
		font-size: 26px;
	}	
	h3, .h3 {
		font-size: 20px;
	}	
	h4, .h4 {
		font-size: 18px;
	}
	h5, .h5 {
		font-size: 15px;
	}	
	h6, .h6 {
		font-size: 16px;
	}	
	.banner-content {
		padding: 80px 0;
	}
	.banner-content h1 {
		font-size: 23px;
		line-height: 29px;
	}
	.banner-content p {
		font-size: 18px;
		line-height: 20px;
	}	
	.banner-img {
		display: none;
	}
	.courses-btn, .get-btn {
		font-size: 14px;
		padding: 12px 24px;
	}
	.work-content {
		width: 100%;
		padding: 0;
	}	
	.bg1 .work-content ,.bg2 .work-content {
		background-image: none;
	}
	.bg1, .bg2 {
		background-size: 150px;
	}
	.digit-group input {
		width: 30px;
		height: 30px;
	}
	.work-content .long-arrow {
		width: 50px;
		height: 50px;
		line-height: 50px;
		font-size: 20px;
	}
	.work-content h2 {
		font-size: 25px;
	}
	.work-content p {
		font-size: 22px;
	}
	.services {
		padding: 60px 0;
	}
	.projects {
		padding: 60px 0;
	}
	.all-btn {
		padding: 12px 26px;
		font-size: 14px;
	}
	.news-blog {
		margin-bottom: 20px;
	}
	.client-img {
		margin-bottom: 20px;
	}
	.social-icon ul li a {
		font-size: 12px;
		margin-right: 1px;
		line-height: 18px;
	}
	.about-content h2 {
		font-size: 24px;
	}
	.about {
		padding: 40px 0;
	}
	.about .about-img {
		text-align: center;
	}
	.agency-content {
		width: 100%;
		padding: 33px;
	}
	.job-counter .counter {
		margin-bottom: 20px;
	}
	.counter h2 {
		font-size: 26px;
	}
	.counter h4 {
		font-size: 16px;
	}
	.expert {
		padding: 40px 0;
	}	
	.profile-list .proj-title {
		font-size: 14px;
	}
	.provider-widget {
		-ms-flex-direction: column;
		flex-direction: column;
		text-align: center;
	}
	.pro-info-right {
		margin-left: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.pro-info-left {
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.profile-box .pro-info-right {
		margin-left: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.camera-bg {
		bottom: 0;
	}
	.bottom-text {
		position: relative;		
	}
	.profile-title {
		font-size: 18px;
	}
	.profile-preword, .profile-right {		
		text-align: center;
		display: block;
	}
	.profile-inf {
		text-align: center;
	}
	.profile-list li {
		width: calc(100%/3);
	}
	.provider-img {
		margin: 0 auto 20px;
	}
	.provider-action {
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
	.submit-section .submit-btn {
		padding: 12px 22px;
		font-size: 13px;
	}
	.management-widget .profile-btn {
		padding: 12px 0;
		font-size: 14px;
	}
	.pro-title, .billing .card-header p, .payment-list label, .transaction h5 {
		font-size: 16px;
	}
	.bookmark-btn, .share-icon, .click-btn {
		font-size: 17px;
	}
	.profile-list li {
		font-size: 15px;
		padding: 15px 0px;
	}
	.payment-detail {
		padding: 20px;
	}
	.payment-detail h2 {
		font-size: 25px;
	}
	.payment-detail p {
		font-size: 15px;
	}	
	#accordion .card-header {
		font-size: 16px;
	}
	.footer-top {
		padding: 50px 0 40px 0;
	}
	.footer-user {
		top: 0;
	}
	.job-category p {
		font-size: 15px;
	}
	.login-header h3 {
		font-size: 21px;
	}
	.login-btn {
		font-size: 17px;
	}
	.settings-widget {
		margin-bottom: 25px;
	}
	.earn-info {
		margin-bottom: 15px;
	}
	.column-5 {
		width: calc(100%/2);
	}
	.news-content .entry-meta li.name img {
		width: 30px;
		height: 30px;
	}
	.nav-tabs {
		border-bottom: 0;
		position: relative;
		background-color: #fff;
		padding: 5px 0;
		border: 1px solid #ddd;
		border-radius: 3px;
	}
	.nav-tabs.nav-justified {
		border-bottom: 1px solid #ddd;
	}
	.user-tabs .nav-tabs.nav-tabs-bottom > li > a.active, .user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:hover, .user-tabs .nav-tabs.nav-tabs-bottom > li > a.active:focus {
		border-left-width: 2px;
		color: #71D3A9;
	}
	.nav-tabs.nav-tabs-bottom > li > a.active, .nav-tabs.nav-tabs-bottom > li > a.active:hover, .nav-tabs.nav-tabs-bottom > li > a.active:focus {
		background-color: #71d3a9 ;
	}
	.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs > li > a.active, .nav-tabs > li > a.active:hover, .nav-tabs > li > a.active:focus {
		border-color: transparent transparent transparent #E65425 !important;
		border-left-width: 2px;
	}
	.user-tabs .nav-tabs .nav-item, .provider-tabs .nav-tabs .nav-item {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
	}
	.nav-tabs .nav-item {
		margin-bottom: 0;
	}
	.copyright-text {
	    padding-bottom: 5px;
	}
	.right-text, .center-text {
		text-align: left;
	}
	.left-content h4 {
		font-size: 13px;
	}
	.transaction-table .head-title {
		font-size: 22px;
	}
	.plan-top i {
		font-size: 30px;
	}
	.change-btn, .plan-btn {
		font-size: 18px;
	}
	.price-features .header-price h3 {
		font-size: 26px;
	}
	.price-features li {
		font-size: 15px;
	}
	.stone-btn {
		font-size: 14px;
		padding: 12px 14px;
	}
	.transaction-table .sort-title,  .sort-title {
		font-size: 14px;
	}
	.transaction-table .sortby-fliter {
		width: 110px;
	}
	.wallet, .payment-process, .wallet-detail {
		margin-bottom: 0;
	}
	.profit-title h3 {
		font-size: 24px;
		margin-bottom: 15px;
	}
	.profit-title {
		 padding: 15px 20px;
	}	
	.stats-detail { 
		width: 50%;
	}
	.stats-content {
		display: flex;
	}
	.table-borderless.table tbody tr td, .portfolio-detail .pro-name {
		font-size: 16px;
	}
	.finance-statement {
		margin-bottom: 25px;
		height: auto;
	}
	.profit-right .sort-by {
		margin-top: 10px;
	}
	.user-tabs ul li + li {
		margin-left: 0;
	}
	.projects-list h2, .page-title h3, .earn-feature .price, .freelance-title h3  {
		font-size: 22px;
	}
	.prolist-details p, .prolist-details h5 {
		font-size: 15px;
	}
	.project-table h3 {
		font-size: 20px;
	}
	.yr-amt, .freelance-title p {
		font-size: 15px;
	}
	.plan-details h3, .member-detail h3 {
		font-size: 25px;
	}
	.package-feature ul li {
		font-size: 14px;
	}
	.package-detail .package-price, .member-detail h4 {
		font-size: 24px;
	}
	.port-title h3, .modal-title {
		font-size: 18px;
	}
	.modal label {
		font-size: 15px;
	}
	.header-navbar-rht li {
		margin-right: 20px;
	}
	.inner-banner {
		padding: 30px 0;
	}
	.transaction-shortby .sort-show  {
		padding: 5px;
	}
	.projects-action .projects-btn ~ .projects-btn {
		margin: 10px 0 0;
	}
	.header-title {
		font-size: 20px;
	}
	.subscribe h3 {
		font-size: 28px;
	}
	#mobile_btn {
		line-height: 0;
	}
	.discount-box .btn-primary {
		margin: 0;
	}
	.banner-content.discount-box {
		padding: 0;
	}
	.about-list ul {
		display: block;
	}
	.about-list ul li {
		padding: 0;
		border: 0;
		margin-bottom: 5px;
	}
	.profile-main h2 {
		font-size: 20px;
	}
	.abouts-view ul li .active-about:before {
		display: none;
	}
	.profile-baner {
		padding: 40px 0px;
	}
	.provider-tabs.abouts-view .nav-tabs.nav-tabs-solid > li > a p {
		font-size: 15px;
	}
	.social-link-profile {
		flex-wrap: wrap;
	}
	.develop-social-link.widget-box {
		padding-bottom: 5px;
	}
	.social-link-profile li {
		margin-bottom: 15px;
	}
	.social-link-profile li a i {
		width: 40px;
		height: 40px;
	}
	.location-widget .pro-title {
		margin-bottom: 25px;
	}
	.abouts-detail .pro-content {
		padding: 5px 0 0;
	}
	.company-profile .pro-content {
		padding: 10px 0 0;
	}
	.project-company .projects-action .projects-btn {
		margin-bottom: 10px;
	}
	.company-gallery .pro-image img {
		width: 100%;
	}
	.company-post .pro-title {
		font-size: 18px;
		margin-bottom: 20px;
	}
	.tab-reviews {
		flex-wrap: wrap;
	}
	.tab-reviews .rating {
		margin-top: 15px;
	}
	.company-post.widget-box {
		padding: 20px;
	}
	.post-btn .more-btn {
		font-size: 20px;
		padding: 6px 15px;
	}
	.profile-head {
		padding: 20px 20px 6px 20px;
	}
	.company-profile .widget-box .latest-posts li {
		padding: 13px 20px 13px;
	}
	.map-location {
		padding: 0px 20px;
	}
	.social-link-profile {
		padding: 20px;
		padding-top: 10px;
		margin: 0;
	}
	.contact-btn {
		margin-top: 0px;
		padding: 20px;
	}
	.contact-btn .btn-primary {
		padding: 8px 15px;
		font-size: 18px;
	}
	.project-company .projects-action .projects-btn {
		margin: 10px 0 10px;
	}
	.post-btn .more-btn {
		min-width: 160px;
	}
	.company-review .review-content p {
		font-size: 14px;
	}
	.img-profile img {
		width: 60px;
		height: 60px;
		top: 16px;
		right: 25px;
		border: 2px solid #E0E0E0
	}
	.developer-box h2 {
		font-size: 24px;
		margin-bottom: 20px;
	}
	.developer-box h2 i {
		font-size: 22px;
		margin-left: 0px;
	}
	.develope-list-rate {
		flex-wrap: wrap;
		display: block;
	}
	.develope-list-rate li {
		padding-left: 0;
		margin-bottom: 10px;
		border: 0;
	}
	.list-book-mark .verified {
		width: 12px;
		height: 12px;
		right: 0;
	}
	.list-book-mark .verified i {
		font-size: 12px;
	}
	.project-rate {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.project-details-view .pro-post-head h6 {
		font-size: 16px;
	}
	.project-details-view .job-type,
	.requirement-img {
		margin-bottom: 15px;
	}
	.wallet-rupee .modal-body {
		padding: 20px;
	}
	.wallet-rupee .dol-btn {
		width: 58px;
		height: 48px;
	}
	.wallet-rupee .form-control {
		height: 48px;
	}
	.wallet-rupee .badge-skills {
		padding: 8px 28px;
	}
	.wallet-rupee .custom_radio .checkmark {
		height: 20px;
		width: 20px;
	}
	.wallet-rupee .custom_radio .checkmark:after {
		top: 3px;
		left: 3px;
		width: 12px;
		height: 12px;
	}
	.proposal-value h4 {
		font-size: 18px;
	}
	.proposal-value span {
		font-size: 12px;
	}
	.proposal-value {
		font-size: 14px;
		margin-bottom: 0px;
	}
	.feed-back-detail {
		flex-wrap: wrap;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}
	.feed-back-detail li {
		margin-bottom: 10px;
		font-size: 13px;
	}
	.watch-list li {
		font-size: 12px;
	}
	.favourites-btn.btn-primary {
		min-width: 80px;
		margin: 0px 10px;
	}
	.proposal-box {
		flex-wrap: wrap;
	}
	.proposal-btn.btn-primary {
		min-width: 120px;
	}
	.favourites-btn.btn-primary, 
	.proposal-btn.btn-primary {
		min-height: auto;
		font-size: 14px;
		padding: 5px 24px;
	}
	.modal {
		z-index: 999999;
	}
	.technical-skill .badge-skills {
		margin-bottom: 10px;
	}
	.board-screen .progressbar {
		max-width: 100%;
	}
	.board-screen .multistep-progress {
		height: auto;
		text-align: center;
	}
	.multi-step {
		width: 30px;
		height: 30px;
	}
	.progressbar {
		justify-content: space-evenly;
	}
	.progressbar li {
		margin-bottom: 10px;
		width: 100%;
	}
	.progressbar li::after{
		display: none;
	}
	.back-home h2{
		font-size: 20px;
	}
	.board-logo{
		width: 100px;
	}
	.select-account .pro-form-img .profile-pic{
		min-width: 100px;
	}
	.steps-count {
		display: block;
		text-align: left;
	}
	.board-logo {
		margin: auto;
	}
	.progress-info {
		width: 100%;
	}
	.employee-level {
		width: 140px;
		height: 140px;
	}
	.onboard-head a {
		width: 140px;
		height: 140px;
		padding: 15px;
	}
	.employee-level img {
		margin-bottom: 6px;
	}
	.free-lance-img {
		margin-right: 15px;
	}
	.select-account .btn-primary {
		min-width: 110px;
		padding: 7px 15px;
		font-size: 15px;
	}
	.select-account .prev_btn {
		min-width: 110px;
		padding: 8px 15px;
		font-size: 15px;
		margin-right: 10px;
	}
	.other-info-list ul li {
		width: 30px;
		height: 30px;
		background: #E65425;
		border-radius: 50%;
		font-size: 10px;
		margin-right: 8px;
	}
	.first-progress, .second-progress {
		display: block;
		height: 100%;
	}
	.onboard-head h2 {
		font-size: 22px;
	}
	.select-account {
		padding: 40px 0px;
	} 
	.select-box {
		margin-bottom: 60px;
	}
	.progressbar li h5 {
		font-size: 14px;
	}
	.onboard-head p {
		font-size: 14px;
	}
	.multistep-form label {
		font-size: 14px !important;
	}
	.new-addd {
		margin-bottom: 10px;
	}
	.select-account.select-btn {
		padding: 40px 0px 80px;
	}
	.personal-info {
		margin-bottom: 40px;
	}
	.back-home .btn-primary {
		background: #E65425;
		border-radius: 5px;
		color: #fff;
		font-size: 16px;
		min-width: 160px;
	}
	.board-screen .progressbar {
		margin: 10px 0px;
	}
	.personal-info input::placeholder{
		font-size:12px;
	}
	.new-addd .add-new,
	.select-level	{
		font-size: 12px;
	}
	.multistep-form .form-control {
		min-height: 40px;
		height: 40px;
	}
	.bread-crumb-bar {
		padding-top: 60px;
	}
	.bread-crumb-bar .breadcrumb-list .page-breadcrumb ol li,
	.bread-crumb-bar .breadcrumb-list .page-breadcrumb .breadcrumb-item + .breadcrumb-item:before 	{
		font-size: 12px;
	}
	.develop-list-select .list-grid {
		margin-left: 0;
	}
	.freelance-view {
		margin-bottom: 15px;
	}
	.list-develop-group .about-author,
	.list-develop-group .about-author .about-list-author {
		    display: block!important;
	}
	.about-list-author .about-author-img {
		position: relative;
	}
	.book-mark .about-list-author .about-author-img {
		margin-bottom: 10px;
	}
	.about-list-author .author-details {
		margin-left: 0;
	}
	.develop-list-pro {
		text-align: left;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		margin-top: 10px;
	}
	.develop-list-pro .btn-cart {
		min-width: auto;
	}
	.develop-list-pro .freelancers-price {
		margin: 0px 10px;
	}
	.develop-list-pro .freelancers-price {
		font-size: 18px;
	}
	.wallet-group {
		margin-bottom: 15px;
	}
	.total-depit {
		margin-bottom: 0;
	}
	.balance-total h2 {
		font-size: 18px;
	}
	.select-account .personal-info .form-control {
		font-size: 12px;
	}
	.account-onborad.complte-board {
		padding: 10px;
	}
	.back-home p {
		font-size: 14px;
	}
	.back-home img {
		max-width: 230px;
	}
	.personal-info {
		padding: 15px;
	}
	.onboard-head {
		padding: 0px 15px;
	}
	.select-level {
		background-image: url(../../assets/img/icon/icon-19.png);
		background-position: 95%;
	}
	.bg1:before {
		content: "";
		position: absolute;
		width: 20px;
		top: 15px;
		left: 9px;
		height: 20px;
		border-radius: 50%;
		background-color: #fff;
	}
	.want-works .work-content {
		width: 100%;
		padding: 40px;
	}
	.developers-slider .owl-nav {
		margin-bottom: 10px;
	}
	.register-job-blk {
		display: block;
	}
	.job-content-blk {
		margin-bottom: 15px;
	}
	.job-content-blk h1 {
		font-size: 20px;
	}
	.job-content-blk p {
		font-size: 15px;
	}
	.footer .input-group {
		flex-wrap: inherit;
	}
	.footer .banner-content .sub-btn {
		flex: inherit;
		width: auto;
	}
	.footer-bottom-nav {
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
	.copyright-text,
	.footer-bottom-logo	{
		text-align: left;
	}
	.app-version-blk h2 {
		font-size: 20px;
	}
	.app-version-blk p {
		font-size: 14px;
		margin-bottom: 20px;
	}
	.testimonial-section .owl-nav {
		margin-bottom: 10px;
	}
	.home-count ul li+li {
		margin-left: 0;
		padding-left: 0px;
		border: 0;
	}
	.home-count ul li {
		margin-right: 10px;
	}
	.home-count ul li {
		font-size: 20px;
	}
	.home-count ul li span {
		font-size: 15px;
	}
	.platform:before {
		display: none;
	}
	.feature-project-list li {
		font-weight: 500;
		font-size: 14px;
	}
	.blog-slider.owl-carousel .owl-nav button.owl-prev, 
	.blog-slider.owl-carousel .owl-nav button.owl-next {
		width: 30px;
		height: 30px;
		font-size: 11px;
		margin: 0;
	}
	.blog-slider.owl-carousel .owl-nav button.owl-prev {
		left: -10px;
	}
	.blog-slider.owl-carousel .owl-nav button.owl-next {
		top: 40%;
		right: -10px;
	}
	.footer .banner-content .sub-btn {
		line-height: 18px;
		padding: 6px 30px;
	}
	.about-position {
		position: relative;
		bottom: 0;
		padding: 80px 0px;
	}
	.great-about {
		padding: 80px 0px;
	}
	.about-it-blk {
		margin-bottom: 20px;
	}
	.home-three .banner-kofe {
		position: relative;
		transform: unset;
		top: 0;
	}
	.home-three .slick-prev, .home-three .slick-next,
	.dot-slider .slick-dots,
	.dot-slider.slick-dotted.slick-slider	{
		display: none !Important;
	}
	.home-three {
		background-image: url(../img/slider/slider-01.jpg);
	}
	.home-three .banner-content {
		padding: 80px 0;
	}
	.home-three .banner-content h1 {
		font-size: 26px;
	}
	.section-header p {
		font-size: 14px;
	}
	.section-locate {
		flex-wrap: wrap;
	}
	.section-locate p {
		margin-bottom: 10px;
	}
	.different-categories {
		padding: 60px 0px 35px;
	}
	.best-marketplace,
	.trend-projects,
	.review-people,
	.top-skill	{
		padding: 60px 0px;
	}
	.market-list-out li {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.market-place-btn .btn-primary,
	.market-place-btn .market-developer	{
		min-width: 130px;
		font-size: 14px;
	}
	.feature-count-head .header-title {
		text-align: center;
		font-size: 20px;
	}
	.owl-carousel.trend-slider .owl-nav {
		top: -36px;
		min-width: 160px;
	}
	.owl-carousel.trend-slider .owl-dots {
		top: -33px;
		min-width: 160px;
	}
	.review-love-list {
		display: block;
	}
	.review-profile-blk {
		margin-bottom: 10px;
	}
	.next-job-btn .btn-primary {
		font-size: 14px;
		min-width: 140px;
	}
	.footer-social-group .footer-bottom-nav,
	.footer-social-group .social-icon	{
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
	}
	.footer .banner-content .form-control {
		height: 35px;
		min-height: 35px;
	}
	.footer .banner-content .sub-btn {
		height: 28px;
		line-height: 0;
	}
	.footer .banner-content {
		padding:0;
	}
	.market-place-group {
		margin-bottom: 20px;
	}
	.job-next-blk h2 {
		font-size: 24px;
	}
	.fullstack-blk,
	.coplete-job {
		display: none;
	}
	.home-four .banner-content h1 {
		font-size: 26px;
	}
	.market-place-group h2 {
		font-size: 22px;
		margin-bottom: 15px;
	}
	.market-place-group p {
		font-size: 14px;
	}
	.popular-slider.developers-slider .owl-nav {
		margin-bottom: 20px;
	}
	.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-next, 
	.popular-slider.developers-slider.owl-carousel .owl-nav button.owl-prev {
		width: 35px;
		height: 35px;
	}
	.popular-list-box {
		flex-wrap: wrap;
	}
	.market-place-group {
		padding-bottom: 0px;
	}
	.popular-list-box ul {
		margin-bottom: 10px;
	}
	.popular-foot,
	.popular-list-box h4 {
		font-size: 14px;
	}
	.head-popular h4 {
		font-size: 16px;
	}
	.market-place-btn.platform-btn .btn-primary {
		min-width: 140px;
		margin-bottom: 15px;
	}
	.article-blog-content h4,
	.article-blog-content p	{
		font-size: 15px;
	}
	.article-blog-content span {
		font-size: 14px;
	}
	.footer.footer-four .banner-content .sub-btn {
		min-width: 135px;
		font-size: 12px;
		padding: 5px 15px;
	}
	.footer.footer-four .footer-menu ul li a {
		font-size: 14px;
	}
	.footer-four .free-customer h6 {
		font-size: 15px;
	}
	.register-profesion {
		position: relative;
		left: 0;
	}
	.markrt-place-img {
		margin-bottom: 20px;
	}
	.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-next, 
	.trust-slider.developers-slider.owl-carousel .owl-nav button.owl-prev {
		width: 38px;
		height: 38px;
	}
	.trust-slider.developers-slider .owl-nav {
		bottom: 58%;
		right: 0;
	}
	.need-job-group .about-it-item {
		width: 100%;
	}
	.demand-professional h2 {
		font-size: 20px;
	}
	.join-market-world {
		padding: 0px 15px 20px;
		width: 100%;
	}
	.market-place-btn.bidding-btn .btn-primary {
		min-width: 126px;
	}
	.market-place-btn .btn-primary {
		padding: 8px 0px;
		font-size: 14px;
	}
	.join-market-world h2 {
		font-size: 20px;
	}
	.market-place-btn.company-register-btn .market-project {
		min-width: 130px;
	}
	.market-place-btn.company-register-btn .project-post {
		min-width: 155px;
	}
	.hire-company-list ul li {
		width: 145px;
	}
	.img-reviews {
		max-width: 170px;
		margin: auto;
	}
	.testimonial-two-head h3 {
		font-size: 18px;
	}
	.testimonial-two-head {
		margin-top: 20px;
	}
	.review-five .slider-nav {
		width: 200px;
		margin: auto;
	}
	.review-five .testimonial-thumb img {
		width: 40px;
	}
	.review-five .slick-prev {
		left: 0;
	}
	.review-five .slick-next {
		right: 75%;
	}
	.blog-trick-five.blog-slider.owl-carousel .owl-nav button.owl-prev {
		left: 50%;
	}
	.great-find-job.job-next-blk h2 {
		font-size: 20px;
	}
	.opportunity-img {
		display: none;
	}
	.project-action::before {
		display: none;
	}
	.bank-selection {
		margin-bottom: 10px;
	}
	.company-splits {
		flex-direction: column;
	}
	.company-splits .company-split-price {
		width: 100%;
	}
	.header.header-three .input-group {
		display: none;
	}
	.header.header-three .navbar-header .navbar-brand {
		flex-shrink: unset;
	}
	.popular-projects-job {
		background-image: none;
	}
}
@media only screen and (max-width:575.98px) {		
	h1, .h1 {
		font-size: 26px;
	}
	h2, .h2 {
		font-size: 22px;
	}
	h3, .h3 {
		font-size: 18px;
	}
	h4, .h4 {
		font-size: 16px;
	}
	h5, .h5 {
		font-size: 15px;
	}
	h6, .h6 {
		font-size: 12px;
	}
	.section-header {
		margin-bottom: 35px;
	}
	.section-header p {
		font-size: 14px;
		line-height: 24px;
	}
	.section-header h5 {
		font-size: 18px;
	}
	.developer .section-header, .review  .section-header {
		margin-bottom: 55px;
	}
	.banner-content {
		padding: 50px 0;
	}
	.banner-content .rating .checked, .banner-content h5 {
		font-size: 12px;
	}
	.banner-content h1 {
		font-size: 26px;
		margin: 10px 0;
	}
	.banner-content p {
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 20px;
	}
	.feature .feature-content h3 {
		font-size: 22px;
	}
	.account-page .account-content{
		width: 100%;
	}
	.feature-item {
		margin-bottom: 25px;
	}
	.work-content .long-arrow {
		width: 40px;
		height: 40px;
		line-height: 40px;
		font-size: 19px;
	}
	.work-content h2 {
		font-size: 22px;
	}
	.work-content p {
		font-size: 16px;
	}
	.services, .projects, .subscribe, .developer, .review, .news {
		padding: 40px 0;
	}
	.all-btn {
		padding: 10px 22px;
		font-size: 12px;
	}
	.services-item .content-part h3 {
		font-size: 16px;
	}
	.services-item .content-part h4 {
		font-size: 15px;
	}
	.services-item .img-part img {
		height: auto;
	}
	.courses-btn, .get-btn {
		font-size: 13px;
		padding: 10px 20px;
	}
	.header-two .top-header {
		display: none;
	}
	.home-banner.home-two {
		margin-top: 50px;
		padding: 50px 0;
	}
	.home-two {
		background-image: url(../img/techBack.png);
	}
	.btn-view {
		font-size: 16px;
	}
	.social-icon ul li a {
		font-size: 12px;
	}
	.breadcrumb-bar .breadcrumb-title {
		font-size: 25px;
	}
	.terms-text h4 {
		font-size: 20px;
	}
	.review-blog {
		padding: 25px;
	}
	.review-content h4 {
		font-size: 18px;
	}
	.header-nav {
		padding-left: 15px;
		padding-right: 15px;
	}
	.header{
		padding: 15px 0;
	}
	.header-navbar-rht {
		display: none;
	}
	.main-nav li.login-link {
		display: block;
	}
	.navbar-header {
		width: 100%;
	}
	.navbar-brand.logo {
		width: 100%;
		text-align: center;
		margin-right: 0;
	}
	#mobile_btn {
		margin-right: 0;
	}
	.profile-detail .clg .icon {
		margin-right: 7px;
	}
	.review-blog .review-img img {
		width: 80px;
		height: 80px;
	    margin-right: 9px;
	}
	.review-bottom .review-img img {
		width: 50px;
		height: 50px;
		margin-right: 5px;
	}
	.review-icon img {
		max-width: 42px;
	}
	.review-bottom .review-info h3 {
		font-size: 16px;
	}
	.review-info h3 {
		font-size: 18px;
	}
	.review-info h5 {
		font-size: 14px;
		margin-bottom: 5px;
	}
	.review-blog .review-content p {
		font-size: 15px;
	}
	.news .d-flex {
		display: block !important;
	}
	.news-content {
		margin-right: 0;
	}
	.news-content h2 {
		font-size: 16px;
	}
	.news-content p {
		font-size: 15px;
	}
	.client-img {
		margin-bottom: 30px;
	}
	.expert h2 {
		font-size: 22px;
	}
	.blog-view .blog-title {
		font-size: 18px;
	}
	.blog-comments .comments-list li img.avatar {
		border-radius: 40px;
		height: 40px;
		width: 40px;
	}
	.blog-comments .comments-list li {
		padding: 10px 0 0 50px;
	}
	.profile-list li {
		width: calc(100%/2);
	}
	.profile-list .proj-title {
	    font-size: 12px;
	}
	.pro-title, .billing .card-header p, .payment-list label, .transaction h5 {
		font-size: 15px;
	}
	.about-widget ul li p, .about-widget ul li h6, .latest-posts li a {
		font-size: 14px;
	}
	.left-content p, .pro-content .left-content p, .dev-right h6  {
		font-size: 10px;	
	}
	.management-widget .profile-btn {
		padding: 10px 0;
		font-size: 12px;
	}
	.follow-posts {
		width: 235px;
	}
	.client-list {
		padding: 10px 7px;
	}
	.pro-content p {
		font-size: 14px;
	}
	.client-list h6 {
		font-size: 20px;
	}
	.attach-detail p {
		font-size: 12px;
	}
	.about-author-img {
		width: 50px;
		height: 50px;
	}
	.author-details {
		margin-left: 65px;
	}
	.btn-list {
		margin-bottom: 7px;
	}
	.left .left-img::before {
		width: 15px;
		height: 15px;
		font-size: 9px;
	}
	.bookmark-btn, .share-icon, .click-btn {
		font-size: 15px;
	}
	.follow-posts li {
		border-right: 0;
	}
	.follow-btn {
		font-size: 15px;
	}
	.login-header h3 {
		font-size: 19px;
	}
	.login-btn {
		font-size: 16px;
	}
	.title h3 {
		font-size: 17px;
	}
	.next-btn, .cancel-btn {
		font-size: 13px;
		padding: 11px 50px;
	}
	.left-content h4 {
		font-size: 12px;
	}
	.submit-section .submit-btn {
		padding: 10px 20px;
		font-size: 12px;
	}
	.pro-widget-profile .dev-text .left.left-bid {
		 margin-top: -20px;
	}
	.bid-btn {
		font-size: 12px;
	}
	.column-5 {
		width: 100%;
	}
	.footer-top {
		padding: 50px 0 7px 0;
		margin-top: 20px;
	}
	.footer-five .footer-bottom-content {
		flex-direction: column;
		row-gap: 10px;
	}
	.position-title h3, .quick-title h3  {
		margin-bottom: 10px !important;
	}
	.align-right {
		text-align: left;
	}
	.profit-title h3, .stats-detail h3, .create-milestone h3, .pay-table .head-title {
		font-size: 22px;
	}
	.position-title .sort-by, .manage-tab .stone-btn {
		margin-bottom: 10px;
	}
	.manage-tab .btn-group {
		float: left;
	}
	.milestone-content .profile-group {
		width: auto;
	}
	.category-box .category-img {
		padding: 7px;
	}	
	.chat-cont-left .nav-link {
		font-size: 10px;
		padding: 8px;
	}
	.plan-details a, .pro-info  .list-details .slot {
		margin-bottom: 15px;
	}
	.banner-content .select2-container .select2-selection--single .select2-selection__rendered {
		font-size: 15px;
	}
	.banner-content .form-inner {
		border-radius: 5px;
		padding: 8px;
	}
	.banner-content .input-group-prepend, .drop-detail, .banner-content .input-group-append {
		width: 100%;
	}
	.banner-content .form-control {
		width: 100%;
		font-size: 15px;
	}
	.banner-content .sub-btn {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		height: 45px;
		font-size: 14px;
		width: 100%;
	}
	.banner-content .select2-container {
		border: 0;
	}
	.proposals-content {
		display: block;
	}
	.proposal-img {
		margin-bottom: 15px;
	}
	.proposal-client {
		margin-bottom: 15px;
	}
	.title-info {
		font-size: 14px;
	}
	.client-price {
		font-size: 22px;
	}
	.project-hire-info {
		display: block;
	}
	.proposer-info.project {
		width: 100%;
	}
	.projects-action.project {
		width: 100%;
		text-align: left !important;
	}
	.projects-btn.project {
		margin: 10px 0;
	}
	.proposal-client {
		margin-right: 0;
	}
	.amnt-type {
		padding-top: 2px;
		font-size: 13px;
	}
	.proposer-detail li {
		padding-left: 0;
		border-right: 0;
	}
	.project-info h2 {
		font-size: 18px;
	}
	.client-price {
		font-size: 18px;
	}
	.card-body {
		padding: 0.85rem;
	}
	.projects-btn {
		margin: 10px 0 0;
	}
	.completed-badge {
		margin-top: 10px;
	}
	.projects-action {
		text-align: left !important;
	}
	.proposal-delete {
		padding-top: 10px;
	}
	.page-title h3 {
		font-size: 22px;
		margin-bottom: 20px;
	}
	.card-top .dev-text {
		padding: 10px;
	}
	.proposals-pricing h4 {
		font-size: 20px;
	}
	.project-proposals-block,.proposals-user-detail {
		flex-direction: column;
	}
	.project-proposals-img {
		margin: auto;
	}
	.tags {
		row-gap: 14px;
		display: flex;
		flex-wrap: wrap;
	}
	.company-detail-title {
		font-size: 18px;
	}
	.company-detail-block {
		padding: 20px 0;
	}
	.company-detail {
		flex-direction: column;
	}
	.company-detail-image {
		margin-right: auto;
	}
	.company-address ul {
		flex-wrap: wrap;
	}
	.company-address {
		margin-bottom: 20px;
	}
	.project-proposal-detail li {
		width: 100%;
	}
	.price-btn {
		padding: 5px 20px;
		font-size: 14px;
	}
	.project-list-card {
		flex-direction: column;
	}
	.skill-custom-col {
		width: 100%;
	}
	.news .blog-trick-content-main .d-flex {
		display: flex !important;
		flex-wrap: wrap;
	}
	.blog-trick-content-main.blog-list-main {
		padding: 15px;
	}
	.entry-meta li {
		border-right: 0;
	}
	.faq-card h4 a {
		font-size: 15px;
	}
	.faq-title > a:after {
		right: 0;
	}	
	.reviewer-details-block {
		flex-direction: column;
	}
	.trusted-by-section ul{
		gap: 15px;
		justify-content: center;
	}
	.pending-proposal-profile .d-flex {
		flex-direction: column;
	}
	.payment-count {
		margin-top: 20px;
	}
	.pending-proposal-profile .user-img-box span i {
		border: 0;
	}
	.about-list-author .author-details {
		flex-wrap: wrap;
		row-gap: 20px;
	}
	.paginations li {
		padding-right: 5px;
	}
	.paginations li a {
		width: 30px;
		height: 30px;
	}
	.proposals-details .d-flex {
		flex-direction: column;
		row-gap: 20px;
	}
	.data-search-input {
		min-width: 200px;
	}
	.table-top-section {
		flex-direction: column;
		row-gap: 20px;
	}
	.change-plan {
		flex-direction: column;
		row-gap: 20px;
	}
	.change-plan-btn {
		flex-direction: column;
		row-gap: 20px;
	}
	.home-five-wrapper .job-content-blk h2 {
		font-size: 30px;
	}
	.next-job-btn {
		flex-direction: column;
	}
	.next-job-btn a {
		margin-right: 0 !important;
		margin-bottom: 15px;
	}
	.home-two-developers .client-rate {
		flex-direction: column;
		row-gap: 10px;
	}
	.header-select .navbar-brand {
		flex-shrink: unset;
	}
	.notification-set {
		flex-direction: column;
		align-items: flex-start;
		row-gap: 10px;
	}
	.notification-btn {
		flex-direction: column;
		row-gap: 10px;
	}
	.notification-btn ul {
		flex-direction: column;
		row-gap: 10px;
	}
}
@media only screen and (max-width:479px) {
	.card-top .dev-text {
		padding: 5px;
	}
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, 
	.chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width: 160px;
	}
	.section-features, .section-features, .section-specialities {
		background-color: #fff;
		padding: 30px 0;
	} 
	.login-header h3 a {
		color: #0de0fe;
		float: none;
		font-size: 15px;
		margin-top: 10px;
		text-align: center;
		display: block;
	}
	.login-header h3 {
		text-align: center;
	}
	.user-tabs .nav-tabs .nav-item {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
	}
	.fc-header-toolbar .fc-left {
		margin-bottom: 10px;
	}
	.fc-header-toolbar .fc-right {
		margin-bottom: 10px;
	}
	.pro-widget-profile .dev-text .left img {
		width: 40px;
	}
	.left-content h4 {
		font-size: 11px;
	}
	.pro-widget-profile .dev-text .left .left-content {
		margin-left: 45px;
	}
	.proposal-widget .custom_check {
		align-items: baseline;
	}
	.prolist-details li:first-child {
		margin-bottom: 10px;
	}
	.submit-section  .click-btn {
		margin-bottom: 5px;
	}
	.bid-btn {
		font-size: 10px;
	}	
	.star {		
		font-size: 10px;
		width: 25px;
		height: 25px;
		line-height: 25px;
	}
	.dev-right p {
		font-size: 13px;	
	}
	.submit-section .submit-btn + .submit-btn {
		margin-left: 8px;
	}
	.submit-section .submit-btn {
		min-width: 112px;
	}
	.pro-head {
		display: block;
	}
	.testimonial-two .owl-item.active {
		width: 296px !important;
	}
	.best-company ul li{
		width: 100%;
		margin-right: 0;
	}
}

.react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: 100% !important;
}
 .home-two.css-13cymwt-control{
	border: none !important;
}
.home-page .home-banner .css-13cymwt-control {
	border: 0;
}
/* .borderless .css-b62m3t-container{
	border: none !important;
} */
.review-four {
    background: #71d3b28c  !important;
    padding: 80px 0px;
    position: relative;
    background-repeat: no-repeat, no-repeat;
    background-position: left center;
}

.custom-header-cls.header.header-three .header-navbar-rht li a{
    color: #fff;
    font-weight: 600;
}
.custom-header-cls.header.header-three.sticky .header-navbar-rht li a{
    color: #2e2e2e;
    font-weight: 600;
}
/* .css-qbdosj-Input{
	border-radius: 5px;
	border-color: #E7E8EA;
    color: #353535;
    font-size: 15px;
    min-height: 40px;
    padding: 6px 15px;
    border-radius: 5px;
	border-color: 2px ;

} */
.ck-content .ck-editor__editable {
	min-height: 400px; /* Set your desired height here */
  }
  .edit-btn{
	margin-right: 35px;
  }
  .edit-btn:hover{
	color: white;
	background-color: #71D3A9;
	border-color:#71D3A9 ;
  }